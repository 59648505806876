import DOMPurify from "isomorphic-dompurify";
import { useLazyQuery } from "@apollo/client";
import Select from "react-select";
import { useOutletContext } from "react-router-dom";
import { GETCVRREPORT } from "../../../Graphql/Queries";
import { useCallback, useEffect, useState } from "react";

export const ClientCVRReport = ({
  client,
  setSearchParams,
  haveMultipleClients,
}) => {
  const [cvrLink, setCVRLink] = useState(null);
  const [getCVRReport] = useLazyQuery(GETCVRREPORT);

  const setClientCvr = useCallback(async () => {
    const { data, loading } = await getCVRReport({
      variables: {
        clientId: client,
      },
    });
    if (!loading && data) {
      setCVRLink({
        link: data?.getCVRReport?.reportLink,
        client: data?.getCVRReport?.client,
      });
    }
  }, [client]);

  // Run when clientID coming in params

  useEffect(() => {
    setClientCvr();
  }, [setClientCvr]);

  const Iframe = `
    <iframe
      src="${cvrLink?.link}"
      title="Looker Studio"
      width="100%"
      height="650px"
      sandbox="allow-scripts allow-same-origin"
    ></iframe>
  `;
  if (!client)
    return (
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <div className="cvr-report-wrapper">No Client Selected</div>
      </div>
    );
  return (
    <>
      <div className="cvr-title-wrapper">
        <h3 className="text-white pb-1">{cvrLink?.client?.name}</h3>
        {haveMultipleClients && (
          <button
            className="close-client"
            onClick={(_) => {
              setSearchParams({});
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#fff"
            >
              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
            </svg>{" "}
          </button>
        )}
      </div>
      {cvrLink?.link ? (
        <div
          className="cvr-iframe-container"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(Iframe, {
              ALLOWED_TAGS: ["iframe"],
            }),
          }}
        />
      ) : (
        <div className="cvr-report-wrapper">No CVR Report Found for Client</div>
      )}
    </>
  );
};
