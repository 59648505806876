import React from "react";
import NoDataFound from "../MiroComponents/NoDataFound/NoDataFound";

export const PreCalculatedValues = ({ precalculations }) => {
  if (!precalculations?.getPrecalculatedData)
    return (
      <NoDataFound
        title="No Precalculations found"
        description="
    No Precalculations data found for this creteria, try to change filters and date range.
  "
      />
    );
  return (
    <div>
      <div className="impression-table-heading">
        <h4>Pre Calculated Results</h4>{" "}
      </div>
      <div className="seq-values">
        {/* <p>
          Number of days:{" "}
          <span>{precalculations?.getPrecalculatedData?.days}</span>
        </p> */}
        <p>
          Number of Weeks :{" "}
          <span>{precalculations?.getPrecalculatedData?.weeks}</span>
        </p>
      </div>

      {precalculations?.getPrecalculatedData?.rows?.map((row, index) => (
        <React.Fragment key={index}>
          <div className="impressions" id="total">
            <div className="impression-data">
              <div className="impression-heading">
                <h4>Users</h4>
              </div>
              <span className="data-count">{row?.users}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Transactions</h4>
              </div>
              <span className="data-count">{row?.transactions}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>CR</h4>
              </div>
              <span className="data-count">{row?.cr?.toFixed(2)}%</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Purchasers</h4>
              </div>
              <span className="data-count">{row?.purchasers}</span>
            </div>
            <div className="impression-data">
              <div className="impression-heading">
                <h4>Revenue</h4>
              </div>
              <span className="data-count">{row?.revenue?.toFixed(2)}</span>
            </div>
          </div>
          <div
            className="impressions"
            id="total"
            style={{
              marginTop: "10px",
            }}
          >
            <div className="impression-data">
              <div className="impression-heading">
                <h4>Average AOV</h4>
              </div>
              <span className="data-count">{row?.avg_aov?.toFixed(2)}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>AVG RPU</h4>
              </div>
              <span className="data-count">{row?.avg_rpu?.toFixed(2)}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Std. Dev. AOV</h4>
              </div>
              <span className="data-count">{row?.stddev_aov?.toFixed(2)}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Std. Dev. RPU</h4>
              </div>
              <span className="data-count">{row?.stddev_rpu?.toFixed(2)}</span>
            </div>
          </div>
        </React.Fragment>
      ))}
      <pre
        style={{
          color: "#fff",
        }}
      >
        <code>{precalculations?.getPrecalculatedData?.query}</code>
      </pre>
    </div>
  );
};
