import MyRoutes from "../../Routes";
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { VscReport, VscAccount } from "react-icons/vsc";
import { Annnouncement, FaqIcon } from "./Icons";
import { NotAuthorized } from "./NotAuthorized";

export const SettingsSidebar = ({ is_admin }) => {
  if (!is_admin) return <NotAuthorized />;
  return (
    <>
      <ul
        className="dropdown-menu topbar-dropdown"
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <NavLink to={MyRoutes.users}>
            <span className="icon">
              <FaUser />
            </span>
            <span>Users</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={MyRoutes.clientsurveyreport}>
            <span className="icon">
              <VscReport />
            </span>
            <span>Client Survey Reports</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={MyRoutes.announcements}>
            <span className="icon">
              <Annnouncement />
            </span>
            <span>Announcements</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={MyRoutes.clientsaccesses}>
            <span className="icon">
              <VscAccount />
            </span>
            <span>Client Accesses</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={MyRoutes.faqs}>
            <span className="icon">
              <FaqIcon />
            </span>
            <span>Faqs</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={MyRoutes.appevents}>
            <span className="icon">
              <VscAccount />
            </span>
            <span>App Events</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
};
