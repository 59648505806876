import React from "react";
import "./ReportTable.scss";
export default function ReportTable({ total = [], controlVariantData = [] }) {
  if (total.length === 0 || controlVariantData.length === 0) return null;
  return (
    <div className="report-data-table oddevenpattern">
      <table>
        <tbody>
          <tr className="header">
            <td></td>
            <td>USERS</td>
            <td>SESSIONS</td>
            <td>TRANSACTIONS</td>
            <td>CR (SESSIONS)</td>

            <td>CR (USERS)</td>
            <td>AVG. RPU</td>

            <td>AOV</td>
            <td>REVENUE</td>
          </tr>
          {total.map((item, index) => (
            <tr className="data" key={index}>
              <td>{item?.controlvariant}</td>
              <td>{item?.totalUsers}</td>
              <td>{item?.totalSessions}</td>

              <td>{item?.totalTransactions}</td>
              <td>{item?.conversionRateSessions}</td>

              <td>{item?.conversionRateUsers}</td>
              <td>{item?.calculatedAverageRevenuePerUser} </td>

              <td>{item?.calculatedAveragePurchaseRevenue}</td>
              <td>{item?.totalPurchaseRevenue}</td>
            </tr>
          ))}

          {controlVariantData.map((item, index) => (
            <tr className="data" key={index}>
              <td>{item?.controlvariant}</td>
              <td>{item?.totalUsers}</td>
              <td>{item?.totalSessions}</td>

              <td>{item?.totalTransactions}</td>
              <td>{item?.conversionRateSessions}</td>

              <td>{item?.conversionRateUsers}</td>
              <td>{item?.calculatedAverageRevenuePerUser} </td>

              <td>{item?.calculatedAveragePurchaseRevenue}</td>
              <td>{item?.totalPurchaseRevenue}</td>
            </tr>
          ))}

          {/* <tr className="data">
            <td>Variation 1</td>
            <td>151970</td>
            <td>191759</td>

            <td>5603</td>
            <td>2.92%</td>

            <td>3.69%</td>
            <td>4.73</td>

            <td>128.21</td>
            <td>718,333.57</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
}
