import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AuthTopBar from "../MiroComponents/AuthTopBar";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../Layouts/NewStyle.css";
import "../Layouts/StoreAudit.css";
import { useMyProfile } from "../../Hooks/useMyProfile";
import { LoaderLogo } from "../Shared/LoaderLogo";
import { ANNOUNCEMENTS } from "../../Graphql/Queries";
import { useLazyQuery } from "@apollo/client";
import SideBarNew from "../MiroComponents/SideBarNew";
import useStore from "../../Store";

function AuthLayoutNew() {
  const navigate = useNavigate();
  const [announcement, setAnnouncement] = useState(null);
  const [showFullAnnouncement, setShowFullAnnouncement] = useState(false);
  const { myProfile, loading, error } = useMyProfile();
  const sideBar = useStore((state) => state.sideBar);
  const setSideBar = useStore((state) => state.setSideBar);
  const [announcements] = useLazyQuery(ANNOUNCEMENTS);

  const setSideBarCallBack = () => {
    setSideBar(!sideBar);
    // const wrapper = document.querySelector(".authlayout-wrapper");
    // if (wrapper.classList.contains("sidebar-is-open")) {
    //   wrapper.classList.remove("sidebar-is-open");
    // } else {
    //   wrapper.classList.add("sidebar-is-open");
    // }
  };
  useEffect(() => {
    if (!loading && myProfile) {
      (async () => {
        const { data } = await announcements({
          variables: {
            announcementFilters: [
              { active: true },
              {
                $or: [
                  {
                    forRoles: { $in: myProfile?.role?.map((rl) => rl?._id) },
                  },
                  {
                    forUsers: { $in: [myProfile?._id] },
                  },
                ],
              },
            ],
          },
        });
        if (data) {
          setAnnouncement(data?.announcements?.announcements);
        }
      })();
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      navigate("/auth/login", {
        state: {
          gobackto: window.location.href,
        },
      });
    }
  }, [error]);
  if (loading) return <LoaderLogo />;
  const is_admin = myProfile?.role?.some((rl) => rl?.name === "ADMIN");
  const is_client = myProfile?.role?.some((rl) => rl?.name === "CLIENT");
  const is_optimizer = myProfile?.role?.some((rl) => rl?.name === "OPTIMIZER");
  const is_user = myProfile?.role?.some((rl) => rl?.name === "USER");
  const is_editor = myProfile?.role?.some((rl) => rl?.name === "EDITOR");
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  // if (!is_admin && !is_optimizer && !is_user && !is_editor)
  //   return <NotAuthorized />;
  return (
    <div className="conversion_full_page">
      <div className={`authlayout-wrapper ${sideBar ? `sidebar-is-open` : ``}`}>
        <div className="authlayout-inner dark-bg">
          <div className={`sidebar`}>
            <SideBarNew
              myProfile={myProfile}
              setSideBar={setSideBarCallBack}
              vw={vw}
            />
          </div>
          <main>
            <AuthTopBar
              is_admin={is_admin}
              userId={myProfile?._id}
              setSideBar={setSideBarCallBack}
              myProfile={myProfile}
            />
            <div className="client-layout-content">
              {announcement && announcement?.length > 0 && (
                <div className="announcements-wrapper">
                  <div className="announcements-inner">
                    <div className="announcement-text">
                      <p>
                        <strong> Announcement: </strong>{" "}
                        {announcement[0]?.title}{" "}
                      </p>
                    </div>
                    <button
                      className="badge bg-dark text-white border-0"
                      onClick={(_) => {
                        setShowFullAnnouncement(!showFullAnnouncement);
                      }}
                    >
                      {showFullAnnouncement ? "Read Less" : "Read More"}
                    </button>
                  </div>
                  <div
                    className="announcement-full-text"
                    style={
                      showFullAnnouncement
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    {announcement[0]?.description}
                  </div>
                </div>
              )}

              {/* <CustomNavigator
              disableBackNavigate={location.state?.disableBackNavigate}
            /> */}
              <Outlet context={[myProfile, is_client, is_admin, is_user]} />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default AuthLayoutNew;
