import { useOutletContext, useSearchParams } from "react-router-dom";
import { returnClientOptionsNameId } from "../../../Libs/Utilities";
import { useEffect, useRef, useState } from "react";
import { ClientCVRReport } from "../Admin/ClientCVRReport";
import Select from "react-select";

export const ClientGetCvrReports = () => {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const clientDropDownRef = useRef(null);
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);

  const setLimitedClients = (clients) => {
    const clientsList = returnClientOptionsNameId(clients);
    if (clientsList?.length === 1) {
      clientDropDownRef.current.classList.add("hideclientselector");
      setSearchParams({ client: clientsList[0]?.value });
    }
    setClientSelectorOptions(clientsList);
  };

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.client = client?.value;
            setSearchParams(_params);
          }}
          value={options?.find((opt) => opt?.value === client)}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Name"
          options={options}
        />
      </div>
    );
  };

  useEffect(() => {
    if (is_client) {
      setLimitedClients(myProfile?.client);
    }
  }, [myProfile]);

  return (
    <div>
      <div className="client-data-wrapper">
        <div className="client-details" ref={clientDropDownRef}>
          <div className="client-dropdown">
            <h1 className="section-heading">Store:</h1>
            <ClientSelector options={clientSelectorOptions} />
          </div>
        </div>
      </div>
      {client && (
        <ClientCVRReport
          client={client}
          haveMultipleClients={clientSelectorOptions?.length > 1}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      )}
    </div>
  );
};
