import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import {
  CREATESEGMENTCOMBINATION,
  UPDATESEGMENTCOMBINATION,
} from "../../Graphql/Mutations";
import useStore from "../../Store";

export function AddEditSegments({ module, segmentObj, refetch }) {
  const { toggleForm } = useStore();
  const clients = useStore((state) => state.clients);
  const [help, setHelp] = useState(false);
  const [segmentClients, setSegmentClients] = useState(null);
  const [segmentType, setSegmentType] = useState("general");
  const [conditions, setConditions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [updatesegment] = useMutation(UPDATESEGMENTCOMBINATION);
  const [createsegment] = useMutation(CREATESEGMENTCOMBINATION);

  useEffect(() => {
    if (module === "edit") {
      setConditions(segmentObj?.combination);
      setFilters(segmentObj?.secondaryFilters);
      setSegmentType(segmentObj?.segmentType);
      if (segmentObj?.client) {
        setSegmentClients(
          segmentObj?.client?.map((_) => {
            return {
              label: _?.name,
              value: _?.value,
            };
          })
        );
      }
    }
  }, [segmentObj]);

  const updateSegment = async (e) => {
    e.preventDefault();
    const name = e.target.segmentname.value;
    if (module === "edit") {
      let segmentPayload = {};
      segmentPayload["name"] = name;
      segmentPayload["combination"] = conditions;
      segmentPayload["segmentType"] = segmentType;
      segmentPayload["client"] =
        segmentType === "clientspecific"
          ? segmentClients?.map((_) => _?.value)
          : null;
      let _filters = [];
      for await (const fl of filters) {
        let ob = {};
        ob.name = fl.name;
        ob.key = fl.key;
        ob.filters = fl.filters;
        _filters.push(ob);
      }
      segmentPayload["secondaryFilters"] = _filters;
      try {
        const { data } = await updatesegment({
          variables: {
            id: segmentObj?._id,
            segmentPayload,
          },
        });
        if (data) {
          // console.log(data);
          refetch();
          toggleForm(false);
        }
      } catch (error) {
        alert(error.message);
      }
    }
    if (module === "add") {
      let segmentPayload = {};
      segmentPayload["name"] = name;
      segmentPayload["combination"] = conditions;
      let _filters = [];
      for await (const fl of filters) {
        let ob = {};
        ob.name = fl.name;
        ob.key = fl.key;
        ob.filters = fl.filters;
        _filters.push(ob);
      }
      segmentPayload["secondaryFilters"] = _filters;
      try {
        const { data } = await createsegment({
          variables: {
            segmentPayload,
          },
        });
        if (data) {
          refetch();
          toggleForm(false);
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  return (
    <div className="form-wrapper">
      <div className="form-wrapper-inner">
        <div className="card">
          <button className="close-modal" onClick={(_) => toggleForm(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#000"
            >
              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
            </svg>{" "}
          </button>
          <div className="card-body p-0">
            <h3> Manage Segments</h3>
            {module && (
              <form onSubmit={(_) => updateSegment(_)}>
                <div className="fields-wrapper">
                  <label> Segment Name</label>
                  <input
                    className="field-control"
                    name="segmentname"
                    placeholder="Segment Name"
                    defaultValue={segmentObj?.name ?? ""}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Segment Type</label>
                  <select
                    className="field-control"
                    name="segmentType"
                    value={segmentType}
                    onChange={(e) => {
                      setSegmentType(e.currentTarget.value);
                    }}
                  >
                    <option value="general"> General</option>
                    <option value="clientspecific"> Client Specific</option>
                  </select>
                </div>
                {segmentType === "clientspecific" && (
                  <div className="fields-wrapper">
                    <label>Select Client(s)</label>
                    <Select
                      classNamePrefix="acc-select"
                      isMulti={true}
                      placeholder="Select Client(s)"
                      defaultValue={segmentClients}
                      onChange={(_) => setSegmentClients(_)}
                      options={clients?.map((clnts) => {
                        return {
                          label: clnts?.name,
                          value: clnts?._id,
                        };
                      })}
                    />
                  </div>
                )}

                <div className="d-flex align-items-center flex-wrap">
                  <div className="fields-wrapper w-50">
                    <label> Metric</label>
                    <input
                      className="field-control"
                      id="gametricname"
                      placeholder="Metric name.."
                    />
                  </div>
                  <div className="fields-wrapper w-50">
                    <label> Condition Value</label>
                    <input
                      className="field-control"
                      id="gametricvalue"
                      placeholder="Metric condition.."
                    />
                  </div>
                  <div className="fields-wrapper w-100">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark ms-auto d-block"
                      onClick={(_) => {
                        const _n =
                          document.querySelector("#gametricname").value;
                        const _v =
                          document.querySelector("#gametricvalue").value;
                        if (_n.trim() === "" || _v.trim() === "") {
                          return;
                        }
                        setConditions((_old) => [
                          ..._old,
                          {
                            [_n]: _v,
                          },
                        ]);
                        document.querySelector("#gametricname").value = "";
                        document.querySelector("#gametricvalue").value = "";
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div className="list-group mb-4">
                  {conditions.map((cnd, i) => {
                    const val = Object.entries(cnd);
                    return (
                      <a
                        key={i}
                        className="list-group-item list-group-item-action flex-column align-items-start"
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <span
                            className="rmve"
                            onClick={(_) => {
                              const _conditions = [...conditions];

                              const newItems = _conditions.filter(
                                (it) => Object.keys(it)[0] !== val[0][0]
                              );
                              setConditions(newItems);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              fill="#000"
                            >
                              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                            </svg>
                          </span>
                        </div>
                        <p className="mb-1">
                          {val[0][0]} = {val[0][1]}
                        </p>
                      </a>
                    );
                  })}
                </div>

                <div className="d-flex align-items-center flex-wrap">
                  <div className="fields-wrapper w-50">
                    <label> Name</label>
                    <input
                      id="gafiltername"
                      className="field-control"
                      placeholder="Dimension name.."
                    />
                  </div>

                  <div className="fields-wrapper w-50">
                    <label> Dimension </label>
                    <input
                      id="gafilterkey"
                      className="field-control"
                      placeholder="Dimension.."
                    />
                  </div>
                  <div className="fields-wrapper w-100">
                    <label> Allowed Filters </label>
                    <input
                      id="gafiltervalues"
                      className="field-control"
                      placeholder="Allowed Filters"
                    />
                  </div>
                  <div className="fields-wrapper w-100">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark ms-auto d-block"
                      onClick={(_) => {
                        const _n =
                          document.querySelector("#gafiltername").value;
                        const _k = document.querySelector("#gafilterkey").value;
                        const _v =
                          document.querySelector("#gafiltervalues").value;
                        if (
                          _n.trim() === "" ||
                          _k.trim() === "" ||
                          _v.trim() === ""
                        ) {
                          return;
                        }
                        let obj = {};
                        obj.name = _n;
                        obj.key = _k;
                        obj.filters = _v;
                        setFilters((_oldf) => [..._oldf, obj]);
                        document.querySelector("#gafiltername").value = "";
                        document.querySelector("#gafilterkey").value = "";
                        document.querySelector("#gafiltervalues").value = "";
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div className="list-group">
                  {filters.map((flt, i) => {
                    return (
                      <a
                        key={i}
                        className="list-group-item list-group-item-action flex-column align-items-start"
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <span
                            className="rmve"
                            onClick={(_) => {
                              const _filters = [...filters];
                              const newItems = _filters.filter(
                                (it) => it?.key !== flt?.key
                              );
                              setFilters(newItems);
                            }}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              fill="#000"
                            >
                              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                            </svg>
                          </span>
                          <h5 className="mb-1">
                            {flt?.name} <small> ({flt?.key})</small>
                          </h5>
                        </div>
                        <p className="mb-1">{flt?.filters}</p>
                      </a>
                    );
                  })}
                </div>

                <div className="fields-wrapper mt-2 mb-0">
                  <button type="submit"> UPDATE</button>
                </div>
                <div className="help-section">
                  <span onClick={(_) => setHelp(!help)}>
                    {" "}
                    Need Help ? Click Here
                  </span>
                  {help && (
                    <div className="help-section-inner">
                      <p className="border p-1">
                        - Browser (ga:browser) <br /> - Android -
                        Webview,Chrome,Edge,Firefox,Safari,Samsung
                        Internet,Safari (in-app)
                      </p>
                      <p className="border p-1">
                        - Operating System (ga:operatingSystem) <br />-
                        iOS,Android,Windows,Chrome OS
                      </p>
                      <p className="border p-1">
                        - User Type (ga:userType) <br /> - New Visitor,Returning
                        Visitor
                      </p>
                      <p className="border p-1">
                        - Source (ga:source) <br />- (direct), google, fb,
                        pinterest , Klaviyo , snapchat
                      </p>
                      <p className="border p-1">
                        - Device Category (ga:deviceCategory) <br />-
                        mobile,tablet,desktop
                      </p>
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
