import { VscLock } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found-wrapper">
      <div className="not-found-inner">
        <div className="not-found-box">
          <VscLock />
          <h2> Coming soon..</h2>
          <button
            className="btn btn-sm btn-warning mt-3"
            onClick={(_) => {
              navigate(-1);
            }}
          >
            {" "}
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};
