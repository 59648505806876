import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar } from "react-chartjs-2";
import { CondifenceOfUpliftOptions } from "../../../../Libs/Utilities";

export function CondifenceOfUplift({ calculatedResults, timeSeriesData }) {
  const labels = timeSeriesData?.datasets
    ?.map((item) => item?.controlvariant)
    .sort(
      (a, b) => (b === "Reference") - (a === "Reference") || a.localeCompare(b)
    );
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  // const labels = [
  //   "Control",
  //   ...calculatedResults?.map((item) => item?.controlvariant),
  // ];
  const colors = [
    "255 51 60",
    "86 122 250",
    "255 124 51",
    "249 194 1",
    "176 89 244",
    "34 193 195", // Light teal
    "123 237 159", // Mint green
    "234 91 159", // Soft pink
    "72 52 212", // Deep purple
    "52 73 94", // Dark slate gray
    "46 204 113", // Light green
    "41 128 185", // Rich blue
  ];

  const getGradient = (ctx, chartArea) => {
    let gradient2 = labels.map((elm, index) => {
      const gradient = ctx.createLinearGradient(0, 0, chartArea.width, 0);
      gradient.addColorStop(0, `rgba(${colors[index]}/20%)`);
      // gradient.addColorStop(0.5, `rgba(${colors[index]},0.5)`);
      gradient.addColorStop(1, `rgba(${colors[index]}/80%)`);
      return gradient;
    });
    return gradient2;
  };

  const data = calculatedResults?.reduce((accum, item, i) => {
    let confidence = [];
    if (i === 0) {
      confidence = [
        ...accum,
        ...[
          Number((100 - item?.confidenceOfSelection).toFixed(2)),
          Number(item?.confidenceOfSelection),
        ],
      ];
    } else {
      confidence = [...accum, ...[Number(item?.confidenceOfSelection)]];
    }
    // return item?.confidenceOf.toLowerCase().indexOf("variation") > -1
    //   ? confidence
    //   : confidence.reverse();
    if (item?.confidenceOf !== item?.audienceName) {
      return confidence.reverse();
    } else {
      return confidence;
    }
  }, []);
  const dataset = {
    labels,
    datasets: [
      {
        data,
        borderColor: "transparent",
        barPercentage: 0.6,
        borderRadius: 5,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea);
        },
      },
    ],
  };
  return (
    <div className="report-charts-height">
      <Bar options={CondifenceOfUpliftOptions} data={dataset} />
    </div>
  );
}
export const ConfidenceOfUpliftGa4 = React.memo(CondifenceOfUplift);
