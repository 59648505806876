import React, { useMemo, useRef, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { ListHeader } from "./ListHeader";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import Select from "react-select";
import { CREATEGOALS, UPDATEGOALS } from "../../../Graphql/Mutations";
import { GOALSPAGINATION } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../MiroComponents/Button/Button";
import useAbTestStore from "../../../AbTestStore";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import { GoalsTargetingForm } from "./GoalsTargetingForm";
import TargetDefination from "../TargetDefination/TargetDefination";
import { EnvironmentTargetingForm } from "./EnvironmentTargetingForm";

export const EnvironmentTargeting = () => {
  const environmentBoxRef = useRef(null);
  const { insights, updateEnvironments } = useAbTestStore();
  const [showForm, setShowForm] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const _params = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams]
  );

  const refreshPage = (data) => {
    if (data) {
      updateEnvironments(data);
    }
    setSearchParams({});
    setShowForm(false);
  };

  const EnvBox = React.forwardRef(({ goal }, ref) => {
    return (
      <section className={`big-margin-bottom`} ref={ref}>
        <TargetDefination
          targetSelected="environments"
          {...goal}
          allowEdit={true}
        />
      </section>
    );
  });

  return (
    <div>
      {(showForm || _params?.id) && (
        <>
          <EnvironmentTargetingForm
            editObject={insights?.environments?.find(
              (item) => item?._id === _params?.id
            )}
            refreshPage={refreshPage}
            id={_params?.id}
            showForm={showForm}
          />
        </>
      )}
      <div className="d-flex justify-content-between default-margin-bottom">
        <h1 className="margin-bottom-auto">Environments </h1>
        <div>
          <Button
            className="smart-button small active"
            onClick={() => setShowForm(!showForm)}
          >
            Add New Enviroment
          </Button>
        </div>
      </div>
      {insights?.environments?.length === 0 && (
        <NoDataFound
          title="No Env. Found"
          description="Add new environment to start testing."
        />
      )}
      {insights?.environments?.map((goal, index) => (
        <EnvBox goal={goal} ref={environmentBoxRef} key={index} />
      ))}
    </div>
  );
};
// export const EnvironmentTargeting = ({
//   type,
//   module,
//   formType,
//   currentData,
//   client,
//   setModule,
//   setCurrentData,
//   setFormType,
// }) => {
//   const [createEnvironment] = useMutation(CREATEENVIRONMENT);
//   const [updateEnvironment] = useMutation(UPDATEENVIRONMENT);

//   const { data, loading, error, refetch } = useQuery(GETENVIRONMENTS, {
//     variables: {
//       filters: [
//         {
//           client: client,
//         },
//       ],
//     },
//   });
//   if (loading) return <LoaderLogo />;
//   if (error) return <div>Error: {error.message}</div>;
//   return (
//     <>
//       {type === "form" ? (
//         <form
//           onSubmit={async (e) => {
//             e.preventDefault();
//             const { name, rules_js } = e.target;
//             const environmentPayload = {
//               client: client,
//               name: name.value,
//               rules_js: rules_js.value,
//             };
//             if (formType === "add") {
//               await createEnvironment({
//                 variables: {
//                   environmentPayload,
//                 },
//               });
//               toast.success("Environment Added Successfully");
//             } else {
//               await updateEnvironment({
//                 variables: {
//                   id: currentData?._id,
//                   environmentPayload,
//                 },
//               });
//               toast.success("Environment Edited Successfully");
//             }
//             refetch();
//             setModule(null);
//           }}
//         >
//           <div className="fields-wrapper fields-wrapper__updated">
//             <div>
//               <label>Name</label>
//               <input
//                 required
//                 name="name"
//                 className="field-control  field-control__updated"
//                 placeholder=""
//                 defaultValue={currentData ? currentData.name : ""}
//               />
//             </div>
//           </div>
//           <div className="fields-wrapper fields-wrapper__updated">
//             <label>Rules js</label>
//             <textarea
//               name="rules_js"
//               className="field-control  field-control__updated"
//               placeholder=""
//               defaultValue={currentData ? currentData.rules_js : "return true;"}
//             />
//           </div>
//           <div className="add__new__test__form-footer">
//             <button className="btn next-btn" type="submit">
//               {formType === "add" ? `Add ${module}` : `Edit ${module}`}
//             </button>
//           </div>
//         </form>
//       ) : (
//         <>
//           <ListHeader header={ENVIRONMENT_HEADER} />
//           <div>
//             {data?.getEnvironments?.environments?.map((row, idx) => (
//               <div
//                 key={`${row.name}-${idx}`}
//                 className="test-details-table-data"
//               >
//                 <div className="test-name text-break">
//                   <h3>{row?.name}</h3>
//                 </div>
//                 <div className="test-name text-break">
//                   <h3>{row?.rules_js}</h3>
//                 </div>
//                 <div className="test-name text-break">
//                   <FaEdit
//                     onClick={() => {
//                       setModule("Environment");
//                       setCurrentData(row);
//                       setFormType("edit");
//                     }}
//                     style={{ cursor: "pointer", color: "gray" }}
//                   />
//                 </div>
//               </div>
//             ))}
//           </div>
//         </>
//       )}
//     </>
//   );
// };
