import { useMutation } from "@apollo/client";
import { CREATEGOALS, UPDATEGOALS } from "../../../Graphql/Mutations";
import useStore from "../../../Store";
import Input from "../../MiroComponents/Input/Input";
import SelectComponent from "../../MiroComponents/SelectComponent/SelectComponent";
import _, { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../MiroComponents/Button/Button";
import { toast } from "react-toastify";

const defaultState = {
  name: "",
  details: "",
  type: null,
  counting_method: null,
  css_selector: "",
  rules_js: "",
  loading: true,
};
const GOAL_TYPES = [
  { value: "custom", label: "Custom" },
  { value: "pageview", label: "Page view" },
  { value: "click", label: "Click" },
  { value: "revenue", label: "Revenue" },
];
const GOAL_COUNTING_METHODS = [
  { value: "one", label: "One" },
  { value: "every", label: "Every" },
];

export const GoalsTargetingForm = ({
  editObject,
  refreshPage,
  id,
  showForm,
}) => {
  const selectedClient = useStore((state) => state.selectedClient);
  const [otherData, setOtherData] = useState(defaultState);
  const [createGoals] = useMutation(CREATEGOALS);
  const [updateGoals] = useMutation(UPDATEGOALS);
  const updateState = debounce((key, value) => {
    setOtherData({ ...otherData, [key]: value });
  }, 1000);

  useEffect(() => {
    if (editObject) {
      setOtherData({
        ..._.omit(editObject, ["__typename", "client", "api_name"]),
        type: GOAL_TYPES.find((item) => item.value === editObject?.type),
        counting_method: GOAL_COUNTING_METHODS.find(
          (item) => item.value === editObject?.counting_method
        ),
        loading: false,
      });
    }
    if (showForm) {
      setOtherData({
        ...defaultState,
        loading: false,
      });
    }
  }, [editObject, id, showForm]);
  return (
    <div>
      <form
        className="default-margin-bottom default-padding default-border bg-1"
        onSubmit={async (e) => {
          e.preventDefault();
          if (
            !otherData?.name ||
            !otherData?.rules_js ||
            !otherData?.type ||
            !otherData?.counting_method
          ) {
            return toast.error("Please fill all the fields");
          }

          const goalPayload = {
            ..._.omit(otherData, ["loading", "api_name", "_id"]),
            client: selectedClient?.value?._id,
            counting_method: otherData.counting_method.value,
            type: otherData.type.value,
          };
          //   console.log("goalPayload", goalPayload);
          if (editObject && editObject?._id) {
            const { data } = await updateGoals({
              variables: {
                id: editObject?._id,
                goalPayload,
              },
            });
            refreshPage(data?.updateGoal);
          } else {
            const { data } = await createGoals({
              variables: {
                goalPayload,
              },
            });
            refreshPage(data?.createGoal);
          }
          toast.success("Data Updated Successfully");
        }}
      >
        <Input
          type={"text"}
          value={otherData?.name || ""}
          inputWrapperClass="full-width medium bg-2"
          label="Goal Name"
          placeholder={"Enter Name"}
          name="name"
          onChange={(e) => {
            updateState("name", e.target.value);
          }}
        />

        <Input
          type={"text"}
          value={otherData?.details || ""}
          inputWrapperClass="full-width medium bg-2"
          label="Details"
          placeholder={"Goal Description"}
          name="details"
          onChange={(e) => {
            updateState("details", e.target.value);
          }}
        />

        <SelectComponent
          options={GOAL_TYPES}
          label="Type"
          value={otherData.type}
          placeholder="Select Goal Type"
          icon={""}
          selectWrapperClass=""
          customClass="default-padding default-border big-margin-bottom bg-2"
          containerClass="full-width"
          onChange={(selected) => {
            // updateState("type", selected);
            setOtherData({ ...otherData, type: selected });
          }}
        />
        <SelectComponent
          options={GOAL_COUNTING_METHODS}
          label="Counting Method"
          value={otherData.counting_method}
          placeholder="Counting Method"
          icon={""}
          selectWrapperClass=""
          customClass="default-padding default-border big-margin-bottom bg-2"
          containerClass="full-width"
          onChange={(selected) => {
            // updateState("counting_method", selected);
            setOtherData({ ...otherData, counting_method: selected });
          }}
        />
        <Input
          type={"text"}
          value={otherData?.css_selector || ""}
          inputWrapperClass="full-width medium bg-2"
          label="CSS Selector"
          placeholder={"CSS Selector"}
          name="css_selector"
          onChange={(e) => {
            updateState("css_selector", e.target.value);
          }}
        />

        <Input
          type={"text"}
          value={otherData?.rules_js || ""}
          inputWrapperClass="full-width medium bg-2"
          label="Rules for JS code to run"
          placeholder={"return true;"}
          name="rules_js"
          onChange={(e) => {
            updateState("rules_js", e.target.value);
          }}
        />

        <div className="d-flex justify-end default-column-gap">
          <Button
            className="smart-button medium notactive"
            type="button"
            onClick={(e) => {
              refreshPage(null);
            }}
            text={`Cancel`}
          />
          <Button
            className="smart-button medium active"
            type="Submit"
            text={`${editObject ? `Update` : `Create`} Goal`}
          />
        </div>
      </form>
    </div>
  );
};
