import DataTable from "react-data-table-component";
import MyLoader from "../../MiroComponents/PreLoader";
import { useMutation, useQuery } from "@apollo/client";
import { GETADDITIONALREVENUEWITHCLIENTS } from "../../../Graphql/Queries";
import { useRef, useState } from "react";
import { Pagination } from "../../Shared/Paginations";
import { FETCHADDITIONALREVENUEFORCLIENT } from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
import { customStyles } from "../../../Libs/TableCustomStyles";
import { Button } from "../../MiroComponents/Button/Button";

export const ClientFetchAdditionalRevenue = () => {
  const [page, setPage] = useState(1);
  const [size] = useState(20);

  const ref = useRef();

  const [fetchadditionalrevenue] = useMutation(FETCHADDITIONALREVENUEFORCLIENT);

  const { data, loading, error, refetch } = useQuery(
    GETADDITIONALREVENUEWITHCLIENTS,
    {
      variables: {
        page,
        size,
      },
    }
  );

  const columnsclients = [
    {
      name: "Client name",
      selector: ({ name, tier }) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
        >
          {tier && (
            <span
              className="colorSwatch"
              style={{
                backgroundColor: tier?.colorSwatch ?? "red",
              }}
            ></span>
          )}

          {name}
        </div>
      ),
      wrap: true,
      maxWidth: "15%",
    },
    {
      name: "Additional Revenue",
      selector: ({ addtionalRevenueDetails }) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
        >
          {addtionalRevenueDetails}
        </div>
      ),
      wrap: true,
      maxWidth: "15%",
    },
    {
      name: "Tests Contributed",
      selector: ({ testsContributing }) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
        >
          {testsContributing?.length > 0 ? testsContributing?.join(", ") : "__"}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Last Synced",
      selector: ({ updatedAt }) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
        >
          {updatedAt &&
            new Date(updatedAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
        </div>
      ),
      wrap: true,
      maxWidth: "15%",
    },
    {
      name: "Actions",
      cell: ({ _id }) => {
        return (
          <div style={{ textAlign: "right" }}>
            <Button
              className="smart-button small active"
              type="button"
              ref={ref}
              onClick={async (e) => {
                e.target.disabled = true;
                e.target.innerText = "Fetching Data...";
                try {
                  await fetchadditionalrevenue({
                    variables: {
                      client: _id,
                    },
                  });
                  e.target.disabled = false;
                  e.target.innerText = "Refetch Additional Revenue";
                  refetch();
                } catch (error) {
                  e.target.disabled = false;
                  e.target.innerText = "Refetch Additional Revenue";
                  toast.error(error.message);
                }
              }}
            >
              Refetch Additional Revenue
            </Button>
          </div>
        );
      },
      right: true,
    },
  ];

  if (loading) return <MyLoader />;
  if (error) return <div> {error?.message}</div>;
  const { clientsWithAdditionalRevenue } = data;
  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Additional Revenue</h2>
        </div>
      </div>

      <DataTable
        columns={columnsclients}
        data={clientsWithAdditionalRevenue?.clients}
        progressPending={loading}
        customStyles={customStyles}
        progressComponent={<MyLoader />}
      />
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={clientsWithAdditionalRevenue?.total}
      />
    </div>
  );
};
