import React from 'react'

export default function UploadIcon() {
  return (
    <>
      
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1476 10.7577C12.1301 10.7353 12.1076 10.7172 12.0821 10.7047C12.0565 10.6923 12.0284 10.6858 11.9999 10.6858C11.9715 10.6858 11.9434 10.6923 11.9178 10.7047C11.8922 10.7172 11.8698 10.7353 11.8523 10.7577L9.22728 14.0788C9.20564 14.1065 9.19221 14.1396 9.18853 14.1745C9.18485 14.2094 9.19107 14.2447 9.20647 14.2762C9.22187 14.3078 9.24584 14.3343 9.27563 14.3529C9.30542 14.3714 9.33983 14.3812 9.37493 14.3812H11.107V20.0624C11.107 20.1655 11.1913 20.2499 11.2945 20.2499H12.7007C12.8038 20.2499 12.8882 20.1655 12.8882 20.0624V14.3835H14.6249C14.782 14.3835 14.8687 14.203 14.7726 14.0812L12.1476 10.7577Z" fill="#F6F6F6"/>
<path d="M19.0172 8.59453C17.9438 5.76328 15.2086 3.75 12.0047 3.75C8.80078 3.75 6.06563 5.76094 4.99219 8.59219C2.98359 9.11953 1.5 10.95 1.5 13.125C1.5 15.7148 3.59766 17.8125 6.18516 17.8125H7.125C7.22813 17.8125 7.3125 17.7281 7.3125 17.625V16.2188C7.3125 16.1156 7.22813 16.0312 7.125 16.0312H6.18516C5.39531 16.0312 4.65234 15.7172 4.09922 15.1477C3.54844 14.5805 3.25547 13.8164 3.28125 13.0242C3.30234 12.4055 3.51328 11.8242 3.89531 11.3344C4.28672 10.8352 4.83516 10.4719 5.44453 10.3102L6.33281 10.0781L6.65859 9.22031C6.86016 8.68594 7.14141 8.18672 7.49531 7.73438C7.8447 7.28603 8.25857 6.89191 8.72344 6.56484C9.68672 5.8875 10.8211 5.52891 12.0047 5.52891C13.1883 5.52891 14.3227 5.8875 15.2859 6.56484C15.7523 6.89297 16.1648 7.28672 16.5141 7.73438C16.868 8.18672 17.1492 8.68828 17.3508 9.22031L17.6742 10.0758L18.5602 10.3102C19.8305 10.6523 20.7188 11.8078 20.7188 13.125C20.7188 13.9008 20.4164 14.632 19.868 15.1805C19.599 15.451 19.2791 15.6655 18.9266 15.8115C18.5742 15.9576 18.1963 16.0323 17.8148 16.0312H16.875C16.7719 16.0312 16.6875 16.1156 16.6875 16.2188V17.625C16.6875 17.7281 16.7719 17.8125 16.875 17.8125H17.8148C20.4023 17.8125 22.5 15.7148 22.5 13.125C22.5 10.9523 21.0211 9.12422 19.0172 8.59453Z" fill="#F6F6F6"/>
</svg>
    </>
  )
}
