import { useMutation, useQuery } from "@apollo/client";
import { GETCLIENTNOTES, GETPAGEOBJECTS } from "../../Graphql/Queries";
import React, { useRef, useState } from "react";
import {
  ClientLink,
  ImagePlaceholder,
  InfoIcon,
  NoteIcon,
  SearchIcon,
} from "./Icons";
import MyLoader from "../MiroComponents/PreLoader";
import { Pagination } from "./Paginations";
import { FaEdit } from "react-icons/fa";
import { TimesCross } from "./Icons";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import { CREATECLIENTNOTE, UPDATECLIENTNOTE } from "../../Graphql/Mutations";
import { Link } from "react-router-dom";

export const ClientDataSheet = ({ client, viewId }) => {
  const [page, setPage] = useState(1);
  const evidenceRef = useRef(null);
  const [pageType, setPageType] = useState(null);
  const [action, setAction] = useState(null);
  const [evidences, setEvidences] = useState([]);
  const size = 10;
  const getpageobjects = useQuery(GETPAGEOBJECTS);
  const [createclientnotes] = useMutation(CREATECLIENTNOTE);
  const [updateclientnotes] = useMutation(UPDATECLIENTNOTE);
  const { data, loading, error, refetch } = useQuery(GETCLIENTNOTES, {
    variables: {
      client,
      viewId,
      page,
      size,
    },
  });
  const Header = () => (
    <div className="store-audit-table-headers">
      <h4 className="audit-table-sno">Edit</h4>
      <h4>
        PageType
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        Source
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        What did you see
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        Interpretation
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        <p className="mb-0">Evidence</p>
        <span>
          <InfoIcon />
        </span>
      </h4>
    </div>
  );

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.5 7L0 0L6.5 3.5L13 0L6.5 7Z" fill="#F6F6F6" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const AddEvidenceComponent = () => {
    return (
      <div>
        <div className="fields-wrapper fields-wrapper__updated mb-0">
          <label>Evidences</label>
        </div>
        <div className="fields-wrapper fields-wrapper__updated input-groups">
          <div>
            <label>Type</label>
            <select
              id="evidenceType"
              name="evidenceType"
              className="field-control  field-control__updated"
            >
              <option value="">Select</option>
              <option value="image">Image</option>
              <option value="link">Link</option>
            </select>
            {/* <input
              id="evidenceType"
              name="evidenceType"
              className="field-control  field-control__updated"
              placeholder="Document Title"
            /> */}
          </div>
          <div>
            <label>Link</label>
            <input
              id="evidenceLink"
              name="evidenceLink"
              className="field-control  field-control__updated"
              placeholder="ID"
            />
          </div>
        </div>

        <div className="fields-wrapper w-100">
          <button
            type="button"
            className="btn btn-sm btn-dark ms-auto d-block"
            onClick={(_) => {
              const _n = document.querySelector("#evidenceType").value;
              const _v = document.querySelector("#evidenceLink").value;

              if (_n.trim() === "" || _v.trim() === "") {
                return;
              }
              setEvidences((_old) => [
                ..._old,
                {
                  type: _n,
                  value: _v,
                  index: _old.length + 1,
                },
              ]);
              document.querySelector("#evidenceType").value = "";
              document.querySelector("#evidenceLink").value = "";
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  };

  const EvidenceComponent = React.forwardRef((props, ref) => {
    const { evidence } = props;
    if (!evidence) return "";
    return (
      <div className="evidence-group" ref={ref}>
        {evidence.map((dt, i) => (
          <div key={i}>
            {dt?.type === "image" ? (
              <div className="test-compare-img">
                <span>
                  <ImagePlaceholder />
                </span>
                <a href={dt?.value} target="_blank">
                  Image
                </a>
              </div>
            ) : (
              <div className="link-icon">
                <ClientLink />
                <a target="_blank" href={dt?.value}>
                  Link
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  });
  if (loading) return <MyLoader />;
  if (error) return <div> {error?.message}</div>;
  return (
    <div>
      {(action?.module === "add" || action?.module === "edit") && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner form-wrapper-inner__updated addtestform  scroll">
            <div
              className="card add-new-test-card scroll modal-card"
              style={{ minHeight: "fit-content" }}
            >
              <button className="close-modal" onClick={(_) => setAction(null)}>
                <TimesCross />
              </button>
              <div className="header-hidden-on-desktop">
                <h3>Make Note</h3>
              </div>
              <h3>Make Note</h3>
              <div className="card-body p-0">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const { source, whatDidYouSee, interpretation } = e.target;

                    if (
                      !pageType ||
                      source?.value === "" ||
                      whatDidYouSee?.value === "" ||
                      interpretation?.value === ""
                    ) {
                      toast.error("Please fill all the fields");
                      return;
                    }
                    const clientNotePayload = {
                      client,
                      interpretation: interpretation?.value,
                      pageType: pageType?.value,
                      source: source?.value,
                      whatYouSee: whatDidYouSee?.value,
                      viewId,
                      evidence: evidences.map(({ index, ...rest }) => rest),
                    };
                    if (action?.module === "add") {
                      await createclientnotes({
                        variables: {
                          clientNotePayload,
                        },
                      });
                      toast.success("Note added successfully");
                      setAction(null);
                    }
                    if (action?.module === "edit") {
                      await updateclientnotes({
                        variables: {
                          updateClientNoteId: action?.data?._id,
                          clientNotePayload: {
                            ...clientNotePayload,
                          },
                        },
                      });
                      toast.success("Note updated successfully");
                      setAction(null);
                    }
                    refetch();
                  }}
                >
                  <div className="fields-wrapper fields-wrapper__updated input-groups">
                    <div>
                      <label> Select Page Type *</label>
                      <Select
                        onChange={(pageType) => {
                          setPageType(pageType);
                        }}
                        value={pageType}
                        // menuIsOpen={true}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        placeholder="Select Page Type"
                        options={getpageobjects?.data?.pageelements?.map(
                          (dt) => ({
                            label: dt?.name,
                            value: dt?._id,
                          })
                        )}
                        components={{ DropdownIndicator }}
                      />
                    </div>
                    <div>
                      <label>Where did you find the data?*</label>
                      <input
                        name="source"
                        defaultValue={action?.data?.source ?? ""}
                        className="field-control  field-control__updated"
                        placeholder="Where did you find the data.."
                      />
                    </div>
                  </div>
                  <div className="fields-wrapper fields-wrapper__updated">
                    <div>
                      <label>What did you see (objectively)*</label>
                      <textarea
                        name="whatDidYouSee"
                        placeholder="What did you see (objectively)*"
                        defaultValue={action?.data?.whatDidYouSee ?? ""}
                        className="field-control  field-control__updated text-area scroll"
                      ></textarea>
                    </div>
                  </div>
                  <div className="fields-wrapper fields-wrapper__updated">
                    <div>
                      <label>Interpretation*</label>
                      <textarea
                        name="interpretation"
                        defaultValue={action?.data?.interpretation ?? ""}
                        className="field-control  field-control__updated text-area scroll"
                        placeholder="Interpretation*"
                      ></textarea>
                    </div>
                  </div>
                  <AddEvidenceComponent />
                  <div className="fields-wrapper">
                    <div className="list-group">
                      {evidences.map((prps, i) => {
                        return (
                          <a
                            key={i}
                            className="list-group-item list-group-item-action flex-column align-items-start"
                          >
                            <div className="d-flex w-100 justify-content-between">
                              <span
                                className="rmve"
                                onClick={(_) => {
                                  const _prps = [...evidences];
                                  const newItems = _prps.filter(
                                    (it) => it?.index !== prps?.index
                                  );
                                  setEvidences(newItems);
                                }}
                              >
                                <TimesCross color="#000" />
                              </span>
                            </div>
                            <p className="mb-1">
                              {prps?.type === "image" ? (
                                <ImagePlaceholder color="#000" />
                              ) : (
                                <ClientLink color="#000" />
                              )}{" "}
                              -{" "}
                              <a
                                target="_blank"
                                referrerPolicy="no-referrer"
                                href={prps?.value}
                              >
                                {prps?.value}
                              </a>
                            </p>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                  <div className="fields-wrapper">
                    <button type="submit" className="next-btn">
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="product-page-search">
        <form className="search-wrapper">
          <div className="search-icon">
            <SearchIcon />
          </div>
          <div className="input-search">
            <input
              type="text"
              className="product-search"
              placeholder="search"
            />
          </div>
        </form>
        <div className="product-card-view">
          <div className="sort-by">
            <span>Sort by</span>
            <span className="sort-icon">
              
            </span>
          </div>
        </div>
      </div> */}
      <div
        className="add-hypothesis-store-audit"
        onClick={(e) =>
          setAction({
            module: "add",
          })
        }
      >
        <NoteIcon />
        <span className="add-hypothesis-store-audit-title">Add New Note</span>
      </div>
      <div className="store-audit-table__g">
        <Header />
        <div className="store-audit-table-rows">
          {data?.clientNotes?.notes?.map((item, index) => (
            <div className="store-audit-table-single-row" key={index}>
              <h4
                className="audit-table-sno store-audit-table-data"
                onClick={(e) => {
                  setAction({
                    module: "edit",
                    data: item,
                  });
                  setPageType({
                    label: item?.pageType?.name,
                    value: item?.pageType?._id,
                  });
                  if (item?.evidence) {
                    setEvidences(
                      item?.evidence?.map((ev, i) => ({
                        ...ev,
                        index: i,
                      }))
                    );
                  }
                }}
              >
                <FaEdit style={{ cursor: "pointer" }} />
              </h4>
              <div className="store-audit-table-data select">
                {item?.pageType?.name}
              </div>
              <div className="store-audit-table-data">{item?.source}</div>
              <div className="store-audit-table-data">{item?.whatYouSee}</div>
              <div className="store-audit-table-data">
                {item?.interpretation}
              </div>
              <div className="store-audit-table-data">
                <EvidenceComponent
                  evidence={item?.evidence}
                  ref={evidenceRef}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={data?.clientNotes?.total}
      />
    </div>
  );
};
