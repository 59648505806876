import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { ANNOUNCEMENTS } from "../../Graphql/Queries";

export const AnnouncementComponent = ({ myProfile, loading }) => {
  const [announcements] = useLazyQuery(ANNOUNCEMENTS);
  const [announcement, setAnnouncement] = useState([]);
  const ref = useRef();
  useEffect(() => {
    if (!loading && myProfile) {
      (async () => {
        const { data } = await announcements({
          variables: {
            announcementFilters: [
              { active: true },
              {
                $or: [
                  {
                    forRoles: { $in: myProfile?.role?.map((rl) => rl?._id) },
                  },
                  {
                    forUsers: { $in: [myProfile?._id] },
                  },
                ],
              },
            ],
          },
        });
        if (data) {
          setAnnouncement(data?.announcements?.announcements ?? []);
        }
      })();
    }
  }, [loading, myProfile]);

  const AnnouncementCard = React.forwardRef(({ title, description }, ref) => {
    const [showDesc, setShowDesc] = useState(false);
    return (
      <div className="announce-inner">
        <div className="announce-title" onClick={(_) => setShowDesc(!showDesc)}>
          <h2> {title}</h2>
        </div>
        <div className={`announce-details ${showDesc ? `show` : `hide`}`}>
          <p>{description}</p>
        </div>
      </div>
    );
  });

  if (announcement.length === 0) return ``;
  return (
    <div className="announce-wrapper">
      {announcement.map((data, i) => (
        <AnnouncementCard {...data} key={i} />
      ))}
    </div>
  );
};
