import React from "react";
import { VscInfo } from "react-icons/vsc";

export function ImpressionsTableGa({
  controlvariant,
  totalUsers,
  totalSessions,
  totalTransactions,
  calculatedAverageRevenuePerUser,
  totalAverageRevenuePerUser,
  calculatedAveragePurchaseRevenue,
  totalAveragePurchaseRevenue,
  totalPurchaseRevenue,
  type,
  conversionRateUsers,
  conversionRateSessions,
}) {
  return (
    <>
      <div className="impression-table-heading">{controlvariant}</div>
      <div className="impressions" id="total">
        <div className="impression-data" id="user">
          <div className="impression-heading">
            <h4>
              User{" "}
              {type === "total" && (
                <span data-tip={`Users`} className="toottip-span">
                  <VscInfo />
                </span>
              )}{" "}
            </h4>
          </div>
          <span className="data-count"> {totalUsers} </span>
        </div>
        <div className="impression-data" id="sessions">
          <div className="impression-heading">
            <h4>
              Session
              {type === "total" && (
                <span data-tip={`Sessions`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {totalSessions} </span>
        </div>
        <div className="impression-data" id="transactions">
          <div className="impression-heading">
            <h4>
              Transactions
              {type === "total" && (
                <span data-tip={`Transactions`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {totalTransactions} </span>
        </div>
        <div className="impression-data" id="crSessions">
          <div className="impression-heading">
            <h4>
              CR (Sessions){" "}
              {type === "total" && (
                <span
                  data-tip={`Transactions / Sessions`}
                  className="toottip-span"
                >
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {conversionRateSessions}</span>
        </div>
        <div className="impression-data" id="crUsers">
          <div className="impression-heading">
            <h4>
              CR (Users){" "}
              {type === "total" && (
                <span
                  data-tip={`Transactions / Users`}
                  className="toottip-span"
                >
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {conversionRateUsers} </span>
        </div>
        <div className="impression-data" id="avgRpu">
          <div className="impression-heading">
            <h4>
              Avg. RPU{" "}
              {type === "total" && (
                <span data-tip={`Revenue / Users`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count">
            {" "}
            {calculatedAverageRevenuePerUser}{" "}
          </span>
        </div>
        <div className="impression-data" id="aov">
          <div className="impression-heading">
            <h4>
              AOV{" "}
              {type === "total" && (
                <span
                  data-tip={`Revenue / Transactions`}
                  className="toottip-span"
                >
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count">
            {" "}
            {calculatedAveragePurchaseRevenue}{" "}
          </span>
        </div>
        <div className="impression-data" id="revenue">
          <div className="impression-heading">
            <h4>
              Revenue ${" "}
              {type === "total" && (
                <span data-tip={`Revenue`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count">{totalPurchaseRevenue}</span>
        </div>
      </div>
    </>
  );
}

export const ImpressionsTableGa4 = React.memo(ImpressionsTableGa);
