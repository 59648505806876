import { useLayoutEffect } from "react";
import ReactCompareImage from "react-compare-image";
import { ScrubberHandle } from "../../Libs/Utilities";
import MyLoader from "../MiroComponents/PreLoader";

export const Scrubber = ({ showScrubber, scrubber }) => {
  useLayoutEffect(() => {
    if (scrubber) {
      // ReactTooltip.rebuild();
      document.querySelector("body").style.overflow = "hidden";
      document.addEventListener(
        "click",
        function (e) {
          if (e.target.classList.contains("form-wrapper")) {
            showScrubber(null);
          }
        },
        false
      );
    }
    return () => {
      document.querySelector("body").style.overflow = "auto";
    };
  }, [scrubber]);

  if (!scrubber) return ``;
  return (
    <div className="form-wrapper scrubber-wrapper">
      <div className="form-wrapper-inner scrubber">
        <button className="close-modal" onClick={(_) => showScrubber(null)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="#000"
          >
            <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
          </svg>{" "}
        </button>

        <div className="scrub">
          {scrubber?.control && scrubber?.variation ? (
            <ReactCompareImage
              leftImage={scrubber?.control}
              rightImage={scrubber?.variation}
              handle={<ScrubberHandle />}
              sliderLineColor="#000"
              // aspectRatio="wider"
              sliderLineWidth="8"
              onSliderPositionChange={(_) => {
                if (
                  document.querySelector(".scrub") &&
                  !document
                    .querySelector(".scrub")
                    .classList.contains("dragged")
                ) {
                  document.querySelector(".scrub").classList.add("dragged");
                }
              }}
              skeleton={<MyLoader />}
            />
          ) : (
            <>
              <img
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                }}
                src={scrubber?.control}
                alt="Control"
                className="scrubber-control"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
