import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { FAQS } from "../../Graphql/Queries";
import parse from "html-react-parser";
import useStore from "../../Store";
import MyLoader from "../MiroComponents/PreLoader";
import { SearchIcon } from "./Icons";
import Accordion from "../MiroComponents/Accordion";

export const Faq = ({ closeFaq }) => {
  const [searchString, setSearchString] = useState(null);
  const [faqData, setFaqData] = useState({});
  const { setShowFaq } = useStore();
  const [faqquery] = useLazyQuery(FAQS);

  const getFaqs = useCallback(async (searchString) => {
    const { data, loading, error } = await faqquery({
      variables: {
        searchString,
      },
    });
    setFaqData({
      faqs: data?.faqs,
      loading,
      error,
    });
  }, []);

  useEffect(() => {
    getFaqs(searchString);
  }, [searchString, getFaqs]);
  if (faqData?.loading) return <MyLoader />;
  if (faqData?.error) return <div> {faqData?.error?.message} </div>;
  return (
    <section className="faq-wrapper">
      <div className="faq-inner">
        <div className="faq-hero">
          <div className="faq-search">
            <div className="faq-heading">
              <h2 className=""> Frequently Asked Questions</h2>
              <span
                className=" d-inline-flex"
                onClick={(_) => setShowFaq(false)}
              >
                {" "}
                <VscClose />{" "}
              </span>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                setSearchString(e.target.search.value);
              }}
              className="search-wrapper"
            >
              <div className="search-icon">
                <SearchIcon />
              </div>
              <input placeholder="Search answers here..." name="search" />
            </form>
          </div>
        </div>
        <div className="faq-results">
          <div className="accordion" id="accordionExample">
            {faqData?.faqs?.length === 0 && <div>No Results Found</div>}
            {faqData?.faqs?.length > 0 &&
              faqData?.faqs?.map((faq, i) => (
                <Accordion
                  key={i}
                  title={faq?.title}
                  description={parse(faq?.description)}
                  faq={faq}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
