export const clientSurveyQuestions = [
  {
    question: "Do you use codebase to check your test data?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "How frequently are you using codebase per month/week?",
    type: "text",
  },
];

export const REPORTGRAPHTYPES = [
  {
    label: "Conversion Rate Time Series",
    value: "timeseries",
  },
  {
    label: "Probability of being a Winner",
    value: "probability",
  },
];
