import React from "react";
import "./SmartProjectionOverview.scss";

export const SmartProjectionOverview = ({
  selectedOverview = "Projection",
  smartProjectionOverview,
  smartOfferOverview,
}) => {
  const OverviewProjectionDataComponent = ({
    heading = "Additional Top Line Revenue",
    prefix,
    postfix,
    value,
  }) => {
    value = value?.toLocaleString("en-US");
    return (
      <div>
        <h4>{heading}</h4>
        <div className="proj-value">
          {prefix && <span>{prefix}</span>}
          <span>{value}</span>
          {postfix && <span>{postfix}</span>}
        </div>
      </div>
    );
  };

  return (
    <div className="smart-proj-overview-outer m-36-bottom m-36-top">
      <div className="smart-proj-overview-inner">
        <div>
          <h3>6 Months {selectedOverview}</h3>
          <div className="proj-overview-data">
            {selectedOverview === "Projection" ? (
              <>
                <OverviewProjectionDataComponent
                  heading="Additional Top Line Revenue"
                  prefix="€"
                  value={
                    smartProjectionOverview?.projection6M
                      ?.additionalTopLineRevenue
                  }
                />
                <OverviewProjectionDataComponent
                  heading="CM2 Profilts"
                  prefix="€"
                  value={smartProjectionOverview?.projection6M?.cm2Profit}
                />
                <OverviewProjectionDataComponent
                  heading="Realized Clv 6M"
                  prefix="€"
                  value={smartProjectionOverview?.projection6M?.realizedClv}
                />
              </>
            ) : (
              <>
                <OverviewProjectionDataComponent
                  heading="Guaranteed Outcome"
                  postfix="%"
                  value={smartOfferOverview?.offer6M?.guaranteeOutcome}
                />
                <OverviewProjectionDataComponent
                  heading="Top Line"
                  prefix="€"
                  value={smartOfferOverview?.offer6M?.topLine}
                />
                <OverviewProjectionDataComponent
                  heading="Profits"
                  prefix="€"
                  value={smartOfferOverview?.offer6M?.profits}
                />
              </>
            )}
          </div>
        </div>
        <div>
          <h3>12 Months {selectedOverview}</h3>
          <div className="proj-overview-data">
            {selectedOverview === "Projection" ? (
              <>
                <OverviewProjectionDataComponent
                  heading="Additional Top Line Revenue"
                  prefix="€"
                  value={
                    smartProjectionOverview?.projection12M
                      ?.additionalTopLineRevenue
                  }
                />
                <OverviewProjectionDataComponent
                  heading="CM2 Profilts"
                  prefix="€"
                  value={smartProjectionOverview?.projection12M?.cm2Profit}
                />
                <OverviewProjectionDataComponent
                  heading="Realized Clv 6M"
                  prefix="€"
                  value={smartProjectionOverview?.projection12M?.realizedClv}
                />
              </>
            ) : (
              <>
                <>
                  <OverviewProjectionDataComponent
                    heading="Guaranteed Outcome"
                    postfix="%"
                    value={smartOfferOverview?.offer12M?.guaranteeOutcome}
                  />
                  <OverviewProjectionDataComponent
                    heading="Top Line"
                    prefix="€"
                    value={smartOfferOverview?.offer12M?.topLine}
                  />
                  <OverviewProjectionDataComponent
                    heading="Profits"
                    prefix="€"
                    value={smartOfferOverview?.offer12M?.profits}
                  />
                </>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
