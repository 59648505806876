import { useLayoutEffect, useState } from "react";
import { SmartButton } from "../../MiroComponents/SmartButton/SmartButton";
import { SmartCard } from "../../MiroComponents/SmartCard/SmartCard";
import { SmartHeading } from "../../MiroComponents/SmartHeading/SmartHeading";
import { SmartInput } from "../../MiroComponents/SmartInput/SmartInput";
import moment from "moment";
import { format, parse } from "date-fns";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import ReactTooltip from "react-tooltip";
import { Calendar } from "../../Shared/Icons";
import { SmartProjectedEffect } from "../../NewComponents/SmartProjectedEffect/SmartProjectedEffect";
import { SmartDetailProjections } from "../../NewComponents/SmartDetailProjections/SmartDetailProjections";
import { useMutation } from "@apollo/client";
import { CREATEBUSINESSCASEPROJECTION } from "../../../Graphql/Mutations";
import SmartContainer from "../../MiroComponents/SmartContainer/SmartContainer";
import { useMyProfile } from "../../../Hooks/useMyProfile";
import NewHeader from "../../MiroComponents/NewHeader/NewHeader";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import { FaChevronCircleDown, FaChevronDown, FaCog } from "react-icons/fa";

export const BusinessProjection = () => {
  const [data, setData] = useState(null);
  const { myProfile, loading, entryRoute } = useMyProfile();
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [getbusinessprojection] = useMutation(CREATEBUSINESSCASEPROJECTION);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().subtract(12, "months").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [oldSelection, setOldSelection] = useState([
    {
      startDate: moment().subtract(3, "months").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
    return () => {};
  }, []);
  if (loading) return <LoaderLogo />;
  return (
    <div className="dark-bg conversionapppage">
      <ReactTooltip
        id="soclose"
        className="swtooltip"
        getContent={(dataTip) => <div>{dataTip}</div>}
        effect="solid"
      />

      <NewHeader myProfile={myProfile} entryRoute={entryRoute} />
      <SmartContainer className="p-24-top p-24-bottom">
        <SmartHeading>Business Case Overview</SmartHeading>
        <SmartCard bodyClass="projected-values">
          <div className="business-case-form">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const {
                  aov,
                  avgneworderpermonth,
                  avgcm2,
                  avg365Dclv,
                  instanteffect,
                  secondaryeffect,
                  guaranteedoutcome,
                } = e.target.elements;
                if (
                  !aov.value ||
                  !avgneworderpermonth.value ||
                  !avgcm2.value ||
                  !avg365Dclv.value ||
                  !instanteffect.value ||
                  !secondaryeffect.value ||
                  !guaranteedoutcome.value
                ) {
                  toast.error("Please fill all the fields");
                  return;
                }
                const {
                  data: { createBusinessCaseProjection },
                } = await getbusinessprojection({
                  variables: {
                    aov: parseInt(aov.value),
                    avgneworderpermonth: parseInt(avgneworderpermonth.value),
                    avgcm2: parseInt(avgcm2.value),
                    avg365Dclv: parseInt(avg365Dclv.value),
                    startDate: moment(selectionRange[0]?.startDate).format(
                      "YYYYMMDD"
                    ),
                    endDate: moment(selectionRange[0]?.endDate).format(
                      "YYYYMMDD"
                    ),
                    instanteffect: parseFloat(instanteffect.value),
                    secondaryeffect: parseFloat(secondaryeffect.value),
                    guaranteedoutcome: parseFloat(guaranteedoutcome.value),
                  },
                });
                setData(createBusinessCaseProjection?.data);
              }}
            >
              <SmartInput
                label="AOV"
                defaultValue="100"
                placeholder="Enter your AOV"
                name="aov"
                // tip={
                //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                // }
              />
              <SmartInput
                label="Avg. NEW Orders p.M."
                defaultValue="10000"
                name="avgneworderpermonth"
                placeholder="Enter your Avg. NEW Orders p.M."
                // tip={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`}
              />
              <SmartInput
                label="Avg. CM2"
                defaultValue="40"
                name="avgcm2"
                placeholder="Enter your Avg. CM2"
                // tip={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`}
              />
              <SmartInput
                label="Avg. 365d CLV"
                defaultValue="200"
                name="avg365Dclv"
                placeholder="Enter your Avg. 365d CLV"
                // tip={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`}
              />

              <div className="smart-input">
                <label> Date Selection </label>
                <div className="smart-input-cover">
                  <span>
                    <button
                      type="button"
                      onClick={(_) => setShowCalendar(!showCalendar)}
                      className="btn btn-default d-inline-flex align-items-center"
                      style={{
                        columnGap: "6px",
                      }}
                    >
                      <Calendar color={"#fff"} />
                      <span
                        className="range-selected"
                        style={{
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        {format(selectionRange[0]?.startDate, "dd.MMMM.yyyy")} -{" "}
                        {format(selectionRange[0]?.endDate, "dd.MMMM.yyyy")}
                      </span>
                    </button>
                  </span>

                  {showCalendar && (
                    <div className={`datepicker-wrapper dark-calendar-wrapper`}>
                      <DateRangePicker
                        showSelectionPreview={true}
                        // maxDate={new Date()}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        direction={`horizontal`}
                        ranges={selectionRange}
                        className={`dark-calendar`}
                        retainEndDateOnFirstSelection={true}
                        onChange={(item) => {
                          const oneYearAhead = moment(
                            item?.selection?.startDate
                          )
                            .add(12, "months")
                            .toDate();
                          const newSelection = [
                            {
                              startDate: item?.selection?.startDate,
                              endDate: oneYearAhead,
                              key: "selection",
                            },
                          ];
                          setSelectionRange(newSelection);
                          callBackWhenClose(selectionRange);
                          setOldSelection(selectionRange);
                        }}
                      />
                      {/* <div className="buttons-operations">
                        <button
                          className="cancel"
                          onClick={(_) => {
                            setShowCalendar(false);
                            setSelectionRange(oldSelection);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="apply"
                          onClick={(_) => {
                            callBackWhenClose(selectionRange);
                            setOldSelection(selectionRange);
                          }}
                        >
                          Apply
                        </button>
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="smart-input">
                <p
                  className={`settings-link ${
                    advancedSettings ? "active" : ""
                  }`}
                  onClick={(e) => {
                    setAdvancedSettings(!advancedSettings);
                  }}
                >
                  <span>
                    <span>
                      <FaCog />
                    </span>
                    <span>Advanced Settings</span>
                  </span>
                  <span>
                    <FaChevronDown />
                  </span>
                </p>
              </div>

              <div
                style={
                  advancedSettings ? { display: "block" } : { display: "none" }
                }
              >
                <SmartInput
                  label="Instant Effect"
                  defaultValue="0.5"
                  name="instanteffect"
                  placeholder="Enter instant effect"
                />
                <SmartInput
                  label="Secondary Effect"
                  defaultValue="1.5"
                  name="secondaryeffect"
                  placeholder="Enter secondary effect"
                />
                <SmartInput
                  label="Guaranteed Outcome"
                  defaultValue="50"
                  name="guaranteedoutcome"
                  placeholder="Enter guaranteed outcome"
                />
              </div>

              <SmartButton
                style={{ width: "100%" }}
                type="submit"
                className="smart-button big active"
                text={`Calculate`}
              />
            </form>
          </div>

          {data && <SmartProjectedEffect data={data} />}
        </SmartCard>

        {data && (
          <SmartCard
            className="smart-card-outer m-48-top"
            id="projectionoverview"
          >
            <SmartDetailProjections data={data} />
          </SmartCard>
        )}
      </SmartContainer>
    </div>
  );
};
