export const Pagination = ({ page, setPage, size, totalcount }) => {
  return (
    <nav className="mt-2">
      <ul className="pagination">
        <li className="">
          <button
            className="page-link previous"
            onClick={(_) => setPage(page - 1)}
            disabled={page === 1 ? true : false}
          >
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.59106 9.3485L4.93958 5L0.59106 0.651489L0.0605602 1.18149L3.87908 5L0.0605602 8.8185L0.59106 9.3485Z"
                fill="black"
              />
            </svg>
          </button>
        </li>
        <li className="">
          <button
            className="page-link next"
            onClick={(_) => setPage(page + 1)}
            disabled={totalcount > page * size ? false : true}
          >
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.59106 9.3485L4.93958 5L0.59106 0.651489L0.0605602 1.18149L3.87908 5L0.0605602 8.8185L0.59106 9.3485Z"
                fill="black"
              />
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  );
};
