import "./Skeletons.scss";

export default function ContentSkeleton({ count = [1, 2] }) {
  return (
    <>
      <div className="card__header full-width">
        <div>
          <img
            className="card__header header__img skeleton"
            id="logo-img"
            alt=""
          />
        </div>
        <h3 className="card__header header__title" id="card-title">
          {count?.map((skel) => (
            <div key={skel} className="skeleton skeleton-text"></div>
          ))}
        </h3>
      </div>

      <div className="card__body">
        <div className="card__body body__text" id="card-details">
          <div className="skeleton skeleton-text skeleton-text__body"></div>
        </div>

        <div className="card__body body__img">
          <img className="skeleton" alt="" id="cover-img" />
        </div>
      </div>
    </>
  );
}
