import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  UPDATECLIENT,
  UPDATEGOAL,
  UPDATEHYPOTHESIS,
  UPDATEPAGE,
  UPDATETAG,
  UPDATETESTTYPE,
  UPDATETRIGGER,
} from "../../../Graphql/Mutations";
import useStore from "../../../Store";
import { BackIcon } from "../../Shared/Icons";

export function AdminEditActions({ data, setEditNull, refetch }) {
  const { toggleEditForm } = useStore();

  //   UPDATE CLIENT
  const [updatepage] = useMutation(UPDATEPAGE);
  const [updatehypothesis] = useMutation(UPDATEHYPOTHESIS);
  const [updatetag] = useMutation(UPDATETAG);
  const [updatetrigger] = useMutation(UPDATETRIGGER);
  const [updatetesttype] = useMutation(UPDATETESTTYPE);
  const [updategoal] = useMutation(UPDATEGOAL);
  const crumb = useStore((state) => state.crumb);
  const tagsforhypothesis = ["General Purpose", "Evergreen"];
  if (data?.data) {
    // console.log(data);
    const formfields = Object.entries(data?.data);
    const submitForm = async (el) => {
      el.preventDefault();
      const fields = Object.keys(data?.data);
      const form = el.currentTarget;
      let obj = {};
      for await (let field of fields) {
        const val = form.querySelector(`[name=${field}]`).value;
        if (val.trim() === "" && field !== "details") {
          toast.error(`${field} can not be empty!`);
          return;
        } else {
          if (field !== "__typename") {
            obj[field] = val;
          }
        }
      }

      if (data?.module === "pageobjects") {
        const name = obj.name;
        const id = obj._id;
        try {
          const _updatepage = await updatepage({
            variables: {
              payload: {
                name,
              },
              id,
            },
          });
          if (_updatepage?.data) {
            toast.success(`Record Updated!`);
            toggleEditForm(false);
            setEditNull();
            refetch();
          }
        } catch (error) {
          toast.error(`${error?.message}`);
        }
      }
      if (data?.module === "hypothesis") {
        const hypothesisPayload = obj;
        const id = obj._id;
        delete hypothesisPayload._id;
        try {
          const _updatehypothesis = await updatehypothesis({
            variables: {
              id,
              hypothesisPayload,
            },
          });
          if (_updatehypothesis?.data) {
            toast.success(`Record Updated!`);
            toggleEditForm(false);
            setEditNull();
            refetch();
          }
        } catch (error) {
          toast.error(`${error?.message}`);
        }
      }
      if (data?.module === "tags") {
        const name = obj.name;
        const id = obj._id;
        try {
          const _updatetags = await updatetag({
            variables: {
              id,
              name,
            },
          });
          if (_updatetags?.data) {
            toast.success(`Record Updated!`);
            toggleEditForm(false);
            setEditNull();
            refetch();
          }
        } catch (error) {
          toast.error(`${error?.message}`);
        }
      }
      if (data?.module === "triggers") {
        const triggerPayload = obj;
        const id = obj._id;
        delete triggerPayload._id;
        try {
          const _updatetrigger = await updatetrigger({
            variables: {
              id,
              triggerPayload,
            },
          });
          if (_updatetrigger?.data) {
            toast.success(`Record Updated!`);
            toggleEditForm(false);
            setEditNull();
            refetch();
          }
        } catch (error) {
          toast.error(`${error?.message}`);
        }
      }

      if (data?.module === "testtypes") {
        const testTypePayload = obj;
        const id = obj._id;
        delete testTypePayload._id;
        try {
          const _updatetesttype = await updatetesttype({
            variables: {
              id,
              testTypePayload,
            },
          });
          if (_updatetesttype?.data) {
            toast.success(`Record Updated!`);
            toggleEditForm(false);
            setEditNull();
            refetch();
          }
        } catch (error) {
          toast.error(`${error?.message}`);
        }
      }

      if (data?.module === "goals") {
        const goalPayload = obj;
        const id = obj._id;
        delete goalPayload._id;
        try {
          const _updategoal = await updategoal({
            variables: {
              id,
              goalPayload,
            },
          });
          if (_updategoal?.data) {
            toast.success(`Record Updated!`);
            toggleEditForm(false);
            setEditNull();
            refetch();
          }
        } catch (error) {
          toast.error(`${error?.message}`);
        }
      }
    };

    return (
      <div className="form-wrapper" id="add-new-test-form">
        <div className="form-wrapper-inner addtestform">
          <div className="card add-new-test-card" id="formcard">
            <button
              className="close-modal"
              onClick={(_) => toggleEditForm(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="#000"
              >
                <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
              </svg>{" "}
            </button>
            <h3> Edit {crumb}</h3>
            <div className="card-body p-0">
              <form onSubmit={(_) => submitForm(_)}>
                {formfields?.map((field, i) => {
                  return (
                    <>
                      <div
                        key={i}
                        className={
                          field[0] === "_id" ||
                          field[0] === "__typename" ||
                          field[0] === "tests"
                            ? `d-none`
                            : `fields-wrapper`
                        }
                      >
                        <label> {field[0]}</label>
                        <input
                          className="field-control"
                          name={field[0]}
                          readOnly={field[0] === "tagged" ? true : false}
                          defaultValue={field[1]}
                        />
                      </div>
                      {data?.module === "hypothesis" &&
                        field[0] === "tagged" &&
                        field[1] !== "Client Specific" && (
                          <div className="mb-1 d-flex justify-content-center">
                            {tagsforhypothesis.map((btns) => (
                              <button
                                type="button"
                                className="mx-2 btn border"
                                value={btns}
                                onClick={(e) => {
                                  document.querySelector(
                                    '[name="tagged"]'
                                  ).value = btns;
                                }}
                              >
                                {btns}
                              </button>
                            ))}
                          </div>
                        )}
                    </>
                  );
                })}
                <div className="add__new__test__form-footer">
                  <div
                    className="btn-prev"
                    onClick={(_) => toggleEditForm(false)}
                  >
                    <BackIcon />
                    Cancel
                  </div>

                  <button type="submit" className="btn next-btn">
                    UPDATE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return "";
}
