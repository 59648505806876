import { FaExclamationTriangle } from "react-icons/fa";

export function NotAuthorized() {
  return (
    <div className="not-authorized-wrapper">
      <div className="not-authorized-inner">
        <FaExclamationTriangle />
        <h2>Oops, something wen't wrong !</h2>
      </div>
    </div>
  );
}
