import Select from "react-select";
import { FilterIcon } from "../Shared/Icons";
import { DateRangeSelectorComponent } from "../Shared/DateRangeSelectorComponent";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../Store";
import { PrecalculatedConditionContainer } from "./PrecalculatedConditionContainer";
import { Button } from "../MiroComponents/Button/Button";

export const FilterPrecalculatedValues = ({
  showCalendar,
  setShowCalendar,
  selectionRange,
  setSelectionRange,
  callBackWhenClose,
  getPreCalculations,
}) => {
  const [eventOrPage, setEventOrPage] = useState(null);
  const [conditions, setConditions] = useState([]);

  return (
    <>
      <section className="selected-test-content live-reports-content">
        <div className="experiment-data-inner">
          <div className="experiment-heading-divider">
            <div className="section-heading-data">
              <h1 className="section-heading">Pre-Calculated Data</h1>
            </div>
            {/* filter icon for mobile screens as general filters will be removed in mobile view */}
            <div className="reporting-mobile-filters">
              <FilterIcon />
              <span className="reporting-mobile-filters__text">Filter</span>
            </div>
          </div>
          <div className="experiment-data-navbar">
            <div className="date-selector">
              <DateRangeSelectorComponent
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                className="dark-calendar"
                classNameWrapper="dark-calendar-wrapper"
                callBackWhenClose={callBackWhenClose}
                showDays={true}
              />
            </div>
          </div>
          <div>
            <PrecalculatedConditionContainer
              eventOrPage={eventOrPage}
              setEventOrPage={setEventOrPage}
              setConditions={setConditions}
              conditions={conditions}
            />
          </div>
          <div className="experiment-segment">
            <div className="segment-data">
              <div className="segment-btn precalc d-flex justify-end">
                <Button
                  className="smart-button full-width big active"
                  onClick={async (event) => {
                    event.preventDefault();
                    getPreCalculations(conditions);
                  }}
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
