import MyLoader from "../MiroComponents/PreLoader";
import { UpArrow } from "../Shared/Icons";

export const ClientTestsOverview = ({ statistics, loading }) => {
  if (loading) return <MyLoader />;
  return (
    <section className="tests-overview">
      <div>
        <div className="next-steps-heading">Test Overview</div>
      </div>
      <div className="top-learning-inner">
        <div className="learning-list">
          <div className="test-overview-box">
            <h3> Success rate</h3>
            <h4>{statistics?.successRate}%</h4>
            <p>
              <span>
                of all tests were successful and adviced to be implemented
              </span>{" "}
              {/* <span>
                <span className="analytics-percentage analytics-increment">
                  <UpArrow />
                  <span className="operator">+</span>
                  <span className="figure">6%</span>
                </span>
              </span>{" "} */}
            </p>
          </div>
        </div>
        <div className="next-steps">
          <div className="steps-wrapper">
            <div className="current-step">
              <h3> Ended Tests</h3>
              <p>{statistics?.endedTest}</p>
            </div>
            <div className="current-step">
              <h3> Live Tests</h3>
              <p>{statistics?.liveTest}</p>
            </div>
            <div className="current-step">
              <h3> Draft Tests</h3>
              <p>{statistics?.draftTest}</p>
            </div>

            <div className="current-step">
              <h3>Average Uplift on conv. rate of successful tests</h3>
              <p>
                <span> {statistics?.averageUpliftSuccessfullTests} %</span>
                {/* <span>
                  <span className="analytics-percentage analytics-increment">
                    <UpArrow />
                    <span className="operator">+</span>
                    <span className="figure">6%</span>
                  </span>
                </span>{" "} */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
