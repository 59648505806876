import { useRef, useState } from "react";
import ConfidenceOfUpliftGraph from "../../Pages/Admin/AdminComponents/ConfidenceOfUpliftGraph";
import ConversionRateTimeSeriesGraph from "../../Pages/Admin/AdminComponents/ConversionRateTimeSeriesGraph";
import { ArrowDown } from "../../Shared/Icons";

export const ClientTimeSeriesandProbability = ({
  experiments,
  calculatedResults,
}) => {
  const [graphType, setGraphType] = useState({
    name: "Conversions rate time series",
    module: "timeseries",
  });
  const reportingRef = useRef();
  const graphItems = [
    {
      name: "Conversions rate time series",
      module: "timeseries",
    },
    {
      name: "Probability of being a winner",
      module: "probability",
    },
  ];

  const TimeSeries = ({ experimentsData, segmentName }) => {
    return (
      <>
        {" "}
        <ConversionRateTimeSeriesGraph
          graphExperimentData={experimentsData}
          segmentName={segmentName}
        />
      </>
    );
  };

  return (
    <div className="graph-component">
      <div className="section-heading-data">
        <h1 className="section-heading">Graphs</h1>
      </div>
      <h3 className="reporting-subtitle">
        Analyse change in Conversion rate over time with advanced Graphs.
      </h3>
      <div>
        <div className="tab-dropdown-mobile">
          <button
            onClick={(e) => {
              const _tabcontent = reportingRef.current;
              if (_tabcontent.classList.contains("open-graph")) {
                _tabcontent.classList.remove("open-graph");
              } else {
                _tabcontent.classList.add("open-graph");
              }
            }}
          >
            <span> {graphType?.name}</span>
            <span>
              {" "}
              <ArrowDown />{" "}
            </span>
          </button>
        </div>
      </div>
      <div className="tab-component reporting-tab-component" ref={reportingRef}>
        {graphItems?.map((item, i) => (
          <div
            className={`tab tab${i + 1} ${
              graphType?.module === item?.module ? `tab-active` : ``
            }`}
            key={i}
            onClick={(e) => {
              const _tabcontent = reportingRef.current;
              if (_tabcontent.classList.contains("open-graph")) {
                _tabcontent.classList.remove("open-graph");
              }
              setGraphType(item);
            }}
          >
            <span className="tab-title">{item?.name}</span>
          </div>
        ))}
      </div>
      {/* <div className="graph-depicts"> */}
      {/* <div className="depict control-depict">
          <span className="point">Control</span>
          <svg
            width="50"
            height="14"
            viewBox="0 0 50 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_418_1718" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8389 9.46347C13.1521 12.608 10.351 14.9623 7 14.9623C3.13401 14.9623 0 11.8288 0 7.96349C0 4.09818 3.13401 0.964722 7 0.964722C10.351 0.964722 13.1521 3.31898 13.8388 6.46347H50V9.46347H13.8389Z"
              ></path>
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.8389 9.46347C13.1521 12.608 10.351 14.9623 7 14.9623C3.13401 14.9623 0 11.8288 0 7.96349C0 4.09818 3.13401 0.964722 7 0.964722C10.351 0.964722 13.1521 3.31898 13.8388 6.46347H50V9.46347H13.8389Z"
              fill="#F6F6F6"
            ></path>
            <path
              d="M13.8389 9.46347V6.46347H11.4233L10.9079 8.82336L13.8389 9.46347ZM13.8388 6.46347L10.9079 7.1036L11.4234 9.46347H13.8388V6.46347ZM50 6.46347H53V3.46347H50V6.46347ZM50 9.46347V12.4635H53V9.46347H50ZM10.9079 8.82336C10.5158 10.6189 8.91158 11.9623 7 11.9623V17.9623C11.7905 17.9623 15.7884 14.597 16.7698 10.1036L10.9079 8.82336ZM7 11.9623C4.79036 11.9623 3 10.1715 3 7.96349H-3C-3 13.4862 1.47765 17.9623 7 17.9623V11.9623ZM3 7.96349C3 5.75553 4.79036 3.96472 7 3.96472V-2.03528C1.47765 -2.03528 -3 2.44083 -3 7.96349H3ZM7 3.96472C8.91157 3.96472 10.5158 5.30803 10.9079 7.1036L16.7698 5.82334C15.7884 1.32994 11.7905 -2.03528 7 -2.03528V3.96472ZM13.8388 9.46347H50V3.46347H13.8388V9.46347ZM47 6.46347V9.46347H53V6.46347H47ZM50 6.46347H13.8389V12.4635H50V6.46347Z"
              fill="#B03232"
              mask="url(#path-1-inside-1_418_1718)"
            ></path>
          </svg>
        </div>
        <div className="depict variant-depict">
          <span className="point">Variant-1</span>
          <svg
            width="50"
            height="15"
            viewBox="0 0 50 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_418_1721" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8389 9.46347C13.1521 12.608 10.351 14.9623 7 14.9623C3.13401 14.9623 0 11.8288 0 7.96349C0 4.09818 3.13401 0.964722 7 0.964722C10.351 0.964722 13.1521 3.31898 13.8388 6.46347H50V9.46347H13.8389Z"
              ></path>
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.8389 9.46347C13.1521 12.608 10.351 14.9623 7 14.9623C3.13401 14.9623 0 11.8288 0 7.96349C0 4.09818 3.13401 0.964722 7 0.964722C10.351 0.964722 13.1521 3.31898 13.8388 6.46347H50V9.46347H13.8389Z"
              fill="#F6F6F6"
            ></path>
            <path
              d="M13.8389 9.46347V6.46347H11.4233L10.9079 8.82336L13.8389 9.46347ZM13.8388 6.46347L10.9079 7.1036L11.4234 9.46347H13.8388V6.46347ZM50 6.46347H53V3.46347H50V6.46347ZM50 9.46347V12.4635H53V9.46347H50ZM10.9079 8.82336C10.5158 10.6189 8.91158 11.9623 7 11.9623V17.9623C11.7905 17.9623 15.7884 14.597 16.7698 10.1036L10.9079 8.82336ZM7 11.9623C4.79036 11.9623 3 10.1715 3 7.96349H-3C-3 13.4862 1.47765 17.9623 7 17.9623V11.9623ZM3 7.96349C3 5.75553 4.79036 3.96472 7 3.96472V-2.03528C1.47765 -2.03528 -3 2.44083 -3 7.96349H3ZM7 3.96472C8.91157 3.96472 10.5158 5.30803 10.9079 7.1036L16.7698 5.82334C15.7884 1.32994 11.7905 -2.03528 7 -2.03528V3.96472ZM13.8388 9.46347H50V3.46347H13.8388V9.46347ZM47 6.46347V9.46347H53V6.46347H47ZM50 6.46347H13.8389V12.4635H50V6.46347Z"
              fill="#003ED4"
              mask="url(#path-1-inside-1_418_1721)"
            ></path>
          </svg>
        </div> */}
      {/* </div> */}

      <div className="timeline-graph-figure">
        {graphType?.module === "timeseries" && (
          <TimeSeries experimentsData={experiments} segmentName="All" />
        )}
        {graphType?.module === "probability" && (
          <>
            {calculatedResults?.map((edata, i) => (
              <ConfidenceOfUpliftGraph
                key={i}
                label={edata?.controlvariant}
                upliftValues={[
                  (100 - edata?.confidence).toFixed(2).toString(),
                  edata?.confidence,
                ]}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
