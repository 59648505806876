import { useMutation, useQuery } from "@apollo/client";
import React, { useMemo, useReducer, useState } from "react";
import { ALLABTESTS } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import { toast } from "react-toastify";
import { Pagination } from "../../Shared/Paginations";
import moment from "moment";
import "./ExperimentsList.scss";
import Select from "react-select";
import { useMatch, useNavigate } from "react-router-dom";
import MyRoutes from "../../../Routes";
import ClientSettings from "../ClientSettings/ClientSettings";
import MyLoader from "../../MiroComponents/PreLoader";
import { Button } from "../../MiroComponents/Button/Button";
import useAbTestStore from "../../../AbTestStore";
import Input from "../../MiroComponents/Input/Input";
import {
  CrossIcon2,
  DropIcon,
  PencilEditIcon,
  ReportIcon,
  StoreIcon,
  TickGreen,
} from "../../Shared/Icons";
import { FaCogs, FaEllipsisV, FaPlus } from "react-icons/fa";
import SelectComponent from "../../MiroComponents/SelectComponent/SelectComponent";
import TargetDefination from "../TargetDefination/TargetDefination";
import {
  ADDUPDATEVARIANT,
  DELETEVARIANT,
  UPDATEMULTIPLEVARIANTS,
  UPDATETEST,
} from "../../../Graphql/Mutations";
import { experimentModes } from "../../../Libs/Utilities";

const targetOptions = [
  {
    value: "page",
    label: "Page Targeting",
    keyname: "pages",
    message:
      "Choose all the target pages for the Experiment. The experiment will run on all the pages selected. (Check below for definations)",
  },
  {
    value: "audience",
    label: "Audience Targeting",
    keyname: "audiences",
    message:
      "Choose all the target Audiences for the Experiment. The experiment will run on passing all the conditions.(Check below for definations)",
  },
  {
    value: "goals",
    label: "Goals",
    keyname: "goals",
    message: "Choose all Goals for this Experiment.",
  },
  {
    value: "environments",
    label: "Environments",
    keyname: "environments",
    message: "Choose at least one Environment for this Experiment.",
  },
];

export default function ExperimentsList() {
  const { insights, updateTest } = useAbTestStore();
  const navigate = useNavigate();
  const experimentBoxRef = React.createRef();
  const [deleteVariant] = useMutation(DELETEVARIANT);
  const [addVariant] = useMutation(ADDUPDATEVARIANT);
  const [updateMutipleVariants] = useMutation(UPDATEMULTIPLEVARIANTS);

  const SwitchButtonLabel = ({ defaultTargetOtions, status }) => {
    if (defaultTargetOtions?.pages?.length === 0) {
      return <span>Start Experiment: Setup Page Targeting</span>;
    }
    if (defaultTargetOtions?.audiences?.length === 0) {
      return <span>Start Experiment: Setup Audience Targeting</span>;
    }
    return <span>Experiment Mode - {status?.toUpperCase()}</span>;
  };

  const TargetOptions = ({
    targetSelected,
    option,
    data,
    selectValues,
    dispatchTargetState,
    className = "",
  }) => {
    const options = data?.map((item) => ({
      label: item.name,
      value: item._id,
    }));
    const definations = data?.filter((defination) =>
      selectValues?.map((item) => item.value).includes(defination._id)
    );

    return (
      <div
        className={className}
        style={
          targetSelected === option.value
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="default-margin-bottom">
          <h2 className="margin-bottom-auto"> {option?.label}</h2>
          <p className="default-margin-top"> {option?.message}</p>
        </div>

        <SelectComponent
          options={options}
          value={selectValues}
          placeholder="Select Store"
          icon={""}
          customClass="default-padding default-border"
          containerClass="full-width"
          isMulti={true}
          onChange={(selected) => {
            dispatchTargetState({ type: option.value, payload: selected });
          }}
        />
        <div className="default-margin-top">
          <ul className="d-flex flex-wrap target-options full-width align-items-start default-column-gap default-row-gap">
            {definations?.map((defination, index) => (
              <li
                key={index}
                className="d-inline-flex default-column-gap align-items-center"
              >
                <TargetDefination
                  targetSelected={targetSelected}
                  {...defination}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const VariationBox = React.forwardRef(
    ({ variation, test, activateButtonsCallBack }, ref) => {
      return (
        <div ref={ref}>
          <div
            data-variation={test?._id}
            data-variation-id={variation._id}
            className="d-flex default-padding-top default-padding-bottom default-border-bottom align-items-center justify-content-between"
          >
            <h3 className="margin-bottom-auto">
              {variation?.name ?? `Variation Name`}{" "}
            </h3>
            <div className="d-inline-flex default-column-gap">
              <Input
                type={"number"}
                value={variation?.traffic_allocation}
                wrapperClass="full-width margin-bottom-auto"
                inputWrapperClass="full-width small"
                style={{
                  maxWidth: "50px",
                }}
                onChange={(e) => {
                  activateButtonsCallBack(true);
                }}
              />
              <Button
                text="Preview"
                onClick={(e) => {
                  window.open(
                    `${test?.urltargeting[0]}?codebase_preview=${test?._id}_${variation._id}&codebase_debug=true`,
                    "_blank"
                    // `width=800,height=600,scrollbars=yes,resizable=yes`
                  );
                }}
                style={{
                  border: "none",
                }}
                className="smart-pill xmall active"
              />
              <Button
                text="Edit"
                onClick={(e) => {
                  navigate(`${test?._id}?variation=${variation._id}`);
                }}
                className="smart-pill xmall"
              />

              <div className="dropdown">
                <a
                  className="dropdown-toggle notification text-white"
                  type="button"
                  id={`${variation._id}`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={
                    variation?.baseline
                      ? {
                          pointerEvents: "none",
                          cursor: "not-allowed",
                          opacity: "0.5",
                        }
                      : {}
                  }
                >
                  <FaEllipsisV />
                  {/* <span className="notification-text"> Settings </span> */}
                </a>
                <ul
                  className="dropdown-menu default-padding default-border radius-4 bg-2"
                  aria-labelledby={`${variation._id}`}
                >
                  {!variation?.baseline && (
                    <li
                      onClick={async (e) => {
                        const { data } = await deleteVariant({
                          variables: {
                            test: test?._id,
                            variant: variation._id,
                          },
                        });
                        updateTest(data?.deleteTestVariant);
                      }}
                    >
                      Delete
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  );

  const ExperimentBox = React.forwardRef(({ experiment }, ref) => {
    const [activateButtons, setActivateButtons] = useState(false);
    const [updatetestdata] = useMutation(UPDATETEST);
    const [editUrl, setEditUrl] = useState(false);
    const defaultTargetOtions = {
      pages: experiment?.pages?.map((page) => ({
        label: page.name ?? "",
        value: page._id,
      })),
      audiences: experiment?.audiences?.map((audience) => ({
        label: audience.name ?? "",
        value: audience._id,
      })),
      goals: experiment?.goal?.map((goal) => ({
        label: goal.name ?? "",
        value: goal._id,
      })),
      environments: experiment?.environments?.map((environment) => ({
        label: environment.name ?? "",
        value: environment._id,
      })),
    };
    const reducerFunction = (state, action) => {
      setEditUrl(true);
      switch (action.type) {
        case "page":
          setEditUrl(true);
          return { ...state, pages: action.payload };
        case "audience":
          setEditUrl(true);
          return { ...state, audiences: action.payload };
        case "goals":
          setEditUrl(true);
          return { ...state, goals: action.payload };
        case "environments":
          setEditUrl(true);
          return { ...state, environments: action.payload };
        default:
          return state;
      }
    };

    const [targetSelected, setTargetSelected] = useState(null);
    const [targetState, dispatchTargetState] = useReducer(
      reducerFunction,
      defaultTargetOtions
    );
    return (
      <section
        className={`default-border radius-4 big-margin-bottom`}
        ref={ref}
      >
        <div
          className="d-flex justify-content-between default-padding bg-2"
          onClick={(e) => {
            // params.test = experiment._id;
            // setSearchParams(params);
          }}
        >
          <h2 className="margin-bottom-auto">
            {experiment?.name ?? `Experiment Name`}{" "}
          </h2>
          <p>{moment(experiment?.createdAt).format("LL")}</p>
        </div>
        <div className="default-padding">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center default-column-gap">
              {targetOptions.map((option, index) => (
                <Button
                  key={index}
                  text={option.label}
                  className={`smart-button small ${
                    targetSelected === option.value ? `active` : ``
                  }`}
                  onClick={(e) => {
                    if (targetSelected === option.value) {
                      setTargetSelected(null);
                      return;
                    }
                    setTargetSelected(option.value);
                  }}
                  icon={
                    <DropIcon
                      style={
                        targetSelected === option.value
                          ? { transform: "rotate(180deg)" }
                          : { transform: "rotate(0deg)" }
                      }
                    />
                  }
                />
              ))}
            </div>
            <div>
              <div className="dropdown">
                <Button
                  className={`smart-button small dropdown-toggle ${
                    experiment?.status?.toLowerCase() === "preview"
                      ? `bg-yellow`
                      : experiment?.status?.toLowerCase() === "running" ||
                        experiment?.status?.toLowerCase() === "live"
                      ? `active`
                      : ``
                  }`}
                  id="experimentmode"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={
                    defaultTargetOtions?.pages?.length === 0 ||
                    defaultTargetOtions?.audiences?.length === 0 ||
                    defaultTargetOtions?.environments?.length === 0
                  }
                >
                  <DropIcon />
                  <SwitchButtonLabel
                    defaultTargetOtions={defaultTargetOtions}
                    status={experiment?.status}
                  />
                </Button>
                <ul
                  className="dropdown-menu default-border radius-4 bg-2"
                  aria-labelledby="experimentmode"
                >
                  {experimentModes?.map((item) => (
                    <li
                      key={item}
                      className={`default-padding 
                        ${experiment?.status === item ? `active-bg neon` : ``}
                      `}
                      onClick={async (e) => {
                        e.preventDefault();
                        const { data } = await updatetestdata({
                          variables: {
                            id: experiment?._id,
                            testPayload: {
                              status: item,
                            },
                          },
                        });
                        updateTest(data?.updatetest);
                        toast.success("Test Mode Updated");
                      }}
                    >
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* <SelectComponent
                options={experimentModes?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                value={null}
                placeholder="Select"
                icon={""}
                customClass="default-border default-padding"
                containerClass="full-width"
                isMulti={false}
                // onChange={(selected) => {
                //   dispatchTargetState({ type: option.value, payload: selected });
                // }}
              /> */}
            </div>
          </div>
        </div>
        <div>
          {targetOptions?.map((option, index) => (
            <TargetOptions
              key={index}
              className="default-padding"
              targetSelected={targetSelected}
              option={option}
              data={insights[option.keyname]}
              selectValues={targetState[option.keyname]}
              dispatchTargetState={dispatchTargetState}
            />
          ))}
        </div>

        <div className="default-padding">
          <div className="d-flex flex-column">
            {experiment?.variations?.map((variation, index) => (
              <VariationBox
                variation={variation}
                key={index}
                test={experiment}
                activateButtonsCallBack={setActivateButtons}
              />
            ))}
          </div>
          <div className="default-margin-top d-flex justify-content-between">
            <Button
              text="Add Variation"
              type="button"
              onClick={async (e) => {
                const { data } = await addVariant({
                  variables: {
                    test: experiment._id,
                  },
                });
                updateTest(data?.addUpdateTestVariant);
              }}
              icon={<FaPlus />}
              className="smart-button small"
            />
            {activateButtons && (
              <div className="d-inline-flex default-column-gap">
                <Button
                  text="Update"
                  className="smart-button small"
                  onClick={async (e) => {
                    const variations = document.querySelectorAll(
                      `[data-variation="${experiment._id}"]`
                    );
                    let total = 0;
                    for (let i = 0; i < variations.length; i++) {
                      total += variations[i].querySelector("input")?.value
                        ? parseInt(variations[i].querySelector("input")?.value)
                        : 0;
                    }
                    if (total > 100) {
                      toast.error(
                        "Total Traffic Allocation should be less than 100"
                      );
                      return;
                    }
                    const { data } = await updateMutipleVariants({
                      variables: {
                        test: experiment._id,
                        variants: Array.from(variations).map((variation) => ({
                          _id: variation.getAttribute("data-variation-id"),
                          traffic_allocation: variation.querySelector("input")
                            ?.value
                            ? parseInt(variation.querySelector("input")?.value)
                            : 0,
                        })),
                      },
                    });
                    updateTest(data?.updateMutipleVariants);
                    toast.success("Variations Updated");
                  }}
                />
                <Button
                  text="Cancel"
                  className="smart-button small"
                  style={{
                    whiteSpace: "nowrap",
                    backgroundColor: "red",
                    borderColor: "red",
                    color: "white",
                  }}
                  onClick={(e) => {
                    setActivateButtons(false);
                    updateTest(null);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between default-padding bg-2">
          <div
            id="targeturl"
            className="d-inline-flex full-width default-column-gap align-items-center"
          >
            <Input
              value={experiment?.urltargeting?.join(", ") ?? "No URL"}
              type="url"
              // label="Editor URL"
              disabled={editUrl ? false : true}
              wrapperClass="full-width margin-bottom-auto"
              inputWrapperClass="full-width small"
            />
            <div className="d-flex default-column-gap">
              <Button
                style={{
                  whiteSpace: "nowrap",
                }}
                onClick={async (e) => {
                  if (!editUrl) {
                    setEditUrl(true);
                    return;
                  }
                  if (targetState?.environments?.length > 1) {
                    toast.error(
                      "Only one environment is allowed for URL Targeting"
                    );
                    return;
                  }
                  if (targetState?.environments?.length === 0) {
                    toast.error(
                      "Please select at least one environment for URL Targeting"
                    );
                    return;
                  }
                  if (editUrl) {
                    const targetUrl = e.target
                      ?.closest("#targeturl")
                      .querySelector("input")?.value;
                    const testPayload = {
                      audiences: targetState?.audiences?.map(
                        (audience) => audience.value
                      ),
                      goal: targetState?.goals?.map((goal) => goal.value),
                      pages: targetState?.pages?.map((page) => page.value),
                      urltargeting: [targetUrl ?? ""],
                      environments: targetState?.environments?.map(
                        (environment) => environment.value
                      ),
                    };
                    try {
                      if (!experiment?._id) {
                        toast.error("Test ID not found, Please try again.");
                        setEditUrl(false);
                        return;
                      }
                      const { data } = await updatetestdata({
                        variables: {
                          id: experiment?._id,
                          testPayload: testPayload,
                        },
                      });
                      updateTest(data?.updatetest);
                      toast.success("Test Updated Successfully");
                    } catch (error) {
                      toast.error(error?.message);
                    }
                  }
                }}
                type="button"
                text={editUrl ? "Save" : "Edit"}
                className="smart-button small active"
              />
              {editUrl && (
                <Button
                  style={{
                    whiteSpace: "nowrap",
                    backgroundColor: "red",
                    borderColor: "red",
                    color: "white",
                  }}
                  onClick={(e) => {
                    setEditUrl(false);
                    updateTest(null);
                  }}
                  type="button"
                  text={`Cancel`}
                  className="smart-button small active"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  });

  if (insights?.loading) return <MyLoader />;
  return (
    <div>
      <div className="d-flex justify-content-between big-margin-bottom">
        <h1 className="margin-bottom-auto">Experiments</h1>
      </div>
      {insights?.experiments?.map((experiment, index) => (
        <ExperimentBox
          experiment={experiment}
          ref={experimentBoxRef}
          key={index}
        />
      ))}
    </div>
  );
}
