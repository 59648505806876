import React from "react";
import "./SmartCard.scss";
export const SmartCard = ({
  icon,
  title,
  children,
  bodyClass = "",
  ...props
}) => {
  return (
    <div className="smart-card-outer" {...props}>
      <div className="smart-card">
        <div className="smart-card-header">
          {icon}
          <h3>{title}</h3>
        </div>
        <div className={`smart-card-body ${bodyClass}`}>{children}</div>
      </div>
    </div>
  );
};
