import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CLIENTTARGETINGPAGES,
  GETAUDIENCES,
  GETENVIRONMENTS,
  GOALSPAGINATION,
  SINGLEABTEST,
} from "../../../Graphql/Queries";
import { UPDATETEST } from "../../../Graphql/Mutations";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import { experimentModes } from "../../../Libs/Utilities";
import "./ExperimentDetails.scss";
import MyLoader from "../../MiroComponents/PreLoader";
export default function ExperimentDetails() {
  const navigate = useNavigate();
  const { experimentId } = useParams();
  const [previewVariant, setPreviewVariant] = useState(null);
  const [totalVariants, setTotalVariants] = useState([]);
  const [module, setModule] = useState(null);
  const [traffic, setTraffic] = useState("");
  const [updatetest] = useMutation(UPDATETEST);

  const { data, loading, error, refetch } = useQuery(SINGLEABTEST, {
    variables: { id: experimentId },
  });

  const handleExperimentUpdate = async (id, testPayload) => {
    try {
      await updatetest({
        variables: {
          id,
          testPayload: {
            ...testPayload,
            variations: testPayload?.variations?.map(
              ({ __typename, ...rest }) => rest
            ),
          },
        },
      });
      toast.success("Experiment updated successfully");
      refetch();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (data?.singletest?.status === "preview") {
      setPreviewVariant({
        label: data?.singletest?.variations[0]?.name,
        value: data?.singletest?.variations[0]?._id,
      });
    }
  }, [data]);

  const TargetingComponent = ({
    queryName,
    client,
    objectNesting,
    defaultValues,
    test,
  }) => {
    const [targetValues, setTargetValues] = useState(defaultValues);
    const [oldValues, setOldValues] = useState(defaultValues);
    const { data, loading, error, refetch } = useQuery(queryName, {
      variables: {
        filters: [
          {
            client: client?._id,
          },
        ],
      },
    });
    if (loading) return <LoaderLogo />;
    if (error) return <div>Error: {error.message}</div>;
    let arr = [];
    if (objectNesting === "getAudiences") {
      arr = data[objectNesting]?.audiences;
    } else if (objectNesting === "pages") {
      arr = data[objectNesting]?.pageData;
    } else if (objectNesting === "goalsPagination") {
      arr = data[objectNesting]?.goals;
    } else if (objectNesting === "getEnvironments") {
      arr = data[objectNesting]?.environments;
    }

    return (
      <>
        <Select
          onChange={(target) => {
            setTargetValues(target);
          }}
          value={targetValues}
          classNamePrefix="acc-newselect"
          isMulti={true}
          className="acc-newselectwrapper darkinput"
          placeholder="Select..."
          options={arr?.map((c) => {
            return {
              label: c?.name,
              value: c?._id,
            };
          })}
        />

        <button
          disabled={JSON.stringify(targetValues) === JSON.stringify(oldValues)}
          className="btn btn-primary mt-2 ms-auto d-block"
          onClick={async (_) => {
            let Obj = {};
            if (objectNesting === "getAudiences") {
              Obj = {
                audiences: targetValues.map(({ value }) => value),
              };
            } else if (objectNesting === "pages") {
              Obj = {
                pages: targetValues.map(({ value }) => value),
              };
            } else if (objectNesting === "goalsPagination") {
              Obj = {
                goal: targetValues.map(({ value }) => value),
              };
            } else if (objectNesting === "getEnvironments") {
              Obj = {
                environments: targetValues.map(({ value }) => value),
              };
              if (targetValues?.length > 1) {
                toast.error("Only one environment can be selected");
                return;
              }
            }
            await handleExperimentUpdate(test, Obj);
          }}
        >
          Save
        </button>
      </>
    );
  };

  useEffect(() => {
    if (data) {
      setTotalVariants([...data?.singletest?.variations]);
      // setPageTargeting([...data?.getClientExperiment?.pageTargeting]);
    }
  }, [data]);

  if (!experimentId) {
    return <div>Experiment Id not found</div>;
  }
  if (loading) return <MyLoader />;
  if (error) return <div>Error: {error.message}</div>;
  const { singletest } = data;
  return (
    <div>
      {module && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner form-wrapper-inner__updated addtestform  scroll">
            <div
              className="card add-new-test-card scroll modal-card"
              style={{ minHeight: "fit-content" }}
            >
              <button
                className="close-modal"
                onClick={(_) => {
                  setModule(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <div className="header-hidden-on-desktop">
                <h3>Add New Variant</h3>
              </div>
              <h3>{module?.type === "add" ? "Add New" : "Edit"} Variant</h3>
              <div className="card-body p-0">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const { name, csscode, jscode, traffic_allocation } =
                      e.target;
                    let newArr = [];
                    let newObj = {
                      name: name.value,
                      csscode: csscode.value,
                      css_code: csscode.value,
                      reset_js: "",
                      baseline: false,
                      jscode: jscode.value,
                      traffic_allocation: parseInt(traffic_allocation.value),
                    };
                    if (module?.type === "add") {
                      newArr = [
                        ...totalVariants,
                        {
                          ...newObj,
                        },
                      ];
                    } else {
                      newObj["traffic_allocation"] = parseInt(
                        traffic_allocation.value
                      );
                      newObj["_id"] = module?.data?._id;
                      newObj["baseline"] = module?.data?.baseline;
                      newArr = [
                        ...totalVariants.filter(
                          ({ _id }) => _id !== module?.data?._id
                        ),
                        {
                          ...newObj,
                        },
                      ];
                    }
                    newArr = newArr.map(({ __typename, ...rest }) => {
                      return rest;
                    });
                    if (
                      Math.round(
                        newArr?.reduce(
                          (acc, curr) => acc + curr.traffic_allocation,
                          0
                        )
                      ) > 100
                    ) {
                      toast.error(
                        "Total traffic allocation should not be more than 100%"
                      );
                      return;
                    }
                    setTotalVariants(newArr);
                    setModule(null);
                  }}
                >
                  <div className="fields-wrapper">
                    <label className="nofloat"> Variant name</label>
                    <input
                      required
                      type="text"
                      name="name"
                      defaultValue={module?.data?.name ?? ""}
                      className="field-control  field-control__updated darkinput"
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label className="nofloat"> Traffic</label>
                    <input
                      name="traffic_allocation"
                      type="number"
                      defaultValue={module?.data?.traffic_allocation}
                      max={100}
                      min={0}
                      // onChange={(e) => setTraffic(e.target.value)}
                      className="field-control  field-control__updated darkinput"
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label className="nofloat"> CSS CODE</label>
                    <textarea
                      type="text"
                      name="csscode"
                      defaultValue={module?.data?.csscode ?? ""}
                      className="field-control  field-control__updated darkinput"
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label className="nofloat"> JS CODE</label>
                    <textarea
                      type="text"
                      name="jscode"
                      defaultValue={module?.data?.jscode ?? ""}
                      className="field-control  field-control__updated darkinput"
                    />
                  </div>
                  <div className="add__new__test__form-footer">
                    <button
                      className="btn next-btn"
                      style={{ marginLeft: "auto" }}
                    >
                      {module?.type === "add" ? "ADD" : "EDIT"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2>{singletest?.name}</h2>
          <div>
            <button
              className="add"
              onClick={(_) => {
                navigate(`/ab-testing-dashboard?test=${singletest?._id}`);
              }}
            >
              Back to Experiments
            </button>
            <button
              disabled={
                singletest?.status !== "live" && singletest?.status !== "paused"
              }
              onClick={(e) => {
                navigate(
                  `/ab-testing-dashboard/${singletest?._id}/experimentresults`,
                  {
                    state: {
                      livedate: singletest?.livedate,
                    },
                  }
                );
              }}
              className="btn btn-success btn-lg"
            >
              Results
            </button>
          </div>
        </div>
      </div>
      <section className="ab-test-overview">
        <div className="experiment-lists">
          <form
            className="experiment-box"
            onSubmit={async (e) => {
              e.preventDefault();
              const { urltargeting } = e.target;
              if (urltargeting.value.trim() === "") {
                toast.error("Experiment URL is required");
                return;
              }
              await handleExperimentUpdate(
                singletest._id,
                {
                  urltargeting: [urltargeting.value],
                },
                refetch
              );
            }}
          >
            <div className="experiment-box-head">
              <h3>{`Overview`}</h3>
              <p>{moment(singletest?.createdAt).format("LL")}</p>
            </div>
            <div className="experiment-overview active" id="add-new-test-form">
              <h2> Experiment is in {singletest?.status} Mode</h2>
              <p>
                This experiment is in {singletest?.status} mode. It will only be
                visible to you.
              </p>
              <div className="fields-wrapper">
                <label className="nofloat">Experiment URL</label>
                <input
                  type="text"
                  defaultValue={singletest?.urltargeting?.[0]}
                  // disabled
                  name="urltargeting"
                  className="field-control  field-control__updated darkinput"
                  placeholder="Enter Variant Name"
                />
              </div>
              {(singletest?.status === "preview" ||
                singletest?.status === "live") && (
                <>
                  <div className="fields-wrapper">
                    <label className="nofloat">Variation to Preview</label>
                    <Select
                      onChange={(variant) => {
                        setPreviewVariant(variant);
                      }}
                      value={previewVariant}
                      classNamePrefix="acc-newselect"
                      className="acc-newselectwrapper darkinput"
                      placeholder="Select..."
                      options={singletest?.variations?.map((c) => {
                        return {
                          label: c?.name,
                          value: c?._id,
                        };
                      })}
                    />
                  </div>
                  {previewVariant && (
                    <div className="fields-wrapper">
                      <label className="nofloat"> Preview URL</label>
                      <input
                        type="text"
                        onClick={(e) => {
                          navigator.clipboard.writeText(e.target.value);
                          toast.success("Copied to clipboard");
                        }}
                        value={`${
                          singletest?.urltargeting?.[0]
                        }?codebase_preview=${singletest?._id}_${
                          previewVariant ? previewVariant?.value : ""
                        }`}
                        readOnly
                        className="field-control  field-control__updated darkinput"
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="experiment-box-footer">
              <div>
                Experminet ID: <strong> {singletest._id}</strong>
              </div>
              <div>
                <button
                  className="btn btn-primary dropdown-toggle me-2"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="name">
                    {" "}
                    {singletest?.status?.toUpperCase()} - Switch Mode
                  </span>
                </button>
                <ul
                  className="dropdown-menu topbar-dropdown"
                  aria-labelledby="dropdownMenuButton2"
                >
                  {experimentModes
                    ?.filter((modes) => modes !== singletest?.status)
                    ?.map((mode, index) => (
                      <li key={index}>
                        <button
                          onClick={async (e) => {
                            if (singletest?.environments?.length === 0) {
                              toast.error(
                                "Please add atleast one environment."
                              );
                              return;
                            }
                            const data = {
                              status: mode,
                            };
                            if (mode === "live") {
                              data.livedate = new Date();
                            }
                            await handleExperimentUpdate(
                              singletest._id,
                              data,
                              refetch
                            );
                          }}
                          type="button"
                          className="btn text-white"
                        >
                          {mode} Mode
                        </button>
                      </li>
                    ))}
                </ul>
                <button type="submit" className="btn btn-primary text-white">
                  Update Details
                </button>
              </div>
            </div>
          </form>
          <div className="experiment-box overflow-visible">
            <div className="experiment-box-head">
              <h3>{`Environment`}</h3>
              {/* GETENVIRONMENTS */}
            </div>
            <div className="experiment-overview active">
              <TargetingComponent
                queryName={GETENVIRONMENTS}
                client={data?.singletest?.client}
                test={data?.singletest?._id}
                defaultValues={
                  singletest?.environments?.map((ev) => {
                    return {
                      label: ev?.name,
                      value: ev?._id,
                    };
                  }) ?? []
                }
                objectNesting={`getEnvironments`}
              />
            </div>
          </div>
          <div className="experiment-box">
            <div className="experiment-box-head">
              <h3>{`Variations`}</h3>
            </div>
            <div className="experiment-overview active" id="add-new-test-form">
              {totalVariants
                ?.sort(
                  (a, b) =>
                    b.baseline - a.baseline || a.name.localeCompare(b.name)
                )
                ?.map((variant, index) => (
                  <div className="variants-list" key={index}>
                    <div className="variants-list-inner">
                      <div className="variant-name">
                        <h4>{variant?.name}</h4>
                      </div>
                      <div className="variant-traffic">
                        <div className="fields-wrapper">
                          <input
                            readOnly
                            type="number"
                            className="field-control  field-control__updated darkinput"
                            value={
                              variant?.traffic_allocation % 1 !== 0.5
                                ? Math.round(variant?.traffic_allocation)
                                : variant?.traffic_allocation
                            }
                          />
                          <span>%</span>
                        </div>
                      </div>
                      <div className="variant-ctas">
                        <button
                          onClick={() => {
                            // setModule("edit");
                            // setActiveIndex(index);
                            setModule({
                              type: "edit",
                              data: variant,
                            });
                            // setTraffic(variant?.traffic_allocation);
                          }}
                          className="btn border text-white"
                        >
                          Edit Details
                        </button>
                      </div>
                      <div className="variant-options">
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="name">Options</span>
                          </a>
                          <ul
                            className="dropdown-menu topbar-dropdown"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                href={`${singletest?.urltargeting?.[0]}?codebase_preview=${singletest?._id}_${variant?._id}`}
                                target="_blank"
                              >
                                <span>Preview </span>
                              </a>
                            </li>
                            {!variant?.baseline && (
                              <li>
                                <a
                                  onClick={async () => {
                                    const remainingVariants = totalVariants
                                      .filter(
                                        (item) => item._id !== variant._id
                                      )
                                      ?.map(({ __typename, ...rest }) => {
                                        return {
                                          ...rest,
                                        };
                                      });
                                    await handleExperimentUpdate(
                                      singletest._id,
                                      {
                                        variations: remainingVariants,
                                      },
                                      refetch
                                    );
                                  }}
                                >
                                  <span>Delete </span>
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="experiment-box-footer">
              <div></div>
              <div>
                <button
                  onClick={() => {
                    if (singletest?.environments?.length === 0) {
                      toast.error("Please add atleast one environment.");
                      return;
                    }
                    setModule({
                      type: "add",
                      data: null,
                    });
                    setTraffic("");
                  }}
                  className="btn border text-white"
                >
                  Add New Variant
                </button>
                <button
                  className="btn btn-primary text-white ms-2"
                  type="button"
                  onClick={async (e) => {
                    try {
                      await handleExperimentUpdate(
                        singletest._id,
                        {
                          variations: totalVariants,
                        },
                        refetch
                      );
                    } catch (error) {}
                  }}
                >
                  Update Details
                </button>
              </div>
            </div>
          </div>
          <div className="experiment-box overflow-visible">
            <div className="experiment-box-head">
              <h3>{`Targeting`}</h3>
            </div>
            <div className="experiment-overview active">
              <div className="port-cards targeting">
                <div className="portfolio-creater">
                  <h5> Audience Targeting</h5>
                  <p>
                    Choose all Audiences for the Experiment <br />
                    All entries are linked by AND.
                  </p>
                  <TargetingComponent
                    queryName={GETAUDIENCES}
                    defaultValues={
                      singletest?.audiences?.map((audience) => {
                        return {
                          label: audience?.name,
                          value: audience?._id,
                        };
                      }) ?? []
                    }
                    test={data?.singletest?._id}
                    client={data?.singletest?.client}
                    objectNesting={`getAudiences`}
                  />
                </div>
                <div className="portfolio-creater">
                  <h5> Page Targeting</h5>
                  <p>
                    {" "}
                    Choose all Pages the Experiment should run on.
                    <br /> All entries are linked by OR.
                  </p>
                  <TargetingComponent
                    queryName={CLIENTTARGETINGPAGES}
                    client={data?.singletest?.client}
                    test={data?.singletest?._id}
                    defaultValues={
                      singletest?.pages?.map((page) => {
                        return {
                          label: page?.name,
                          value: page?._id,
                        };
                      }) ?? []
                    }
                    objectNesting={`pages`}
                  />
                </div>

                <div className="portfolio-creater">
                  <h5> Goals</h5>
                  <p>
                    Choose all Goals for this Experiment
                    <br /> Goals will trigger based on the selected events.
                  </p>
                  <TargetingComponent
                    queryName={GOALSPAGINATION}
                    test={data?.singletest?._id}
                    defaultValues={
                      singletest?.goal?.map((goal) => {
                        return {
                          label: goal?.name,
                          value: goal?._id,
                        };
                      }) ?? []
                    }
                    client={data?.singletest?.client}
                    objectNesting={`goalsPagination`}
                  />
                </div>
              </div>
            </div>
            {/* <div className="experiment-box-footer"></div> */}
          </div>
        </div>
      </section>
      {/* <div className="header-bar">
        <div className="header-bar-inner justify-content-end">
          <div>
            <button className="add ">Update Details</button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
