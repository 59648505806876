import uuid from "react-uuid";

const PRESET =
  process.env.REACT_APP_UPLOAD_PRESET || "acceleratedcodebaseuploads";
const FOLDER = process.env.REACT_APP_UPLOAD_FOLDER || "CONTROLVARIATIONIMAGES";
const SCRIPTFOLDER = "CLIENTSCRIPTS";
const CLIENTLOGOSFOLDER =
  process.env.REACT_APP_CLIENT_LOGOS_UPLOAD_FOLDER || "CLIENTLOGOS";

const CLOUDNAME = process.env.REACT_APP_CLOUD_NAME || "acceleratedagency";

export const UploadFile = async (file) => {
  const filename = file?.name.split(".").slice(0, -1).join(".") + uuid();
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", PRESET);
  formData.append("folder", FOLDER);
  formData.append("public_id", filename);
  const url = `https://api.cloudinary.com/v1_1/${CLOUDNAME}/image/upload`;
  try {
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: formData, // body data type must match "Content-Type" header
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const UploadClientLogo = async (file) => {
  const filename = file?.name.split(".").slice(0, -1).join(".") + uuid();
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", PRESET);
  formData.append("folder", CLIENTLOGOSFOLDER);
  formData.append("public_id", filename);
  const url = `https://api.cloudinary.com/v1_1/${CLOUDNAME}/image/upload`;
  try {
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: formData, // body data type must match "Content-Type" header
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const UploadScriptFile = async (fileData, client) => {
  const filename = "index-" + client;
  const formData = new FormData();
  formData.append(
    "file",
    new Blob([fileData], { type: "text/javascript" }),
    "index.js"
  );
  formData.append("upload_preset", PRESET);
  formData.append("folder", SCRIPTFOLDER);
  formData.append("public_id", filename);
  formData.append("resource_type", "raw");
  const url = `https://api.cloudinary.com/v1_1/${CLOUDNAME}/raw/upload`;
  try {
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: formData, // body data type must match "Content-Type" header
    });
    return response.json();
  } catch (error) {
    console.log("error", error);
    return error;
  }
};
