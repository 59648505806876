import { useLazyQuery } from "@apollo/client";
import { differenceInCalendarDays } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import ReactCompareImage from "react-compare-image";
import { useLocation, useNavigate } from "react-router-dom";
import { SINGLEHYPOTHESIS } from "../../Graphql/Queries";
import { ScrubberHandle } from "../../Libs/Utilities";
import MyLoader from "../MiroComponents/PreLoader";
import ConfidenceOfUpliftGraph from "../Pages/Admin/AdminComponents/ConfidenceOfUpliftGraph";
import ConversionRateTimeSeriesGraph from "../Pages/Admin/AdminComponents/ConversionRateTimeSeriesGraph";
import { ExperimentsCalculatedResults } from "./ExperimentsCalculatedResults";
import { ExperimentsData } from "./ExperimentsData";
import { Scrubber } from "./Scrubber";
import { TotalExperimentsData } from "./TotalExperimentsData";

export function ReportDetails({
  reportDetails,
  unsetDetails,
  className,
  notificationScreen,
  client,
  showCloseIcon,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [hypothesesDetail, setHypothesesDetail] = useState(null);
  const [scrubber, showScrubber] = useState(null);
  const { test } = reportDetails;
  const [singleHypothesis] = useLazyQuery(SINGLEHYPOTHESIS);
  useEffect(() => {
    if (reportDetails?.test?.hypothesis) {
      (async () => {
        const id = test?.hypothesis?._id
          ? test?.hypothesis?._id
          : test?.hypothesis;
        const { data } = await singleHypothesis({
          variables: {
            id,
          },
        });
        setHypothesesDetail(data?.singleHypothesis);
      })();
    }
  }, [reportDetails]);

  const days =
    Number(
      differenceInCalendarDays(
        new Date(reportDetails?.to),
        new Date(reportDetails?.from)
      )
    ) + 1;

  return (
    <div className={`report-details-wrapper ${className ? className : ``}`}>
      <div className={`report-details-inner`}>
        <div className="card">
          {!showCloseIcon && (
            <button
              className="close-modal"
              onClick={(_) => {
                if (notificationScreen) {
                  navigate(location?.state?.backUrl);
                } else {
                  unsetDetails();
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="#000"
              >
                <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
              </svg>{" "}
            </button>
          )}

          <div className="card-body">
            {reportDetails?.reportDetails ? (
              <>
                <h4 className="mb-2 d-inline-block border-bottom">
                  <span className="small text-primary">
                    {test?.property?.label} -
                  </span>{" "}
                  {test?.name}{" "}
                </h4>
                <div className="visual-box-wrapper">
                  <div className="visual-box-inner">
                    <button className="btn btn-default border shadow d-flex align-items-center justify-content-center">
                      {reportDetails?.segmentName}
                    </button>
                  </div>
                </div>
                <div className="visual-box-wrapper">
                  <div className="visual-box-inner">
                    <div className="d-flex align-items-center justify-content-between">
                      <h3>
                        <span className="me-1"> Performance Results from </span>
                        <span className="badge bg-primary text-white">
                          {moment(reportDetails?.from).format("ll")}
                        </span>{" "}
                        to{" "}
                        <span className="badge bg-primary text-white">
                          {moment(reportDetails?.to).format("ll")}
                        </span>
                        <span className="ms-2">Days : {days}</span>
                      </h3>
                    </div>
                    <TotalExperimentsData
                      experimentsData={
                        reportDetails?.reportDetails?.experiments
                      }
                      uniqueUsersWithTransactions={
                        reportDetails?.reportDetails
                          ?.uniqueUsersWithTransactions
                      }
                      title="Total"
                    />
                  </div>
                </div>
                <div className="visual-box-wrapper">
                  <div className="visual-box-inner">
                    {reportDetails?.reportDetails?.experiments?.map(
                      (expsdata, i) => {
                        return (
                          <ExperimentsData
                            experiments={expsdata?.experiments}
                            title={expsdata?.controlvariant}
                            key={i}
                            index={i}
                            uniqueUsersWithTransactions={
                              reportDetails?.reportDetails
                                ?.uniqueUsersWithTransactions
                            }
                            users={expsdata?.users}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
                {reportDetails?.reportDetails?.experimentResults?.map(
                  (edt, i) => (
                    <ExperimentsCalculatedResults
                      edt={edt}
                      key={i}
                      client={client}
                    />
                  )
                )}

                <div className="visual-box-wrapper">
                  <div className="visual-box-inner">
                    <div className="segment-graph-wrapper">
                      <div className="segment-graph-inner">
                        <ConversionRateTimeSeriesGraph
                          graphExperimentData={
                            reportDetails?.reportDetails?.experiments
                          }
                          controlVariant={reportDetails?.controlVariant}
                          segmentName={reportDetails?.segmentName}
                        />
                      </div>
                      <div className="segment-graph-inner">
                        {reportDetails?.reportDetails?.experimentResults?.map(
                          (edata, i) => (
                            <ConfidenceOfUpliftGraph
                              key={i}
                              label={edata?.controlvariant}
                              upliftValues={[
                                (100 - edata?.confidence).toFixed(2).toString(),
                                edata?.confidence,
                              ]}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="visual-box-wrapper">
                  <div className="visual-box-inner">
                    <div className="d-flex align-items-center justify-content-between">
                      <h3
                        className={`fw-bold text-decoration-underline text-uppercase ${
                          reportDetails?.conclusion === "winner"
                            ? `text-success`
                            : `text-dark`
                        }`}
                      >
                        {reportDetails?.conclusion}
                        <i> ({reportDetails?.segmentName})</i>
                      </h3>
                    </div>
                    <div>
                      <ul>
                        {reportDetails?.learnings?.map((_, i) => (
                          <li key={i}> {_} </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {hypothesesDetail && (
                  <div className="p-2 mb-2">
                    <h5> Hypothesis</h5>
                    <div className="p-2 bg-light rounded-1">
                      <p> {hypothesesDetail.name} </p>
                      <p> - {hypothesesDetail.action} </p>
                      <p> - {hypothesesDetail.expectation} </p>
                      <p> - {hypothesesDetail.result} </p>
                    </div>
                  </div>
                )}
                {test?.urltargeting?.length > 0 && (
                  <div className="p-2 mb-2">
                    <h5> Target URL(s)</h5>
                    <div className="p-2 bg-light rounded-1">
                      <ul className="pl-0 ml-0 mb-0">
                        {test?.urltargeting.map((url, i) => (
                          <li key={i}> {url} </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                <button
                  className="btn border"
                  onClick={(e) => {
                    showScrubber({
                      control: test?.controlimage,
                      variation: test?.variationimage,
                    });
                  }}
                >
                  Compare Control vs Variation
                </button>
                {scrubber && (
                  <Scrubber showScrubber={showScrubber} scrubber={scrubber} />
                )}
              </>
            ) : (
              <>
                <div className="test-details">
                  <h4> {reportDetails?.name}</h4>
                  <hr />
                  <div
                    className="p-2 bg-light mb-2"
                    style={{ borderRadius: "5px" }}
                  >
                    <h5> Hypothesis</h5>
                    {reportDetails?.hypothesis && (
                      <>
                        <p> {reportDetails.hypothesis.name} </p>
                        <p> - {reportDetails.hypothesis.action} </p>
                        <p> - {reportDetails.hypothesis.expectation} </p>
                        <p> - {reportDetails.hypothesis.result} </p>
                      </>
                    )}
                  </div>

                  <div className="p-2 bg-light" style={{ borderRadius: "5px" }}>
                    <h5> Goals</h5>
                    {reportDetails?.goal && (
                      <>
                        {reportDetails.goal.map((gl, i) => (
                          <li key={i}> {gl.name} </li>
                        ))}
                      </>
                    )}
                  </div>
                  {reportDetails?.controlimage &&
                    reportDetails?.variationimage && (
                      <div className="mt-2">
                        <ReactCompareImage
                          leftImage={reportDetails?.controlimage}
                          rightImage={reportDetails?.variationimage}
                          handle={<ScrubberHandle />}
                          sliderLineColor="#000"
                          sliderLineWidth="6"
                          skeleton={<MyLoader />}
                        />
                      </div>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
