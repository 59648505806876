import { gql } from "@apollo/client";

export const GETADDREVENUE = gql`
  query Query(
    $client: ID
    $viewId: String
    $dateRange: dateRange
    $type: String
  ) {
    getrevenueV2(
      client: $client
      viewId: $viewId
      dateRange: $dateRange
      type: $type
    )
  }
`;

export const CLIENTSTATISTICS = gql`
  query Query($client: ID!, $viewId: String) {
    clientTestsStatisticsV2(client: $client, viewId: $viewId)
  }
`;

export const GETREPORTSV2 = gql`
  query getReportsV2(
    $client: ID
    $viewId: String
    $page: Int
    $size: Int
    $filters: JSON
  ) {
    getReportsV2(
      client: $client
      viewId: $viewId
      page: $page
      size: $size
      filters: $filters
    )
  }
`;

export const GETBUSINESSCASEVALIDATIONDATA = gql`
  query Query($client: ID!, $viewId: String, $filters: JSON) {
    getBusinessCaseValidationDataV2(
      client: $client
      viewId: $viewId
      filters: $filters
    )
  }
`;

export const GETREPORTV2 = gql`
  query Query($getReportV2Id: ID!) {
    getReportV2(id: $getReportV2Id)
  }
`;
