import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ClientRoutes } from "../../ClientRoutes";

import useStore from "../../Store";
import {
  ClientLink,
  Clients,
  Dashboard,
  Feedbacks,
  Reporting,
  ShowReportIcon,
} from "../Shared/Icons";
import { SettingsBar } from "../Shared/SettingsBar";

function ClientSideBar({ myProfile, setSideBar, vw }) {
  const rolesCheck = myProfile?.role?.map((rl) => rl.name);
  const { toggleForm, setCrumb, toggleEditForm } = useStore();
  const [routes] = useState(() => [
    {
      name: "Dashboard",
      route: ClientRoutes.clientdashboard,
      icon: <Dashboard />,
      className: "client-dashboard",
      subItem: null,
    },
    {
      name: "Active Tests",
      className: "client-active-tests",
      icon: <Reporting />,
      route: `${ClientRoutes.clienttests}`,
      subItem: null,
    },
    {
      name: "Audience Insights",
      className: "",
      icon: <Clients />,
      route: ClientRoutes.clientaudienceinsights,
      subItem: null,
    },
    // {
    //   name: "Send Feedback",
    //   className: "",
    //   route: ClientRoutes.sendfeedback,
    //   icon: <Feedbacks />,
    //   subItem: null,
    // },
    {
      name: "Documents & Links",
      route: ClientRoutes.clientsheetsandlinks,
      className: "",
      icon: <ClientLink />,
      subItem: null,
    },
    {
      name: "CVR Report",
      route: ClientRoutes.cvrReport,
      className: "",
      icon: <ShowReportIcon />,
      subItem: null,
    },
  ]);

  return (
    <div className="sidebar-inner">
      <ul className="sidebar-content">
        {routes?.map((item, i) => {
          return (
            <li
              className={`sidebar-list ${item?.subItem ? `arrowdown` : ``}`}
              key={i}
            >
              <NavLink
                className={
                  item?.subItem &&
                  (item?.route === window.location.pathname ||
                    item?.subItem?.some(
                      (item) => item.route === window.location.pathname
                    ))
                    ? "dropactive"
                    : ``
                }
                onClick={(_) => {
                  toggleForm(false);
                  toggleEditForm(false);
                  if (vw < 1024) {
                    setSideBar(true);
                  }

                  setCrumb(item.name);
                }}
                to={item?.route}
              >
                <span>
                  <span className="icon">{item?.icon}</span>
                  <span className="sidebar-text"> {item?.name}</span>
                </span>
              </NavLink>
              {item?.subItem && (
                <div id="subitem" className="submenu">
                  {item?.subItem.map((si, i) => {
                    if (
                      si?.rolesAllowed?.some((r) => rolesCheck.indexOf(r) > -1)
                    ) {
                      return (
                        <NavLink
                          to={si?.route}
                          key={i}
                          onClick={(_) => {
                            toggleForm(false);
                            toggleEditForm(false);
                            if (vw < 1024) {
                              setSideBar(true);
                            }
                            setCrumb(si.name);
                          }}
                        >
                          {si?.name}
                        </NavLink>
                      );
                    }
                  })}
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {/* <SettingsBar
        rolesCheck={rolesCheck}
        items={[
          {
            name: "Account",
            route: "/myaccount",
            rolesAllowed: ["ADMIN", "EDITOR", "OPTIMIZER"],
            icon: <VscSettingsGear />,
          },
          {
            name: "Settings",
            route: MyRoutes.users,
            rolesAllowed: ["ADMIN"],
            icon: <VscAccount />,
          },
        ]}
      /> */}
    </div>
  );
}

export default ClientSideBar;
