import { useEffect, useRef, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Select from "react-select";

import { ClientInsightsCard } from "../../NewComponents/ClientInsightsCard";
import { ClientPropertiesList } from "../../NewComponents/ClientPropertiesList";

export const ClientAudienceInsights = () => {
  const [myProfile, is_client] = useOutletContext();
  const clientDropDownRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const viewId = searchParams.get("viewId");
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const [clientProperties, setClientProperties] = useState(null);
  const [clientId, setClientId] = useState(null);
  // const [client, setClient] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [clientStatsReports, setClientStatsReports] = useState({
    show: false,
  });
  const _params = Object.fromEntries([...searchParams]);

  const returnClientOptions = (clients) => {
    return clients?.map((c) => {
      return {
        label: c?.name,
        value: JSON.stringify({
          _id: c?._id,
          gaproperties: c?.gaproperties,
          currency: c?.currency,
          logo: c?.logo,
        }),
      };
    });
  };
  const setLimitedClients = (clients) => {
    setClientSelectorOptions(returnClientOptions(clients));
    if (returnClientOptions(clients)?.length === 1) {
      if (clientDropDownRef.current) {
        clientDropDownRef.current.classList.add("hideclientselector");
      }
      _params.client = JSON.parse(returnClientOptions(clients)[0]?.value)?._id;
      const properties = JSON.parse(returnClientOptions(clients)[0]?.value);
      if (properties?.gaproperties?.length === 1) {
        _params.viewId = properties?.gaproperties[0]?.viewId;
      }
      setSearchParams(_params);
    }
  };
  useEffect(() => {
    if (is_client) {
      setLimitedClients(myProfile?.client);
    }
  }, [is_client, myProfile]);

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.client = JSON.parse(client?.value)?._id;
            delete _params.viewId;
            setSearchParams(_params);
            setClientStatsReports({
              ...clientStatsReports,
              show: false,
            });
          }}
          value={options?.find((opt) => JSON.parse(opt?.value)?._id === client)}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Name"
          options={options}
        />
      </div>
    );
  };

  const PropertySelector = ({ clientProperties }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(propertyvalue) => {
            setSelectedProperty(propertyvalue);
          }}
          isDisabled={!client ? true : false}
          value={selectedProperty}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Choose One"
          options={clientProperties?.map((c, i) => {
            return {
              label: c?.propertyname,
              value: c?.viewId,
            };
          })}
        />
      </div>
    );
  };

  // if (loading && !tests) return <MyLoader />;

  return (
    <>
      <section className="live-reports audience-insight-client-select">
        <div className="live-reports-inner">
          <div className="client-data-wrapper">
            <div className="client-details full" ref={clientDropDownRef}>
              <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clientSelectorOptions} />
              </div>
              {client && (
                <div className="properties-dropdown">
                  <h1 className="section-heading">Properties:</h1>
                  <div className="">
                    <ClientPropertiesList
                      searchParams={searchParams}
                      _params={_params}
                      setSearchParams={setSearchParams}
                      selectedViewId={viewId}
                      properties={
                        client
                          ? clientSelectorOptions?.find((opt) => {
                              return JSON.parse(opt?.value)?._id === client;
                            })?.value
                            ? JSON.parse(
                                clientSelectorOptions?.find((opt) => {
                                  return JSON.parse(opt?.value)?._id === client;
                                })?.value
                              )?.gaproperties
                            : []
                          : []
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ClientInsightsCard client={client} viewId={viewId} />
    </>
  );
};
