import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FiLock, FiUsers } from "react-icons/fi";
import { Link, useOutletContext } from "react-router-dom";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  CREATECLIENTLINKS,
  DELETECLIENTLINK,
  UPDATECLIENTLINKS,
} from "../../../Graphql/Mutations";
import {
  GETCLIENTSLINKSANDSHEETS,
  GETCLIENTSNAMEANDIDS,
} from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { customStyles } from "../../../Libs/TableCustomStyles";
import { Button } from "../../MiroComponents/Button/Button";

export default function ClientLinks() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [action, setAction] = useState(null);
  const [linkIsPublic, setLinkIsPublic] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(30);
  const [client, setClient] = useState(null);
  const [clientFilter, setClientFilter] = useState(null);

  const [createclientlinks] = useMutation(CREATECLIENTLINKS);
  const [updateclientlinks] = useMutation(UPDATECLIENTLINKS);
  const [deleteclientlink] = useMutation(DELETECLIENTLINK);
  const { data, loading, error, refetch } = useQuery(GETCLIENTSLINKSANDSHEETS, {
    variables: {
      page,
      size,
      client: clientFilter?.value ?? null,
      includePrivateDocs: true,
    },
  });

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.5 7L0 0L6.5 3.5L13 0L6.5 7Z" fill="#F6F6F6" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const columns = [
    {
      name: "Title",
      selector: ({ title, link }) => (
        <div>
          <a target="_blank" href={link} title={link}>
            {title}
          </a>
        </div>
      ),
      wrap: true,
    },
    {
      name: "Private",
      selector: ({ private: privatedoc }) => (
        <div>{privatedoc ? `True` : `False`}</div>
      ),
      wrap: true,
    },
    {
      name: "Client",
      selector: ({ client }) => (
        <div>{client ? <>{client?.name}</> : `N/A`}</div>
      ),
      wrap: true,
    },
    {
      name: "Actions",
      selector: ({
        _id,
        client,
        title,
        description,
        private: privatedoc,
        link,
      }) => (
        <div>
          <Button
            className="smart-button small"
            onClick={(_) => {
              setAction({
                module: "edit",
                data: {
                  _id,
                  client: client?._id,
                  title,
                  description,
                  link,
                  name: client?.name,
                },
              });
              setLinkIsPublic(privatedoc);
            }}
          >
            Edit
          </Button>
          <Button
            className="ms-2 smart-button small"
            style={{
              backgroundColor: "red",
              borderColor: "red",
            }}
            onClick={async (_) => {
              if (
                window.confirm(
                  "Are you sure you wan't to delete this Link ? This is irreversible."
                )
              )
                await deleteclientlink({
                  variables: {
                    id: _id,
                  },
                });
              toast.success("Link Deleted");
              refetch();
            }}
          >
            Delete
          </Button>
        </div>
      ),
      wrap: true,
      width: "30%",
      right: true,
    },
  ];
  if (error) return <> {error?.message}</>;
  return (
    <>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Links / Documents</h2>
          <div>
            {!is_user && (
              <Button
                className="smart-button big active"
                onClick={(_) =>
                  setAction({
                    module: "add",
                  })
                }
              >
                Add New Link
              </Button>
            )}
          </div>
        </div>
      </div>

      {action && (
        <>
          <div className="form-wrapper add-sheet-link-modal">
            <div className="form-wrapper-inner form-wrapper-inner__updated">
              <div className="card">
                <button
                  className="close-modal"
                  onClick={(_) => setAction(null)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#000"
                  >
                    <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                  </svg>{" "}
                </button>
                <div className="card-body p-0">
                  <h3> Manage Client Links</h3>
                  <form
                    onSubmit={async (_) => {
                      _.preventDefault();
                      const title = _.target.title.value;
                      const description = _.target.description.value;
                      const link = _.target.link.value;

                      if (title === "" || link === "") {
                        toast.error("Please insert all required fields");
                        return;
                      }
                      let clientLinksInput = {
                        client: client?.value ?? null,
                        title,
                        description,
                        link,
                        private: linkIsPublic,
                        viewId: null,
                      };
                      // const id = editData?._id;
                      try {
                        if (action?.module === "add") {
                          await createclientlinks({
                            variables: {
                              clientLinksInput,
                            },
                          });
                        } else {
                          const id = action?.data?._id;
                          await updateclientlinks({
                            variables: {
                              id,
                              clientLinksInput,
                            },
                          });
                          toast.success("Link Updated");
                        }

                        setAction(null);
                        setClient(null);
                        setLinkIsPublic(false);
                        refetch();
                        // if (_updateclient?.data) {
                        //   toast.success(`Record Updated!`);
                        //   toggleEditForm(false);
                        //   setEditNull();
                        //   refetch();
                        // }
                      } catch (error) {
                        toast.error(`${error?.message}`);
                      }
                    }}
                  >
                    <div className="fields-wrapper fields-wrapper__updated">
                      <label> Select Client *</label>
                      <Select
                        onChange={(client) => {
                          setClient(client);
                        }}
                        value={client}
                        // menuIsOpen={true}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        placeholder={
                          action?.module === "edit"
                            ? action?.data?.name
                              ? action?.data?.name
                              : "N/A"
                            : `Select Client`
                        }
                        options={myProfile?.client?.map((client) => ({
                          value: client?.value?._id,
                          label: client?.label,
                        }))}
                        components={{ DropdownIndicator }}
                      />
                    </div>
                    <div className="fields-wrapper fields-wrapper__updated">
                      <label> Title</label>
                      <input
                        name="title"
                        className="field-control field-control__updated"
                        defaultValue={action?.data?.title}
                        placeholder="Document Title"
                      />
                    </div>
                    <div className="fields-wrapper fields-wrapper__updated">
                      <label> Description</label>
                      <textarea
                        name="description"
                        className="field-control field-control__updated"
                        defaultValue={action?.data?.description}
                        cols={5}
                        rows={4}
                      ></textarea>
                    </div>
                    <div className="fields-wrapper fields-wrapper__updated">
                      <label> Link</label>
                      <input
                        name="link"
                        className="field-control field-control__updated"
                        defaultValue={action?.data?.link}
                        placeholder="Document Link"
                      />
                    </div>
                    <div className="fields-wrapper">
                      <span
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={(_) => setLinkIsPublic(!linkIsPublic)}
                      >
                        {linkIsPublic ? (
                          <>
                            <FiLock /> <span> Link is Private</span>
                          </>
                        ) : (
                          <>
                            <FiUsers /> Link is Public
                          </>
                        )}
                      </span>
                    </div>

                    <div className="fields-wrapper">
                      <Button className="smart-button medium active full-width">
                        {" "}
                        {action?.module} Link
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <DataTable
        columns={columns}
        data={data?.getClientLinks?.links}
        progressPending={loading}
        customStyles={customStyles}
        progressComponent={<MyLoader />}
      />
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={data?.getClientLinks?.total}
      />
    </>
  );
}
