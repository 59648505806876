import { DetailsTab } from "./DetailsTab";

export const ExperimentsData = ({
  experiments,
  title,
  users,
  index,
  uniqueUsersWithTransactions,
}) => {
  // ------------
  const sessions = experiments?.reduce(
    (cumm, vexpval) => cumm + Number(vexpval?.sessions),
    0
  );
  // ----------------
  const transactions = experiments?.reduce(
    (cumm, vexpval) => cumm + Number(vexpval?.transactions),
    0
  );
  const conversionratesessions = transactions / sessions;
  const conversionrateusers = transactions / users;
  const revenue = experiments
    ?.reduce((cumm, vexpval) => cumm + Number(vexpval?.transactionRevenue), 0)
    .toFixed(2);

  const averagerevenueperuser = revenue / users;
  const averageordervalue = revenue / transactions;

  return (
    <>
      <DetailsTab
        index={index}
        title={title}
        vlusers={users}
        vlsessions={sessions}
        vltransactions={transactions}
        vlCRsession={conversionratesessions}
        vlCRuser={conversionrateusers}
        vlAVGRPU={isNaN(averagerevenueperuser) ? 0 : averagerevenueperuser}
        vlrevenue={revenue}
        vlAOV={averageordervalue}
      />
    </>
  );
};
