import { useEffect, useState } from "react";
import useStore from "../../../Store";
import _, { debounce } from "lodash";
import { useMutation } from "@apollo/client";
import {
  CREATEENVIRONMENT,
  UPDATEENVIRONMENT,
} from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
import { Button } from "../../MiroComponents/Button/Button";
import Input from "../../MiroComponents/Input/Input";

const defaultState = {
  name: "",
  rules_js: "",
  loading: true,
};

export const EnvironmentTargetingForm = ({
  editObject,
  refreshPage,
  id,
  showForm,
}) => {
  const selectedClient = useStore((state) => state.selectedClient);
  const [otherData, setOtherData] = useState(defaultState);
  const [createEnvironment] = useMutation(CREATEENVIRONMENT);
  const [updateEnvironment] = useMutation(UPDATEENVIRONMENT);
  useEffect(() => {
    if (editObject) {
      setOtherData({
        ..._.omit(editObject, ["__typename", "client"]),
        loading: false,
      });
    }
    if (showForm) {
      setOtherData({
        ...defaultState,
        loading: false,
      });
    }
  }, [editObject, id, showForm]);

  const updateState = debounce((key, value) => {
    setOtherData({ ...otherData, [key]: value });
  }, 1000);

  return (
    <div>
      <form
        className="default-margin-bottom default-padding default-border bg-1"
        onSubmit={async (e) => {
          e.preventDefault();
          if (!otherData?.name || !otherData?.rules_js) {
            return toast.error("Please fill all the fields");
          }

          const environmentPayload = {
            ..._.omit(otherData, ["loading", "_id"]),
            client: selectedClient?.value?._id,
          };
          //   console.log("goalPayload", goalPayload);
          if (editObject && editObject?._id) {
            const { data } = await updateEnvironment({
              variables: {
                id: editObject?._id,
                environmentPayload,
              },
            });
            refreshPage(data?.updateEnvironment);
          } else {
            const { data } = await createEnvironment({
              variables: {
                environmentPayload,
              },
            });
            refreshPage(data?.createEnvironment);
          }
          toast.success("Data Updated Successfully");
        }}
      >
        <Input
          type={"text"}
          value={otherData?.name || ""}
          inputWrapperClass="full-width medium bg-2"
          label="Environment Name"
          placeholder={"Enter Name"}
          name="name"
          onChange={(e) => {
            updateState("name", e.target.value);
          }}
        />

        <Input
          type={"textarea"}
          value={otherData?.rules_js || ""}
          inputWrapperClass="full-width medium bg-2"
          label="Rules for JS code to run"
          placeholder={"return true;"}
          name="rules_js"
          onChange={(e) => {
            updateState("rules_js", e.target.value);
          }}
        />

        <div className="d-flex justify-end default-column-gap">
          <Button
            className="smart-button medium notactive"
            type="button"
            onClick={(e) => {
              refreshPage(null);
            }}
            text={`Cancel`}
          />
          <Button
            className="smart-button medium active"
            type="Submit"
            text={`${editObject ? `Update` : `Create`} Environment`}
          />
        </div>
      </form>
    </div>
  );
};
