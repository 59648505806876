import { differenceInCalendarDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Calendar } from "./Icons";
import { Button } from "../MiroComponents/Button/Button";

export const DateRangeSelectorComponent = ({
  showCalendar,
  setShowCalendar,
  selectionRange,
  setSelectionRange,
  callBackWhenClose,
  showDays,
  className,
  classNameWrapper,
  color,
}) => {
  const [calendarDirection, setCalendarDirection] = useState("horizontal");
  const [oldSelection, setOldSelection] = useState(null);
  useEffect(() => {
    setOldSelection(selectionRange);
  }, []);

  const manageCalendarDirection = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    let direction = "horizontal";
    if (vw <= 767) {
      direction = "vertical";
    } else {
      direction = "horizontal";
    }
    if (calendarDirection !== direction) {
      setCalendarDirection(direction);
    }
  };

  useEffect(() => {
    manageCalendarDirection();
  }, []);

  const days =
    Number(
      differenceInCalendarDays(
        new Date(selectionRange[0]?.endDate),
        new Date(selectionRange[0]?.startDate)
      )
    ) + 1;

  return (
    <>
      <span>
        <button
          type="button"
          onClick={(_) => setShowCalendar(!showCalendar)}
          className="btn btn-default d-inline-flex align-items-center"
          style={{
            columnGap: "10px",
          }}
        >
          <Calendar color={color} />
          <span className="range-selected">
            {format(new Date(selectionRange[0]?.startDate), "dd.MMMM.yyyy")} -{" "}
            {format(new Date(selectionRange[0]?.endDate), "dd.MMMM.yyyy")}
          </span>
        </button>
        {showDays && <span className="numdays">Days : {days}</span>}
      </span>

      {showCalendar && (
        <div className={`datepicker-wrapper ${classNameWrapper}`}>
          <DateRangePicker
            showSelectionPreview={true}
            maxDate={new Date()}
            moveRangeOnFirstSelection={true}
            months={2}
            direction={calendarDirection}
            ranges={selectionRange}
            className={className}
            onChange={(item) => {
              setSelectionRange([item.selection]);
            }}
          />
          <div className="buttons-operations">
            <Button
              className="smart-button small"
              onClick={(_) => {
                setShowCalendar(false);
                setSelectionRange(oldSelection);
              }}
            >
              Cancel
            </Button>
            <Button
              className="smart-button small active"
              onClick={(_) => {
                callBackWhenClose(selectionRange);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
