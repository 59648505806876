import { useMutation, useQuery } from "@apollo/client";
import { GETNEWIDEAS, GETPAGEOBJECTS } from "../../Graphql/Queries";
import { GETHYPOTHESIS } from "../../Graphql/Queries";
import React, { useEffect, useState } from "react";
import "../Layouts/TestIdeas.css";
import { InfoIcon, NoteIcon, BackIcon } from "./Icons";
import MyLoader from "../MiroComponents/PreLoader";
import { FaEdit } from "react-icons/fa";
import { TimesCross } from "./Icons";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import { CREATENEWIDEAS, UPDATENEWIDEAS } from "../../Graphql/Mutations";
import { CREATEHYPOTHESIS, UPDATEHYPOTHESIS } from "../../Graphql/Mutations";
import { Priotrizations } from "../../Libs/Priotrizations";
import { PriotriLabel } from "../../Libs/PriotriLabel";
import { Pagination } from "./Paginations";

export const IdeasDataSheet = ({ client, viewId }) => {
  const [page, setPage] = useState(1);
  const [pageType, setPageType] = useState(null);
  const [action, setAction] = useState(null);
  const [priorityDetailsValues, setpriorityDetails] = useState(null);
  const size = 20;
  const getpageobjects = useQuery(GETPAGEOBJECTS);
  const [hypothesis, setHypothesis] = useState(null);
  const [createNewIdeas] = useMutation(CREATENEWIDEAS);
  const [updateNewIdeas] = useMutation(UPDATENEWIDEAS);
  const [createhypothesis] = useMutation(CREATEHYPOTHESIS);
  const {
    data: newIdeasData,
    loading: newIdeasLoading,
    error: newIdeasError,
    refetch: refetchNewIdeas,
  } = useQuery(GETNEWIDEAS, {
    fetchPolicy: "network-only",
    variables: {
      client,
      viewId,
      page,
      size,
    },
  });

  const {
    data: hypothesisData,
    loading: hypothesisLoading,
    error: hypothesisError,
    refetch: refetchHypothesis,
  } = useQuery(GETHYPOTHESIS, {
    variables: {
      fullSet: false,
    },
  });
  // const tempIdeas = newIdeasData?.getNewIdeas?.notes;
  // const tempHypo = hypothesisData?.allHypothesis?.hypotheses;

  useEffect(() => {
    if (newIdeasData?.getNewIdeas?.notes) {
      let updatedPriority = { ...priorityDetailsValues }; // Copy the existing state

      newIdeasData?.getNewIdeas?.notes.forEach((item) => {
        const priorityDetails = item?.priorityDetails
          ? item?.priorityDetails
          : null;
        updatedPriority = { ...updatedPriority, [item?._id]: priorityDetails };
      });

      setpriorityDetails(updatedPriority);
    }
  }, [newIdeasData]);

  // console.log(newIdeasData?.getNewIdeas?.notes, "NOTES");

  // const mergedArray = [];

  // if (tempHypo && tempIdeas) {
  //   tempHypo.forEach((hypothesisItem) => {
  //     const hypothesisId = hypothesisItem._id;

  //     const correspondingNote = tempIdeas.find(
  //       (noteItem) => noteItem.hypothesisId === hypothesisId
  //     );

  //     // console.log(hypothesisItem, correspondingNote, "HYP ITEM");

  //     if (correspondingNote) {
  //       const mergedItem = {
  //         ...hypothesisItem,
  //         ...correspondingNote,
  //       };

  //       mergedArray.push(mergedItem);
  //     }
  //   });
  // }

  // const sortedNotes = mergedArray?.slice()?.sort((a, b) => {
  //   const prioA = a.totalPriority || 0;
  //   const prioB = b.totalPriority || 0;
  //   return prioB - prioA;
  // });

  const Header = () => (
    <div className="store-ideas-table-headers">
      {client && viewId && <h4 className="ideas-table-sno">Edit</h4>}
      <h4>
        PageType
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        Name
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        Hypothesis Name
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>Priority</h4>
    </div>
  );

  const IdeaHeader = () => (
    <div className="fields-wrapper fields-wrapper__updated input-groups">
      <div>
        <label>
          <h4>Page Type</h4>
        </label>
      </div>
      <div>
        <label>
          <h4>Name</h4>
        </label>
      </div>
      {PriotriLabel?.map((label, index) => {
        return (
          <div key={index}>
            <label>{label.name}</label>
          </div>
        );
      })}
    </div>
  );

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.5 7L0 0L6.5 3.5L13 0L6.5 7Z" fill="#F6F6F6" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const getDefaultValues = (item, index) => {
    const values = item.priorityDetails
      ? Object.values(item.priorityDetails)
      : [];
    return values[index];
  };

  const sumPriorityValues = (prioDetails) => {
    if (prioDetails) {
      return Object.values(prioDetails)
        .filter((item) => item !== undefined)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    }
  };

  const handleSetPriority = async () => {
    newIdeasData?.getNewIdeas?.notes?.map(async (item) => {
      if (!item?.pageType || item?.ideaName?.value === "") {
        toast.error("Please fill all the fields");
        return;
      }
      const priorityDetails = priorityDetailsValues[item?._id];
      const updatedpriorityDetails = {
        chan_made: priorityDetails?.chan_made,
        disc_by: priorityDetails?.disc_by,
        supp_by_qd: priorityDetails?.supp_by_qd,
        supp_by_hmt: priorityDetails?.supp_by_hmt,
        chen_noti: priorityDetails?.chen_noti,
        level_effort: priorityDetails?.level_effort,
        is_it_add_remo: priorityDetails?.is_it_add_remo,
        chen_on_pg: priorityDetails?.chen_on_pg,
        desi_to_incr: priorityDetails?.desi_to_incr,
        desi_to_simp: priorityDetails?.desi_to_simp,
        thou_of_team: priorityDetails?.thou_of_team,
      };
      const newIdeasPayload = {
        client,
        viewId,
        hypothesis: item?.hypothesis?._id,
        pageType: item?.pageType?._id,
        ideaName: item?.ideaName,
        priorityDetails: updatedpriorityDetails,
        totalPriority: String(sumPriorityValues(updatedpriorityDetails)),
      };

      if (action?.module === "pri") {
        await updateNewIdeas({
          variables: {
            updateNewIdeasId: item?._id,
            newIdeasPayload: {
              ...newIdeasPayload,
            },
          },
        });
      }
    });
    toast.success("Priorities updated successfully");
    setAction(false);
    refetchNewIdeas();
  };

  const createHypoWithIdea = async (e) => {
    e.preventDefault();
    const { name, result, action, expectation } = e.target;
    const hypothesisPayload = {
      name: name.value,
      result: result.value,
      action: action.value,
      expectation: expectation.value,
      client: client,
      viewId: viewId,
      tagged: "Client Specific",
    };
    try {
      const { data } = await createhypothesis({
        variables: {
          hypothesisPayload,
        },
      });
      toast.success("Hypothesis Added Successfully");
      refetchHypothesis();
      const hypoId = data.createHypothesis._id;
      return hypoId;
    } catch (error) {
      toast.error("Error creating hypothesis");
    }
  };

  if (newIdeasLoading) return <MyLoader />;
  if (newIdeasError) return <div> {newIdeasError?.message}</div>;
  return (
    <div>
      {(action?.module === "add" || action?.module === "edit") && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner form-wrapper-inner__updated addtestform  scroll">
            <div
              className="card add-new-test-card scroll modal-card"
              style={{ minHeight: "fit-content" }}
            >
              <button
                className="close-modal"
                onClick={(_) => {
                  setAction(null);
                  setPageType(null);
                  setHypothesis(null);
                }}
              >
                <TimesCross />
              </button>
              <div className="header-hidden-on-desktop">
                <h3>Make Idea</h3>
              </div>
              <h3>Save New Test Hypothesis</h3>
              <div className="card-body p-0">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const { iname } = e.target;

                    if (!pageType || iname?.value === "") {
                      toast.error("Please fill all the fields");
                      return;
                    }
                    const newIdeasPayload = {
                      client,
                      viewId,
                      pageType: pageType?.value,
                      ideaName: iname?.value,
                      //evidence: evidences.map(({ index, ...rest }) => rest),
                    };

                    if (action?.module === "add") {
                      const hyId = await createHypoWithIdea(e);

                      const upNewIdeasPayload = {
                        ...newIdeasPayload,
                        hypothesis: hyId,
                      };

                      await createNewIdeas({
                        variables: {
                          newIdeasPayload: {
                            ...upNewIdeasPayload,
                            hypothesis: hyId,
                          },
                        },
                      });
                      toast.success("Idea added successfully");
                      setAction(null);
                    }

                    if (action?.module === "edit") {
                      newIdeasPayload.hypothesis =
                        action?.data?.hypothesis?._id;
                      await updateNewIdeas({
                        variables: {
                          updateNewIdeasId: action?.data?._id,
                          newIdeasPayload: {
                            ...newIdeasPayload,
                          },
                        },
                      });
                      toast.success("Idea updated successfully");
                      setAction(null);
                    }
                    refetchNewIdeas();
                  }}
                >
                  <div className="fields-wrapper">
                    <div>
                      <label> Select Page Type the test targets *</label>
                      <Select
                        onChange={(pageType) => {
                          setPageType(pageType);
                        }}
                        value={pageType}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        placeholder="Select Page Type"
                        options={getpageobjects?.data?.pageelements?.map(
                          (dt) => ({
                            label: dt?.name,
                            value: dt?._id,
                          })
                        )}
                        components={{ DropdownIndicator }}
                      />
                    </div>
                  </div>
                  <div className="fields-wrapper">
                    <div>
                      <label>Data Insight</label>
                      <input
                        name="iname"
                        id="iname"
                        defaultValue={action?.data?.ideaName ?? ""}
                        className="field-control  field-control__updated"
                        placeholder="In which tool did you find data supporting the hypothesis?"
                      />
                    </div>
                  </div>
                  <div
                    className="fields-wrapper"
                    style={
                      action?.module === "edit"
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <label>Select from already saved Hypothesis</label>
                    <Select
                      options={hypothesisData?.allHypothesis?.hypotheses?.map(
                        (hp) => {
                          // const labelTitle = <strong>{hp?.name}</strong>;
                          return {
                            label: `${hp?.name} - ${hp?.action} - ${hp?.expectation} - ${hp?.result}`,
                            value: JSON.stringify(hp),
                          };
                        }
                      )}
                      isDisabled={action?.module === "edit"}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      value={hypothesis}
                      onChange={(hypo) => {
                        const selectors = [
                          "name",
                          "action",
                          "expectation",
                          "result",
                        ];
                        const value = JSON.parse(hypo?.value);
                        selectors.forEach((selector) => {
                          document.getElementById(selector).value =
                            value[selector];
                        });
                        setHypothesis(hypo);
                      }}
                    />
                  </div>
                  {action?.module === "add" && (
                    <>
                      <div className="fields-wrapper">
                        <label>Create new Hypothesis</label>
                        <input
                          className="field-control"
                          name="name"
                          id="name"
                          defaultValue={action?.data?.hypothesis?.name}
                          placeholder="Give Name"
                        />
                      </div>
                      <div className="fields-wrapper">
                        <label>If We Do X</label>
                        <input
                          className="field-control"
                          name="action"
                          id="action"
                          defaultValue={action?.data?.hypothesis?.action}
                          placeholder="If we do x"
                        />{" "}
                      </div>
                      <div className="fields-wrapper">
                        <label> Then Y Happens</label>
                        <input
                          className="field-control"
                          defaultValue={action?.data?.hypothesis?.result}
                          name="result"
                          id="result"
                          placeholder="Then y happens"
                        />{" "}
                      </div>
                      <div className="fields-wrapper">
                        <label> Because We Expect Z</label>
                        <input
                          className="field-control"
                          name="expectation"
                          id="expectation"
                          defaultValue={action?.data?.hypothesis?.expectation}
                          placeholder="Because we expect"
                        />{" "}
                      </div>
                    </>
                  )}
                  <div className="add-idea-btn">
                    <div className="btn-prev" onClick={(_) => setAction(null)}>
                      <BackIcon /> Cancel
                    </div>
                    <div className="fields-wrapper">
                      <button type="submit" className="next-btn">
                        {" "}
                        Submit{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {action?.module === "pri" && (
        <div className="form-wrapper" id="add-new-test-form">
          <div
            className="form-wrapper-inner form-wrapper-inner__updated addtestform  scroll"
            id="idea-model"
          >
            <div
              className="card add-new-test-card scroll modal-card"
              style={{ minHeight: "fit-content" }}
            >
              <button className="close-modal" onClick={(_) => setAction(null)}>
                <TimesCross />
              </button>
              <div className="header-hidden-on-desktop">
                <h3>Set Priotrization</h3>
              </div>
              <h3>Set Priotrization</h3>

              <div className="card-body p-0">
                <div className="idea-prio-head">
                  <IdeaHeader />
                </div>
                {newIdeasData?.getNewIdeas?.notes?.map((item, index1) => (
                  <form key={index1}>
                    <div className="fields-wrapper fields-wrapper__updated input-groups">
                      <div className="idea-medel-pt-nm">
                        <label>
                          <h6>{item.pageType.name}</h6>
                        </label>
                      </div>
                      <div className="idea-medel-pt-nm">
                        <label>
                          <h6>{item.ideaName}</h6>
                        </label>
                      </div>
                      {Priotrizations?.map((filter, index) => {
                        return (
                          <div key={`${item?._id}-${index}`}>
                            <select
                              name={filter.name}
                              className="field-control  field-control__updated"
                              defaultValue={getDefaultValues(item, index)}
                              onChange={(e) => {
                                const priorityDetails = priorityDetailsValues
                                  ? priorityDetailsValues[item?._id]
                                  : null;
                                setpriorityDetails({
                                  ...priorityDetailsValues,
                                  [item?._id]: {
                                    ...priorityDetails,
                                    [e.target.name]: e.target.value,
                                  },
                                });
                              }}
                            >
                              {filter?.option?.map((opt, inx) => {
                                return (
                                  <option
                                    value={filter?.value?.[inx]}
                                    key={inx}
                                  >
                                    {opt}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        );
                      })}
                      <div className="result-idea">
                        <label>
                          {priorityDetailsValues &&
                          priorityDetailsValues[item?._id]
                            ? sumPriorityValues(
                                priorityDetailsValues[item?._id]
                              )
                            : 0}
                        </label>
                      </div>
                    </div>
                  </form>
                ))}
                <div className="fields-wrapper">
                  <button
                    type="button"
                    className="next-btn-idea"
                    onClick={() => handleSetPriority()}
                  >
                    {" "}
                    Set{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {client && viewId && (
        <div className="add-hypothesis-store-ideas">
          <div
            onClick={(e) => {
              setAction({
                module: "add",
              });
              setPageType(null);
              setHypothesis(null);
            }}
          >
            <NoteIcon />
            <span className="add-hypothesis-store-ideas-title">
              Add New Idea
            </span>
          </div>
          <span
            className="add-hypothesis-store-ideas-title"
            onClick={(e) => {
              setAction({
                module: "pri",
                data: newIdeasData?.getNewIdeas.notes,
              });
            }}
          >
            Set Priotrization
          </span>
        </div>
      )}
      <div className="store-ideas-table__g">
        <Header />
        <div className="store-ideas-table-rows">
          {newIdeasData?.getNewIdeas?.notes?.map((item, index) => (
            <div className="store-ideas-table-single-row" key={index}>
              <h4
                style={
                  !client || !viewId
                    ? { display: "none" }
                    : { display: "block" }
                }
                className="ideas-table-sno store-ideas-table-data"
                onClick={(e) => {
                  setAction({
                    module: "edit",
                    data: item,
                  });
                  setPageType({
                    label: item?.pageType?.name,
                    value: item?.pageType?._id,
                  });
                }}
              >
                <FaEdit style={{ cursor: "pointer" }} />
              </h4>
              <h4 className="store-ideas-table-data select">
                {item?.pageType?.name}
              </h4>
              <h4 className="store-ideas-table-data">{item?.ideaName}</h4>
              <h4 className="store-ideas-table-data">
                {item.hypothesis?.name}
              </h4>
              <h4 className="store-ideas-table-data">
                {item?.priorityDetails
                  ? sumPriorityValues(item?.priorityDetails)
                  : 0}
              </h4>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={newIdeasData?.getNewIdeas?.total ?? 0}
      />
    </div>
  );
};
