import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar } from "react-chartjs-2";

function ConfidenceOfUpliftGraph({ upliftValues, label }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          color: "rgb(255 255 255 / 5%)",
        },
      },
      x: {
        grid: {
          display: true,
          color: "rgb(255 255 255 / 5%)",
        },
        // suggestedMin: 50,
        // suggestedMax: 90,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Probability of being a winner",
      },
      datalabels: {
        color: "#fff",
        font: {
          weight: "400",
          family: "'Neue Haas Grotesk Text Pro', 'sans-serif'",
          size: 12,
          lineHeight: "110%",
        },
        formatter: function (value, context) {
          return value + " % ";
        },
      },
    },
  };
  const labels = ["Control", label];
  const colors = [
    "255,51,60",
    "0, 62,212",
    "34 193 195", // Light teal
    "123 237 159", // Mint green
    "234 91 159", // Soft pink
    "72 52 212", // Deep purple
    "52 73 94", // Dark slate gray
    "46 204 113", // Light green
    "41 128 185", // Rich blue
  ];

  const getGradient = (ctx, chartArea) => {
    let gradient2 = labels.map((elm, index) => {
      const gradient = ctx.createLinearGradient(0, 0, chartArea.width, 0);
      gradient.addColorStop(0, `rgba(${colors[index]},0.2)`);
      // gradient.addColorStop(0.5, `rgba(${colors[index]},0.5)`);
      gradient.addColorStop(1, `rgba(${colors[index]},0.8)`);
      return gradient;
    });
    return gradient2;
  };

  const dataset = {
    labels,
    datasets: [
      {
        data: upliftValues,
        borderColor: "transparent",
        barPercentage: 0.6,
        borderRadius: 5,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea);
        },
      },
    ],
  };

  return (
    <div className="report-charts-height">
      <Bar options={options} data={dataset} />
    </div>
  );
}

export default ConfidenceOfUpliftGraph;
