import moment from "moment";

export const TestsTimelineList = ({
  test,
  report,
  selectedReport,
  setSelectedReport,
}) => {
  return (
    <div
      className={`test-timeline-card-single ${
        selectedReport === report?._id ? `timeline-card-selected` : ``
      } `}
      onClick={(_) => {
        setSelectedReport(report?._id);
        setTimeout(function () {
          document.querySelector(".selected-test-content").scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      }}
    >
      <h3 className="timeline-card-title">{test?.name}</h3>
      <div className="timeline-card-divider">
        <p className="header-content test-live-date">
          <span className="header live-date">Live Date: </span>
          {moment(report?.from).format("ll")}
        </p>
        <p className="header-content test-live-date">
          <span className="header end-date">End Date: </span>{" "}
          {moment(report?.to).format("ll")}
        </p>
        <p className="header-content test-live-date">
          <span className="header conclusion">Conclusion: &nbsp;</span>
          <span
            className={`${
              report?.conclusion === "winner" ? `winner` : `no-uplift`
            }`}
          >
            {report?.conclusion}
          </span>{" "}
          {report?.segmentName ? `(${report?.segmentName})` : " "}
        </p>
      </div>
    </div>
  );
};
