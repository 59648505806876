import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { UPDATECLIENTRETENTIONS } from "../../../Graphql/Mutations";
import {
  GETCLIENTPROPERTIES,
  GETCLIENTRETENTIONS,
} from "../../../Graphql/Queries";
import { YearsList } from "../../../Libs/Utilities";
import { NotAuthorized } from "../../Shared/NotAuthorized";

export const ManageClientRetentions = () => {
  const [myprofile, is_client, is_admin, is_user] = useOutletContext();
  const params = useParams();
  const { hash } = useLocation();
  const [noRetainer, setNoRetainer] = useState(true);
  const [retainerValue, setRetainerValue] = useState(null);
  const [retainers, setRetainers] = useState([]);
  const [getclientretentions] = useLazyQuery(GETCLIENTRETENTIONS);
  const [updateclientretentions] = useMutation(UPDATECLIENTRETENTIONS);
  const { data } = useQuery(GETCLIENTPROPERTIES, {
    variables: {
      id: params?.clientId,
    },
  });

  const clientRetentionQuery = useCallback(async () => {
    try {
      const { data } = await getclientretentions({
        fetchPolicy: "network-only",
        variables: {
          client: params?.clientId,
          year: Number(hash.replace("#", "")),
        },
      });
      if (data?.clientRetentions) {
        const _payload = data?.clientRetentions?.retentions;
        const _allMonthsRetainers = Array.from({ length: 12 }, (item, i) => {
          const valueforthismonth = _payload.find(
            (item) =>
              item?.month === i + 1 &&
              item?.year === Number(hash.replace("#", ""))
          );
          return {
            year: _payload[0]?.year,
            monthName: new Date(0, i).toLocaleString("en-US", {
              month: "long",
            }),
            month: i + 1,
            active: valueforthismonth?.active || true,
            retainerValue: valueforthismonth?.retainerValue || 0,
            otherPayments: valueforthismonth?.otherPayments || 0,
          };
        });
        setRetainers(_allMonthsRetainers);
        setNoRetainer(false);
      } else {
        setNoRetainer(true);
      }
    } catch (error) {
      toast.error("Error fetching client retentions");
    }
  }, [hash]);

  useEffect(() => {
    if (hash) {
      clientRetentionQuery();
    }
  }, [clientRetentionQuery]);

  const manageRetainers = (retainers, field, value, filterField) => {
    const _retainers = retainers.filter((item) => item?.month !== filterField);
    const _obj = { ...retainers.find((item) => item?.month === filterField) };
    _obj[field] = value;
    const newArr = [..._retainers, _obj].sort((a, b) => a.month - b.month);
    setRetainers(newArr);
  };

  const updateClientRetention = async () => {
    const { data } = await updateclientretentions({
      variables: {
        input: {
          client: params?.clientId,
          year: Number(hash.replace("#", "")),
          retentions: retainers,
        },
      },
    });
    if (data) {
      toast.success("Retainers Updated");
    }
  };
  if (!is_admin) return <NotAuthorized />;
  return (
    <div>
      <div className="report-head-main">
        <h1 className="section-heading-title">
          Client Retainers <small>({data?.clientProperties?.name})</small>{" "}
        </h1>
      </div>
      <div className="client-data-wrapper">
        <div className="client-details">
          <div>
            <div className="client-dropdown">
              <h1 className="section-heading">Select Year:</h1>
              <div className="fields-wrapper">
                <Select
                  onChange={(year) => {
                    setRetainers([]);
                    window.location.hash = `#${year?.value}`;
                  }}
                  // isDisabled={}
                  value={{
                    label: hash.replace("#", ""),
                    value: hash.replace("#", ""),
                  }}
                  className="acc-newselectwrapper"
                  classNamePrefix="acc-newselect"
                  placeholder={`Select Year`}
                  // defaultMenuIsOpen={true}
                  options={YearsList()?.map((c) => {
                    return {
                      label: c,
                      value: c,
                    };
                  })}
                  isOptionDisabled={(option) =>
                    option.value <
                    moment(data?.clientProperties?.onboardDate).format("YYYY")
                  }
                />
              </div>
            </div>

            {noRetainer && (
              <>
                {hash && (
                  <div className="fields-wrapper">
                    {/* <label> Add Retainer Value</label> */}
                    <input
                      type="number"
                      placeholder="Value for retainer for year"
                      className="field-control"
                      onChange={(_) => {
                        setRetainers([]);
                        setRetainerValue(Number(_?.currentTarget.value));
                      }}
                    />
                  </div>
                )}

                {retainerValue && (
                  <div className="fields-wrapper">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        const _retainers = Array.from(
                          { length: 12 },
                          (item, i) => {
                            return {
                              year: Number(hash.replace("#", "")),
                              monthName: new Date(0, i).toLocaleString(
                                "en-US",
                                {
                                  month: "long",
                                }
                              ),
                              month: Number(
                                new Date(0, i).toLocaleString("en-US", {
                                  month: "2-digit",
                                })
                              ),
                              active: true,
                              retainerValue,
                              otherPayments: 0,
                            };
                          }
                        );

                        setRetainers(_retainers);
                      }}
                    >
                      Generate
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="retainer-management-form">
        {retainers?.map((item, i) => (
          <div className="retainer-wrapper" key={i}>
            <div className="fields-wrapper">
              <label> Year </label>
              <input
                disabled
                defaultValue={item?.year}
                className="field-control"
              />
            </div>
            <div className="fields-wrapper">
              <label> Month </label>
              <input
                disabled
                defaultValue={item?.monthName}
                className="field-control"
              />
            </div>
            <div className="fields-wrapper">
              <label> Retainer Value </label>
              <input
                type="number"
                defaultValue={item?.retainerValue}
                onChange={(_) =>
                  manageRetainers(
                    retainers,
                    "retainerValue",
                    Number(_.currentTarget.value),
                    item?.month
                  )
                }
                className="field-control"
              />
            </div>
            <div className="fields-wrapper">
              <label> Other Payments </label>
              <input
                type="number"
                defaultValue={item?.otherPayments}
                onChange={(_) =>
                  manageRetainers(
                    retainers,
                    "otherPayments",
                    Number(_.currentTarget.value),
                    item?.month
                  )
                }
                className="field-control"
              />
            </div>

            <div className="fields-wrapper">
              <button
                className={`btn btn-sm ${
                  item.active ? `btn-primary` : `btn-danger`
                }`}
                onClick={(_) => {
                  manageRetainers(
                    retainers,
                    "active",
                    !item.active,
                    item?.month
                  );
                }}
              >
                {item?.active ? `Mark Not Paid` : `Mark Paid`}{" "}
              </button>
            </div>
          </div>
        ))}
      </div>
      {retainers?.length > 0 && (
        <div style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary"
            onClick={(e) => {
              updateClientRetention();
            }}
          >
            Update Retainers
          </button>
        </div>
      )}
    </div>
  );
};
