import { useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { VscCheck, VscClose, VscReport } from "react-icons/vsc";
import { CHECKREPORTEXISTS, CREATEREPORT } from "../../../../Graphql/Mutations";
import { toast } from "react-toastify";
import InputEmoji from "react-input-emoji";
import {
  ReportFilterValues,
  ImplementedStatusOptions,
} from "../../../../Libs/ReportFiltersValues";
import Select from "react-select";
import {
  CrossIcon,
  CrossIcon2,
  CrossIconModal,
  TickGreen,
} from "../../../Shared/Icons";
import moment from "moment";
import { AddManualReportData } from "../../../MiroComponents/AddManualReportData";

export const CreateReportGa4 = ({
  reportDetails,
  currentReportType,
  is_user,
  myProfile,
  selectedTest,
  client,
  // metricFilterExpression,
  // dimensionFilterExpression,
  viewId,
  selectionRange,
}) => {
  const variantOptions = reportDetails?.result?.map((item) => {
    return {
      label: item?.controlvariant,
      value: item?.controlvariant,
    };
  });

  const [evaluationType, setEvaluationType] = useState("codebase");
  const addLearningsRef = useRef(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const platform = queryParameters.get("platform");
  const [showModal, setShowModal] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [segmentName, setSegmentName] = useState({ label: "", value: "" });
  const [selectedVariant, setVariant] = useState({ label: "", value: "" });
  const [manualData, setManualData] = useState([]);
  const [learnings, setLearnings] = useState([]);

  const initialImplementedStatus = {
    label: "",
    value: "",
  };
  const evaulationTypeValues = [
    { label: "Codebase", value: "codebase" },
    { label: "Manual", value: "manual" },
  ];

  const [implementedStatus, setImplementedStatus] = useState(
    initialImplementedStatus
  );
  const [checkReportExists] = useMutation(CHECKREPORTEXISTS);
  const [createreport] = useMutation(CREATEREPORT);
  const btns = ["Winner", "Neutral", "No Uplift"];
  const AllOption = [{ label: "All Users", values: ["All Users"] }];
  const initialtFactor = {
    "Change in CR": null,
    "Change in AOV": null,
    "Change in RPU": null,
  };
  const defaultFactorKey = "Change in CR";
  const [conclusionFactor, setConclusionFactor] = useState(initialtFactor);

  const groupedOptions = [...AllOption, ...ReportFilterValues].map((filter) => {
    return {
      label: filter.label,
      options: filter.values.map((op) => {
        return { label: op, value: op };
      }),
    };
  });

  const conclusionfactors = ["Change in CR", "Change in AOV", "Change in RPU"];

  //   console.log(reportDetails, currentReportType, is_user, selectedTest);

  // useEffect(() => {
  //   console.log(
  //     //   reportDetails,
  //     metricFilterExpression,
  //     dimensionFilterExpression
  //   );
  // }, [reportDetails]);
  const AddLearnings = React.forwardRef((props, ref) => {
    const inputRef = useRef(null);
    return (
      <div className="fields-wrapper fields-wrapper-group" ref={ref}>
        <label>Add Learnings</label>
        <div>
          <input
            id="learningText"
            ref={inputRef}
            placeholder="Add lernings from this test"
          />
          <button
            type="button"
            onClick={(_) => {
              if (inputRef.current.value.trim() !== "") {
                setLearnings((_learnings) => [
                  ..._learnings,
                  inputRef.current.value,
                ]);
              }
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  });

  if (!currentReportType || currentReportType?.type !== "live") return ``;
  return (
    <div className="create-reports-wrapper">
      <div className="create-reports-inner">
        {!is_user && (
          <div className="create-report-cta">
            <button
              onClick={async (_) => {
                const { data } = await checkReportExists({
                  variables: {
                    test: selectedTest?._id,
                  },
                });
                if (!data?.reportExistsForTest) {
                  setShowModal(!showModal);
                } else {
                  toast.error("Report already exists for this test!");
                }
              }}
            >
              <VscReport /> <span>Create Report</span>
            </button>
          </div>
        )}
      </div>

      {showModal && (
        <div className="create-report-modal">
          <div className="create-report-modal-inner" id="add-new-test-form">
            <div className="card">
              <button
                className="close-modal"
                onClick={(_) => {
                  setShowModal(!showModal);
                  setLearnings([]);
                }}
              >
                <CrossIconModal />
              </button>
              <div className="card-body">
                <h3> Save Report</h3>
                <div className="report-evaulation-type">
                  {evaulationTypeValues.map((type, i) => (
                    <button
                      key={i}
                      type="button"
                      disabled={platform === "GA4"}
                      onClick={(_) => {
                        setConclusionFactor(initialtFactor);
                        setEvaluationType(type.value);
                      }}
                      className={`btn btn-outline-dark text-white ${
                        evaluationType === type.value && `bg-primary text-white`
                      }`}
                    >
                      {type.label}
                    </button>
                  ))}
                </div>
                <form>
                  {evaluationType !== "manual" && (
                    <>
                      <div className="fields-wrapper input-groups">
                        <div>
                          <label>Add Segment Name *</label>
                          <div>
                            <Select
                              className="acc-newselectwrapper"
                              classNamePrefix="acc-newselect"
                              defaultValue={segmentName ?? ""}
                              placeholder="Add segment name"
                              onChange={(selectedOption) =>
                                setSegmentName(selectedOption)
                              }
                              options={groupedOptions}
                            />
                          </div>
                        </div>
                        <div>
                          <label>Evaluating Variant</label>
                          <div>
                            <Select
                              className="acc-newselectwrapper"
                              classNamePrefix="acc-newselect"
                              defaultValue={selectedVariant ?? ""}
                              placeholder="Add segment name"
                              onChange={(variant) => setVariant(variant)}
                              options={variantOptions}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {conclusionfactors.map((factor, i) => {
                          return (
                            <div key={`factor-${i}`}>
                              <p className="fw-bold text-white">{factor}</p>
                              <div className="fields-wrapper success-creteria">
                                {btns.map((btn, j) => (
                                  <div className="form-check" key={`btn-${j}`}>
                                    <input
                                      type="radio"
                                      name={`conclusion-tips-${i}`} // Use a unique name for each set of radio buttons
                                      checked={conclusionFactor[factor] === btn}
                                      value={btn}
                                      className="form-check-input"
                                      onChange={() => {
                                        setConclusionFactor({
                                          ...conclusionFactor,
                                          [factor]: btn,
                                        });
                                      }}
                                    />
                                    <label>
                                      {conclusionFactor[factor] === btn && (
                                        <VscCheck />
                                      )}
                                      {btn}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })}

                        {conclusionFactor[defaultFactorKey] && (
                          <div className="fields-wrapper">
                            <label>Message</label>
                            <InputEmoji
                              borderRadius={0}
                              borderColor="#dfe2e8"
                              value={notificationText}
                              onChange={setNotificationText}
                              cleanOnEnter
                              placeholder="Type a message"
                            />
                          </div>
                        )}
                      </div>
                      <div className="fields-wrapper">
                        <label>Implemented Status</label>
                        <div>
                          <Select
                            className="acc-newselectwrapper"
                            classNamePrefix="acc-newselect"
                            defaultValue={implementedStatus ?? ""}
                            placeholder="Implemented Status"
                            onChange={(status) => setImplementedStatus(status)}
                            options={ImplementedStatusOptions}
                          />
                        </div>
                      </div>

                      <AddLearnings ref={addLearningsRef} />
                    </>
                  )}
                  {/* 
                  {evaluationType === "manual" && (
                    <AddManualReportData
                      variantOptions={variantOptions}
                      manualData={manualData}
                      setManualData={setManualData}
                    />
                  )} */}

                  <div className="leanings-preview">
                    <ul>
                      {learnings.map((l, i) => (
                        <li key={i}>
                          <TickGreen />
                          <span>
                            {l}
                            <VscClose
                              onClick={(_) => {
                                const arr = [...learnings];
                                arr.splice(i, 1);
                                setLearnings(arr);
                              }}
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="fields-wrapper">
                    <label>Link to Report (optional)</label>
                    <div>
                      <input
                        id="reportLink"
                        className="field-control"
                        placeholder="Add external report link"
                        type="link"
                        // defaultValue={segmentName ?? ""}
                      />
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <button
                      type="button"
                      onClick={async (_) => {
                        let reportPayload = {
                          from: moment(selectionRange[0]?.startDate)
                            .startOf("day")
                            .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                          to: moment(selectionRange[0]?.endDate)
                            .endOf("day")
                            .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                          test: selectedTest?._id,
                          client,
                          learnings: [],
                          variant: null,
                          conclusion:
                            "no evaluation was done as there was no data in store",
                          viewId: viewId,
                          name: `${selectedTest?.name}^report`,
                          segmentName: "no data",
                          createdBy: myProfile?._id,
                          reportDetails: null,
                        };
                        if (evaluationType === "manual") {
                          // console.log("manualData", reportPayload);
                        } else {
                          if (
                            Object.values(conclusionFactor).some(
                              (value) => value === null
                            ) ||
                            defaultFactorKey === "" ||
                            segmentName?.value?.trim() === ""
                          ) {
                            toast.error("Please add all madatory fields");
                            return;
                          }
                          let processReport = {
                            ...reportDetails,
                          };
                          delete processReport.loading;
                          delete processReport.propertyQuota;
                          reportPayload = {
                            ...reportPayload,
                            segmentName: segmentName?.value,
                            variant: selectedVariant?.value,
                            conclusion:
                              conclusionFactor[defaultFactorKey]?.toLowerCase(),
                            learnings,
                            reportDetails: {
                              ...processReport,
                              conclusionFactor: defaultFactorKey?.toLowerCase(),
                              conclusionFactorStatusAll: conclusionFactor,
                              platform,
                              notificationText,
                              implementedStatus: implementedStatus?.value,
                              evaluationType,
                            },
                          };
                        }
                        const reportLink =
                          document.querySelector("#reportLink");
                        if (reportLink?.value?.trim() !== "") {
                          reportPayload.reportLink = reportLink?.value;
                        }
                        try {
                          const { data } = await createreport({
                            variables: {
                              reportPayload,
                            },
                          });
                          setShowModal(false);
                          setConclusionFactor(initialtFactor);
                          setImplementedStatus(initialImplementedStatus);
                          if (evaluationType === "manual") {
                            window.open(
                              `/create-manual-report/${data?.createReport?._id}`,
                              "_blank"
                            );
                            toast.success(
                              "Report saved. Please add manual data."
                            );
                          } else {
                            toast.success("Report saved.");
                          }
                        } catch (error) {
                          toast.error(error?.message);
                        }
                      }}
                    >
                      Create Report
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// {
//   "conclusion": "no evaluation was done as there was no data in store",
//   "learnings": [],
//   "segmentName": "no data",
//   "variant": null,
//   "client": "66b640fd4114daf042d1d23e",
//   "test": "66dd6a652a776ee26407dff8",
//   "from": "2024-09-06T00:00:00.000+05:30",
//   "to": "2024-09-15T23:59:59.999+05:30",
//   "name": "ACC_Stepr_CartDrawer_04^report",
//   "createdBy": "6239b7bb5129bc7ae25fdceb",
//   "reportDetails": null
// }
