import React, { Children } from "react";
import "./SmartButton.scss";

export const SmartButton = ({ icon, text, onClick, ...props }) => {
  return (
    <button onClick={onClick} {...props}>
      {icon}
      <span>{text}</span>
    </button>
  );
};
// path: src/Components/MiroComponents/SmartButton/SmartButton.jsx
