import React from "react";
import "./SmartContainer.scss";
export default function SmartContainer({
  children,
  title,
  className = "",
  style,
}) {
  return (
    <div className={`smart-container ${className}`} style={style}>
      {title && (
        <div className="smart-container-header">
          <h2>{title}</h2>
        </div>
      )}

      <div className="smart-container-body">{children}</div>
    </div>
  );
}
