import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GETREPORTANDTEST } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { NotFound } from "../Pages/NotFound";
import { ReportDetails } from "./ReportDetails";

export const NotificationDetails = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { module } = params;
  // const { moduleData } = location?.state;
  const [notificationData, setNotificationData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState(null);
  const [reportDetails] = useLazyQuery(GETREPORTANDTEST);

  const unsetDetails = () => {
    navigate(-1);
  };

  const _getReport = useCallback(async (id) => {
    const { data, loading, error } = await reportDetails({
      variables: {
        id,
      },
    });
    if (loading) {
      setLoader(true);
    }
    if (error) {
      setLoader(false);
      setErrors(error?.message);
    }
    if (data) {
      setLoader(false);
      setErrors(null);
      setNotificationData({
        reportDetails: {
          reportDetails: data?.getReportDetails?.report?.reportDetails,
          test: data?.getReportDetails?.test,
          from: data?.getReportDetails?.report?.from,
          to: data?.getReportDetails?.report?.to,
          segmentName: data?.getReportDetails?.report?.segmentName,
          learnings: data?.getReportDetails?.report?.learnings,
          conclusion: data?.getReportDetails?.report?.conclusion,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (module === "report") {
      _getReport(location?.state?.moduleData?.testId);
    }
  }, [location?.state?.moduleData, _getReport]);

  if (!location?.state?.moduleData) return <NotFound />;
  if (loader || !notificationData) return <MyLoader />;
  if (errors) return <div> {errors}</div>;
  if (module === "report") {
    return (
      <div>
        {module === "report" && (
          <ReportDetails
            reportDetails={notificationData?.reportDetails}
            unsetDetails={unsetDetails}
            className="notification-report-details"
            notificationScreen={true}
          />
        )}
      </div>
    );
  }
  return "";
};
