import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ReportDetails.scss";
import { useQuery } from "@apollo/client";
// import ReportTable from "../../Components/ReportTable/ReportTable";
// import ReportEvaluation from "../../Components/ReportEvaluationInfo/ReportEvaluation";
// import VariantPerformance from "../../Components/VariantPerformance/VariantPerformance";
// import ReportGraphs from "../../Components/ReportGraphs/ReportGraphs";
// import { useContext, useState } from "react";
// import Scrubber from "../../Components/Scrubber/Scrubber";
// import { UserContext } from "../../../UserContext";
import { GETREPORTV2 } from "../../../Graphql/QueriesNew";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import { Button } from "../../MiroComponents/Button/Button";
import { ArrowNextIcon, ShowDesignIcon } from "../../Shared/Icons";
import { checkConclusion } from "../../../Libs/Utilities";
import Scrubber from "../Scrubber/Scrubber";
import ReportEvaluation from "../ReportEvaluationInfo/ReportEvaluation";
export default function ReportDetailsNew() {
  // const { myProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showScrubber, setShowScrubber] = useState(false);

  const { data, loading, error } = useQuery(GETREPORTV2, {
    variables: {
      getReportV2Id: id,
    },
  });

  if (loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  if (error)
    return <NoDataFound title="Error" description="An error occurred" />;
  const report = data?.getReportV2[0];

  return (
    <div>
      <div className="sharable-report-detail section-pd">
        {/* {myProfile && ( */}
        <div className="margin-top-auto">
          <Button
            className="smart-button medium active"
            text={`Go Back`}
            onClick={() => navigate(-1)}
            icon={
              <div
                className="inline-flex"
                style={{
                  transform: "rotate(180deg)",
                }}
              >
                <ArrowNextIcon />
              </div>
            }
          />
        </div>
        {/* )} */}

        <div className="report-name-heading flex align-center justify-space-between flex-wrap">
          <h1 className="m-0">
            {report.test?.name || "Report Details"} ({report?.segmentName})
          </h1>
          <Button
            icon={<ShowDesignIcon />}
            onClick={() => setShowScrubber(true)}
            className="smart-button big active"
            text={`Show Design`}
          />
        </div>
        {/* <Scrubber
          showScrubber={showScrubber}
          setShowScrubber={setShowScrubber}
          images={{
            left: report?.test?.controlimage,
            right: report?.test?.variationimage,
          }}
        /> */}

        {report?.reportDetails?.conclusionFactorStatusAll && (
          <div className="conclusion-factors-wrapper inline-flex align-center">
            {Object.keys(report?.reportDetails?.conclusionFactorStatusAll).map(
              (key) => (
                <Button className="smart-pill small" key={key}>
                  {key.replaceAll("Change in", "")}:{" "}
                  {checkConclusion(
                    report?.reportDetails?.conclusionFactorStatusAll[
                      key
                    ]?.toLowerCase()
                  )}
                </Button>
              )
            )}
          </div>
        )}

        <ReportEvaluation report={report} />
        {/* 
        <div className="report-table-wrapper border-bottom section-pd-bottom">
          {report?.reportLink && report?.reportLink !== "" && (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p
                style={{
                  marginBottom: "1rem",
                }}
              >
                Report Was evaulated manually and the full report is available
                at the link below:
              </p>
              <div className="flex align-center justify-center">
                <Button
                  className="smart-button medium active"
                  text={`Show Full Report`}
                  onClick={() => {
                    window.open(report?.reportLink, "_blank");
                  }}
                  icon={null}
                />
              </div>
            </div>
          )}
          <ReportTable
            total={[report?.reportDetails?.totalOfResults]}
            controlVariantData={report?.reportDetails?.result}
          />
        </div>
        <VariantPerformance
          calculatedResults={report?.reportDetails?.calculatedResults}
        />
        <ReportGraphs
          timeSeriesData={report?.reportDetails?.timeSeriesData}
          probabilityOfWinner={report?.reportDetails?.probabilityOfWinner}
        /> */}
      </div>
    </div>
  );
}
