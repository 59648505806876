import React, { useRef, useState } from "react";
import { ArrowDown } from "../../Shared/Icons";
import ConversionRateTimeSeriesGraph from "../../Pages/Admin/AdminComponents/ConversionRateTimeSeriesGraph";
import { TimeSeriesGa4 } from "../../Pages/Admin/AdminComponents/TimeSeriesGa4";
import { ConfidenceOfUpliftGa4 } from "../../Pages/Admin/AdminComponents/ConfidenceUpliftGa4";

export function GraphsComponent({ timeSeriesData, calculatedResults }) {
  const reportingRef = useRef();
  const [graphType, setGraphType] = useState({
    name: "Conversions rate time series",
    module: "timeseries",
  });

  const graphItems = [
    {
      name: "Conversions rate time series",
      module: "timeseries",
    },
    {
      name: "Probability of being a winner",
      module: "probability",
    },
  ];
  return (
    <div>
      <div className="graph-component">
        <div className="section-heading-data">
          <h1 className="section-heading">Graphs</h1>
        </div>
        <h3 className="reporting-subtitle">
          Analyse change in Conversion rate over time with advanced Graphs.
        </h3>
        <div>
          <div className="tab-dropdown-mobile">
            <button
              onClick={(e) => {
                const _tabcontent = reportingRef.current;
                if (_tabcontent.classList.contains("open-graph")) {
                  _tabcontent.classList.remove("open-graph");
                } else {
                  _tabcontent.classList.add("open-graph");
                }
              }}
            >
              <span> {graphType?.name}</span>
              <span>
                {" "}
                <ArrowDown />{" "}
              </span>
            </button>
          </div>
        </div>
        <div
          className="tab-component reporting-tab-component"
          ref={reportingRef}
        >
          {graphItems?.map((item, i) => (
            <div
              className={`tab tab${i + 1} ${
                graphType?.module === item?.module ? `tab-active` : ``
              }`}
              key={i}
              onClick={(e) => {
                const _tabcontent = reportingRef.current;
                if (_tabcontent.classList.contains("open-graph")) {
                  _tabcontent.classList.remove("open-graph");
                }
                setGraphType(item);
              }}
            >
              <span className="tab-title">{item?.name}</span>
            </div>
          ))}
        </div>

        <div className="timeline-graph-figure">
          {graphType?.module === "timeseries" && (
            <TimeSeriesGa4 timeSeriesData={timeSeriesData} segmentName="All" />
          )}
          {graphType?.module === "probability" && (
            <ConfidenceOfUpliftGa4
              calculatedResults={calculatedResults}
              timeSeriesData={timeSeriesData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export const GraphsGa4 = React.memo(GraphsComponent);
