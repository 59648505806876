import { useLazyQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { GETREPORTANDTESTWITHREPORTID } from "../../Graphql/Queries";
import { experimentEndResults } from "../../Libs/Utilities";
import { DownArrow } from "../Shared/Icons";
import { Scrubber } from "../Shared/Scrubber";
import { UaGa4Report } from "./UaGa4Report";
import moment from "moment";
import MyLoader from "../MiroComponents/PreLoader";

const ClientReportDetailsandInsightsMemo = ({ selectedReport }) => {
  const [getreportdetails] = useLazyQuery(GETREPORTANDTESTWITHREPORTID);
  const [data, setData] = useState({
    loading: true,
  });
  const [scrubber, showScrubber] = useState(null);
  const fetchReportDetails = useCallback(async () => {
    if (selectedReport) {
      const {
        data: { getReportandTest },
        loading,
        error,
      } = await getreportdetails({
        variables: {
          id: selectedReport,
        },
      });
      const details = {
        platform: getReportandTest?.test?.platform,
        test: getReportandTest?.test,
        reportLink: getReportandTest?.reportLink,
        segmentName: getReportandTest?.segmentName,
        conclusion: getReportandTest?.conclusion,
        reportDetails: getReportandTest?.reportDetails,
        from: getReportandTest?.from,
        to: getReportandTest?.to,
        learnings: getReportandTest?.learnings,
      };

      if (
        getReportandTest?.test?.platform !== "GA4" &&
        getReportandTest?.reportDetails
      ) {
        const selectionRange = [
          {
            startDate: getReportandTest?.from,
            endDate: getReportandTest?.to,
          },
        ];

        const _ = experimentEndResults(
          getReportandTest?.reportDetails?.experiments,
          selectionRange,
          getReportandTest?.reportDetails?.currency
        );
        details.calculatedResults = _;
      }
      setData({
        loading,
        error,
        ...details,
      });
    }
  }, [selectedReport]);

  useEffect(() => {
    fetchReportDetails();
    return () => {
      setData({
        loading: true,
      });
    };
  }, [fetchReportDetails]);

  const Hypothesis = React.forwardRef(({ hypothesis }, ref) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
      <React.Fragment>
        <div
          className="selected-test-content-title"
          ref={ref}
          onClick={(e) => {
            setShowDetails(!showDetails);
          }}
          style={{ justifyContent: "space-between", cursor: "pointer" }}
        >
          <span className="test-proposition">
            Hypothesis : <small>{hypothesis?.name}</small>{" "}
          </span>
          <span className="dropdown-icon">
            <DownArrow />
          </span>
        </div>
        {showDetails && (
          <div className="hypothesis-details-dropdown">
            <p>
              Action: <span> {hypothesis?.action} </span>
            </p>
            <p>
              {" "}
              Expectation: <span> {hypothesis?.expectation}</span>
            </p>
            <p>
              {" "}
              Result : <span>{hypothesis?.result}</span>
            </p>
          </div>
        )}
      </React.Fragment>
    );
  });

  const DateRangeForTest = React.forwardRef(({ from, to }, ref) => {
    return (
      <React.Fragment>
        <div
          className="selected-test-content-title"
          ref={ref}
          style={{ justifyContent: "space-between" }}
        >
          <span className="test-proposition">
            Perfomance Results from :{" "}
            <small>
              {from} - {to}
            </small>{" "}
          </span>
        </div>
      </React.Fragment>
    );
  });

  const conclusionStyle = (result) => {
    switch (result) {
      case "winner":
        return "report-status-success";
      case "no uplift":
        return "report-status-danger";
      case "neutral":
        return "report-status-warning";
      default:
        return "d-none";
    }
  };

  const RenderStatus = ({ conclusion, factor }) => {
    return (
      <span
        className={`report-status-box ${conclusionStyle(
          conclusion.toLowerCase()
        )}`}
      >
        <span className="report-status-factor">
          {factor?.toLowerCase().replace("change in ", "")}:
          <span className="report-status-value"> {conclusion}</span>
        </span>
      </span>
    );
  };

  const RenderNoDataComponent = ({ reportLink }) => {
    return (
      <div className="manual-evaluation">
        <p className="text-white">
          The reports for this test are calculated manually. Please refer
          <br /> to the Manual Evaluation Spreadsheet for the results.
        </p>
        {reportLink && (
          <a href={reportLink} target="_blank" rel="noreferrer">
            <button className="btn btn-primary">
              Open Manual Evaluation Spreadsheet{" "}
            </button>
          </a>
        )}
      </div>
    );
  };

  if (data?.loading) return <MyLoader />;
  if (!selectedReport) return ``;
  const conclusionFactorAll = data?.reportDetails?.conclusionFactorStatusAll;
  // Sample data for all metrics coming like this
  // {
  //   "change in CR": "winner",
  //   "change in RPU": "neutral",
  //   "change in AOV": "no uplift",
  // };
  return (
    <>
      <Scrubber showScrubber={showScrubber} scrubber={scrubber} />
      <div className="selected-test-content">
        <div className="selected-test-content-title">
          <div className="selected-test-content-dropdown">
            <span className="test-proposition">
              {data?.test?.name}{" "}
              {data?.segmentName ? `(${data?.segmentName})` : " "} -{" "}
              {conclusionFactorAll ? (
                Object.keys(conclusionFactorAll)?.map((item, index) => {
                  return (
                    <RenderStatus
                      key={index}
                      conclusion={conclusionFactorAll[item]}
                      factor={item}
                    />
                  );
                })
              ) : (
                <RenderStatus
                  conclusion={data?.conclusion}
                  factor={data?.reportDetails?.conclusionFactor}
                />
              )}
            </span>
            <span className="dropdown-icon"></span>
          </div>
          <h1
            className="selected-comparison-head"
            onClick={(e) => {
              showScrubber({
                control: data?.test?.controlimage,
                variation: data?.test?.variationimage,
              });
            }}
          >
            Show designs for the test
          </h1>
        </div>
        <hr className="mt-1 mb-1 bg-light" style={{ height: "0.04px" }} />
        <DateRangeForTest
          from={moment(data?.from).format("ll")}
          to={moment(data?.to).format("ll")}
        />
        <hr className="mt-1 mb-1 bg-light" style={{ height: "0.04px" }} />
        <Hypothesis hypothesis={data?.test?.hypothesis} />

        <hr className="mt-1 mb-1 bg-light" style={{ height: "0.04px" }} />

        {data?.segmentName === "no data" && (
          <RenderNoDataComponent reportLink={data?.reportLink} />
        )}

        {data?.learnings?.length > 0 && (
          <div className="learnings-report">
            <h3 className="">Learnings:</h3>
            <ul>
              {data?.learnings?.map((learning, index) => {
                return (
                  <li key={index}>
                    <span>- {learning} </span>{" "}
                  </li>
                );
              })}
            </ul>

            <hr className="mt-1 mb-1 bg-light" style={{ height: "0.04px" }} />
          </div>
        )}

        {data.segmentName !== "no data" && <UaGa4Report data={data} />}
      </div>
    </>
  );
};

export const ClientReportDetailsandInsights = React.memo(
  ClientReportDetailsandInsightsMemo
);
