import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { CLIENTTESTSREPORTS, GETCLIENTSURVEY } from "../../../Graphql/Queries";
import { ClientStats } from "../../NewComponents/ClientStats";
import { ClientStrategyandRoadmap } from "../../NewComponents/ClientStrategyandRoadmap";
import { ClientTestCardSection } from "../../NewComponents/ClientTestCardsSection";
import { ClientTestsOverview } from "../../NewComponents/ClientTestsOverview";
import { ClientTopLearningsandNextstep } from "../../NewComponents/ClientTopLearningsandNextstep";
import { ClientSurveyPopup } from "./ClientSurveyPopup";
import { ClientPropertiesList } from "../../NewComponents/ClientPropertiesList";

export const ClientDashboardNew = () => {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const viewId = searchParams.get("viewId");

  const clientDropDownRef = useRef(null);
  // const [client, setClient] = useState(null);
  const [isSurveyPopup, setIsSurveyPopup] = useState(false);
  const [clientStatsReports, setClientStatsReports] = useState({
    show: false,
  });
  const [statusFilter, setStatusFilter] = useState("ended");
  const [clientTestReports] = useLazyQuery(CLIENTTESTSREPORTS);
  const [getClientSurvey] = useLazyQuery(GETCLIENTSURVEY);
  const _params = Object.fromEntries([...searchParams]);

  useEffect(() => {
    if (client && viewId && statusFilter === "ended") {
      setClientStatsReports({
        ...clientStatsReports,
        loading: true,
        show: true,
      });
      (async () => {
        const { data, loading, error } = await clientTestReports({
          variables: {
            viewId: viewId,
          },
        });
        setClientStatsReports({
          clientTestReports: data?.clientTestReports,
          loading,
          error,
          show: !loading,
        });
      })();
    }
  }, [client, viewId, statusFilter, clientTestReports, setClientStatsReports]);

  const getSurveyStatus = async (user) => {
    const survey = await getClientSurvey({
      variables: {
        user,
      },
    });
    // console.log("survey?.data?.getSurvey", survey?.data?.getSurvey);
    if (!survey?.data?.getSurvey) {
      setIsSurveyPopup(true);
    }
  };

  useEffect(() => {
    if (is_client) {
      getSurveyStatus(myProfile?._id);
    }
  }, [is_client, myProfile]);

  return (
    <>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="client-data-wrapper">
            <div className="client-details full" ref={clientDropDownRef}>
              {client && (
                <div className="properties-dropdown">
                  <h1 className="section-heading">Properties:</h1>
                  <div className="">
                    {/* <ClientPropertiesList
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                      selectedViewId={viewId}
                      _params={_params}
                      properties={
                        client
                          ? clientSelectorOptions?.find((opt) => {
                              return JSON.parse(opt?.value)?._id === client;
                            })?.value
                            ? JSON.parse(
                                clientSelectorOptions?.find((opt) => {
                                  return JSON.parse(opt?.value)?._id === client;
                                })?.value
                              )?.gaproperties
                            : []
                          : []
                      }
                    /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {isSurveyPopup && (
        <ClientSurveyPopup
          user={myProfile?._id}
          closePopup={setIsSurveyPopup}
        />
      )}
      <>
        {clientStatsReports?.show && (
          <ClientStats client={client} viewId={viewId} />
        )}
        {clientStatsReports?.show && (
          <ClientStrategyandRoadmap
            clientId={client}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />
        )}
        {clientStatsReports?.show && (
          <ClientTopLearningsandNextstep
            clientId={client}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />
        )}

        {clientStatsReports?.show && (
          <ClientTestsOverview
            statistics={clientStatsReports?.clientTestReports?.statistics}
            loading={clientStatsReports?.loading}
            show={clientStatsReports?.show}
          />
        )}

        <ClientTestCardSection client={client} viewId={viewId} />
      </>
    </>
  );
};
