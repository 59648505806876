import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GETCLIENTSFORTABLE, TIERS } from "../../../Graphql/Queries";
import {
  VscCheckAll,
  VscChromeClose,
  VscDebugPause,
  VscHome,
} from "react-icons/vsc";
import useStore from "../../../Store";
// import { AdminEditActions } from "./AdminEditActions";
import { toast } from "react-toastify";
import { CREATECLIENT, UPDATECLIENT } from "../../../Graphql/Mutations";
import MyLoader from "../../MiroComponents/PreLoader";
import { Currencies } from "../../../Libs/Utilities";
import Select from "react-select";
import { UploadFile } from "../../../Libs/Cloudinary";
import { Pagination } from "../../Shared/Paginations";
import { AdminStats } from "../../Shared/AdminStats";
import MyRoutes from "../../../Routes";
import moment from "moment";
import UploadIcon from "../../MiroComponents/NewForm/UploadIcon";
import Dropzone from "react-dropzone";
import { customStyles } from "../../../Libs/TableCustomStyles";
import { FiltersComponent } from "../../Shared/FiltersComponent";
import { Button } from "../../MiroComponents/Button/Button";
import { CrossIcon2 } from "../../Shared/Icons";

function Clients() {
  const navigate = useNavigate();
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const { toggleForm, toggleEditForm } = useStore();
  // const showForm = useStore((state) => state.showForm);
  // const editForm = useStore((state) => state.editForm);
  const [filter, showFilter] = useState(false);
  const [action, setAction] = useState(null);
  const [page, setPage] = useState(1);
  const size = 20;
  const [clientFilters, setClientFilters] = useState([
    // { active: true },
    // { tier: null },
  ]);
  const [showStats, setShowStats] = useState(false);
  const [clientType, setClientType] = useState("Active");
  const { data, loading, error, refetch } = useQuery(GETCLIENTSFORTABLE, {
    variables: {
      clientFilters: clientFilters?.filter(
        (item) => !Object.values(item).includes(null)
      ),
      page: page,
      size: size,
    },
  });

  const tiers = useQuery(TIERS);
  const [updatclient] = useMutation(UPDATECLIENT);
  const [addclient] = useMutation(CREATECLIENT);
  // const [editData, setEditData] = useState(null);
  const [gaProperties, setGaProperties] = useState([]);
  const [tier, setTier] = useState(null);
  const [clientLogo, setClientLogo] = useState(null);
  const [clientCurrency, selectClientCurrency] = useState([]);
  const [clientActive, setClientActive] = useState(true);
  // const setEditNull = () => {
  //   setEditData(null);
  // };
  const currencies = Currencies();

  const hanldeFilterChange = (active, tier, title) => {
    setClientType(title);
    setClientFilters([{ active }, { tier }]);
    setPage(1);
  };

  const columnsclients = [
    {
      name: "Client name",
      selector: ({ _id, gaproperties, tier, active, name }) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
          onClick={(_) => {
            navigate(
              `${MyRoutes.clientreports}?client=${_id}&viewId=${gaproperties[0]?.viewId}`
            );
          }}
        >
          <span
            className="colorSwatch"
            style={{
              backgroundColor: active ? tier?.colorSwatch : "red",
            }}
          ></span>
          {name}
        </div>
      ),
      wrap: true,
      maxWidth: "15%",
    },
    {
      name: "Properties",
      cell: ({ gaproperties, _id }) => {
        return (
          <div className="properties">
            {gaproperties.map((property, i) => {
              return (
                <div
                  className="sing-property"
                  key={i}
                  onClick={(e) => {
                    navigate(
                      `${MyRoutes.clientreports}?client=${_id}&viewId=${property?.viewId}`
                    );
                  }}
                  titlel={property?.viewId}
                >
                  <VscHome /> {property?.propertyname}
                </div>
              );
            })}
          </div>
        );
      },
      wrap: true,
    },
    {
      name: "Test Stats",
      cell: (row) => (
        <div>
          <div
            className="d-flex flex-wrap"
            style={{
              columnGap: "10px",
              rowGap: "10px",
            }}
          >
            <Button className="smart-pill xmall active">
              <span className="">Live </span>
              {row.tests.filter((tst) => tst.status === "live")?.length}
            </Button>
            <Button className="smart-pill xmall active">
              <span className="">Draft</span>{" "}
              {row.tests.filter((tst) => tst.status === "draft")?.length}
            </Button>
            <Button className="smart-pill xmall active">
              <span className="">Ended</span>{" "}
              {row.tests.filter((tst) => tst.status === "ended")?.length}
            </Button>
          </div>
        </div>
      ),
      wrap: true,
    },
    {
      name: "Win Rate",
      cell: (row) => {
        const _winrate =
          (Number(row?.winningTests) / Number(row?.endedTests)) * 100;
        return (
          <div
            className="d-flex flex-column"
            style={{
              rowGap: "10px",
            }}
          >
            <div className="fw-light">
              {row?.winningTests} / {row?.endedTests}{" "}
              <strong>({!isNaN(_winrate) ? _winrate.toFixed(2) : 0} %)</strong>
            </div>
            <div>
              <AdminStats
                show={showStats}
                id={row?._id}
                title={`Avg. CR & Revenue`}
              />
            </div>
          </div>
        );
      },
      wrap: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div style={{ textAlign: "right" }}>
            {!is_user && (
              <div
                className="d-flex flex-wrap"
                style={{
                  columnGap: "10px",
                  rowGap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  className="smart-button full-width small"
                  onClick={(_) => {
                    setAction({
                      module: "edit",
                      data: row,
                    });
                    setGaProperties(
                      row?.gaproperties?.map(({ __typename, ...rest }) => ({
                        ...rest,
                      }))
                    );
                    selectClientCurrency({
                      label: row?.currency,
                      value: row?.currency,
                    });
                    setTier({
                      label: row?.tier?.name,
                      value: row?.tier?._id,
                    });
                    setClientLogo(row?.logo);
                    setClientActive(row?.active);
                    // toggleEditForm(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  className="smart-button full-width small"
                  onClick={(e) => {
                    navigate(`${MyRoutes.manageclient}/${row?._id}`);
                  }}
                >
                  Manage
                </Button>

                <Button
                  className="smart-button full-width small elipses"
                  onClick={(e) => {
                    navigate(`${MyRoutes.manageclientretentions}/${row?._id}`);
                  }}
                >
                  Retentions
                </Button>
              </div>
            )}
          </div>
        );
      },
      right: true,
      width: "15%",
    },
  ];
  if (loading) return <MyLoader />;
  if (error) return <div> {error?.message}</div>;
  const { clients } = data;
  return (
    <div className="clients-inner">
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2>
            {" "}
            {clientType} Clients ({clients?.total})
          </h2>
          <div>
            <button
              className="btn-as-link d-inline-flex align-items-center text-white"
              onClick={(_) => {
                if (filter) {
                  setPage(1);
                  setClientFilters([]);
                }
                showFilter(!filter);
              }}
            >
              {filter ? "Close Filters" : "Filters"}
            </button>
            {!is_user && (
              <Button
                className="smart-button big active"
                onClick={(_) =>
                  setAction({
                    module: "add",
                  })
                }
              >
                Add Client
              </Button>
            )}
          </div>
        </div>
      </div>
      {filter && (
        <FiltersComponent
          serverFilters={clientFilters}
          setServerFilters={setClientFilters}
          resetFilters={() => {
            setClientFilters([]);
            setPage(1);
          }}
          setPage={setPage}
          filterTypes={["findtestbytestname"]}
        />
      )}

      {/* {showForm && (
        <FormInstance
          title=" Add Client"
          type="addclient"
          tiers={tiers?.data?.tiers}
          clientrefetch={refetch}
        />
      )} */}
      {action && (
        <div>
          <div className="form-wrapper" id="add-new-test-form">
            <div className="form-wrapper-inner addtestform">
              <div className="card add-new-test-card">
                <button
                  className="close-modal"
                  onClick={(_) => setAction(null)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#000"
                  >
                    <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                  </svg>{" "}
                </button>
                <div className="card-body p-0">
                  <h3> Edit Client Details</h3>
                  <form
                    onSubmit={async (_) => {
                      _.preventDefault();
                      const name = _.target.clientName.value;
                      const onboardDate = _.target.onboardDate.value;
                      const bqClientId = _.target.bqClientId.value;
                      const defaultDataSet = _.target.defaultDataSet.value;
                      const retainerValue = _.target.retainerValue.value
                        ? Number(_.target.retainerValue.value)
                        : 0;
                      if (
                        name === "" ||
                        gaProperties?.length <= 0 ||
                        !clientCurrency
                      ) {
                        toast.error("Please insert all fields");
                        return;
                      }
                      let clientPayload = {
                        name,
                        currency: clientCurrency?.value,
                        logo: clientLogo,
                        tier: tier?.value,
                        gaproperties: gaProperties,
                        active: clientActive,
                        onboardDate,
                        bqClientId,
                        defaultDataSet,
                        retainerValue,
                      };
                      if (action?.module === "edit") {
                        const id = action?.data?._id;
                        try {
                          const _updateclient = await updatclient({
                            variables: {
                              id,
                              clientPayload,
                            },
                          });
                          if (_updateclient?.data) {
                            toast.success(`Record Updated!`);
                            toggleEditForm(false);
                            setAction(null);
                            refetch();
                          }
                        } catch (error) {
                          toast.error(`${error?.message}`);
                        }
                      }
                      if (action?.module === "add") {
                        try {
                          const _addclient = await addclient({
                            variables: {
                              clientPayload,
                            },
                          });
                          if (_addclient?.data) {
                            toast.success(`Record Added!`);
                            toggleEditForm(false);
                            setAction(null);
                            refetch();
                          }
                        } catch (error) {
                          toast.error(`${error?.message}`);
                        }
                      }
                    }}
                  >
                    <div className="fields-wrapper input-groups">
                      <div>
                        <label> Client Name</label>
                        <input
                          name="clientName"
                          className="field-control"
                          defaultValue={action?.data?.name ?? ""}
                          placeholder="Client Name"
                        />
                      </div>

                      <div>
                        <label> Client OnBoard Date</label>
                        <input
                          type="date"
                          className="field-control"
                          name="onboardDate"
                          defaultValue={moment(
                            action?.data?.onboardDate
                          ).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>

                    <div className="fields-wrapper input-groups">
                      <div>
                        <label> Select Base Currency</label>
                        <Select
                          classNamePrefix="acc-newselect"
                          className="acc-newselectwrapper"
                          placeholder="Select Currency"
                          defaultValue={clientCurrency}
                          onChange={(_) => selectClientCurrency(_)}
                          options={currencies.map((cr) => {
                            return {
                              label: cr,
                              value: cr,
                            };
                          })}
                        />
                      </div>
                      <div>
                        <label>Tier</label>
                        <Select
                          classNamePrefix="acc-newselect"
                          className="acc-newselectwrapper"
                          placeholder="Select Tier"
                          defaultValue={tier}
                          onChange={(_) => setTier(_)}
                          options={tiers?.data?.tiers.map((tr) => {
                            return {
                              label: tr?.name,
                              value: tr?._id,
                            };
                          })}
                        />
                      </div>

                      {/* <div className="control-variant-image-container">
                      <div
                        className="client-logo"
                        style={
                          clientLogo
                            ? { backgroundImage: `url(${clientLogo})` }
                            : {}
                        }
                      >
                        <div className="upload-icon">
                          <VscCloudUpload
                            onClick={(_) => {
                              _.currentTarget.nextSibling.click();
                            }}
                          />
                          <input
                            className="field-control"
                            onChange={async (_) => {
                              if (_.currentTarget.files?.length > 0) {
                                const _fileupload = await UploadClientLogo(
                                  _.currentTarget.files[0]
                                );
                                if (_fileupload?.error) {
                                  alert(_fileupload?.error?.message);
                                } else {
                                  setClientLogo(_fileupload?.secure_url);
                                }
                              }
                            }}
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            style={{ display: "none" }}
                          />
                          {clientLogo && (
                            <VscChromeClose
                              onClick={(_) => {
                                setClientLogo(null);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div> */}
                    </div>

                    <div className="control-variant-image-container control-variant-image-container__updated">
                      <Dropzone
                        accept={{
                          "image/png": [".png", ".jpg", ".jpeg"],
                        }}
                        onDrop={async (acceptedFiles) => {
                          setClientLogo(
                            "https://res.cloudinary.com/acceleratedagency/image/upload/f_auto/STATICIMAGES/Preloader_rlboab.gif"
                          );
                          const _fileupload = await UploadFile(
                            acceptedFiles[0]
                          );
                          if (_fileupload?.error) {
                            alert(_fileupload?.error?.message);
                            setClientLogo(null);
                          } else {
                            setClientLogo(_fileupload?.secure_url);
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="control-image"
                            {...getRootProps()}
                            style={
                              clientLogo
                                ? {
                                    backgroundImage: `url(${clientLogo})`,
                                    maxWidth: "100%",
                                  }
                                : { maxWidth: "100%" }
                            }
                          >
                            <input {...getInputProps()} />
                            <div className="upload-icon">
                              <UploadIcon />
                              {clientLogo && (
                                <VscChromeClose
                                  onClick={(_) => {
                                    setClientLogo(null);
                                  }}
                                  className="close-icon"
                                />
                              )}
                              <span>Add Client Logo...</span>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div className="fields-wrapper">
                      <label> Retainer Value</label>
                      <input
                        className="field-control"
                        placeholder="Enter Retainer Value"
                        name="retainerValue"
                        type="number"
                        defaultValue={action?.data?.retainerValue ?? null}
                      />
                    </div>

                    <div className="fields-wrapper">
                      <label> Big Query Client ID</label>
                      <input
                        className="field-control"
                        placeholder="Big Query ClientID"
                        name="bqClientId"
                        defaultValue={action?.data?.bqClientId ?? null}
                      />
                    </div>
                    <div className="fields-wrapper">
                      <label> Big Query Default Dataset</label>
                      <input
                        className="field-control"
                        placeholder="Big Query Default DataSet"
                        name="defaultDataSet"
                        defaultValue={action?.data?.defaultDataSet ?? null}
                      />
                    </div>

                    <div className="fields-wrapper input-groups">
                      <div>
                        <label> Property Name</label>
                        <input
                          id="propertyName"
                          className="field-control"
                          placeholder="Property Name"
                        />
                      </div>
                      <div>
                        <label> Property ID</label>
                        <input
                          id="propertyViewId"
                          className="field-control"
                          placeholder="Property ID (must be correct GA4 Property ID)"
                        />
                      </div>
                    </div>

                    {/* <div className="fields-wrapper w-33">
                      <label> Select Platform</label>
                      <Select
                        classNamePrefix="acc-newselect"
                        className="acc-newselectwrapper"
                        placeholder="Select Platform"
                        defaultValue={selectedPlatform}
                        onChange={(_) => setSelectedPlatform(_)}
                        options={platforms}
                      />
                    </div> */}

                    <div className="fields-wrapper w-100">
                      <button
                        type="button"
                        className="btn btn-sm btn-dark ms-auto d-block"
                        onClick={(_) => {
                          const _n =
                            document.querySelector("#propertyName").value;
                          const _v =
                            document.querySelector("#propertyViewId").value;

                          if (_n.trim() === "" || _v.trim() === "") {
                            return;
                          }
                          setGaProperties((_old) => [
                            ..._old,
                            {
                              propertyname: _n,
                              viewId: _v,
                              platform: "GA4",
                            },
                          ]);
                          document.querySelector("#propertyName").value = "";
                          document.querySelector("#propertyViewId").value = "";
                        }}
                      >
                        Add
                      </button>
                    </div>

                    <div className=" default-padding default-border">
                      <ul className="d-flex flex-column default-row-gap default-column-gap">
                        {gaProperties.map((prps, i) => {
                          return (
                            <li
                              key={i}
                              className="d-flex justify-content-between"
                            >
                              <p>
                                {prps?.propertyname}{" "}
                                {prps?.platform && <> ({prps?.platform}) </>} -{" "}
                                {prps?.viewId}
                              </p>

                              <span
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(_) => {
                                  setGaProperties(
                                    [...gaProperties].filter(
                                      (it) => it?.viewId !== prps?.viewId
                                    )
                                  );
                                }}
                              >
                                <CrossIcon2 fill="#fff" />
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div
                      className="fields-wrapper default-margin-top"
                      style={{
                        color: "#fff",
                      }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={(_) => setClientActive(!clientActive)}
                      >
                        {clientActive ? (
                          <>
                            <VscCheckAll /> <span> Client is active</span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <VscDebugPause /> Client is in-active
                          </>
                        )}
                      </span>
                    </div>
                    <div className="fields-wrapper">
                      <button> UPDATE </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        // <AdminEditActions
        //   data={editData}
        //   setEditNull={setEditNull}
        //   refetch={refetch}
        // />
      )}

      <div className="tiers-list">
        <button
          className={`btn me-2 border text-white border-success`}
          onClick={(e) => {
            hanldeFilterChange(true, null, "Active");
          }}
        >
          <span
            className="colorSwatch"
            style={{
              backgroundColor: "green",
            }}
          ></span>
          <span>Active</span>
        </button>
        <button
          className="btn me-2 border text-white"
          onClick={(e) => {
            hanldeFilterChange(null, null, "All");
          }}
        >
          <span
            className="colorSwatch"
            style={{
              backgroundColor: "#fff",
            }}
          ></span>
          <span>All Clients</span>
        </button>
        <button
          className="btn me-2 border border-danger text-white"
          onClick={(e) => {
            hanldeFilterChange(false, null, "In Active");
          }}
        >
          <span
            className="colorSwatch"
            style={{
              backgroundColor: "red",
            }}
          ></span>
          <span>In Active</span>
        </button>
        {tiers?.data?.tiers?.map((trs, i) => (
          <button
            className={`btn me-2 text-white`}
            style={{
              border: `1px solid ${trs?.colorSwatch}`,
            }}
            key={i}
            onClick={(e) => {
              hanldeFilterChange(true, [trs?._id], trs?.name);
            }}
          >
            <span
              className="colorSwatch"
              style={{
                backgroundColor: trs?.colorSwatch,
              }}
            ></span>
            <span>{trs.name}</span>
          </button>
        ))}
      </div>
      <DataTable
        columns={columnsclients}
        data={clients?.clients}
        progressPending={loading}
        customStyles={customStyles}
        progressComponent={<MyLoader />}
      />
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={clients?.total}
      />
    </div>
  );
}
export default Clients;
