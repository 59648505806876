import React, { useMemo, useState } from "react";
import moment from "moment";
import { SmartButton } from "../../MiroComponents/SmartButton/SmartButton";
import "./SmartDetailProjections.scss";
import { SmartProjectionOverview } from "../../MiroComponents/SmartProjectionOverview/SmartProjectionOverview";
import { SmartProjectionDetails } from "../../MiroComponents/SmartProjectionDetails/SmartProjectionDetails";

export const SmartDetailProjections = ({
  data,
  businessCase = "codebase-projection",
}) => {
  const projectionTypes = ["Overview", "Detailed projection"];
  const overViewTypes = ["Projection", "Offer"];
  const detailProjectionTypes = [
    "Business Data",
    "6M Business Case with CLV",
    "12M Business Case with CLV",
  ];
  const clientDetailsProjectionTypes = [
    "Business Data",
    "Business Case with 3m Cohort",
    "Business case including realized CLP",
    "Future projection of incoming CLP + CRO Work",
  ];
  const [selectedProjection, setSelectedProjection] = useState(
    projectionTypes[0]
  );
  const [selectedProjectionType, setSelectedProjectionType] = useState(
    detailProjectionTypes[0]
  );
  const [selectedOverview, setSelectedOverview] = useState(overViewTypes[0]);

  const smartProjectionData = useMemo(() => {
    if (selectedProjectionType === "Business Data") {
      return {
        businessData: data?.calculatedData?.businessData,
        instantEffect: data?.calculatedData?.instanctEffect,
        secondaryEffect: data?.calculatedData?.secondaryEffect,
      };
    } else if (selectedProjectionType === "6M Business Case with CLV") {
      return {
        projection182d: data?.calculatedData?.projection182d,
        businessCaseWithClv6M: data?.calculatedData?.businessCaseWithClv6M,
      };
    } else if (
      selectedProjectionType === "Future projection of incoming CLP + CRO Work"
    ) {
      return {
        futureCLP: data?.calculatedData?.futureCLP,
        businessCaseWithClv12M: data?.calculatedData?.businessCaseWithClv12M,
      };
    }
    return {
      threeMonthCohort: data?.calculatedData?.threeMonthCohort,
      projection365d: data?.calculatedData?.projection365d,
      businessCaseWithClv12M: data?.calculatedData?.businessCaseWithClv12M,
    };
  }, [data, selectedProjectionType]);

  // const smartProjectionOverview = useMemo(() => {
  //   console.log(data);

  //   return {
  //     projection6M: {
  //       additionalTopLineRevenue:
  //         data?.calculatedData?.instanctEffect?.total?.effectWhileTesting / 2 +
  //         data?.calculatedData?.secondaryEffect?.total?.effectFor3Months / 2,
  //       cm2Profit:
  //         data?.calculatedData?.businessCaseWithClv6M?.total?.allRealizedProfit,
  //       realizedClv:
  //         data?.calculatedData?.businessCaseWithClv6M?.total?.realizedClv,
  //     },
  //     projection12M: {
  //       additionalTopLineRevenue:
  //         data?.calculatedData?.instanctEffect?.total?.effectWhileTesting +
  //         data?.calculatedData?.secondaryEffect?.total?.effectFor3Months,
  //       cm2Profit:
  //         data?.calculatedData?.businessCaseWithClv12M?.total
  //           ?.allRealizedProfit,
  //       realizedClv:
  //         data?.calculatedData?.businessCaseWithClv12M?.total?.realizedClv,
  //     },
  //   };
  // }, [data, selectedProjection]);

  return (
    <div className="projected-calculated-data">
      <div className="projected-calculated-header overview">
        <div>
          {projectionTypes.map((type, i) => {
            return (
              <SmartButton
                key={i}
                onClick={() => setSelectedProjection(type)}
                className={`smart-button small ${
                  type === selectedProjection ? "active" : ""
                }`}
                style={{
                  textTransform: "none",
                }}
                text={type}
              />
            );
          })}
        </div>

        {selectedProjection === "Overview" && (
          <div>
            {overViewTypes.map((type, i) => {
              return (
                <SmartButton
                  key={i}
                  onClick={() => setSelectedOverview(type)}
                  className={`smart-pill small ${
                    type === selectedOverview ? "active" : ""
                  }`}
                  style={{
                    textTransform: "none",
                  }}
                  text={type}
                />
              );
            })}
          </div>
        )}
      </div>

      {selectedProjection === "Overview" ? (
        <SmartProjectionOverview
          selectedOverview={selectedOverview}
          smartProjectionOverview={
            data?.calculatedData?.smartProjectionOverview
          }
          smartOfferOverview={data?.calculatedData?.smartOfferOverview}
        />
      ) : (
        <>
          <div className="projected-calculated-header m-36-top">
            {businessCase === "codebase-projection" ? (
              <div className="detail-proj-type-buttons">
                {detailProjectionTypes.map((type, i) => {
                  return (
                    <SmartButton
                      key={i}
                      onClick={() => setSelectedProjectionType(type)}
                      className={`smart-button small ${
                        type === selectedProjectionType ? "active" : ""
                      }`}
                      style={{
                        textTransform: "none",
                      }}
                      text={type}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="detail-proj-type-buttons">
                {clientDetailsProjectionTypes.map((type, i) => {
                  return (
                    <SmartButton
                      key={i}
                      onClick={() => setSelectedProjectionType(type)}
                      className={`smart-button small ${
                        type === selectedProjectionType ? "active" : ""
                      }`}
                      style={{
                        textTransform: "none",
                      }}
                      text={type}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <SmartProjectionDetails
            smartProjectionData={smartProjectionData}
            months={data?.calculatedData?.businessData?.data?.map((item) =>
              businessCase === "client-projection"
                ? moment(item.month).format("DD/MM/YYYY")
                : item.month
            )}
            selectedProjectionType={selectedProjectionType}
            businessCase={businessCase}
          />
        </>
      )}
    </div>
  );
};
