import "../../MiroComponents/Input/Input.scss";
export default function Checkbox({
  type,
  label,
  subLabel = "",
  name,
  icon,
  value,
  wrapperClass = "",
  inputWrapperClass = "",
  onChange = () => {},
  // onChange = (e) => {
  //   const checkBoxValue = e.target
  //     .closest(".checkbox-wrapper")
  //     .querySelector("input");
  //   checkBoxValue.checked = !checkBoxValue.checked;
  // },
  component,
  children,
  ...props
}) {
  if (component) return <>{children}</>;
  return (
    <div className={`field-wrapper ${wrapperClass}`}>
      <div className={`checkbox-wrapper ${inputWrapperClass}`}>
        <input
          {...props}
          name={name}
          type={`checkbox`}
          id={name}
          //   defaultValue={value}
          onChange={onChange}
          checked={value}
        />
        {label && (
          <label onClick={onChange} htmlFor={name}>
            {" "}
            {label}
          </label>
        )}
      </div>
      {subLabel && <p className="sub-label">{subLabel}</p>}
    </div>
  );
}
