import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Calendar } from "react-date-range";
import { toast } from "react-toastify";
import { MAKETESTLIVE } from "../../Graphql/Mutations";

function CalendarComponent({ test, setTestNull, refetch }) {
  const [liveDate, setLiveDate] = useState(new Date());
  const [testlive] = useMutation(MAKETESTLIVE);
  return (
    <div className="form-wrapper testlive-calendar">
      <div className="form-wrapper-inner">
        <div className="card text-center">
          <button className="close-modal" onClick={(_) => setTestNull()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#000"
            >
              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
            </svg>{" "}
          </button>
          <div className="card-body">
            <h5>Test published date</h5>
            <Calendar date={liveDate} onChange={(_) => setLiveDate(_)} />
            <div>
              <button
                className="badge bg-success text-white border-0 px-3 py-2 fw-normal"
                onClick={async (_) => {
                  const makelive = await testlive({
                    variables: {
                      id: test?._id,
                      livedate: liveDate.toString(),
                    },
                  });
                  if (makelive?.error) {
                    toast.error(makelive?.error?.message);
                  }
                  if (makelive?.data) {
                    setTestNull();
                    refetch();
                  }
                }}
              >
                SAVE DATE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarComponent;
