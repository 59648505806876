import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { GETCLIENTNOTES, GETCLIENTSNAMEANDIDS } from "../../../Graphql/Queries";
import { returnClientOptions } from "../../../Libs/Utilities";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClientHypothesisSheet } from "../../Shared/ClientHypothesisSheet";
import { IdeasDataSheet } from "../../Shared/IdeasDataSheet";
import MyLoader from "../../MiroComponents/PreLoader";
import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";

export const Testideas = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const selectedClient = useStore((state) => state.selectedClient);
  const property = searchParams.get("property");
  const module = searchParams.get("module");
  const [getClientNotes] = useLazyQuery(GETCLIENTNOTES);

  const selectedProperty = selectedClient
    ? selectedClient?.value?.gaproperties?.find((p) => p?.viewId === property)
    : null;

  const PropertySelector = ({ clientProperties }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(propertyvalue) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.property = propertyvalue?.value;
            _params.module = "hypothesis";
            setSearchParams(_params);
          }}
          value={
            selectedProperty
              ? {
                  label: selectedProperty?.propertyname,
                  value: selectedProperty?.viewId,
                }
              : null
          }
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Choose One"
          options={clientProperties?.map((c) => {
            return {
              label: c?.propertyname,
              value: c?.viewId,
            };
          })}
        />
      </div>
    );
  };

  if (!selectedClient) return <NoDataFound />;
  return (
    <div>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <h1 className="section-heading-title">Test Ideas</h1>
          </div>
          <div className="client-data-wrapper">
            <div className="client-details">
              <div className="properties-dropdown">
                <h1 className="section-heading">Properties:</h1>
                <PropertySelector
                  clientProperties={selectedClient?.value?.gaproperties}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedClient && property && (
        <div className="audit-client-wrapper">
          {selectedClient?.value?.logo && (
            <div className="audit-client-img">
              <img src={selectedClient?.value?.logo} alt="" />
            </div>
          )}

          <div className="audit-client-name">
            <h3 className="section-heading">{selectedClient?.value?.name}</h3>
          </div>
        </div>
      )}
      <IdeasDataSheet client={client} viewId={property} />
    </div>
  );
};
