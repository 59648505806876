import _ from "lodash";
import create from "zustand";
const useAbTestStore = create((set, get) => ({
  insights: {
    experiments: [],
    pages: [],
    audiences: [],
    goals: [],
    environments: [],
    experimentsCount: 0,
    pagesCount: 0,
    goalsCount: 0,
    audiencesCount: 0,
    environmentsCount: 0,
    loading: false,
    forceRender: Math.random(),
  },
  setTests: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        experiments: data?.tests,
        experimentsCount: data?.total,
        refetchTest: data?.refetchTests,
      },
    }));
  },
  setEnvironments: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        environments: data?.environments,
        environmentsCount: data?.total,
      },
    }));
  },
  updateEnvironments: (data) => {
    set((state) => {
      return {
        insights: {
          ...state.insights,
          environments: state?.insights?.environments
            ?.map(({ _id }) => _id)
            .includes(data._id)
            ? state.insights.environments.map((page) =>
                page._id === data._id ? data : page
              )
            : [...state.insights.environments, data],
          forceRender: Math.random(),
          environmentsCount: _.uniqBy(
            [...state.insights.environments, data],
            "_id"
          ).length,
        },
      };
    });
    if (!data) {
      set((state) => ({
        insights: {
          ...state.insights,
          forceRender: Math.random(),
        },
      }));
    }
  },
  updateTest: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        experiments: state?.insights?.experiments
          ?.map(({ _id }) => _id)
          .includes(data?._id)
          ? state.insights?.experiments.map((exp) =>
              exp._id === data?._id ? data : exp
            )
          : [...state.insights.experiments],
        // experiments: data?._id
        //   ? state.insights.experiments.map((test) =>
        //       test._id === data._id ? data : test
        //     )
        //   : [...state.insights.experiments],
        experimentsCount: _.uniqBy([...state.insights.experiments, data], "_id")
          .length,
        forceRender: Math.random(),
      },
    }));
    if (!data) {
      set((state) => ({
        insights: {
          ...state.insights,
          forceRender: Math.random(),
        },
      }));
    }
  },
  setGoals: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        goals: data?.goals,
        goalsCount: data?.total,
      },
    }));
  },
  setAudiences: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        audiences: data?.audiences,
        audiencesCount: data?.total,
      },
    }));
  },
  setPages: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        pages: data?.pageData,
        pagesCount: data?.total,
      },
    }));
  },
  updateAudiences: (data) => {
    set((state) => {
      return {
        insights: {
          ...state.insights,
          audiences: state?.insights?.audiences
            ?.map(({ _id }) => _id)
            .includes(data._id)
            ? state.insights?.audiences.map((page) =>
                page._id === data._id ? data : page
              )
            : [...state.insights.audiences, data],
          forceRender: Math.random(),
          audiencesCount: _.uniqBy([...state.insights.audiences, data], "_id")
            .length,
        },
      };
    });
    if (!data) {
      set((state) => ({
        insights: {
          ...state.insights,
          forceRender: Math.random(),
        },
      }));
    }
  },
  updatePages: (data) => {
    set((state) => {
      return {
        insights: {
          ...state.insights,
          pages: state?.insights?.pages
            ?.map(({ _id }) => _id)
            .includes(data._id)
            ? state.insights.pages.map((page) =>
                page._id === data._id ? data : page
              )
            : [...state.insights.pages, data],
          forceRender: Math.random(),
          pagesCount: _.uniqBy([...state.insights.pages, data], "_id").length,
        },
      };
    });
    if (!data) {
      set((state) => ({
        insights: {
          ...state.insights,
          forceRender: Math.random(),
        },
      }));
    }
  },
  updateGoals: (data) => {
    set((state) => {
      return {
        insights: {
          ...state.insights,
          goals: state?.insights?.goals
            ?.map(({ _id }) => _id)
            .includes(data._id)
            ? state.insights.goals.map((page) =>
                page._id === data._id ? data : page
              )
            : [...state.insights.goals, data],
          forceRender: Math.random(),
          goalsCount: _.uniqBy([...state.insights.goals, data], "_id").length,
        },
      };
    });
    if (!data) {
      set((state) => ({
        insights: {
          ...state.insights,
          forceRender: Math.random(),
        },
      }));
    }
  },
  clearInsights: () =>
    set({
      insights: {
        experiments: [],
        pages: [],
        audiences: [],
        experimentsCount: 0,
        pagesCount: 0,
        audiencesCount: 0,
        loading: true,
      },
    }),
}));
export default useAbTestStore;
