import ChangePassword from "./ChangePassword";

function ChangePasswordModal({ setIsModal, myProfile }) {
  return (
    <div className="form-wrapper">
      <div className="form-wrapper-inner">
        <div className="card password-card" id="formcard">
          <button className="close-modal" onClick={(_) => setIsModal(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#000"
            >
              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
            </svg>{" "}
          </button>
          <ChangePassword
            isModal
            myProfile={myProfile}
            setIsModal={setIsModal}
          />
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
