import { toast } from "react-toastify";
import Select from "react-select";

export const PrecalculatedConditionContainer = ({
  conditions,
  setConditions,
  eventOrPage,
  setEventOrPage,
}) => {
  const eventSelectors = [
    { label: "Equals to", value: "EQUALS TO" },
    { label: "Not Equals to", value: "NOT EQUALS TO" },
  ];

  const eventPageOptions = [
    {
      label: "Page",
      value: "Page",
    },
    {
      label: "Event",
      value: "Event",
    },
  ];

  const pageSelectors = [
    { label: "Contains", value: "LIKE" },
    { label: "Not Contains", value: "NOT LIKE" },
    { label: "Equals to", value: "EQUALS TO" },
    { label: "Not Equals to", value: "NOT EQUALS TO" },
  ];

  const handleRemoveCondition = (key, name) => {
    const updatedCondition = conditions
      ?.find((item) => item?.dataSetType === key)
      ?.filters?.filter((item) => item?._id !== name);
    const newConditions = conditions?.map((item) => {
      if (item?.dataSetType === key) {
        return { ...item, filters: updatedCondition };
      } else {
        return item;
      }
    });
    setConditions(newConditions);
  };

  return (
    <>
      <div
        style={{
          flexDirection: "column",
          marginTop: "20px",
        }}
        className="pre-condition-container"
      >
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          Include data when:
        </div>
        {conditions?.map((item, idx) => (
          <div className="event-or-page-wrapper" key={idx}>
            <div>
              <Select
                onChange={(role) => {
                  // setConditions(role);
                }}
                isDisabled={true}
                value={{
                  label: item?.dataSetType,
                  value: item?.dataSetType,
                }}
                className="acc-newselectwrapper"
                classNamePrefix="acc-newselect"
                placeholder="Page"
                options={[
                  {
                    label: "Page",
                    value: "Page",
                  },
                  {
                    label: "Event",
                    value: "Event",
                  },
                ]}
              />
            </div>

            <div>
              {item?.filters?.map((fltr, index) => (
                <div key={index} className="pre-condition-sub-container">
                  <div>
                    <span className="operat">{fltr?.matchType}</span>{" "}
                    <span className="operatval">{fltr?.value}</span>
                  </div>
                  <div
                    onClick={() =>
                      handleRemoveCondition(item?.dataSetType, fltr?._id)
                    }
                    style={{ width: "16px", marginRight: "12px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="#fff"
                    >
                      <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                    </svg>
                  </div>
                </div>
              ))}
              <div
                className="pre-condition-container addNew"
                onClick={() => setEventOrPage(item?.dataSetType)}
              >
                + Add Condition
              </div>
            </div>
          </div>
        ))}
        <div
          className="pre-condition-container addNew"
          onClick={() => setEventOrPage("Event")}
        >
          + Add Condition Group
        </div>
      </div>
      {eventOrPage !== null && (
        <div className="form-wrapper add-sheet-link-modal">
          <div
            className="form-wrapper-inner form-wrapper-inner__updated"
            style={{ overflow: "inherit", maxWidth: "450px" }}
          >
            <div className="card" id="formcard">
              <div style={{ color: "#fff" }} className="card-body p-0">
                <p> Conditions* </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (
                      !e.target["location"].value ||
                      !e.target["contains"].value
                    ) {
                      toast.error("Please fill all the fields");
                      return;
                    }
                    const check = conditions?.find(
                      (item) => item?.dataSetType === eventOrPage
                    );
                    if (check) {
                      const newConditions = conditions?.map((item) => {
                        if (item?.dataSetType === eventOrPage) {
                          return {
                            ...item,
                            filters: [
                              ...item?.filters,
                              {
                                _id: new Date().getTime(),
                                matchType: e.target["contains"].value,
                                value: e.target["location"].value,
                              },
                            ],
                          };
                        } else {
                          return item;
                        }
                      });
                      setConditions(newConditions);
                      setEventOrPage(null);
                    } else {
                      const newConditions = [
                        ...conditions,
                        {
                          dataSetType: eventOrPage,
                          filters: [
                            {
                              _id: new Date().getTime(),
                              matchType: e.target["contains"].value,
                              value: e.target["location"].value,
                            },
                          ],
                        },
                      ];
                      setConditions(newConditions);
                      setEventOrPage(null);
                    }
                  }}
                >
                  <div className="fields-wrapper">
                    <Select
                      onChange={(role) => {
                        setEventOrPage(role?.value);
                      }}
                      value={{
                        label: eventOrPage,
                        value: eventOrPage,
                      }}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      placeholder="Page"
                      options={eventPageOptions}
                    />
                  </div>

                  <div className="fields-wrapper">
                    <Select
                      name="contains"
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      placeholder="Please select condition"
                      options={pageSelectors}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <input
                      className="field-control"
                      name="location"
                      placeholder={`Add ${eventOrPage} name`}
                    />
                  </div>
                  <div className="field-wrapper pre-button-box">
                    <button
                      type="button"
                      className="pre-cal-cancel"
                      onClick={() => setEventOrPage(null)}
                    >
                      Cancel
                    </button>
                    <button className="pre-cal-apply">Apply</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
