import React from "react";
import "./SmartHeading.scss";
export const SmartHeading = ({ children, ...props }) => {
  return (
    <div className="smart-heading" {...props}>
      {children}
    </div>
  );
};
// Path: src/Components/MiroComponents/SmartHeading/SmartHeading.jsx
