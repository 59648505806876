export const Priotrizations = [
  {
    name: "chan_made",
    value: [0, 1],
    option: ["No", "Yes"],
  },
  {
    name: "disc_by",
    value: [0, 1],
    option: ["No", "Yes"],
  },
  {
    name: "supp_by_qd",
    value: [0, 1],
    option: ["No", "Yes"],
  },
  {
    name: "supp_by_hmt",
    value: [0, 1],
    option: ["No", "Yes"],
  },
  {
    name: "chen_noti",
    value: [0, 2],
    option: ["No", "Yes"],
  },
  {
    name: "level_effort",
    value: [0, 1, 2, 3],
    option: ["More", "2days", "8h", "4h"],
  },
  {
    name: "is_it_add_remo",
    value: [0, 2],
    option: ["Remove", "Add"],
  },
  {
    name: "chen_on_pg",
    value: [0, 1],
    option: ["No", "Yes"],
  },
  {
    name: "desi_to_incr",
    value: [0, 1],
    option: ["No", "Yes"],
  },
  {
    name: "desi_to_simp",
    value: [0, 1],
    option: ["No", "Yes"],
  },
  {
    name: "thou_of_team",
    value: [0, 1, 2],
    option: ["Low", "Medium", "Good"],
  },
];
