export const PriotriLabel = [
  {
    name: "Change made 'Above the fold'? (1 = Yes / 0 = No)",
  },
  {
    name: "Discovered by User Testing? (1 = Yes / 0 = No)",
  },
  {
    name: "Supported by quantitative Data? (1 = Yes / 0 = No)",
  },
  {
    name: "Supported by Heatmaps or Mouse Tracking? (1 = Yes / 0 = No)",
  },
  {
    name: "Change noticeable within 5 Seconds? (2 = Yes / 0 = No)",
  },
  {
    name: "Level of Effort for DEV (3 = 4h / 2 = 8h / 1 = 2days / 0 = more)",
  },
  {
    name: "Is it Adding or Removing something ? (2 = Add / 0 = Remove)",
  },
  {
    name: "Change on Page with high Traffic? (1 = Yes / 0 = No)",
  },
  {
    name: "Designed to Increase User Motivation? (1 = Yes / 0 = No)",
  },
  {
    name: "Designed to simplify the Process of Purchasing? (1 = Yes / 0 = No)",
  },
  {
    name: "Thoughts of Team (2 = Good / 1 = Medium / 0 = Low)",
  },
  {
    name: "RESULT (0-15) High Nr = High Prio.",
  },
];
