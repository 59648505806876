import React, { useLayoutEffect, useMemo } from "react";
import "./ReportCard.scss";
import { Link, useLocation } from "react-router-dom";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
import { Button } from "../../MiroComponents/Button/Button";
import { ReportIcon } from "../../Shared/Icons";
import MyRoutes from "../../../Routes";
import {
  checkAdditionalRevenue,
  checkConclusion,
  checkConversionRate,
} from "../../../Libs/Utilities";

export default function ReportCard({ type = "report", data = null, currency }) {
  const { state } = useLocation();

  const isMabTest = useMemo(() => {
    return data?.test?.tags?.some((tag) => tag?.name === "MAB Test");
  }, [data]);

  const MabTag = () => {
    return <Button className="smart-pill xmall active">MAB Test</Button>;
  };

  useLayoutEffect(() => {
    const selectedkey = document.getElementById(
      state?.reportId ? `reportcard-${state?.reportId}` : null
    );
    if (selectedkey) {
      selectedkey.classList.add("active");
      selectedkey.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      setTimeout(() => {
        selectedkey.classList.remove("active");
      }, 1000);
    }
  }, [data, state]);

  const checkStringLocalStrings = (
    stringValue,
    prefix = "",
    postfix = "",
    formatColor = {}
  ) => {
    if (!stringValue) {
      return 0;
    }
    stringValue = stringValue.toString();
    stringValue = stringValue.replace(/[$,%]/g, "");
    stringValue = isFinite(Number(stringValue)) ? stringValue : "0";
    let numberClass = "";
    if (stringValue > formatColor.threshold) {
      numberClass = "green";
    }
    if (stringValue < formatColor.threshold) {
      numberClass = "red";
    }
    stringValue = Number(stringValue).toLocaleString("en-US");
    return (
      <span className={numberClass}>
        {prefix}
        {stringValue}
        {postfix}
      </span>
    );
  };

  const TypeReportContent = ({
    _id,
    conclusion = "",
    segmentName = "",
    duration = "",
    reportvalues = [],
    test,
    currency,
  }) => (
    <>
      <div className="report-card-header">
        <h2>
          {" "}
          {test?.name}
          {isMabTest && <MabTag />}
        </h2>
      </div>
      {conclusion !== "" && (
        <div className="report-card-content flex flex-wrap justify-space-between default-column-gap">
          <div>
            <h4> Segment:</h4>
            <p>
              {" "}
              {segmentName}
              {segmentName?.toLowerCase() === "no data" &&
                !reportvalues?.length && (
                  <Link
                    className="editReport"
                    to={`${MyRoutes.createmanualreport}/${_id}`}
                  >
                    Edit
                  </Link>
                )}
            </p>
          </div>
          <div>
            <h4> Duration:</h4>
            <p> {duration} Days</p>
          </div>
          {!isMabTest && (
            <div>
              <h4> Result:</h4>
              <p> {checkConclusion(conclusion)}</p>
              <p className="conversionRate">
                {reportvalues?.map(({ changeInConversionRate }, index) => (
                  <React.Fragment key={index}>
                    {checkConversionRate(changeInConversionRate, "", "%")}{" "}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          )}
          {isMabTest && (
            <div
              style={
                reportvalues?.length > 0
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            >
              <h4> Additional Revenue During Now:</h4>
              <p>
                {" "}
                {reportvalues?.map(({ additionalRevenueDuringNow }, index) => (
                  <React.Fragment key={index}>
                    {checkAdditionalRevenue(
                      additionalRevenueDuringNow,
                      true,
                      currency,
                      "",
                      true
                    )}{" "}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          )}
          {!isMabTest && (
            <div
              style={
                reportvalues?.length > 0
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            >
              <h4> Additional Revenue /month:</h4>
              <p>
                {" "}
                {reportvalues?.map(({ additionalRevenuePerMonth }, index) => (
                  <React.Fragment key={index}>
                    {checkAdditionalRevenue(
                      additionalRevenuePerMonth,
                      true,
                      currency,
                      "",
                      true
                    )}{" "}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          )}
        </div>
      )}

      <div className="report-card-footer">
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (_id) {
              // navigate(`/report/${_id}/report`);
              window.open(
                `${process.env.REACT_APP_CLIENT_APP_URL}/sharereport/${_id}`,
                "_blank"
              );
            }
          }}
          text={`More Details`}
          className={`smart-button big uppercase full-width`}
          icon={<ReportIcon />}
        />
      </div>
    </>
  );

  const TypeVariantPerformance = ({
    title = "Title",
    value,
    prefix = "",
    postfix = "",
    formatColor,
    check = true,
  }) => (
    <>
      <div className="report-card-header">
        <h2> {title}</h2>
      </div>
      <div
        className="report-card-content flex flex-wrap justify-center"
        style={{
          paddingBottom: "0px",
        }}
      >
        {!check
          ? value
          : checkStringLocalStrings(value, prefix, postfix, formatColor)}
      </div>
    </>
  );

  if (!data) return <ContentSkeleton />;
  return (
    <div className={`report-card`} id={`reportcard-${data?._id}`}>
      {type === "report" ? (
        <TypeReportContent {...data} currency={currency} />
      ) : null}
      {type === "variantperformance" ? (
        <TypeVariantPerformance {...data} />
      ) : null}

      {type === "sq" ? <TypeVariantPerformance {...data} /> : null}
    </div>
  );
}
