export const ReportFilterValues = [
  {
    label: "Browser",
    fieldName: "browser",
    filterType: "string",
    matchType: "EXACT",
    values: ["Chrome", "Firefox", "Safari", "Edge", "Opera", "Other"],
  },
  {
    label: "Visitor Type",
    fieldName: "visitorType",
    filterType: "string",
    matchType: "EXACT",
    values: ["All Users", "New Visitor", "Returning Visitor"],
  },
  {
    label: "Device",
    fieldName: "deviceCategory",
    filterType: "string",
    matchType: "EXACT",
    values: ["Desktop", "Mobile", "Tablet"],
  },
  {
    label: "Operating System",
    fieldName: "operatingSystem",
    filterType: "string",
    matchType: "EXACT",
    values: [
      "Windows",
      "Macintosh",
      "Linux",
      "Chrome OS",
      "Android",
      "iOS",
      "Other",
    ],
  },
  // {
  //   label: "Revenue Currency",
  //   fieldName: "revenueCurrency",
  //   filterType: "string",
  //   matchType: "EXACT",
  //   values: ["USD"],
  // },
  {
    label: "Store Currency",
    fieldName: "storeCurrency",
    filterType: "string",
    matchType: "EXACT",
    values: ["USD", "EUR", "GBP"],
  },
  //   {
  //     label: "Source",
  //     fieldName: "source",
  //     filterType: "string",
  //     matchType: "EXACT",
  //     values: ["Direct", "Organic Search", "Social", "Referral", "Other"],
  //   },
];

export const ImplementedStatusOptions = [
  { label: "Not Implemented", value: "not-implemented" },
  { label: "To Be Implemented", value: "to-be-implemented" },
  { label: "Implemented", value: "implemented" },
];
