import Select from "react-select";

export const SmartSelector = ({
  onChange,
  value,
  label,
  placeholder,
  isDisabled,
  options,
}) => {
  return (
    <div className="smart-input">
      {label && <label>{label}</label>}
      <div className="smart-input-cover">
        <Select
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder={placeholder}
          onChange={(client) => onChange(client)}
          value={value}
          isDisabled={isDisabled}
          options={options}
        />
      </div>
    </div>
  );
};
