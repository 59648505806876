import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SENDRESETPASSWORDEMAIL } from "../../Graphql/Mutations";
import { ValidateEmail } from "../../Libs/Utilities";
import { LoaderLogo } from "../Shared/LoaderLogo";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [fieldsValidated, setFieldsValidated] = useState({
    email: false,
  });
  const [sendResetPasswordEmail, { reset }] = useMutation(
    SENDRESETPASSWORDEMAIL
  );
  const navigate = useNavigate();
  const fieldsValidate = (field, value) => {
    setFieldsValidated({
      ...fieldsValidated,
      [field]: value,
    });
  };

  const validateFields = useCallback(
    (value) => {
      const name = value.name;
      const fieldvalue = value.value;
      if (name === "email") {
        if (
          String(fieldvalue)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          value.classList.add("valid");
          fieldsValidate("email", true);
        } else {
          value.classList.remove("valid");
          fieldsValidate("email", false);
        }
      }
      return true;
    },
    [fieldsValidated]
  );

  const promtError = (message) => {
    var errorDiv = document.querySelector("#error-messages");
    errorDiv.innerHTML = message;
    errorDiv.style.opacity = 1;
  };

  const resetPasswordSubmit = async (_) => {
    const email = _?.target.email.value;
    setLoading(true);
    if (email.trim() === "" || !ValidateEmail(email.trim())) {
      // toast.error("Email address is invalid.");
      promtError("Email address is invalid.");
      return;
    }
    try {
      await sendResetPasswordEmail({
        variables: {
          email,
        },
      });
      toast.success(`Send Reset link Successfully. Please check your Email!`);
      setLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      promtError(error?.message);
      setLoading(false);
      reset();
    }
  };

  if (loading) return <LoaderLogo />;
  return (
    <div className="login-wrapper">
      <div className="login-inner">
        <div className="login-card">
          <div className="login-header">
            <div className="logo-wrapper">
              <img
                src={`${window.location.origin}/Images/AcceleratedLOGOICON.png`}
                alt=""
              />
            </div>
          </div>
          <form
            className=""
            onSubmit={(_) => {
              _.preventDefault();
              resetPasswordSubmit(_);
            }}
          >
            <div className="fields-wrapper">
              <input
                type="text"
                autoComplete="off"
                className="field-control"
                onChange={(e) => validateFields(e.currentTarget)}
                placeholder="Email"
                name="email"
              />
            </div>
            <div className="fields-wrapper submit-cta">
              <button
                className=""
                type="submit"
                disabled={fieldsValidated?.email ? false : true}
              >
                Reset Password
              </button>
            </div>
          </form>
          <button id="error-messages"> Error message space</button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
