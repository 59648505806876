import { useEffect, useState } from "react";
import Input from "../../MiroComponents/Input/Input";
import SelectComponent from "../../MiroComponents/SelectComponent/SelectComponent";
import { Button } from "../../MiroComponents/Button/Button";
import Checkbox from "../../MiroComponents/Checkbox/Checkbox";
import { DeleteUrlIcon } from "../../Shared/Icons";
import _, { debounce } from "lodash";
import useStore from "../../../Store";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { CREATEPAGEABTEST, UPDATEPAGEABTEST } from "../../../Graphql/Mutations";

const URL_TYPES = [
  { value: "substring", label: "Sub string" },
  { value: "regex", label: "Regex" },
  { value: "simple", label: "Simple" },
];

const URL_TARGETING_TYPES = [
  { value: "include", label: "Include" },
  { value: "exclude", label: "Exclude" },
];

const defaultState = {
  name: "",
  trigger_js: "return true;",
  rules_js: "return true;",
  trigger: null,
  poll_on_rules: false,
  urltargetings: [
    {
      type: null,
      url: "",
      url_type: null,
    },
  ],
  loading: true,
};

export default function PageTargetingForm({
  editObject,
  refreshPage,
  id,
  showForm,
}) {
  const selectedClient = useStore((state) => state.selectedClient);
  const [createPage] = useMutation(CREATEPAGEABTEST);
  const [updatePage] = useMutation(UPDATEPAGEABTEST);
  const [otherData, setOtherData] = useState(defaultState);
  useEffect(() => {
    if (editObject) {
      setOtherData({
        ..._.omit(editObject, ["__typename", "client"]),
        trigger: {
          label: editObject?.trigger,
          value: editObject?.trigger,
        },
        loading: false,
      });
    }
    if (showForm) {
      setOtherData({
        ...defaultState,
        loading: false,
      });
    }
  }, [editObject, id, showForm]);

  const updateState = debounce((key, value) => {
    setOtherData({ ...otherData, [key]: value });
  }, 1000);
  if (otherData?.loading) return null;

  return (
    <form
      className="default-margin-bottom default-padding default-border bg-1"
      onSubmit={async (e) => {
        e.preventDefault();
        const urltargetings = otherData?.urltargetings.map(
          ({ __typename, _id, ...rest }) => rest
        );
        if (
          urltargetings.length === 0 ||
          urltargetings.some(
            (item) => item.url === "" || !item.type || !item.url_type
          )
        ) {
          return toast.error("Please add correct URL targetings");
        }
        if (
          !otherData?.trigger ||
          !otherData?.name ||
          !otherData?.trigger_js ||
          !otherData?.rules_js
        ) {
          return toast.error("Please fill all the fields");
        }
        const pageData = {
          ..._.omit(otherData, ["loading", "api_name", "_id"]),
          client: selectedClient?.value?._id,
          trigger: otherData?.trigger?.value,
          deactivation_mode: "reset",
          urltargetings: urltargetings,
        };
        if (editObject && editObject?._id) {
          const { data } = await updatePage({
            variables: {
              id: editObject?._id,
              pageData: pageData,
            },
          });
          refreshPage(data?.updatePage);
        } else {
          const { data } = await createPage({
            variables: {
              pageData: pageData,
            },
          });
          refreshPage(data?.createPage);
        }
        toast.success("Data Updated Successfully");
      }}
    >
      <SelectComponent
        options={[
          {
            value: "direct",
            label: "Direct (immediately when Codebase runs)",
          },
          {
            value: "callback",
            label: "Call back (when callback function is called)",
          },
        ]}
        label="Trigger"
        value={otherData.trigger}
        placeholder="Select Trigger"
        icon={""}
        selectWrapperClass=""
        customClass="default-padding default-border big-margin-bottom bg-2"
        containerClass="full-width"
        onChange={(selected) => {
          // updateState("trigger", selected);
          setOtherData({ ...otherData, trigger: selected });
        }}
      />
      <Input
        type={"text"}
        value={otherData?.name || ""}
        inputWrapperClass="full-width medium bg-2"
        label="Page Name"
        placeholder={"Enter Name"}
        name="name"
        onChange={(e) => {
          updateState("name", e.target.value);
          // setOtherData({ ...otherData, name: e.target.value });
        }}
      />
      <Input
        type={"text"}
        value={otherData?.trigger_js || ""}
        inputWrapperClass="full-width medium bg-2"
        label="JS trigger"
        placeholder={"return true;"}
        name="trigger_js"
        onChange={(e) => {
          updateState("trigger_js", e.target.value);
          // setOtherData({ ...otherData, trigger_js: e.target.value });
        }}
      />

      <Input
        type={"text"}
        value={otherData?.rules_js || ""}
        inputWrapperClass="full-width medium bg-2"
        label="Rules for JS code to run"
        placeholder={"return true;"}
        name="rules_js"
        onChange={(e) => {
          updateState("rules_js", e.target.value);
          // setOtherData({ ...otherData, rules_js: e.target.value });
        }}
      />

      <Checkbox
        value={otherData?.poll_on_rules || false}
        label={`Poll on rules`}
        onChange={(e) => {
          const checkBoxValue = e.target
            .closest(".checkbox-wrapper")
            .querySelector("input").checked;
          // updateState("poll_on_rules", !checkBoxValue);
          setOtherData({ ...otherData, poll_on_rules: !checkBoxValue });
        }}
      />

      {otherData?.urltargetings?.map((item, index) => (
        <div
          className="d-flex align-items-end default-column-gap justify-content-between default-margin-bottom"
          key={index}
        >
          <SelectComponent
            options={URL_TARGETING_TYPES}
            label="Condition"
            value={
              URL_TARGETING_TYPES?.find((tt) => tt.value === item?.type) ?? null
            }
            placeholder="Select Type"
            icon={""}
            selectWrapperClass="margin-bottom-auto"
            customClass="default-padding default-border bg-2"
            containerClass=""
            onChange={(selected) => {
              const arr = _.cloneDeep(otherData.urltargetings);
              arr[index].type = selected.value;
              // updateState("urltargetings", arr);
              setOtherData({ ...otherData, urltargetings: arr });
            }}
          />
          <SelectComponent
            options={URL_TYPES}
            label="URL Match Type"
            value={
              URL_TYPES?.find((urltype) => urltype.value === item?.url_type) ??
              null
            }
            placeholder="Select URL Match Type"
            icon={""}
            selectWrapperClass="full-width margin-bottom-auto"
            customClass="default-padding default-border bg-2"
            containerClass="full-width"
            onChange={(selected) => {
              const arr = _.cloneDeep(otherData.urltargetings);
              arr[index].url_type = selected.value;
              setOtherData({ ...otherData, urltargetings: arr });
            }}
          />
          <Input
            type={"text"}
            value={item.url}
            inputWrapperClass="medium bg-2 full-width"
            label="URL"
            wrapperClass="full-width margin-bottom-auto"
            placeholder={""}
            name={`url_${index}`}
            onChange={(e) => {
              const arr = _.cloneDeep(otherData.urltargetings);
              arr[index].url = e.target.value;
              // updateState("url_targetings", arr);
              setOtherData({ ...otherData, urltargetings: arr });
            }}
          />

          <Button
            className="smart-button medium"
            onClick={() => {
              setOtherData({
                ...otherData,
                urltargetings: otherData.urltargetings.filter(
                  (item, i) => i !== index
                ),
              });
            }}
          >
            <DeleteUrlIcon />
          </Button>
        </div>
      ))}
      <div className="d-flex justify-end default-margin-bottom">
        <Button
          onClick={() => {
            const arr = _.cloneDeep(otherData.urltargetings);
            arr.push({
              type: null,
              url: "",
              url_type: null,
            });
            setOtherData({ ...otherData, urltargetings: arr });
          }}
          type="button"
          className="smart-button small"
        >
          Add New Target
        </Button>
      </div>
      <div className="d-flex justify-end default-column-gap">
        <Button
          className="smart-button medium notactive"
          type="button"
          text={`Cancel`}
          onClick={(e) => {
            refreshPage(null);
          }}
        />
        <Button
          className="smart-button medium active"
          type="Submit"
          text={`${editObject ? `Update` : `Create`} Page`}
        />
      </div>
    </form>
  );
}
