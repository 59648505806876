import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import MyLoader from "../../MiroComponents/PreLoader";
import { REVENUEPERMONTH } from "../../../Graphql/Queries";
import { ApolloError, useLazyQuery } from "@apollo/client";

const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: true,
        color: "rgb(255 255 255 / 5%)",
      },
    },
    y: {
      grid: {
        color: "rgb(255 255 255 / 5%)",
        drawTicks: true,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
      font: {
        size: 14,
      },
    },
    title: {
      display: false,
      text: `Conversions Rate Time Series`,
    },
    datalabels: {
      display: false,
      color: "#fff",
    },
  },
};

export const AdditionalRevenueAfterImplementation = ({
  client,
  viewId,
  selectionRange,
  showCalendar,
}) => {
  const [graphData, setGraphData] = useState({
    loading: true,
  });
  const [getRevenuePerMonth] = useLazyQuery(REVENUEPERMONTH);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );
  const createGraphData = useCallback(async () => {
    if (client && viewId && !showCalendar) {
      try {
        const {
          data: { revenuePerMonth },
          loading,
        } = await getRevenuePerMonth({
          variables: {
            client,
            viewId,
            from: selectionRange[0].startDate,
            to: selectionRange[0].endDate,
          },
        });
        const labels = revenuePerMonth.map(
          (item) => `${item.month},${item.year}`
        );
        const dataset = revenuePerMonth.map((item) => item.revenue);
        const datasets = [
          {
            lineTension: 0.4,
            label: "Add. revenue per month",
            data: dataset,
            borderColor: "#003ED4",
            fill: true,
            borderWidth: 1,
            pointRadius: 4,
            pointHoverRadius: 8,
            pointBorderColor: "#003ED4",
            pointBackgroundColor: "#fff",
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "#003ED4");
              gradient.addColorStop(1, "#003ed417");
              return gradient;
            },
          },
        ];
        setGraphData({
          loading,
          data: {
            labels,
            datasets,
          },
        });
      } catch (error) {
        throw new ApolloError(error);
      }
    }
  }, [selectionRange, viewId, client, showCalendar, getRevenuePerMonth]);

  useEffect(() => {
    createGraphData();
    return () => {
      setGraphData({ loading: true });
    };
  }, [createGraphData]);

  if (graphData?.loading) return <MyLoader />;
  return (
    <>
      <Line options={options} data={graphData?.data} />
    </>
  );
};
