import { useLazyQuery, useMutation } from "@apollo/client";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import { ReportFilterValues } from "../../../Libs/ReportFiltersValues";
import SmartContainer from "../../MiroComponents/SmartContainer/SmartContainer";
import { SmartCard } from "../../MiroComponents/SmartCard/SmartCard";
import { SmartInput } from "../../MiroComponents/SmartInput/SmartInput";
import { SmartSelector } from "../../MiroComponents/SmartSelector/SmartSelector";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../MiroComponents/Button/Button";
import { toast } from "react-toastify";
import { GETCLIENTTESTS } from "../../../Graphql/Queries";
import useStore from "../../../Store";
import MyLoader from "../../MiroComponents/PreLoader";
import { useSearchParams } from "react-router-dom";
import { DateRangeSelectorComponent } from "../../Shared/DateRangeSelectorComponent";
import { subMonths } from "date-fns";
import { PREVIEWMANUALREPORT } from "../../../Graphql/Mutations";
import { ImpressionsTableGa4 } from "../ImpressionTableGa4";
import { CalculatedKpisGa4 } from "../CalculatedKpisGa4";
import html2canvas from "html2canvas";

const initialtFactor = {
  "Change in CR": null,
  "Change in AOV": null,
  "Change in RPU": null,
};
const defaultFactorKey = "Change in CR";
const conclusionfactors = ["Change in CR", "Change in AOV", "Change in RPU"];
const btns = ["Winner", "Neutral", "No Uplift"];
export default function PreviewManualReport() {
  const [searchParams, setSearchParams] = useSearchParams({});
  const nodeToCapture = useRef(null);
  const _params = Object.fromEntries([...searchParams]);
  const selectedClient = useStore((state) => state.selectedClient);
  const [conclusionFactor, setConclusionFactor] = useState(initialtFactor);
  const [previewGenerateManualReport] = useMutation(PREVIEWMANUALREPORT);
  const [segmentName, setSegmentName] = useState({
    label: "All Users",
    value: "All Users",
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [testsList, setTestsList] = useState({
    loading: false,
  });
  const [variants, setVariants] = useState([]);
  const [evaluationVariant, setEvaluationVariant] = useState(null);
  const [clientLearnings, setClientLearnings] = useState([]);

  const [getLiveTestsQuery] = useLazyQuery(GETCLIENTTESTS);

  const AllOption = [{ label: "All Users", values: ["All Users"] }];
  const groupedOptions = [...AllOption, ...ReportFilterValues].map((filter) => {
    return {
      label: filter.label,
      options: filter.values.map((op) => {
        return { label: op, value: op };
      }),
    };
  });

  const handleVariantInputChange = (e, i, key) => {
    const newVariants = [...variants];
    if (
      key === "audienceName" &&
      newVariants.find((v) => v.audienceName === e.target.value)
    ) {
      toast.error("Audience Name already exists");
      return;
    }
    if (key !== "audienceName" && isNaN(parseFloat(e.target.value))) {
      toast.error("Please enter a valid number");
      e.target.value = "";
      return;
    }
    newVariants[i][key] =
      key !== "audienceName" ? parseFloat(e.target.value) : e.target.value;
    setVariants(newVariants);
  };

  useEffect(() => {
    if (selectedClient) {
      (async () => {
        setTestsList({ ...testsList, loading: true });
        const { data, loading, error } = await getLiveTestsQuery({
          variables: {
            client: selectedClient?.value?._id,
            viewId: "all",
            status: "live",
          },
        });
        setTestsList({ tests: data?.getClientTests?.tests, loading, error });
      })();
    }
  }, [selectedClient]);

  const captureScreenshot = () => {
    var canvasPromise = html2canvas(nodeToCapture.current, {
      useCORS: true,
    });
    canvasPromise.then((canvas) => {
      canvas.toBlob(async (blob) => {
        try {
          // Use Clipboard API to write the blob to the clipboard
          await navigator.clipboard.write([
            new ClipboardItem({ "image/png": blob }),
          ]);
          toast.success("Image copied to clipboard");
        } catch (err) {
          console.error("Failed to copy image: ", err);
        }
      });
    });
  };

  if (testsList.loading)
    return (
      <div>
        <MyLoader />
      </div>
    );
  return (
    <div>
      <SmartContainer className="p-24-top p-24-bottom">
        <h2>Generate Report Preview :</h2>
        <SmartCard bodyClass="projected-values">
          <form
            className="full-width"
            onSubmit={async (e) => {
              e.preventDefault();
              if (!conclusionFactor[defaultFactorKey] || !evaluationVariant) {
                toast.error("Please fill all the fields");
                return;
              }
              const checkVariantData = variants?.every((v) =>
                Object.values(v).every((v) => v)
              );
              if (!checkVariantData) {
                toast.error("Please fill all the fields in variants data!");
                return;
              }
              const reportPayload = {
                conclusion: conclusionFactor[defaultFactorKey]?.toLowerCase(),
                learnings: clientLearnings,
                segmentName: segmentName?.value,
                variant: evaluationVariant?.value,
                test: _params?.testId,
                reportDetails: {
                  to: selectionRange[0].endDate,
                  from: selectionRange[0].startDate,
                  conclusionFactor: defaultFactorKey?.toLowerCase(),
                  conclusionFactorStatusAll: conclusionFactor,
                  evaluationType: "manual",
                  manualData: variants,
                },
              };
              try {
                const { data } = await previewGenerateManualReport({
                  variables: {
                    reportPayload,
                  },
                });
                setReportDetails(data?.generateManualReport?.reportDetails);
                document.querySelector(".reportpreview").scrollIntoView();
              } catch (error) {
                toast.error("Error while updating report");
              }
            }}
          >
            <SmartSelector
              options={testsList?.tests?.map((test) => {
                return {
                  label: test?.name,
                  value: test?._id,
                };
              })}
              label="Choose Test"
              value={
                _params?.testId
                  ? {
                      label: testsList?.tests?.find(
                        (t) => t?._id === _params?.testId
                      )?.name,
                      value: _params?.testId,
                    }
                  : null
              }
              onChange={(value) => {
                setSearchParams({ testId: value?.value });
              }}
            />

            <div className="smart-input">
              <div className="smart-input-cover smart-select-cover">
                <DateRangeSelectorComponent
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                  selectionRange={selectionRange}
                  setSelectionRange={setSelectionRange}
                  callBackWhenClose={(e) => {
                    setShowCalendar(false);
                  }}
                  showDays={true}
                  className="dark-calendar"
                  classNameWrapper="dark-calendar-wrapper"
                  color={"#fff"}
                />
              </div>
            </div>

            <SmartSelector
              options={groupedOptions}
              label="Choose Segment"
              value={segmentName}
              onChange={(value) => {
                setSegmentName(value);
              }}
            />

            <div className="default-border default-padding default-margin-bottom">
              {conclusionfactors.map((factor, i) => {
                return (
                  <div className="smart-input" key={`factor-${i}`}>
                    <label>{factor}</label>
                    <div className=" d-flex default-column-gap">
                      {btns.map((btn, j) => (
                        <div className="" key={j}>
                          <Button
                            type="button"
                            className={`smart-pill small ${
                              conclusionFactor[factor] === btn ? `active` : ``
                            }`}
                            text={btn}
                            onClick={(e) => {
                              setConclusionFactor({
                                ...conclusionFactor,
                                [factor]: btn,
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="default-padding default-border default-margin-bottom">
              <div className="d-flex align-center justify-content-between">
                <h3> Add Variants</h3>
                <Button
                  className="smart-button small active"
                  text="Add New Variant"
                  type="button"
                  onClick={(e) => {
                    setVariants([
                      ...variants,
                      {
                        audienceName:
                          variants?.length > 0
                            ? `variation_${variants?.length}`
                            : "reference",
                        totalUsers: null,
                        sessions: null,
                        transactions: null,
                        purchasers: null,
                        purchaseRevenue: null,
                        stddev_rpu: null,
                        stddev_aov: null,
                      },
                    ]);
                  }}
                ></Button>
              </div>
              <div>
                {variants?.length === 0 ? (
                  <NoDataFound
                    title="Add at least one variant"
                    description="You need to add variants and the data in order to create report."
                  />
                ) : (
                  <div>
                    {variants.map((variant, i) => {
                      return (
                        <div
                          className="default-border default-padding default-margin-bottom default-margin-top d-flex flex-wrap default-column-gap default-row-gap justify-content-between"
                          key={`variant-${i}`}
                        >
                          <SmartInput
                            label="Audience Name"
                            type="text"
                            placeholder="Enter Audience Name"
                            name="audienceName"
                            value={variant.audienceName}
                            // readOnly
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "audienceName")
                            }
                          />
                          <SmartInput
                            label="Total Users"
                            placeholder="Enter Total Users"
                            name="totalUsers"
                            type="number"
                            value={variant.totalUsers || ""}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "totalUsers")
                            }
                          />
                          <SmartInput
                            label="Sessions"
                            placeholder="Enter Sessions"
                            type="number"
                            name="sessions"
                            value={variant.sessions || ""}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "sessions")
                            }
                          />
                          <SmartInput
                            label="Transactions"
                            placeholder="Enter Transactions"
                            type="number"
                            name="transactions"
                            value={variant.transactions || ""}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "transactions")
                            }
                          />
                          <SmartInput
                            label="Purchasers"
                            placeholder="Enter Purchasers"
                            type="number"
                            name="purchasers"
                            value={variant.purchasers || ""}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "purchasers")
                            }
                          />
                          <SmartInput
                            label="Purchase Revenue"
                            placeholder="Enter Purchase Revenue"
                            type="number"
                            name="purchaseRevenue"
                            value={variant.purchaseRevenue || ""}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "purchaseRevenue")
                            }
                          />
                          <SmartInput
                            label="Stddev RPU"
                            placeholder="Enter Stddev RPU"
                            type="number"
                            name="stddev_rpu"
                            value={variant.stddev_rpu || ""}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "stddev_rpu")
                            }
                          />
                          <SmartInput
                            label="Stddev AOV"
                            placeholder="Enter Stddev AOV"
                            type="number"
                            name="stddev_aov"
                            value={variant.stddev_aov || ""}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "stddev_aov")
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {variants?.length > 0 && (
                <SmartSelector
                  options={variants?.map((variant, i) => {
                    return {
                      label: variant.audienceName,
                      value: variant.audienceName,
                    };
                  })}
                  label="Choose Evaluating Variant"
                  value={evaluationVariant}
                  onChange={(value) => {
                    setEvaluationVariant(value);
                  }}
                />
              )}
            </div>
            <div
              className="
                d-flex
                justify-content-end
                default-margin-top
                default-margin-bottom

              "
            >
              <Button
                className="smart-button big active"
                text="Generate Report"
                type="submit"
                // onClick={handleSubmit}
              />
            </div>
          </form>
        </SmartCard>
      </SmartContainer>

      {_params?.testId && reportDetails && (
        <>
          <div ref={nodeToCapture} className="reportpreview dark-bg">
            <SmartContainer>
              <section className="selected-test-content live-reports-content">
                <ImpressionsTableGa4
                  type="total"
                  {...reportDetails?.totalOfResults}
                />
                {reportDetails?.result?.map((r, i) => (
                  <ImpressionsTableGa4 key={i} {...r} />
                ))}
                <div className="experiment-data-inner">
                  {reportDetails?.calculatedResults?.map((data, i) => (
                    <CalculatedKpisGa4 key={i} {...data} />
                  ))}
                </div>

                <div className="impression-table-heading">
                  <h4>Sequential AB tests results</h4>{" "}
                </div>
                <div className="seq-values">
                  <p>
                    Confidence:{" "}
                    <span>
                      {(1 -
                        reportDetails?.sequentialABTestResult?.values?.alpha) *
                        100}
                      %{" "}
                    </span>{" "}
                  </p>
                  <p>
                    Power:
                    <span>
                      {" "}
                      {(1 -
                        reportDetails?.sequentialABTestResult?.values.beta) *
                        100}
                      %
                    </span>
                  </p>
                  <p>
                    Min. Detectable effect:{" "}
                    <span>
                      {" "}
                      {(1 -
                        reportDetails?.sequentialABTestResult?.values
                          .deltaMin) *
                        100}
                      %
                    </span>
                  </p>
                  <p>
                    Max. Sample size:{" "}
                    <span>
                      {reportDetails?.sequentialABTestResult?.values.nMax}{" "}
                    </span>
                  </p>
                </div>

                <div className="impressions" id="total">
                  <div className="impression-data" id="aov">
                    <div className="impression-heading">
                      <h4>Control Group Success</h4>
                    </div>
                    <span className="data-count">
                      {
                        reportDetails?.sequentialABTestResult
                          ?.controlGroupSuccess
                      }
                    </span>
                  </div>

                  <div className="impression-data" id="aov">
                    <div className="impression-heading">
                      <h4>Variant Group Success</h4>
                    </div>
                    <span className="data-count">
                      {
                        reportDetails?.sequentialABTestResult
                          ?.variantGroupSuccess
                      }
                    </span>
                  </div>

                  <div className="impression-data" id="aov">
                    <div className="impression-heading">
                      <h4>Test Result</h4>
                    </div>
                    <span className="data-count">
                      {reportDetails?.sequentialABTestResult?.testResult}
                    </span>
                  </div>
                  <div className="impression-data" id="aov">
                    <div className="impression-heading">
                      <h4>Significant Percentage</h4>
                    </div>
                    <span className="data-count">
                      {reportDetails?.sequentialABTestResult?.percentageResult}%
                    </span>
                  </div>
                </div>
              </section>
            </SmartContainer>
          </div>
          <SmartContainer>
            <div className="d-flex justify-content-end">
              <Button
                className="smart-button big active"
                type="button"
                onClick={() => captureScreenshot()}
              >
                Capture Screenshot
              </Button>
            </div>
          </SmartContainer>
        </>
      )}
    </div>
  );
}

// PAYLOAD
