import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GETCLIENTLEARNINGS, GETCLIENTNEXTSTEPS } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { TickGreen } from "../Shared/Icons";

export const ClientTopLearningsandNextstep = ({
  showSection,
  clientId,
  selectedProperty,
}) => {
  const [clientLearnings] = useLazyQuery(GETCLIENTLEARNINGS);
  const [clientNextSteps] = useLazyQuery(GETCLIENTNEXTSTEPS);

  const [learningsData, setLearningsData] = useState({
    show: false,
  });
  const [nextStepsData, setNextStepsData] = useState({
    show: false,
  });

  const getClientLearnings = async (viewId, client) => {
    setLearningsData({
      ...learningsData,
      loading: true,
    });

    const { data, loading, error } = await clientLearnings({
      variables: {
        client,
        viewId,
      },
    });
    setLearningsData({
      show: !loading,
      loading,
      clientLearnings: data?.clientLearnings,
      error,
    });
  };

  const getClientNextSteps = async (viewId, client) => {
    setNextStepsData({
      ...nextStepsData,
      loading: true,
    });

    const { data, loading, error } = await clientNextSteps({
      variables: {
        client,
        viewId,
      },
    });
    setNextStepsData({
      show: !loading,
      loading,
      clientNextSteps: data?.clientNextSteps,
      error,
    });
  };

  useEffect(() => {
    if ((clientId, selectedProperty)) {
      getClientLearnings(selectedProperty, clientId);
      getClientNextSteps(selectedProperty, clientId);
    }
  }, [clientId, selectedProperty]);

  if (learningsData?.loading || nextStepsData?.loading) return <MyLoader />;
  return (
    <>
      {learningsData?.clientLearnings?.length === 0 ? (
        <></>
      ) : (
        <section className="top-learning">
          <div className="top-learning-inner">
            <div className="learning-list">
              <div className="next-steps-heading">Top 3 Learnings</div>
              <div className="learning-list-content">
                <ul>
                  {learningsData?.clientLearnings?.map((list, i) => (
                    <li key={i}>
                      <TickGreen />
                      <p className="learning-text">{list?.name}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="next-steps">
              {nextStepsData?.clientNextSteps?.length === 0 ? (
                <></>
              ) : (
                <>
                  <div className="next-steps-heading">Next steps</div>
                  <div className="steps-wrapper">
                    {nextStepsData?.clientNextSteps?.map((list, i) => (
                      <div className="current-step" key={i}>
                        <p>{list?.name}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};
