import React, { useRef, useEffect, useState } from "react";

export default function Accordion({ title, description, faq }) {
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");
  const [active, setActive] = useState(null);

  const content = useRef(null);

  useEffect(() => {
    const anchorElements = content.current.querySelectorAll("a");
    anchorElements.forEach((anchor) => {
      anchor.addEventListener("click", handleAnchorClick);
    });
  }, []);

  function toggleAccordion() {
    setHeightState(() => {
      if (active === faq?._id ? true : false) {
        setActive(null);
        return "0px";
      } else {
        setActive(faq?._id);
        return `${content.current.scrollHeight}px`;
      }
    });

    setRotateState(() => {
      return active ? "accordion__icon" : "accordion__icon rotate";
    });
  }

  function handleAnchorClick(event) {
    event.preventDefault();

    const anchorLink = event.target.getAttribute("href");
    window.open(anchorLink, "_blank");
  }

  return (
    <>
      <div className="faq-question-wrapper">
        <div className="faq-question-inner">
          <div
            className={`heading accordion${
              active === faq?._id ? `active-heading` : ``
            }`}
            onClick={() => {
              toggleAccordion();
            }}
          >
            <h3>{title}</h3>
            <svg
              className={`${setRotate}`}
              width="19"
              height="10"
              viewBox="0 0 19 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.5 10L0 0L9.5 5L19 0L9.5 10Z" fill="#F6F6F6" />
            </svg>
          </div>
          <div
            className="description accordion__content"
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
          >
            <div className="description-wrapper">{description}</div>
          </div>
        </div>
      </div>
    </>
  );
}
