import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useOutletContext } from "react-router-dom";
import { GETCLIENTSWITHUSERS } from "../../../Graphql/Queries";
import {
  SENDWELCOMEEMAIL,
  SENDCREDENTIALS,
  UPDATENDASTATUS,
  REMOVECLIENTFROMUSER,
} from "../../../Graphql/Mutations";
import { FaTrash } from "react-icons/fa";
import { CrossIconModal } from "../../Shared/Icons";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { FiltersComponent } from "../../Shared/FiltersComponent";
import { customStyles } from "../../../Libs/TableCustomStyles";

const UserRowComponent = ({
  selectedRow,
  user,
  removeClientUsers,
  setUserModal,
  refetch,
}) => {
  return (
    <div className="test-details-table-data">
      <div className="test-name text-break">
        <h3>{user?.name}</h3>
      </div>
      <div className="test-email">
        <h3>{user?.email}</h3>
      </div>
      <div className="test-action-wrapper">
        <div className="test-action">
          <div className="test-action-group">
            <FaTrash
              onClick={async () => {
                try {
                  await removeClientUsers({
                    variables: {
                      email: user?.email,
                      clientId: selectedRow?._id,
                    },
                  });
                  toast.success("Remove User From Client");
                  setUserModal(false);
                  refetch();
                } catch (error) {
                  toast.error(error?.message);
                }
              }}
              style={{ cursor: "pointer", color: "#fff" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function ClientAccesses() {
  const [is_admin] = useOutletContext();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [isForm, setIsForm] = useState(false);
  const [clientFilters, setClientFilters] = useState([]);
  const [filter, showFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sendWelcomeEmail] = useMutation(SENDWELCOMEEMAIL);
  const [sendCredentials] = useMutation(SENDCREDENTIALS);
  const [updateNDAStatus] = useMutation(UPDATENDASTATUS);
  const [removeClientUsers] = useMutation(REMOVECLIENTFROMUSER);
  const { data, loading, error, refetch } = useQuery(GETCLIENTSWITHUSERS, {
    variables: {
      clientFilters,
      page: page,
      size: size,
    },
  });

  const [isUserModal, setUserModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.clientEmail.value;
    try {
      if (!selectedRow?.NDAStatus) {
        await sendWelcomeEmail({
          variables: {
            email,
          },
        });
        toast.success(`Send Welcome Email Successfully!`);
      } else {
        await sendCredentials({
          variables: {
            id: selectedRow._id,
            email,
          },
        });
        refetch();
        toast.success(`Send Credentials Successfully!`);
      }
    } catch (error) {
      toast.error(error?.message);
    }
    setIsForm(false);
  };

  const handleNDAStatus = async (e, row) => {
    e.preventDefault();
    const NDAStatus = e.target.checked;
    const id = row?._id;
    await updateNDAStatus({
      variables: {
        id,
        NDAStatus,
      },
    });
  };

  const columnsclients = [
    {
      name: "Client name",
      selector: (row) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
        >
          {row?.name}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Properties",
      cell: (row) => {
        const { gaproperties } = row;

        return (
          <div className="properties">
            {gaproperties.map((property, i) => {
              return (
                <div
                  className="sing-property"
                  key={i}
                  titlel={property?.viewId}
                >
                  {property?.propertyname}
                </div>
              );
            })}
          </div>
        );
      },
      wrap: true,
    },
    {
      name: "NDA Status",
      selector: (row) => (
        <div className="d-inline-flex align-items-center">
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            checked={row?.NDAStatus}
            onChange={(e) => handleNDAStatus(e, row)}
          />
        </div>
      ),
      wrap: true,
    },
    {
      name: "Users",
      selector: (row) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
        >
          {is_admin && row?.users?.length > 0 && (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setUserModal(true);
                setSelectedRow(row);
              }}
            >
              Users List
            </button>
          )}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div style={{ textAlign: "right" }}>
            {is_admin && (
              <>
                {row?.active ? (
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      setIsForm(true);
                      setSelectedRow(row);
                    }}
                  >
                    {!row.NDAStatus ? "Welcome Form" : "Send Credentials"}
                  </button>
                ) : (
                  "In-active Client"
                )}
              </>
            )}
          </div>
        );
      },
      right: true,
    },
  ];

  if (loading) return <MyLoader />;
  if (error) return <div> {error?.message}</div>;
  return (
    <div className="clients-inner">
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Clients Accesses</h2>
          <div>
            <button
              className="btn-as-link d-inline-flex align-items-center text-white"
              onClick={(_) => {
                if (filter) {
                  setPage(1);
                  setClientFilters([]);
                }
                showFilter(!filter);
              }}
            >
              {filter ? "Close Filters" : "Filters"}
            </button>
            <div></div>
          </div>
        </div>
      </div>

      {filter && (
        <FiltersComponent
          serverFilters={clientFilters}
          setServerFilters={setClientFilters}
          setPage={setPage}
          filterTypes={["findtestbytestname"]}
        />
      )}

      {isUserModal && (
        <div>
          <div className="form-wrapper" id="add-new-test-form">
            <div
              style={{ maxWidth: "750px" }}
              className="form-wrapper-inner addtestform"
            >
              <div className="card add-new-test-card">
                <div className="header-bar user-modal-header-bar">
                  <div
                    style={{ padding: "0 0 0 12px" }}
                    className="header-bar-inner"
                  >
                    <h4>
                      {" "}
                      {selectedRow?.name} ({selectedRow?.users?.length})
                    </h4>
                  </div>
                </div>
                <button
                  className="close-modal"
                  onClick={(_) => setUserModal(!isUserModal)}
                >
                  <CrossIconModal />
                </button>
                <div className="card-body p-0">
                  <div className="table-body">
                    <div className="test-details-table-data heading-row">
                      <div className="test-name">
                        <h3>Name</h3>
                      </div>
                      <div className="test-name">
                        <h3>Email</h3>
                      </div>
                      <div className="test-action-wrapper">
                        <div className="test-action">
                          <span>Action</span>
                        </div>
                      </div>
                    </div>
                    {selectedRow?.users?.map((user, i) => (
                      <UserRowComponent
                        selectedRow={selectedRow}
                        user={user}
                        key={i}
                        removeClientUsers={removeClientUsers}
                        refetch={refetch}
                        setUserModal={setUserModal}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isForm && (
        <div>
          <div className="form-wrapper" id="add-new-test-form">
            <div className="form-wrapper-inner addtestform">
              <div className="card add-new-test-card">
                <button
                  className="close-modal"
                  onClick={(_) => setIsForm(false)}
                >
                  <CrossIconModal />
                </button>
                <div className="card-body p-0">
                  <form
                    onSubmit={async (e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="fields-wrapper">
                      <div>
                        <label> Client Email</label>
                        <input
                          name="clientEmail"
                          className="field-control"
                          placeholder="Client Email"
                        />
                      </div>
                    </div>

                    <div className="fields-wrapper">
                      <button>
                        {!selectedRow?.NDAStatus
                          ? "Send Welcome Email"
                          : "Send Credentials"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <DataTable
        columns={columnsclients}
        data={data?.clientsWithUsers?.clients}
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={<MyLoader />}
      />
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        totalcount={data?.clientsWithUsers?.total}
      />
    </div>
  );
}
export default ClientAccesses;
