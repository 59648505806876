import { useState } from "react";
import Highlight from "react-highlight";
import { toast } from "react-toastify";
import "../../../node_modules/highlight.js/styles/androidstudio.css";
import { AspectRatio } from "react-aspect-ratio";

export const SnippetPreview = ({
  setPreviewSnippets,
  previewSnippets: { htmlcode, csscode, jscode, figmaUrl },
}) => {
  const [raw, setRaw] = useState(false);
  //   console.log(previewSnippets);

  const SnippetsComponent = ({ title, code, codetype }) => {
    const [open, setOpen] = useState(true);
    return (
      <div className="snippets-overflow">
        <div className="snippet-heading">
          <p
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
          >
            {" "}
            {title} Code
          </p>
          <button
            onClick={(_) => {
              navigator.clipboard.writeText(code);
              toast.success("Code copied to clipboard");
            }}
          >
            {" "}
            Copy{" "}
          </button>
        </div>
        {open && (
          <Highlight innerHTML={raw} className={codetype}>
            {code}
          </Highlight>
        )}
      </div>
    );
  };

  return (
    <div className="form-wrapper scrubber-wrapper">
      <div className="form-wrapper-inner snippets-preview">
        <div className="card add-new-test-card" style={{ minHeight: "455px" }}>
          <button
            className="close-modal"
            onClick={(_) => setPreviewSnippets(null)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#000"
            >
              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
            </svg>{" "}
          </button>
          <h3>
            {" "}
            Snippets Preview{" "}
            <button
              className="btn btn-sm bg-primary text-white"
              onClick={(_) => setRaw(!raw)}
            >
              Raw
            </button>
          </h3>
          {figmaUrl && (
            <AspectRatio
              ratio="755/424"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <iframe
                style={{
                  width: "100%",
                  // border: "1px solid rgba(0, 0, 0, 0.1)",
                  border: `1px solid rgb(0,0,0,0.02)`,
                  borderRadius: "8px",
                  filter: `drop-shadow(0px 12px 36px rgba(0, 0, 0, 0.05))`,
                }}
                src={`https://www.figma.com/embed?embed_host=share&url=${figmaUrl}`}
                allowFullScreen
              ></iframe>
            </AspectRatio>
          )}
          <div className="code-snippets">
            {htmlcode && (
              <SnippetsComponent title="HTML" code={htmlcode} codetype="html" />
            )}
            {csscode && (
              <SnippetsComponent title="CSS" code={csscode} codetype="css" />
            )}
            {jscode && (
              <SnippetsComponent title="JS" code={jscode} codetype="js" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
