import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { GETCLIENTTESTS } from "../../Graphql/Queries";
import { GridIcons, ListIcon, SearchIcon } from "../Shared/Icons";
import MyLoader from "../MiroComponents/PreLoader";

export const ClientInsightsCard = ({ client, viewId }) => {
  const [clientTestsData, setClientTestsData] = useState({
    show: false,
    loading: true,
  });
  const [view, setView] = useState("grid");
  const size = 15;
  const [page, setPage] = useState(1);
  const [nameSearch, setNameSearch] = useState("");
  const [getclienttests] = useLazyQuery(GETCLIENTTESTS);

  const getClientTestsData = useCallback(
    async (client, viewId) => {
      try {
        const { data, loading, error } = await getclienttests({
          variables: {
            client,
            viewId,
            status: "ended",
            nameSearch,
          },
        });
        setClientTestsData({
          show: !loading,
          loading,
          getClientTests: data?.getClientTests,
        });
      } catch (error) {
        setClientTestsData({
          show: false,
          error,
          loading: false,
        });
      }
    },
    [page, size, nameSearch]
  );
  const toggleView = (view) => {
    setView(view);
  };

  useEffect(() => {
    if (client && viewId) {
      getClientTestsData(client, viewId);
    }
  }, [client, viewId, getClientTestsData]);

  if (!client || !viewId) return ``;
  if (clientTestsData?.loading) return <MyLoader />;
  if (clientTestsData?.error)
    return <div>Something went wrong {clientTestsData?.error?.message}</div>;
  return (
    <>
      <div className="product-page-search insight-search">
        <form
          className="search-wrapper"
          onSubmit={(e) => {
            e.preventDefault();
            if (e.target.search.value.trim() !== "") {
              setNameSearch(e.target.search.value.trim());
            } else {
              setNameSearch(null);
            }
          }}
        >
          <div className="search-icon">
            <SearchIcon />
          </div>
          <div className="input-search">
            <input
              type="text"
              className="product-search"
              placeholder="search"
              name="search"
            />
          </div>
        </form>

        <div className="product-card-view">
          <div className={`grid-view ${view === "grid" ? `view-active` : ``}`}>
            <span className="grid-icon" onClick={(e) => toggleView("grid")}>
              <GridIcons />
            </span>
          </div>
          <div
            className={`menu-view hide-on-mobile ${
              view === "list" ? `view-active` : ``
            }`}
          >
            <span className="menu-icon" onClick={(e) => toggleView("list")}>
              <ListIcon />
            </span>
          </div>
        </div>
      </div>
      <div
        className={`client-insight-card-list ${
          view === "list" ? `insight-list-view` : ``
        }`}
      >
        <div className="list-view-headings">
          <h2 className="list-view-heading-single test-head">Test</h2>
          <h2 className="list-view-heading-single result-head">Result</h2>
          <h2 className="list-view-heading-single triggers-head">Triggers</h2>
          {/* <h2 className="list-view-heading-single tag-head">Tags</h2> */}
          <h2 className="list-view-heading-single learning-head">Learnings</h2>
        </div>
        {clientTestsData?.getClientTests?.tests?.map((test, i) => (
          <div className={`client-insight-card`} key={i}>
            <h3 className="insight-card-heading">{test?.name}</h3>
            <div className="insight-card-details">
              <div className="insight-results">
                <div className="insight-result-wrapper">
                  <span className="card-detail-head">Result:</span>
                  <span
                    className={`card-detail-data insight-result ${
                      test?.report?.conclusion === "winner"
                        ? `insight-result-success`
                        : ``
                    }`}
                  >
                    {test?.report?.conclusion}
                  </span>
                </div>
                {/* <div className="insight-result-wrapper">
                  <h2 className="card-detail-head">Tags:</h2>
                  {test?.tags?.map((tag, index) => (
                    <span className="card-detail-data tags-data" key={index}>
                      {tag?.name}
                    </span>
                  ))}

                  <span className="card-detail-data tags-data"> ... </span>
                </div> */}
              </div>
              <h2 className="card-detail-head">Triggers:</h2>
              <div className="insight-trigger-list">
                {test?.trigger?.map((trig, index) => (
                  <span className="single-trigger" key={index}>
                    {trig?.name}
                  </span>
                ))}
              </div>
            </div>
            <div className="insight-card-learnings">
              <h2 className="card-detail-head">Learnings:</h2>
              {test?.report?.learnings?.map((learn, inde) => (
                <div className="insight-learning-content" key={inde}>
                  {learn}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
