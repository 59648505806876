import { useState } from "react";
import { VscClose } from "react-icons/vsc";
import { CREATECLIENTSURVEY } from "../../../Graphql/Mutations";
import { clientSurveyQuestions } from "../../../Libs/Constants";
import { useMutation } from "@apollo/client";

export const ClientSurveyPopup = ({ user, closePopup }) => {
  const [selectedOption, setOption] = useState(null);
  const [surveyResponse, setSurveyResponse] = useState([]);
  const [validateMsg, setValidateMsg] = useState("");
  const [createClientSurvey] = useMutation(CREATECLIENTSURVEY);

  const pushOrUpdateState = (key, value) => {
    setSurveyResponse((prevData) => {
      const existingIndex = prevData.findIndex((item) => item.question === key);

      if (existingIndex !== -1) {
        const newData = [...prevData];
        newData[existingIndex].answer = value;
        return newData;
      } else {
        return [...prevData, { question: key, answer: value }];
      }
    });
  };

  const validateFields = (e) => {
    const codebaseUse = e.target.codebaseUse.value;
    const surveyDesc = e.target.surveyDesc.value;
    if (!codebaseUse || !surveyDesc) {
      setValidateMsg("Fields cannot be empty");
      return false;
    } else {
      setValidateMsg("");
      return true;
    }
  };

  return (
    <section className="faq-wrapper">
      <div className="faq-inner survey-inner">
        <div className="faq-hero">
          <div className="faq-search">
            <div className="faq-heading">
              <h2 className=""> Codebase Client Survey</h2>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (validateFields(e)) {
                  createClientSurvey({
                    variables: {
                      user,
                      responses: surveyResponse,
                    },
                  });
                  closePopup(false);
                }
              }}
              className="search-wrapper"
            >
              {clientSurveyQuestions?.map((csq, i) => {
                return (
                  <div key={i} className="fields-wrapper">
                    <div className="survey-question">{csq?.question}</div>
                    {csq?.options?.length ? (
                      <div className="radio-group-wrapper">
                        {csq?.options?.map((op, j) => (
                          <div key={`${op}-j`} className="radio-wrapper">
                            <input
                              name="codebaseUse"
                              type={csq?.type}
                              value={op}
                              className="form-check-input"
                              checked={selectedOption === op}
                              onChange={() => {
                                setOption(op);
                                pushOrUpdateState(csq?.question, op);
                              }}
                            />
                            <label className="radio-labels">{op}</label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <input
                        name="surveyDesc"
                        type={csq?.type}
                        onChange={(e) => {
                          pushOrUpdateState(csq?.question, e.target.value);
                        }}
                      />
                    )}
                  </div>
                );
              })}
              {validateMsg && (
                <div className="survey-error-msg">{validateMsg}</div>
              )}
              <div className="fields-wrapper">
                <button className="survey-submit-btn"> Submit Survey</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
