import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactCompareImage from "react-compare-image";
import { useParams } from "react-router-dom";
import { GETREPORTANDTEST, GETREPORTIDFORTEST } from "../../Graphql/Queries";
import { ScrubberHandle } from "../../Libs/Utilities";
import MyLoader from "../MiroComponents/PreLoader";
import ConfidenceOfUpliftGraph from "../Pages/Admin/AdminComponents/ConfidenceOfUpliftGraph";
import ConversionRateTimeSeriesGraph from "../Pages/Admin/AdminComponents/ConversionRateTimeSeriesGraph";
import { ExperimentsCalculatedResults } from "./ExperimentsCalculatedResults";
import { ExperimentsData } from "./ExperimentsData";
import { TotalExperimentsData } from "./TotalExperimentsData";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { differenceInCalendarDays } from "date-fns";
import { CrossIcon } from "./Icons";
import { ClientReportDetailsandInsights } from "../NewComponents/ClientReportDetailsandInsights";

export const ShareReport = () => {
  const params = useParams();
  const [reports, setReports] = useState({
    loading: true,
  });
  const testId = params?._id;

  const [getReportId] = useLazyQuery(GETREPORTIDFORTEST);

  const _getReport = useCallback(async (id) => {
    try {
      const {
        data: { getReportIdsForTest },
        loading,
        error,
      } = await getReportId({
        variables: {
          test: id,
        },
      });
      window.location.href = getReportIdsForTest;
    } catch (error) {
      setReports({
        error,
      });
    }
  }, []);

  useEffect(() => {
    if (testId) {
      _getReport(testId);
    }
  }, [testId, _getReport]);

  //   const printPDF = () => {
  //     var HTML_Width = document.querySelector("#reportCanvas").offsetWidth;
  //     var HTML_Height = document.querySelector("#reportCanvas").offsetHeight;
  //     var top_left_margin = 15;
  //     var PDF_Width = HTML_Width + top_left_margin * 2;
  //     var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
  //     var canvas_image_width = HTML_Width;
  //     var canvas_image_height = HTML_Height;

  //     var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

  // html2canvas(document.querySelector("#reportCanvas"), {
  //   allowTaint: true,
  //   foreignObjectRendering: true,
  // }).then(function (canvas) {
  //   canvas.getContext("3d");
  //   var img = canvas.toDataURL("image/jpeg", 1.0);
  //   const ctx = canvasRef.current.getContext("3d");
  //   canvasRef.current.width = canvas.width;
  //   canvasRef.current.height = canvas.height;
  //   var background = new Image();
  //   background.src = img;
  //   // Make sure the image is loaded first otherwise nothing will draw.
  //   background.onload = function () {
  //     ctx.drawImage(background, 0, 0);
  //   };

  //   console.log(canvas.height + "  " + canvas.width);
  //   console.log(canvas);
  //   canvasRef.current.innerHTML = canvas;
  // var img = canvas.toDataURL("image/jpeg");
  //   console.log(img);
  //   window.open(img);
  //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //   var pdf = new jsPdf("p", "pt", [PDF_Width, PDF_Height]);
  //   pdf.addImage(
  //     imgData,
  //     "JPG",
  //     top_left_margin,
  //     top_left_margin,
  //     canvas_image_width,
  //     canvas_image_height
  //   );

  //   for (var i = 1; i <= totalPDFPages; i++) {
  //     pdf.addPage(PDF_Width, PDF_Height);
  //     pdf.addImage(
  //       imgData,
  //       "JPG",
  //       top_left_margin,
  //       -(PDF_Height * i) + top_left_margin * 4,
  //       canvas_image_width,
  //       canvas_image_height
  //     );
  //   }

  //   pdf.save("HTML-Document.pdf");
  // });
  //   };

  if (reports?.loading) return <MyLoader />;
  return (
    <>
      <div id="reportCanvas">
        <div className="report-details-modal">
          <div className="report-details-modal-inner">
            <div className="report-details-modal-content-wrapper">
              <ClientReportDetailsandInsights
                selectedReport={reports?.getReportIdsForTest}
              />
            </div>
          </div>
        </div>

        {/* <h4 className="mb-2 d-inline-block border-bottom">
          <span className="small text-primary">
            {reports?.test?.property?.label} -
          </span>{" "}
          {reports?.test?.name}{" "}
        </h4>
        <div className="visual-box-wrapper">
          <div className="visual-box-inner">
            <button className="btn btn-default border shadow d-flex align-items-center justify-content-center">
              {reports?.segmentName}
            </button>
          </div>
        </div>
        <div className="visual-box-wrapper">
          <div className="visual-box-inner">
            <div className="d-flex align-items-center justify-content-between">
              <h3>
                <span className="me-1"> Performance Results from </span>
                <span className="badge bg-primary text-white">
                  {moment(reports?.from).format("ll")}
                </span>{" "}
                to{" "}
                <span className="badge bg-primary text-white">
                  {moment(reports?.to).format("ll")}
                </span>
                <span className="ms-2">Days : {reports?.days} </span>
              </h3>
            </div>
            <TotalExperimentsData
              experimentsData={reports?.reportDetails?.experiments}
              uniqueUsersWithTransactions={
                reports?.reportDetails?.uniqueUsersWithTransactions
              }
              title="Total"
            />
          </div>
        </div>
        <div className="visual-box-wrapper">
          <div className="visual-box-inner">
            {reports?.reportDetails?.experiments?.map((expsdata, i) => {
              return (
                <ExperimentsData
                  experiments={expsdata?.experiments}
                  title={expsdata?.controlvariant}
                  key={i}
                  index={i}
                  uniqueUsersWithTransactions={
                    reports?.reportDetails?.uniqueUsersWithTransactions
                  }
                  users={expsdata?.users}
                />
              );
            })}
          </div>
        </div>
        {reports?.reportDetails?.experimentResults?.map((edt, i) => (
          <ExperimentsCalculatedResults edt={edt} key={i} client={null} />
        ))}

        <div className="visual-box-wrapper">
          <div className="visual-box-inner">
            <div className="segment-graph-wrapper">
              <div className="segment-graph-inner">
                <ConversionRateTimeSeriesGraph
                  graphExperimentData={reports?.reportDetails?.experiments}
                  controlVariant={reportDetails?.controlVariant}
                  segmentName={reportDetails?.segmentName}
                />
              </div>
              <div className="segment-graph-inner">
                {reports?.reportDetails?.experimentResults?.map((edata, i) => (
                  <ConfidenceOfUpliftGraph
                    key={i}
                    label={edata?.controlvariant}
                    upliftValues={[
                      (100 - edata?.confidence).toFixed(2).toString(),
                      edata?.confidence,
                    ]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="visual-box-wrapper">
          <div className="visual-box-inner">
            <div className="d-flex align-items-center justify-content-between">
              <h3
                className={`fw-bold text-decoration-underline text-uppercase ${
                  reports?.conclusion === "winner"
                    ? `text-success`
                    : `text-dark`
                }`}
              >
                {reports?.conclusion}
                <i> ({reports?.segmentName})</i>
              </h3>
            </div>
            <div>
              <ul>
                {reports?.learnings?.map((_, i) => (
                  <li key={i}> {_} </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {reports?.test?.hypothesis && (
          <div className="p-2 mb-2">
            <h5> Hypothesis</h5>
            <div className="p-2 bg-light rounded-1">
              <p> {reports?.test?.hypothesis.name} </p>
              <p> - {reports?.test?.hypothesis?.action} </p>
              <p> - {reports?.test?.hypothesis?.expectation} </p>
              <p> - {reports?.test?.hypothesis?.result} </p>
            </div>
          </div>
        )}
        {reports?.test?.urltargeting?.length > 0 && (
          <div className="p-2 mb-2">
            <h5> Target URL(s)</h5>
            <div className="p-2 bg-light rounded-1">
              <ul className="pl-0 ml-0 mb-0">
                {test?.urltargeting.map((url, i) => (
                  <li key={i}> {url} </li>
                ))}
              </ul>
            </div>
          </div>
        )} */}
        {/* <ReactCompareImage
          leftImage={reports?.test?.controlimage}
          rightImage={reports?.test?.variationimage}
          handle={<ScrubberHandle />}
          sliderLineColor="#000"
          sliderLineWidth="8"
          onSliderPositionChange={(_) => {
            if (
              document.querySelector(".scrub") &&
              !document.querySelector(".scrub").classList.contains("dragged")
            ) {
              document.querySelector(".scrub").classList.add("dragged");
            }
          }}
          skeleton={<MyLoader />}
        /> */}
      </div>
    </>
  );
};
