import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMyProfile } from "../../Hooks/useMyProfile";
import MyLoader from "../MiroComponents/PreLoader";
import { LoaderLogo } from "./LoaderLogo";

export function Redirect() {
  const navigate = useNavigate();
  const { myProfile, loading, error, entryRoute } = useMyProfile();
  useEffect(() => {
    setTimeout(() => {
      if (myProfile && !myProfile?.twoFactor) {
        navigate("/two-factor-auth", {
          state: {
            returnUrl: window.location.href,
          },
        });
      } else if (!loading && myProfile) {
        navigate(entryRoute, {
          state: {
            disableBackNavigate: true,
          },
        });
      }
      if (error) {
        navigate("/auth/login", {
          state: {
            returnUrl: window.location.href,
          },
        });
      }
    }, 500);
  }, [loading, error, myProfile]);
  if (loading) return <LoaderLogo />;
  return <LoaderLogo />;
}
