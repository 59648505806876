import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { GETREPORTANDTEST } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { ReportDetails } from "./ReportDetails";

export const GetReportFromTestId = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [notificationData, setNotificationData] = useState({
    loading: true,
    error: null,
  });
  const testId = location?.state?.testId ?? params?._id;
  const [reportDetails] = useLazyQuery(GETREPORTANDTEST);
  const _getReport = useCallback(async (id) => {
    const { data, loading, error } = await reportDetails({
      variables: {
        id,
      },
    });
    setNotificationData({
      loading,
    });
    if (loading) {
      setNotificationData({
        loading,
      });
    }
    if (error) {
      setNotificationData({
        error,
      });
    }
    if (data) {
      setNotificationData({
        reportDetails: {
          reportDetails: data?.getReportDetails?.report?.reportDetails,
          test: data?.getReportDetails?.test,
          from: data?.getReportDetails?.report?.from,
          to: data?.getReportDetails?.report?.to,
          segmentName: data?.getReportDetails?.report?.segmentName,
          learnings: data?.getReportDetails?.report?.learnings,
          conclusion: data?.getReportDetails?.report?.conclusion,
        },
        loading,
        error,
      });
    }
  }, []);

  const unsetDetails = () => {
    navigate(location?.state?.backUrl ? location?.state?.backUrl : -1, {
      state: {
        page: location?.state?.page,
      },
    });
  };

  useEffect(() => {
    if (testId) {
      _getReport(testId);
    }
  }, [testId, _getReport]);
  if (notificationData?.loading) return <MyLoader />;
  return (
    <div>
      {/* <button onClick={(e) => printPDF()}> CLick to Open Pdf</button> */}
      {notificationData?.reportDetails && (
        <ReportDetails
          reportDetails={notificationData.reportDetails}
          unsetDetails={unsetDetails}
          className="notification-report-details max-width100"
          notificationScreen={false}
          showCloseIcon={params?._id}
        />
      )}
    </div>
  );
};
