import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { GETLANDINGPAGES } from "../../../Graphql/Queries";
import useStore from "../../../Store";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { FaEdit } from "react-icons/fa";
import { TimesCross, CrossIcon } from "../../Shared/Icons";
import {
  CREATELANDINGPAGES,
  UPDATELANDINGPAGES,
} from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
import UploadIcon from "../../MiroComponents/NewForm/UploadIcon";
import { UploadFile } from "../../../Libs/Cloudinary";
import Dropzone from "react-dropzone";
import { VscChromeClose } from "react-icons/vsc";
import { ImagePlaceholder } from "../../Shared/Icons";
import { Scrubber } from "../../Shared/Scrubber";
import Select from "react-select";
import { useOutletContext } from "react-router-dom";
import { Button } from "../../MiroComponents/Button/Button";

function Landingpages() {
  const [myProfile] = useOutletContext();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [action, setAction] = useState(null);
  const [controlImage, setControlImage] = useState(null); // [controlImage, setControlImage
  const [scrubber, showScrubber] = useState(null);
  const [createLandingPages] = useMutation(CREATELANDINGPAGES);
  const [updateLandingPages] = useMutation(UPDATELANDINGPAGES);
  const [selectedClient, setSelectedClient] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const { data, loading, error, refetch } = useQuery(GETLANDINGPAGES, {
    variables: { page: page, size: size },
  });

  // console.log(clients, clientsLoading, clientsError);

  const { toggleForm } = useStore();
  const showForm = useStore((state) => state.showForm);

  const handleState = () => {
    showScrubber(null);
    setControlImage(null);
    setSelectedClient(null);
  };

  const TestRowComponent = ({ test }) => {
    return (
      <div className="test-details-table-data">
        <div className="test-name text-break">
          <h3>{test?.title}</h3>
        </div>
        <div className="test-compare-img">
          <span
            onClick={() => {
              showScrubber({
                control: test?.screenshot,
              });
            }}
          >
            <ImagePlaceholder />
          </span>
        </div>
        <div className="test-name text-break">
          <h3>{test?.dataPoints?.conversionRate} %</h3>
        </div>
        <div className="test-action-wrapper">
          <div className="test-action">
            <div className="test-action-group">
              <FaEdit
                onClick={() => {
                  setAction({ module: "edit", data: test });
                  setControlImage(test.screenshot);
                  setSelectedClient({
                    label: test?.client?.name,
                    value: test?.client?._id,
                  });
                  toggleForm(!showForm);
                }}
                style={{ cursor: "pointer", color: "gray" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) return <MyLoader />;
  if (error)
    return (
      <div className="no-data text-white">
        {" "}
        <h3>{error?.message}</h3>
      </div>
    );
  return (
    <div>
      <Scrubber showScrubber={showScrubber} scrubber={scrubber} />
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Landingpages</h2>
          <div>
            {
              <>
                <Button
                  className="smart-button big active"
                  onClick={(_) => {
                    toggleForm(!showForm);
                    setAction({ module: "add" });
                  }}
                >
                  Add Landing page
                </Button>
              </>
            }
          </div>
        </div>
      </div>
      {showForm && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner form-wrapper-inner__updated addtestform  scroll">
            <div
              className="card add-new-test-card scroll modal-card"
              style={{ minHeight: "fit-content" }}
            >
              <button
                className="close-modal"
                onClick={(_) => {
                  toggleForm(!showForm);
                  handleState();
                }}
              >
                <TimesCross />
              </button>

              <h3>Landing Page</h3>
              <div className="card-body p-0">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const { title, conversionRate } = e.target;
                    if (
                      !selectedClient ||
                      title.value.trim() === "" ||
                      conversionRate.value.trim() === "" ||
                      !controlImage
                    ) {
                      toast.error("Please fill all the fields");
                      return;
                    }

                    const landingPagesPayload = {
                      title: title.value.trim(),
                      screenshot: controlImage,
                      client: selectedClient.value,
                      dataPoints: {
                        conversionRate: conversionRate.value.trim(),
                      },
                    };
                    if (action.module === "add") {
                      try {
                        await createLandingPages({
                          variables: {
                            landingPagesPayload,
                          },
                        });
                        toast.success("Added successfully");
                        toggleForm(!showForm);
                      } catch (error) {
                        toast.error(error.message);
                      }
                    }
                    if (action.module === "edit") {
                      try {
                        await updateLandingPages({
                          variables: {
                            updateLandingPagesId: action?.data?._id,
                            landingPagesPayload,
                          },
                        });
                        toast.success("updated successfully");
                        toggleForm(!showForm);
                        setAction(null);
                      } catch (error) {
                        toast.error(error.message);
                      }
                    }
                    handleState();
                    refetch();
                  }}
                >
                  <div className="fields-wrapper">
                    <label> Select Client *</label>
                    <Select
                      onChange={(client) => {
                        setSelectedClient(client);
                      }}
                      value={selectedClient}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      placeholder={
                        module === "edit" ? `Change Client` : `Select Client`
                      }
                      // isDisabled={module === "edit" ? true : false}
                      options={myProfile?.client?.map((c, i) => {
                        return {
                          label: c?.label,
                          value: c?.value?._id,
                        };
                      })}
                    />
                  </div>

                  <div className="fields-wrapper">
                    <div>
                      <label> Title</label>
                      <input
                        name="title"
                        className="field-control  field-control__updated"
                        defaultValue={action?.data ? action?.data.title : " "}
                        // onChange={(e) => {
                        //   setFormData({ ...formData, title: e.target.value });
                        // }}
                      />
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div>
                      <label>Conversion Rate</label>
                      <input
                        name="conversionRate"
                        type="number"
                        step=".01"
                        className="field-control  field-control__updated"
                        defaultValue={
                          action?.data
                            ? action?.data?.dataPoints?.conversionRate
                            : 0
                        }
                        // onChange={(e) =>
                        //   setFormData({
                        //     ...formData,
                        //     dataPoints: {
                        //       conversionRate: e.target.value,
                        //     },
                        //   })
                        // }
                      />
                    </div>
                  </div>
                  <div className="fields-wrapper">
                    <Dropzone
                      accept={{
                        "image/png": [".png", ".jpg", ".jpeg"],
                      }}
                      onDrop={async (acceptedFiles) => {
                        setPageLoading(true);
                        const _fileupload = await UploadFile(acceptedFiles[0]);
                        if (_fileupload?.error) {
                          setPageLoading(false);
                          toast.error(_fileupload?.error?.message);
                        } else {
                          setControlImage(_fileupload?.secure_url);
                          setPageLoading(false);
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="control-image"
                          {...getRootProps()}
                          style={
                            controlImage
                              ? {
                                  backgroundImage: `url(${controlImage})`,
                                  minHeight: "200px",
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }
                              : {}
                          }
                        >
                          <input {...getInputProps()} />
                          <div
                            className="upload-icon"
                            style={{
                              border: "1px solid #000",
                              background: "#fff",
                            }}
                          >
                            <UploadIcon />
                            {controlImage && (
                              <VscChromeClose
                                onClick={(_) => {
                                  setControlImage(null);
                                  setPageLoading(false);
                                }}
                              />
                            )}
                            <span
                              style={{
                                color: "#000",
                              }}
                            >
                              Add Screenshot...
                            </span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>

                  <div className="add-idea-btn">
                    <Button
                      type="submit"
                      className="smart-button big full-width active"
                      disabled={pageLoading}
                    >
                      Add
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {data?.getLandingPages?.results?.length === 0 && (
        <div className="no-data text-white">
          <h3>No Landing Pages Found</h3>
        </div>
      )}
      {data?.getLandingPages?.results?.length > 0 && (
        <>
          <div className="table-body">
            <div className="test-details-table-data heading-row">
              <div className="test-name">
                <h3>Title</h3>
              </div>

              <div className="test-compare-img">
                <span>Screenshot</span>
              </div>
              <div className="test-name">
                <h3>Conversion Rate</h3>
              </div>
              <div className="test-action-wrapper">
                <div className="test-action">
                  <span> Action</span>
                </div>
              </div>
            </div>
            {data?.getLandingPages?.results?.map((test, i) => (
              <TestRowComponent test={test} key={i} />
            ))}
          </div>
          <Pagination
            page={page}
            size={size}
            setPage={setPage}
            setSize={setSize}
            totalcount={data?.getLandingPages?.total}
          />
        </>
      )}
    </div>
  );
}
export default Landingpages;
