import { useEffect, useState } from "react";
import { CrossIconModal } from "../Shared/Icons";
import MyLoader from "./PreLoader";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { Button } from "./Button/Button";

export const GenerateContentReport = ({
  content,
  setContent,
  maxWidth = 80,
}) => {
  return (
    <>
      <div className="form-wrapper">
        <div
          className={`form-wrapper-inner bg-2 default-padding radius-4`}
          style={maxWidth ? { maxWidth: maxWidth + "%" } : {}}
        >
          <div className="flex align-center justify-content-between default-border-bottom default-padding-bottom">
            <div className="flex align-center default-column-gap">
              <h3 className="margin-bottom-auto"> Content Report </h3>
              {content?.content && (
                <Button
                  className="smart-button medium active"
                  text={`Copy Report`}
                  type="button"
                  onClick={(e) => {
                    navigator.clipboard.writeText(content.content);
                    e.target.innerText = "Report Copied!";
                    setTimeout(() => {
                      e.target.innerText = "Copy Report";
                    }, 2000);
                  }}
                />
              )}
            </div>

            <Button
              style={{
                border: "none",
                background: "none",
              }}
              icon={<CrossIconModal />}
              onClick={(_) => setContent(null)}
            />
          </div>

          <div className="default-padding-top default-padding-bottom">
            {content?.loading ? (
              <ContentLoader />
            ) : (
              <div
                className="card-body p-0"
                style={{ whiteSpace: "pre-wrap", color: "#fff" }}
              >
                {content?.content || "No content available"}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
