import "./Input.scss";
export default function Input({
  type,
  placeholder,
  label,
  subLabel = "",
  name,
  icon,
  value,
  wrapperClass = "",
  inputWrapperClass = "",
  onChange,
  component,
  children,
  ...props
}) {
  if (component) return <>{children}</>;
  return (
    <div className={`field-wrapper ${wrapperClass}`}>
      {label && <label> {label}</label>}
      {subLabel && (
        <p className="sub-label default-margin-bottom">{subLabel}</p>
      )}

      <div className={`input-wrapper ${inputWrapperClass}`}>
        {icon && <span>{icon}</span>}
        {type === "textarea" && (
          <textarea
            {...props}
            name={name}
            placeholder={placeholder}
            defaultValue={value}
            onChange={onChange}
          />
        )}
        {type !== "textarea" && (
          <input
            {...props}
            name={name}
            type={type}
            placeholder={placeholder}
            defaultValue={value}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
}
