import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { VscEdit, VscRemove, VscTrash } from "react-icons/vsc";
import { useOutletContext, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  CREATECLIENTLEARNING,
  CREATECLIENTNEXTSTEPS,
  CREATECLIENTSTRATEGY,
  UPDATECLIENTLEARNING,
  UPDATECLIENTNEXTSTEPS,
  UPDATECLIENTSTRATEGY,
} from "../../../Graphql/Mutations";
import {
  GETCLIENTLEARNINGS,
  GETCLIENTNEXTSTEPS,
  GETCLIENTPROPERTIES,
  GETCLIENTSTRATEGIES,
} from "../../../Graphql/Queries";
import { MonthsList, YearsList } from "../../../Libs/Utilities";
import MyLoader from "../../MiroComponents/PreLoader";
import { PendingCircle, TickGreen } from "../../Shared/Icons";

export const ManageClient = () => {
  const params = useParams();
  const [learningsData, setLearningsData] = useState({
    show: false,
  });
  const [nextStepsData, setNextStepsData] = useState({
    show: false,
  });
  const [strategyData, setStrategyData] = useState({
    show: false,
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const { data } = useQuery(GETCLIENTPROPERTIES, {
    variables: {
      id: params?.clientId,
    },
  });
  const [editObj, setEditObj] = useState(null);
  const [strategies, setStrategies] = useState([]);
  const [clientLearnings] = useLazyQuery(GETCLIENTLEARNINGS);
  const [clientNextSteps] = useLazyQuery(GETCLIENTNEXTSTEPS);
  const [clientStrategies] = useLazyQuery(GETCLIENTSTRATEGIES);
  const [createclientlearning] = useMutation(CREATECLIENTLEARNING);
  const [updateclientlearning] = useMutation(UPDATECLIENTLEARNING);
  const [createclientnextstep] = useMutation(CREATECLIENTNEXTSTEPS);
  const [updateclientnextstep] = useMutation(UPDATECLIENTNEXTSTEPS);

  const [createclientstrategy] = useMutation(CREATECLIENTSTRATEGY);
  const [updateclientstrategy] = useMutation(UPDATECLIENTSTRATEGY);

  const [year, setYear] = useState({
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  });
  const [month, setMonth] = useState({
    label: new Date().toLocaleString("en-US", { month: "long" }),
    value: Number(new Date().toLocaleString("en-US", { month: "2-digit" })),
  });

  const resetDates = () => {
    setMonth({
      label: new Date().toLocaleString("en-US", { month: "long" }),
      value: Number(new Date().toLocaleString("en-US", { month: "2-digit" })),
    });
    setYear({
      label: new Date().getFullYear(),
      value: new Date().getFullYear(),
    });
    setEditObj(null);
    setStrategies([]);
  };

  const deleteStrategy = (id) => {
    const _subps = strategies.filter((sp) => sp.id !== id);
    const newArr = _subps.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    setStrategies(newArr);
  };

  const getClientLearnings = async (viewId, client) => {
    setLearningsData({
      ...learningsData,
      loading: true,
    });
    const { data, loading, error } = await clientLearnings({
      variables: {
        client,
        viewId,
      },
    });
    setLearningsData({
      show: !loading,
      loading,
      clientLearnings: data?.clientLearnings,
      error,
    });
  };

  const getClientStrategies = async (viewId, client) => {
    var currentDate = moment(new Date());
    var nextMonth = moment(currentDate).add(1, "M");
    var nextMonthEnd = moment(futureMonth).endOf("month");
    var futureMonth = moment(currentDate).add(2, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");
    if (
      currentDate.date() != nextMonth.date() &&
      nextMonth.isSame(nextMonthEnd.format("YYYY-MM-DD"))
    ) {
      nextMonth = nextMonth.add(1, "d");
    }
    if (
      currentDate.date() != futureMonth.date() &&
      futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
    ) {
      futureMonth = futureMonth.add(1, "d");
    }
    let dates = [
      currentDate.toDate(),
      nextMonth.toDate(),
      futureMonth.toDate(),
    ];

    setStrategyData({
      ...strategyData,
      loading: true,
    });
    const { data, loading, error } = await clientStrategies({
      variables: {
        viewId,
        client,
        dates,
      },
    });
    setStrategyData({
      show: !loading,
      loading,
      clientStrategies: data?.clientStrategies,
      error,
    });
  };

  const getClientNextSteps = async (viewId, client) => {
    setNextStepsData({
      ...nextStepsData,
      loading: true,
    });
    const { data, loading, error } = await clientNextSteps({
      variables: {
        client,
        viewId,
      },
    });
    setNextStepsData({
      show: !loading,
      loading,
      clientNextSteps: data?.clientNextSteps,
      error,
    });
  };

  useEffect(() => {
    if (selectedProperty) {
      getClientLearnings(selectedProperty?.value, params?.clientId);
      getClientNextSteps(selectedProperty?.value, params?.clientId);
      getClientStrategies(selectedProperty?.value, params?.clientId);
    }
  }, [params, selectedProperty]);

  const setStrategyValue = (id, key, value) => {
    const formObj = strategies.find((sp) => sp.id === id);
    formObj[key] = value;
    const _subps = strategies.filter((sp) => sp.id !== id);
    const newArr = [..._subps, formObj].sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    setStrategies(newArr);
  };

  const StrategyForm = React.forwardRef(({ obj }, ref) => (
    <div className="strategy-form-outer" ref={ref}>
      <div className="strategy-form-inner">
        <div className="fields-wrapper strategy-name-field">
          <label> Title *</label>
          <input
            className="field-control"
            onBlur={(_) => {
              setStrategyValue(obj.id, "name", _.currentTarget.value);
            }}
            defaultValue={obj.name}
          />
        </div>
        <div className="fields-wrapper strategy-prepared-delete">
          <span
            onClick={(e) => {
              setStrategyValue(obj.id, "done", !obj?.done);
            }}
          >
            {obj.done ? `Mark Prepared` : `Mark Done`}
          </span>
          <span type="button" onClick={(e) => deleteStrategy(obj.id)}>
            <VscTrash />
          </span>
        </div>
      </div>
    </div>
  ));
  const PropertySelector = ({ clientProperties }) => {
    return (
      <div className="fields-wrapper">
        <label className="">Properties </label>
        <Select
          onChange={(propertyvalue) => {
            setSelectedProperty(propertyvalue);
          }}
          isDisabled={!params?.clientId ? true : false}
          value={selectedProperty}
          classNamePrefix="acc-select"
          className={selectedProperty ? `active-select` : ``}
          placeholder="Select Property"
          options={clientProperties?.map((c, i) => {
            return {
              label: c?.propertyname,
              value: c?.viewId,
            };
          })}
        />
      </div>
    );
  };
  if (learningsData?.loading || nextStepsData?.loading || strategyData?.loading)
    return <MyLoader />;
  return (
    <div className="manage-client-wrapper">
      <div className="manage-client-inner">
        <div>
          <PropertySelector
            clientProperties={data?.clientProperties?.gaproperties}
          />
        </div>
        {selectedProperty && (
          <>
            <div className="card learnings">
              <div className="card-header">
                <h3> Manage Top Learnings</h3>
              </div>
              <div className="card-body">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const name = e.target.name.value;
                    const viewId = selectedProperty?.value;
                    const client = params?.clientId;
                    if (name.trim() === "") {
                      return;
                    }
                    if (editObj && editObj?.module === "learnings") {
                      try {
                        await updateclientlearning({
                          variables: {
                            id: editObj?.data?._id,
                            name,
                          },
                        });
                        getClientLearnings(viewId, client);
                        setEditObj(null);
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    } else {
                      try {
                        await createclientlearning({
                          variables: {
                            name,
                            viewId,
                            client,
                          },
                        });
                        getClientLearnings(viewId, client);
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    }
                    e.target.name.value = "";
                  }}
                >
                  <div className="fields-wrapper">
                    <label> Learning Text</label>
                    <input
                      className="field-control"
                      name="name"
                      defaultValue={
                        editObj?.module === "learnings"
                          ? editObj?.data?.name
                          : ``
                      }
                    />
                  </div>
                  <div className="fields-wrapper">
                    <button className="ms-auto me-0" type="submit">
                      Add
                    </button>
                  </div>
                </form>
                <ul>
                  {learningsData?.clientLearnings?.map((learning, i) => (
                    <li key={i}>
                      <span>{learning?.name}</span>
                      <span className="learning-actions">
                        {/* <span>
                          <VscTrash />
                        </span> */}
                        <span
                          onClick={(e) => {
                            setEditObj({
                              module: "learnings",
                              data: {
                                _id: learning?._id,
                                name: learning?.name,
                              },
                            });
                          }}
                        >
                          <VscEdit />
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="card nextsteps mt-3 w-100">
              <div className="card-header">
                <h3> Manage Next Steps</h3>
              </div>
              <div className="card-body">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const name = e.target.name.value;
                    const viewId = selectedProperty?.value;
                    const client = params?.clientId;
                    if (name.trim() === "") {
                      return;
                    }

                    if (editObj && editObj?.module === "nextstep") {
                      try {
                        await updateclientnextstep({
                          variables: {
                            id: editObj?.data?._id,
                            name,
                          },
                        });
                        getClientNextSteps(viewId, client);
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    } else {
                      try {
                        await createclientnextstep({
                          variables: {
                            name,
                            viewId,
                            client,
                          },
                        });
                        getClientNextSteps(viewId, client);
                        e.target.name.value = "";
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    }
                    e.target.name.value = "";
                  }}
                >
                  <div className="fields-wrapper">
                    <label> Next Step Text</label>
                    <input
                      className="field-control"
                      name="name"
                      defaultValue={
                        editObj?.module === "nextstep"
                          ? editObj?.data?.name
                          : ``
                      }
                    />
                  </div>
                  <div className="fields-wrapper">
                    <button className="ms-auto me-0" type="submit">
                      Add
                    </button>
                  </div>
                </form>
                <ul>
                  {nextStepsData?.clientNextSteps?.map((nextStep, i) => (
                    <li key={i}>
                      <span>{nextStep?.name}</span>
                      <span className="learning-actions">
                        {/* <span>
                          <VscTrash />
                        </span> */}
                        <span
                          onClick={(e) => {
                            setEditObj({
                              module: "nextstep",
                              data: {
                                _id: nextStep?._id,
                                name: nextStep?.name,
                              },
                            });
                          }}
                        >
                          <VscEdit />
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="card strategy mt-3 w-100">
              <div className="card-header">
                <h3> Strategy and Road Map</h3>
              </div>
              <div className="card-body">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const viewId = selectedProperty?.value;
                    const client = params?.clientId;
                    if (editObj && editObj?.module === "strategy") {
                      try {
                        await updateclientstrategy({
                          variables: {
                            id: editObj?.data?._id,
                            client,
                            viewId,
                            year,
                            month,
                            strategies,
                          },
                        });
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    } else {
                      try {
                        await createclientstrategy({
                          variables: {
                            client,
                            viewId,
                            year,
                            month,
                            strategies,
                          },
                        });
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    }
                    resetDates();
                    getClientStrategies(viewId, client);
                  }}
                >
                  <div className="fields-wrapper">
                    <label> Select Year</label>
                    <Select
                      onChange={(year) => {
                        setYear(year);
                      }}
                      isDisabled={editObj && editObj?.module === "strategy"}
                      value={year}
                      classNamePrefix="acc-select"
                      placeholder={`Select Year`}
                      options={YearsList()?.map((c) => {
                        return {
                          label: c,
                          value: c,
                        };
                      })}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label> Select Month</label>
                    <Select
                      onChange={(mnth) => {
                        setMonth(mnth);
                      }}
                      isDisabled={editObj && editObj?.module === "strategy"}
                      value={month}
                      classNamePrefix="acc-select"
                      placeholder={`Select Month`}
                      options={MonthsList()?.map((c) => {
                        return {
                          label: c.name,
                          value: c.month,
                        };
                      })}
                    />
                  </div>

                  {strategies.map((obj, i) => (
                    <StrategyForm obj={obj} key={i} />
                  ))}

                  <div className="fields-wrapper">
                    <div>
                      <button
                        type="button"
                        className="add-strategy-cta"
                        onClick={(_) => {
                          setStrategies([
                            ...strategies,
                            {
                              id: new Date().valueOf(),
                              name: null,
                              done: false,
                            },
                          ]);
                        }}
                      >
                        <span> Add Strategy / Roadmap text</span>
                      </button>
                    </div>
                  </div>
                  <div className="fields-wrapper">
                    <button className="ms-auto me-0" type="submit">
                      Add
                    </button>
                  </div>
                </form>
                {strategyData?.clientStrategies?.map((strtgy, i) => (
                  <div className="card mt-2" key={i}>
                    <div className="card-header">
                      <h4>
                        {strtgy?.month?.label} ({strtgy?.year?.label})
                        <span
                          onClick={(e) => {
                            setEditObj({
                              module: "strategy",
                              data: {
                                _id: strtgy?._id,
                              },
                            });
                            setMonth(strtgy?.month);
                            setYear(strtgy?.year);
                            setStrategies(
                              strtgy?.strategies?.map((item, index) => {
                                return {
                                  id: index,
                                  name: item?.name,
                                  done: item?.done,
                                };
                              })
                            );
                          }}
                        >
                          <VscEdit />
                        </span>
                      </h4>
                    </div>
                    <div className="card-body">
                      <ul>
                        {strtgy?.strategies?.map((lists, index) => (
                          <li key={index}>
                            <span>
                              {" "}
                              {lists?.done ? <TickGreen /> : <PendingCircle />}
                            </span>{" "}
                            <span>{lists?.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
