import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../Pages/Admin/admin-style.css";
import { CustomNavigator } from "../Shared/CustomNavigator";
import { useEffect } from "react";
import { useMyProfile } from "../../Hooks/useMyProfile";
import { LoaderLogo } from "../Shared/LoaderLogo";
import { NotificationSidebar } from "../Shared/NotificationSidebar";
import useStore from "../../Store";
import { VscArrowSmallLeft } from "react-icons/vsc";
function NotificationLayout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { myProfile, loading, error, entryRoute } = useMyProfile();
  const { setSideBar } = useStore();
  useEffect(() => {
    if (error) {
      navigate("/auth/login", {
        state: {
          gobackto: window.location.href,
        },
      });
    }
  }, [error]);
  if (loading) return <LoaderLogo />;
  const is_admin = myProfile?.role?.some((rl) => rl?.name === "ADMIN");
  const is_optimizer = myProfile?.role?.some((rl) => rl?.name === "OPTIMIZER");
  const is_client = myProfile?.role?.some((rl) => rl?.name === "CLIENT");
  return (
    <div>
      <div className="notification-layout-wrapper">
        {/* <AuthTopBar sideBar={sideBar} is_admin={is_admin} /> */}
        <div className="notification-layout-inner">
          <div className="notification-layout-sidebar-wrapper">
            {location?.state?.backUrl && (
              <div className="p-2">
                <button
                  className="border-0 badge text-white bg-dark fw-normal"
                  title="Go Back"
                  onClick={(_) => navigate(location?.state?.backUrl)}
                >
                  <VscArrowSmallLeft /> Go Back
                </button>
              </div>
            )}
            <NotificationSidebar
              is_client={is_client}
              is_admin={is_admin}
              userId={myProfile?._id}
              setSideBar={setSideBar}
            />
          </div>
          <div className="authcontent">
            {/* <CustomNavigator
              disableBackNavigate={location.state?.disableBackNavigate}
            /> */}
            <Outlet context={[myProfile, is_client, is_admin]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationLayout;
