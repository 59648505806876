import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { CREATEFEEDBACK } from "../../../Graphql/Mutations";
import MyLoader from "../../MiroComponents/PreLoader";

export const SendFeedback = () => {
  const [myProfile, is_client] = useOutletContext();
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState("");
  const [createFeedback] = useMutation(CREATEFEEDBACK);
  if (loader) return <MyLoader />;
  return (
    <>
      <section className="feedback">
        <div className="feedback-inner">
          <div className="feedback-wrapper">
            <h1 className="feedback-title">Leave your Feedback:</h1>
            <div className="product-page-search">
              <form
                action="#"
                className="search-wrappe"
                onSubmit={async (_) => {
                  _.preventDefault();
                  const feedbackPayload = {};
                  const title = _.target.title.value;
                  if (title.trim() === "" || details.trim() === "") {
                    toast.warning("Please add valid details.");
                    return;
                  }
                  feedbackPayload["details"] = details;
                  feedbackPayload["title"] = title;
                  // CHANGE BOY
                  feedbackPayload["client"] = myProfile?.client[0]?._id;
                  try {
                    setLoader(true);
                    const { data, loading, error } = await createFeedback({
                      variables: {
                        feedbackPayload,
                      },
                    });
                    if (error) {
                      setLoader(false);
                      toast.error(error?.message);
                    }
                    if (data) {
                      setLoader(false);
                      toast.success("Feedback sent. Thank you!");
                      setTimeout(function () {
                        setDetails("");
                      }, 200);
                    }
                  } catch (error) {
                    setLoader(false);
                    toast.error(error?.message);
                  }
                }}
              >
                <div className="input-search">
                  <input
                    type="text"
                    className="product-search"
                    name="title"
                    placeholder="Title"
                  />
                </div>
                <div className="ck-editor">
                  <CKEditor
                    editor={ClassicEditor}
                    data={details}
                    // onReady={(editor) => {
                    //     console.log("Editor is ready to use!", editor);
                    // }}
                    // onChange={(event, editor) => {
                    //     const data = editor.getData();
                    //     console.log({ event, editor, data });
                    // }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setDetails(data);
                    }}
                    // onFocus={(event, editor) => {
                    //     console.log("Focus.", editor);
                    // }}
                  />
                </div>
                <div className="">
                  <button className="send-feedback-btn">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="form-wrapper2">
        <div className="form-inner">
          <form
            onSubmit={async (_) => {
              _.preventDefault();
              const feedbackPayload = {};
              const title = _.target.title.value;
              if (title.trim() === "" || details.trim() === "") {
                toast.warning("Please add valid details.");
                return;
              }
              setLoader(true);
              feedbackPayload["details"] = details;
              feedbackPayload["title"] = title;
              // CHANGE BOY
              feedbackPayload["client"] = myProfile?.client[0]?._id;
              const { data, loading, error } = await createFeedback({
                variables: {
                  feedbackPayload,
                },
              });
              if (data) {
                setLoader(false);
                toast.success("Feedback sent. Thank you!");
                setTimeout(function () {
                  setDetails("");
                }, 200);
              }
            }}
          >
            <div className="fields-wrapper">
              <label> Title</label>
              <input className="field-control" name="title" />
            </div>
            <div className="fields-wrapper">
              <CKEditor
                editor={ClassicEditor}
                data={details}
                onReady={(editor) => {
                  //   console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  //   const data = editor.getData();
                  //   console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  setDetails(data);
                  //   console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  //   console.log("Focus.", editor);
                }}
              />
            </div>
            <div className="fields-wrapper">
              <button> Submit Feedback</button>
            </div>
          </form>
        </div>
      </div> */}
      {/* <p className="mb-3 mt-3 bg-light fs-italic p-2">
        Our team is working hard to release more features and settings in the
        future.
      </p> */}
    </>
  );
};
