import { FaEdit } from "react-icons/fa";
import { BackIcon, InfoIcon, NoteIcon, SearchIcon } from "./Icons";
import { GETCLIENTHYPOTHESISSHEET, GETHYPOTHESIS } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { useMutation, useQuery } from "@apollo/client";
import FormInstance from "../MiroComponents/FormInstance";
import { CREATEHYPOTHESIS, UPDATEHYPOTHESIS } from "../../Graphql/Mutations";
import { toast } from "react-toastify";
import { useState } from "react";
import Select from "react-select";

export const ClientHypothesisSheet = ({ client, viewId }) => {
  const [action, setAction] = useState(null);
  const [hypothesis, setHypothesis] = useState(null);
  const hypothesisList = useQuery(GETHYPOTHESIS, {
    variables: {
      fullSet: false,
    },
  });
  const { data, loading, error, refetch } = useQuery(GETCLIENTHYPOTHESISSHEET, {
    variables: {
      client,
      viewId,
    },
  });

  const [createhypothesis] = useMutation(CREATEHYPOTHESIS);
  const [updatehypothesis] = useMutation(UPDATEHYPOTHESIS);

  if (loading || hypothesisList?.loading) return <MyLoader />;
  if (error) return <p>Error: {error?.message}</p>;
  const Header = () => (
    <div className="store-audit-table-headers">
      <h4 className="audit-table-sno">Edit</h4>
      <h4>
        PageType
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        Name
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        If
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        Then
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4>
        Because
        <span>
          <InfoIcon />
        </span>
      </h4>
      <h4 className="psychological-trigger">
        <p>Psychological Trigger</p>
        <span>
          <InfoIcon />
        </span>
      </h4>
    </div>
  );

  return (
    <div>
      {(action?.module === "edit" || action?.module === "add") && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner addtestform">
            <div className="card add-new-test-card" id="formcard">
              <button
                className="close-modal"
                onClick={(_) => {
                  setAction(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <h3>Add Hypothesis</h3>
              <div className="card-body p-0">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const form = e.target;
                    const formData = new FormData(form);
                    const data = Object.fromEntries(formData.entries());
                    data.tagged = "Client Specific";
                    data.hypothesisNumber = "";
                    data.client = client;
                    data.viewId = viewId;
                    const hypothesisPayload = {
                      ...data,
                    };
                    if (action?.module === "edit") {
                      try {
                        await updatehypothesis({
                          variables: {
                            hypothesisPayload,
                            sheetId: action?.data?._id,
                            id: action?.data?.hypothesis?._id,
                          },
                        });
                        toast.success("Hypothesis Updated Successfully");
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    }
                    if (action?.module === "add") {
                      try {
                        await createhypothesis({
                          variables: {
                            hypothesisPayload,
                          },
                        });
                        toast.success("Hypothesis Added Successfully");
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    }
                    refetch();
                    form.reset();
                    setAction(null);
                  }}
                >
                  <div
                    className="fields-wrapper"
                    style={
                      action?.module === "edit"
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <label>Clone Hypothesis</label>
                    <Select
                      options={hypothesisList?.data?.allHypothesis?.hypotheses?.map(
                        (hp) => {
                          // const labelTitle = <strong>{hp?.name}</strong>;
                          return {
                            label: `${hp?.name} - ${hp?.action} - ${hp?.expectation} - ${hp?.result}`,
                            value: JSON.stringify(hp),
                          };
                        }
                      )}
                      isDisabled={action?.module === "edit"}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      value={hypothesis}
                      onChange={(hypo) => {
                        const selectors = [
                          "name",
                          "action",
                          "expectation",
                          "result",
                        ];
                        const value = JSON.parse(hypo?.value);
                        selectors.forEach((selector) => {
                          document.getElementById(selector).value =
                            value[selector];
                        });
                        setHypothesis(hypo);
                      }}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label>Name</label>
                    <input
                      className="field-control"
                      name="name"
                      id="name"
                      defaultValue={action?.data?.hypothesis?.name}
                      placeholder="Hypothesis name"
                    />
                  </div>
                  {/* <div className="fields-wrapper">
                    <label> Hypothesis Number</label>
                    <input
                      className="field-control"
                      name="hypothesisNumber"
                      defaultValue={action?.data?.hypothesis?.hypothesisNumber}
                      placeholder="Hypothesis Number"
                    />{" "}
                  </div> */}
                  <div className="fields-wrapper">
                    <label>If we do x</label>
                    <input
                      className="field-control"
                      name="action"
                      id="action"
                      defaultValue={action?.data?.hypothesis?.action}
                      placeholder="If we do x"
                    />{" "}
                  </div>
                  <div className="fields-wrapper">
                    <label> then y happens</label>
                    <input
                      className="field-control"
                      defaultValue={action?.data?.hypothesis?.result}
                      name="result"
                      id="result"
                      placeholder="Then y happens"
                    />{" "}
                  </div>
                  <div className="fields-wrapper">
                    <label> Because we expect</label>
                    <input
                      className="field-control"
                      name="expectation"
                      id="expectation"
                      defaultValue={action?.data?.hypothesis?.expectation}
                      placeholder="Because we expect"
                    />{" "}
                  </div>
                  {/* <div className="fields-wrapper">
                    <label> Tag</label>
                    <select className="field-control" name="hypoTagged">
                      <option value="General Purpose"> General Purpose</option>
                      <option value="Evergreen"> Evergreen</option>
                      <option value="Client Specific"> Client Specific</option>
                    </select>
                  </div> */}
                  <div className="add__new__test__form-footer">
                    <div className="btn-prev" onClick={(_) => setAction(null)}>
                      <BackIcon />
                      Cancel
                    </div>

                    <button type="submit" className="btn next-btn">
                      Add Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="product-page-search">
        <form className="search-wrapper">
          <div className="search-icon">
            <SearchIcon />
          </div>
          <div className="input-search">
            <input
              type="text"
              className="product-search"
              placeholder="search"
            />
          </div>
        </form>
        <div className="product-card-view">
          <div className="sort-by">
            <span>Sort by</span>
            <span className="sort-icon">
              <svg
                width="19"
                height="10"
                viewBox="0 0 19 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.5 10L0 0L9.5 5L19 0L9.5 10Z" fill="#F6F6F6" />
              </svg>
            </span>
          </div>
        </div>
      </div> */}
      <div
        className="add-hypothesis-store-audit"
        onClick={(e) =>
          setAction({
            module: "add",
          })
        }
      >
        <NoteIcon />
        <span className="add-hypothesis-store-audit-title">
          Add New hypothesis
        </span>
      </div>
      <div className="store-audit-table__g">
        <Header />
        <div className="store-audit-table-rows">
          {data?.hypothesisSheetsByClient?.map((hypothesis, i) => (
            <div
              className={`store-audit-table-single-row ${
                hypothesis?.tested ? `tested` : ``
              }`}
              key={i}
            >
              <h4
                className="audit-table-sno store-audit-table-data"
                onClick={(e) => {
                  setAction({
                    module: "edit",
                    data: hypothesis,
                  });
                }}
              >
                <FaEdit style={{ cursor: "pointer" }} />
              </h4>
              <div className="store-audit-table-data select">
                {hypothesis?.pageelement?.map((pe) => pe?.name).join(",")}
                <hr />
                <p className="mb-0">
                  <small className="text-break">
                    {" "}
                    Tested for :{" "}
                    <span className="text-primary">
                      {" "}
                      {hypothesis?.test?.name}{" "}
                    </span>
                  </small>
                </p>
              </div>
              <div className="store-audit-table-data">
                {hypothesis?.hypothesis?.name}
              </div>
              <div className="store-audit-table-data">
                {hypothesis?.hypothesis?.action}
              </div>

              <div className="store-audit-table-data">
                {hypothesis?.hypothesis?.result}
              </div>
              <div className="store-audit-table-data">
                {hypothesis?.hypothesis?.expectation}
              </div>
              <div className="store-audit-table-data">
                <div className="insight-trigger-list">
                  {hypothesis?.trigger?.map((t, ind) => (
                    <span className="single-trigger" key={ind}>
                      {t?.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
