import { useEffect, useState } from "react";
import { VscInfo } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";

export const CalculatedKpisGa4 = ({
  significanceAOV,
  significanceRPU,
  change_relativeAOV,
  change_relativeRPU,
  controlvariant,
  changeInRpu,
  confidenceOfSelection,
  confidenceOf,
  changeInAov,
  changeInConversionRate,
  additionalTransactionsPerMonth,
  additionalRevenuePerYear,
  additionalRevenuePerMonth,
  additionalRevenueDuringNow,
  significanceRPUFor,
  significanceAOVFor,
}) => {
  const [showAovRpu, setShowAovRpu] = useState(false);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showAovRpu]);

  const valueColor = ({
    number,
    symbol = null,
    prefix = "",
    postfix = "",
    formatColor,
  }) => {
    // value = value?.toString();
    // value = value?.replaceAll(/,\s?/g, "");
    // value = isFinite(value) ? value : 0;
    // if (value <= 0) {
    //   return <span className="number-danger"> {decimal ? value : 0} </span>;
    // }
    // return (
    //   <span className="numbers-success">
    //     {" "}
    //     {Number(value).toLocaleString("en-US", {
    //       minimumFractionDigits: decimal ? 2 : 0,
    //       maximumFractionDigits: decimal ? 2 : 0,
    //     })}{" "}
    //   </span>
    // );
    if (!number) {
      return 0;
    }
    number = number.toString();
    number = number.replace(/[$,%]/g, "");
    number = isFinite(number) ? number : 0;
    let numberPrefix = "";
    let numberClass = "";
    if (number > 0) {
      numberPrefix = "+";
      numberClass = "green";
    }
    if (number < 0) {
      numberClass = "red";
    }
    number = Number(number).toLocaleString("en-US");
    return (
      <span className={formatColor ? numberClass : ``}>
        {prefix}
        {symbol && numberPrefix}
        {number}
        {postfix}
      </span>
    );
  };

  return (
    <>
      <div className="calculated-kpis-variants">
        <div className="experiment-heading-divider">
          <div className="variant-heading section-heading-data">
            <h3 className="variant-name">{controlvariant} Performance</h3>
            <span className="section-heading-subtitle">(to-date)</span>
          </div>
          <div>
            <span
              className="showaovrpucta"
              onClick={(_) => {
                setShowAovRpu(!showAovRpu);
              }}
            >
              {" "}
              {showAovRpu ? `Hide` : `Show`} AOV/RPU Details
            </span>
          </div>
        </div>

        <div className="time-line-variants">
          <div className="time-line-variant-heading">
            <div className="variant-performance-cards">
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Revenue{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Variant revenue - Control revenue`}
                  >
                    <VscInfo />
                  </span>
                  <br />
                  <span className="performance-time-period">(during now)</span>
                </h2>
                <div className="performance-card-numbers">
                  {valueColor({
                    number: additionalRevenueDuringNow,
                  })}
                  <small>{}</small>
                </div>
              </div>

              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Change in CR{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Variant CR (users) ÷ (Control CR (users)) - 1`}
                  >
                    <VscInfo />
                  </span>
                </h2>
                <div className="performance-card-numbers">
                  {valueColor({
                    number: changeInConversionRate.replace("%", ""),
                  })}
                  %
                </div>
              </div>

              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Confidence of {confidenceOf}
                  <span
                    className="toottip-span"
                    data-tip={`= 1 - NormalDist(Control Transactions (per session) - Variant Transactions (per session)) ÷
                SQRT(Math.pow(Control Std. Error, 2) + Math.pow(Variant Std Error, 2))`}
                  >
                    <VscInfo />
                  </span>
                </h2>
                <div className="performance-card-numbers">
                  {confidenceOfSelection} %
                </div>
              </div>

              {/* SECOND ROW */}
              {showAovRpu && (
                <>
                  <div className="single-performance-card">
                    <h2 className="performance-card-heading">
                      Change in RPU{" "}
                      <span
                        className="toottip-span"
                        data-tip={`= Variant RPU - Control RPU`}
                      >
                        <VscInfo />
                      </span>
                      <br />
                      <span className="performance-time-period">
                        (Absolute)
                      </span>
                    </h2>
                    <div className="performance-card-numbers">
                      {valueColor({
                        number: changeInRpu,
                      })}{" "}
                    </div>
                  </div>

                  {change_relativeRPU && (
                    <div className="single-performance-card">
                      <h2 className="performance-card-heading">
                        Change in RPU{" "}
                        {/* <span
                      className="toottip-span"
                      data-tip={`= Variant CR (users) * Monthly Traffic -
                  Control CR (users) * Monthly Traffic`}
                    >
                      <VscInfo />
                    </span>{" "} */}
                        <br />
                        <span className="performance-time-period">
                          (Relative)
                        </span>
                      </h2>
                      <div className="performance-card-numbers">
                        {valueColor({
                          number: change_relativeRPU,
                        })}
                      </div>
                    </div>
                  )}

                  {significanceRPU && (
                    <div className="single-performance-card">
                      <h2 className="performance-card-heading">
                        Confidence RPU{" "}
                        {/* <span
                      className="toottip-span"
                      data-tip={`= Variant CR (users) * Monthly Traffic -
                  Control CR (users) * Monthly Traffic`}
                    >
                      <VscInfo />
                    </span>{" "} */}
                        {/* <br /> */}
                        <span className="performance-time-period">
                          {" "}
                          ({significanceRPUFor}){" "}
                        </span>
                      </h2>
                      <div className="performance-card-numbers">
                        <span
                          className={
                            significanceRPUFor
                              ?.toLowerCase()
                              ?.indexOf("reference") > -1
                              ? `number-danger`
                              : ``
                          }
                        >
                          {!isNaN(significanceRPU) ? significanceRPU : 0} %
                        </span>
                      </div>
                    </div>
                  )}

                  {/* THIRD ROW */}

                  <div className="single-performance-card">
                    <h2 className="performance-card-heading">
                      Change in AOV{" "}
                      <span
                        className="toottip-span"
                        data-tip={`= Variant AOV - Control AOV`}
                      >
                        <VscInfo />
                      </span>
                      <br />
                      <span className="performance-time-period">
                        (Absolute)
                      </span>
                    </h2>
                    <div className="performance-card-numbers">
                      {valueColor({
                        number: changeInAov,
                      })}{" "}
                    </div>
                  </div>

                  {change_relativeAOV && (
                    <div className="single-performance-card">
                      <h2 className="performance-card-heading">
                        Change in AOV{" "}
                        {/* <span
                      className="toottip-span"
                      data-tip={`= Variant CR (users) * Monthly Traffic -
                  Control CR (users) * Monthly Traffic`}
                    >
                      <VscInfo />
                    </span>{" "}
                    <br /> */}
                        <span className="performance-time-period">
                          (Relative)
                        </span>
                      </h2>
                      <div className="performance-card-numbers">
                        {valueColor({
                          number: change_relativeAOV,
                        })}
                      </div>
                    </div>
                  )}

                  {significanceAOV && (
                    <div className="single-performance-card">
                      <h2 className="performance-card-heading">
                        Confidence AOV{" "}
                        {/* <span
                      className="toottip-span"
                      data-tip={`= Variant CR (users) * Monthly Traffic -
                  Control CR (users) * Monthly Traffic`}
                    >
                      <VscInfo />
                    </span>{" "}
                    <br /> */}
                        <span className="performance-time-period">
                          ({significanceAOVFor})
                        </span>
                      </h2>
                      <div className="performance-card-numbers">
                        <span
                          className={
                            significanceAOVFor
                              ?.toLowerCase()
                              ?.indexOf("reference") > -1
                              ? `number-danger`
                              : ``
                          }
                        >
                          {!isNaN(significanceAOV) ? significanceAOV : 0} %
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* 4th ROW */}

              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Transactions{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Variant CR (users) * Monthly Traffic -
                  Control CR (users) * Monthly Traffic`}
                  >
                    <VscInfo />
                  </span>{" "}
                  <br />
                  <span className="performance-time-period">(Per Month)</span>
                </h2>
                <div className="performance-card-numbers">
                  {valueColor({
                    number: additionalTransactionsPerMonth,
                  })}{" "}
                </div>
              </div>

              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Revenue{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Additional Transactions (per month) * Average Order Value`}
                  >
                    <VscInfo />
                  </span>
                  <br />
                  <span className="performance-time-period">(Per Month)</span>
                </h2>

                <div className="performance-card-numbers">
                  {valueColor({
                    number: additionalRevenuePerMonth,
                  })}
                  {/* <small>{data?.currency}</small> */}
                </div>
              </div>
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Revenue{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= (Additional Transactions (per month)) * 12`}
                  >
                    <VscInfo />
                  </span>
                  <br />
                  <span className="performance-time-period">(Per year)</span>
                </h2>
                <div className="performance-card-numbers">
                  {valueColor({
                    number: additionalRevenuePerYear,
                  })}
                  {/* <small>{data?.currency}</small> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
