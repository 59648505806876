import { DetailsTab } from "./DetailsTab";

export const TotalExperimentsData = ({
  experimentsData,
  title,
  uniqueUsersWithTransactions,
}) => {
  const totalusers = experimentsData?.reduce(
    (_totalusers, item) => Number(item?.users) + _totalusers,
    0
  );
  const totalsessionscount = experimentsData
    ?.reduce((allexps, exp) => allexps.concat(exp?.experiments), [])
    .reduce((sestotal, item) => sestotal + Number(item?.sessions), 0);
  const totaltransactionscount = experimentsData
    ?.reduce((allexps, exp) => allexps.concat(exp?.experiments), [])
    .reduce((transtotal, item) => transtotal + Number(item?.transactions), 0);
  const totalrevenuecount = experimentsData
    ?.reduce((allexps, exp) => allexps.concat(exp?.experiments), [])
    .reduce((revtotal, item) => revtotal + Number(item?.transactionRevenue), 0);
  const totalconversionratesessionscount =
    totaltransactionscount / totalsessionscount;
  const totalconversionrateuserscount = totaltransactionscount / totalusers;
  const totalaveragerevenueperusercount = totalrevenuecount / totalusers;
  // const totalaveragerevenueperusercount =
  //   totalrevenuecount / uniqueUsersWithTransactions;
  const totalaverageordervaluecount =
    totalrevenuecount / totaltransactionscount;
  return (
    <DetailsTab
      title={title}
      vlusers={totalusers}
      vlsessions={totalsessionscount}
      vltransactions={totaltransactionscount}
      vlCRsession={totalconversionratesessionscount}
      vlCRuser={totalconversionrateuserscount}
      vlAVGRPU={
        isNaN(totalaveragerevenueperusercount)
          ? 0
          : totalaveragerevenueperusercount
      }
      vlrevenue={totalrevenuecount}
      vlAOV={totalaverageordervaluecount}
    />
  );
};
