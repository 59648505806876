import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { GETCLIENTNOTES, GETCLIENTSNAMEANDIDS } from "../../../Graphql/Queries";
import { returnClientOptions } from "../../../Libs/Utilities";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClientHypothesisSheet } from "../../Shared/ClientHypothesisSheet";
import { ClientDataSheet } from "../../Shared/ClientDataSheet";
import MyLoader from "../../MiroComponents/PreLoader";
import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";

export const ClientAudit = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const selectedClient = useStore((state) => state.selectedClient);
  const property = searchParams.get("property");
  const module = searchParams.get("module");
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);

  const [getClientNotes] = useLazyQuery(GETCLIENTNOTES);

  const selectedProperty = selectedClient
    ? selectedClient?.value?.gaproperties?.find((p) => p?.viewId === property)
    : null;

  // const ClientSelector = ({ options }) => {
  //   return (
  //     <div className="fields-wrapper">
  //       <Select
  //         onChange={(client) => {
  //           setSearchParams({ client: JSON.parse(client?.value)?._id });
  //           setSelectedProperty(null);
  //         }}
  //         value={selectedClient}
  //         // isMulti={true}
  //         className="acc-newselectwrapper"
  //         classNamePrefix="acc-newselect"
  //         placeholder="Name"
  //         options={options}
  //       />
  //     </div>
  //   );
  // };

  const PropertySelector = ({ clientProperties }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(propertyvalue) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.property = propertyvalue?.value;
            _params.module = "hypothesis";
            setSearchParams(_params);
          }}
          // isDisabled={!client ? true : false}
          value={
            selectedProperty
              ? {
                  label: selectedProperty?.propertyname,
                  value: selectedProperty?.viewId,
                }
              : null
          }
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Choose One"
          options={clientProperties?.map((c) => {
            return {
              label: c?.propertyname,
              value: c?.viewId,
            };
          })}
        />
      </div>
    );
  };

  if (!selectedClient) return <NoDataFound />;
  return (
    <div>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <h1 className="section-heading-title">Store Audit</h1>
          </div>
          <div className="client-data-wrapper">
            <div className="client-details">
              {/* <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clientSelectorOptions} />
              </div> */}
              <div className="properties-dropdown">
                <h1 className="section-heading">Properties:</h1>
                <PropertySelector
                  clientProperties={selectedClient?.value?.gaproperties}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedClient && property && (
        <>
          <div className="audit-client-wrapper">
            {selectedClient?.value?.logo && (
              <div className="audit-client-img">
                <img src={selectedClient?.value?.logo} alt="" />
              </div>
            )}

            <div className="audit-client-name">
              <h3 className="section-heading">{selectedClient?.name}</h3>
            </div>
            <p className="client-note-description">
              Fill out the structure below to create a full report of the client
            </p>
          </div>

          <div className="tab-component">
            <div
              className={`tab tab1 ${
                module === "hypothesis" ? `tab-active` : ``
              }`}
              onClick={(e) => {
                const _params = Object.fromEntries([...searchParams]);
                _params.module = "hypothesis";
                setSearchParams(_params);
              }}
            >
              <span className="tab-title"> Hypothesis Sheet</span>
            </div>
            <div
              className={`tab tab1 ${
                module === "datasheet" ? `tab-active` : ``
              }`}
              onClick={(e) => {
                const _params = Object.fromEntries([...searchParams]);
                _params.module = "datasheet";
                setSearchParams(_params);
              }}
            >
              <span className="tab-title">Data Sheet</span>
            </div>
          </div>

          {module === "hypothesis" && (
            <ClientHypothesisSheet
              client={selectedClient?.value?._id}
              viewId={property}
            />
          )}
          {module === "datasheet" && (
            <ClientDataSheet
              client={selectedClient?.value?._id}
              viewId={property}
            />
          )}
        </>
      )}
    </div>
  );
};

// useSearchParams react update url params
// https://reactrouter.com/web/example/query-parameters
// https://reactrouter.com/web/api/Hooks/useparams
