import { Button } from "../../MiroComponents/Button/Button";
import Input from "../../MiroComponents/Input/Input";
import { DropIcon, TickGreen } from "../../Shared/Icons";
import "./AbTestToolOnBoarding.scss";

export default function AbTestToolOnBoarding({
  state: { onBoarding },
  dispatch,
}) {
  return (
    <div className="default-margin-bottom">
      <div className="d-flex justify-content-end">
        <Button
          className={`smart-button medium active`}
          text="Onboarding"
          icon={
            <DropIcon
              style={
                onBoarding
                  ? { transform: "rotate(0deg)" }
                  : { transform: "rotate(180deg)" }
              }
            />
          }
          onClick={(e) => {
            dispatch({ type: "SET_ONBOARDING", payload: !onBoarding });
          }}
        />
      </div>
      <div
        className="onboarding"
        style={onBoarding ? { display: "block" } : { display: "none" }}
      >
        <div className="default-margin-bottom">
          <h1 className="">Get started with Conversion Tool.</h1>
        </div>

        <ul className="d-flex flex-column default-border default-padding">
          <li className="default-margin-bottom default-padding border-bottom d-inline-flex default-column-gap align-items-center">
            <div>
              <TickGreen className="" />
            </div>
            <div>
              <h3 className="default-margin-bottom">Step 1</h3>
              <p className="default-margin-bottom">
                Check script integration status. If not integraded, please add
                the script to your website.
              </p>
              {/* <form
                className="default-margin-top default-margin-bottom"
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("Check Integration");
                  const { siteurl } = e.target.elements;
                  console.log(siteurl.value);
                  checkURL(siteurl.value);
                }}
              >
                <div className="d-inline-flex full-width align-items-center">
                  <Input
                    placeholder="Enter site url"
                    name="siteurl"
                    type="url"
                    wrapperClass="full-width margin-bottom-auto"
                  />
                  <Button
                    type="submit"
                    text="Check Integration"
                    className="smart-button medium active"
                  />
                </div>
              </form> */}
            </div>
          </li>
          <li className="default-margin-bottom default-padding border-bottom d-inline-flex default-column-gap align-items-center">
            <div>
              <TickGreen className="" />
            </div>
            <div>
              <h3 className="default-margin-bottom">Step 2</h3>
              <p className="default-margin-bottom">
                Create your first experiment. Add variations and targeting
                rules.
              </p>
            </div>
          </li>
          <li className="default-padding d-inline-flex default-column-gap align-items-center">
            <div>
              <TickGreen className="" />
            </div>
            <div>
              <h3 className="default-margin-bottom">Step 3</h3>
              <p className="">
                Check for data in live reporting module after 24 hours of
                running an experiment.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
