import { useEffect, useState } from "react";
import { VscInfo } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";

export const ImpressionsTable = ({ type, name, users, experiments }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const sessions = experiments?.reduce(
      (cumm, vexpval) => cumm + Number(vexpval?.sessions),
      0
    );
    const transactions = experiments?.reduce(
      (cumm, vexpval) => cumm + Number(vexpval?.transactions),
      0
    );
    const conversionratesessions = ((transactions / sessions) * 100).toFixed(2);
    const conversionrateusers = ((transactions / users) * 100).toFixed(2);
    const revenue = experiments
      ?.reduce((cumm, vexpval) => cumm + Number(vexpval?.transactionRevenue), 0)
      .toFixed(2);

    const averagerevenueperuser = (revenue / users).toFixed(2);
    const averageordervalue = (revenue / transactions).toFixed(2);
    setData({
      sessions,
      transactions,
      conversionratesessions,
      conversionrateusers,
      averagerevenueperuser,
      averageordervalue,
      revenue: Number(revenue).toLocaleString("en-US"),
    });
  }, [type, experiments]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <div className="impression-table-heading">{name}</div>
      <div className="impressions" id="total">
        <div className="impression-data" id="user">
          <div className="impression-heading">
            <h4>
              User{" "}
              {type === "total" && (
                <span data-tip={`Users`} className="toottip-span">
                  <VscInfo />
                </span>
              )}{" "}
            </h4>
          </div>
          <span className="data-count"> {users} </span>
        </div>
        <div className="impression-data" id="sessions">
          <div className="impression-heading">
            <h4>
              Session{" "}
              {type === "total" && (
                <span data-tip={`Sessions`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {data?.sessions} </span>
        </div>
        <div className="impression-data" id="transactions">
          <div className="impression-heading">
            <h4>
              Transactions
              {type === "total" && (
                <span data-tip={`Transactions`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {data?.transactions} </span>
        </div>
        <div className="impression-data" id="crSessions">
          <div className="impression-heading">
            <h4>
              CR (Sessions){" "}
              {type === "total" && (
                <span
                  data-tip={`Transactions / Sessions`}
                  className="toottip-span"
                >
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {data?.conversionratesessions}% </span>
        </div>
        <div className="impression-data" id="crUsers">
          <div className="impression-heading">
            <h4>
              CR (Users){" "}
              {type === "total" && (
                <span
                  data-tip={`Transactions / Users`}
                  className="toottip-span"
                >
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {data?.conversionrateusers}% </span>
        </div>
        <div className="impression-data" id="avgRpu">
          <div className="impression-heading">
            <h4>
              Avg. RPU{" "}
              {type === "total" && (
                <span data-tip={`Revenue / Users`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {data?.averagerevenueperuser} </span>
        </div>
        <div className="impression-data" id="aov">
          <div className="impression-heading">
            <h4>
              AOV{" "}
              {type === "total" && (
                <span
                  data-tip={`Revenue / Transactions`}
                  className="toottip-span"
                >
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {data?.averageordervalue} </span>
        </div>
        <div className="impression-data" id="revenue">
          <div className="impression-heading">
            <h4>
              Revenue ${" "}
              {type === "total" && (
                <span data-tip={`Revenue`} className="toottip-span">
                  <VscInfo />
                </span>
              )}
            </h4>
          </div>
          <span className="data-count"> {data?.revenue} </span>
        </div>
      </div>
    </>
  );
};
