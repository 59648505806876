import { useMutation } from "@apollo/client";
import { useState } from "react";
import { VscChromeClose, VscCloudUpload } from "react-icons/vsc";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  CREATEMULTIPLEGOALS,
  CREATEPAGEELEMENTS,
  CREATEMULTIPLETRIGGERS,
  CREATESTAGESINCUSTOMERSJOURNEY,
  CREATEHYPOTHESISCSV,
} from "../../../Graphql/Mutations";
import { CsvAllowedFields } from "../../../Libs/Utilities";
import { useCSVReader } from "react-papaparse";
import { useOutletContext } from "react-router-dom";
import { NotAuthorizedInternal } from "../../Shared/NotAuthorizedInternal";

export const ImportCsvData = () => {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const { CSVReader, jsonToCSV } = useCSVReader();

  const [module, setModule] = useState(null);
  const [dataToImport, setDataToImport] = useState([]);
  const [allowedFields, setAllowedFields] = useState(null);
  const [createGoals] = useMutation(CREATEMULTIPLEGOALS);
  const [createPageElements] = useMutation(CREATEPAGEELEMENTS);
  const [createTriggers] = useMutation(CREATEMULTIPLETRIGGERS);
  const [createCustomerJourneys] = useMutation(CREATESTAGESINCUSTOMERSJOURNEY);
  const [createHypothesis] = useMutation(CREATEHYPOTHESISCSV);

  const CSVMODULES = [
    {
      label: "Goals",
      value: "goals",
    },
    {
      label: "Object/Page",
      value: "page_elements",
    },
    {
      label: "Psychological Trigger",
      value: "psychological_triggers",
    },
    {
      label: "Stage in Customer Journey",
      value: "stages_in_customer_journey",
    },
    {
      label: "Hypothesis",
      value: "hypothesis",
    },
  ];

  const ModuleSelector = () => {
    return (
      <div className="fields-wrapper">
        {/* <label className="">Select Module to Import Data from CSV </label> */}
        <Select
          onChange={(_module) => {
            setModule(_module);
            const _allowedFields = CsvAllowedFields()[_module?.value];
            setAllowedFields(_allowedFields);
          }}
          value={module}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Select Module"
          options={CSVMODULES}
        />
      </div>
    );
  };

  if (!is_admin) return <NotAuthorizedInternal />;
  return (
    <div>
      <ModuleSelector />
      {module && allowedFields && (
        <>
          <p style={{ color: "#fff" }}>
            CSV should have following fields as Header/First row.{" "}
            <small> (Please follow same text case)</small>
          </p>
          <div className="bg-light mt-2">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {allowedFields.map((af, i) => (
                    <td scope="col" key={i}>
                      {af}
                    </td>
                  ))}
                </tr>
              </thead>
            </table>
          </div>

          <CSVReader
            onUploadAccepted={async (results) => {
              let { data } = results;
              data = data.filter((d) => d.every((dd) => dd !== ""));
              const result = [];
              const headers = data[0];
              for (let i = 1; i < data.length; i++) {
                let obj = {};
                let currentline = data[i];
                for (let j = 0; j < headers.length; j++) {
                  obj[headers[j]] = currentline[j];
                }
                result.push(obj);
              }
              setDataToImport(result);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }) => (
              <>
                <div className="csv-import-inner">
                  <div className="upload-sheet">
                    <label> Upload CSV</label>
                    <button
                      type="button"
                      className="border-0"
                      {...getRootProps()}
                    >
                      <VscCloudUpload />
                    </button>
                    <div>{acceptedFile && acceptedFile.name}</div>
                    {/* <button {...getRemoveFileProps()}>Remove</button> */}
                  </div>
                </div>
                <ProgressBar />
              </>
            )}
          </CSVReader>

          <div className="mt-2">
            {dataToImport.map((data, i) => {
              return (
                <span
                  className="badge bg-light text-dark p-2 d-inline-flex align-items-center me-2 bg-light mb-1"
                  key={i}
                >
                  {data[`${allowedFields[0]}`]}
                  <span
                    className="d-inline-flex ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={(_) => {
                      const newData = [...dataToImport].filter(
                        (item) =>
                          item[allowedFields[0]] !== data[allowedFields[0]]
                      );
                      setDataToImport(newData);
                    }}
                  >
                    <VscChromeClose />{" "}
                  </span>{" "}
                </span>
              );
            })}
            <div className="d-flex align-items-right mt-2 mb-2">
              <button
                className="ms-auto me-0 d-inline-block btn bg-dark text-white"
                onClick={async (event) => {
                  if (module.value === "goals") {
                    const goalsPayload = [...dataToImport].map((o) =>
                      allowedFields.reduce((acc, curr) => {
                        acc[curr] = o[curr] ? o[curr] : "";
                        return acc;
                      }, {})
                    );
                    try {
                      const _response = await createGoals({
                        variables: {
                          goalsPayload,
                        },
                      });
                      if (_response?.data) {
                        toast.success("Data Import Successful.");
                        setModule(null);
                        setDataToImport([]);
                        setAllowedFields(null);
                      }
                    } catch (error) {
                      toast.error(error?.message);
                    }
                  }
                  if (module.value === "page_elements") {
                    const payload = [...dataToImport].map((o) =>
                      allowedFields.reduce((acc, curr) => {
                        acc[curr] = o[curr] ? o[curr] : "";
                        return acc;
                      }, {})
                    );
                    try {
                      const _response = await createPageElements({
                        variables: {
                          payload: payload,
                        },
                      });
                      if (_response?.data) {
                        toast.success("Data Import Successful.");
                        setModule(null);
                        setDataToImport([]);
                        setAllowedFields(null);
                      }
                    } catch (error) {
                      toast.error(error?.message);
                    }
                  }
                  if (module.value === "psychological_triggers") {
                    const triggersPayload = [...dataToImport].map((o) =>
                      allowedFields.reduce((acc, curr) => {
                        acc[curr] = o[curr] ? o[curr] : "";
                        return acc;
                      }, {})
                    );
                    try {
                      const _response = await createTriggers({
                        variables: {
                          triggersPayload: triggersPayload,
                        },
                      });
                      if (_response?.data) {
                        toast.success("Data Import Successful.");
                        setModule(null);
                        setDataToImport([]);
                        setAllowedFields(null);
                      }
                    } catch (error) {
                      toast.error(error?.message);
                    }
                  }
                  if (module.value === "stages_in_customer_journey") {
                    const payload = [...dataToImport].map((o) =>
                      allowedFields.reduce((acc, curr) => {
                        acc[curr] = o[curr] ? o[curr] : "";
                        return acc;
                      }, {})
                    );
                    try {
                      const _response = await createCustomerJourneys({
                        variables: {
                          payload,
                        },
                      });
                      if (_response?.data) {
                        toast.success("Data Import Successful.");
                        setModule(null);
                        setDataToImport([]);
                        setAllowedFields(null);
                      }
                    } catch (error) {
                      toast.error(error?.message);
                    }
                  }
                  if (module.value === "hypothesis") {
                    const payload = [...dataToImport].map((o) =>
                      allowedFields.reduce((acc, curr) => {
                        acc[curr] = o[curr] ? o[curr] : "";
                        return acc;
                      }, {})
                    );
                    const chunkSize = 100;
                    for (let i = 0; i < payload.length; i += chunkSize) {
                      const chunk = payload.slice(i, i + chunkSize);
                      try {
                        await createHypothesis({
                          variables: {
                            hypothesisPayloads: chunk,
                          },
                        });
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    }
                    toast.success("Data Import Successful.");
                    setModule(null);
                    setDataToImport([]);
                    setAllowedFields(null);
                  }
                }}
              >
                Upload
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
