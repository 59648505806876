import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { GETCLIENTSLINKSANDSHEETS } from "../../../Graphql/Queries";
import { ClientLink } from "../../Shared/Icons";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import { NotAuthorized } from "../../Shared/NotAuthorized";

export default function LinksAndDocuments() {
  const [myProfile, is_client, is_admin] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const clientDropDownRef = useRef(null);
  const [documents, setDocuments] = useState(null);
  const [getclientlinksandsheets] = useLazyQuery(GETCLIENTSLINKSANDSHEETS);
  const getDocuments = useCallback(async () => {
    if (client) {
      const {
        data: { getClientLinks },
        loading,
        error,
      } = await getclientlinksandsheets({
        variables: {
          page: null,
          size: null,
          client: client,
          includePrivateDocs: false,
        },
      });
      setDocuments({
        loading,
        error,
        links: getClientLinks?.links,
      });
    }
  }, [client]);

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.client = JSON.parse(client?.value)?._id;
            setSearchParams(_params);
          }}
          isDisabled={!options ? true : false}
          value={options?.find((opt) => JSON.parse(opt?.value)?._id === client)}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Select Store"
          options={options}
        />
      </div>
    );
  };

  const returnClientOptions = (clients) => {
    return clients?.map((c) => {
      return {
        label: c?.name,
        value: JSON.stringify({
          _id: c?._id,
          gaproperties: c?.gaproperties,
          currency: c?.currency,
          logo: c?.logo,
        }),
      };
    });
  };
  const setLimitedClients = (clients) => {
    setClientSelectorOptions(returnClientOptions(clients));
    if (returnClientOptions(clients)?.length === 1) {
      if (clientDropDownRef.current) {
        clientDropDownRef.current.classList.add("hideclientselector");
      }
      const _params = Object.fromEntries([...searchParams]);
      _params.client = JSON.parse(returnClientOptions(clients)[0]?.value)?._id;
      const properties = JSON.parse(returnClientOptions(clients)[0]?.value);
      if (properties?.gaproperties?.length === 1) {
        _params.viewId = properties?.gaproperties[0]?.viewId;
      }
      setSearchParams(_params);
    }
  };

  useEffect(() => {
    if (is_client) {
      setLimitedClients(myProfile?.client);
    }
  }, [is_client, myProfile]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  if (!is_client) return <NotAuthorized />;
  if (documents?.loading) return <LoaderLogo />;
  return (
    <>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <h1 className="section-heading-title">Your Links</h1>
          </div>
        </div>
      </section>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="client-data-wrapper">
            <div className="client-details full" ref={clientDropDownRef}>
              <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clientSelectorOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="list-client-documents">
        {documents?.links?.length === 0 ? (
          <h3 style={{ color: "#fff" }}> No Documents Found </h3>
        ) : (
          <ul>
            {documents?.links?.map(({ title, link, description }, i) => (
              <li key={i}>
                <div className="links-header">
                  <span className="title">{title} </span>
                  <a href={link} target="_blank">
                    <ClientLink />
                    <span> Link</span>
                  </a>
                </div>
                <span className="description">{description}</span>
              </li>
            ))}
          </ul>
        )}
      </section>
    </>
  );
}
