import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { CREATESNIPPET, UPDATESNIPPET } from "../../../Graphql/Mutations";
import { SNIPPETS, MYTESTS, FORMPREFILLDATAV2 } from "../../../Graphql/Queries";
import { returnClientOptions, RowHeading } from "../../../Libs/Utilities";
import MyLoader from "../../MiroComponents/PreLoader";
import { CrossIcon, PencilEditIcon, SearchIcon } from "../../Shared/Icons";
import { Pagination } from "../../Shared/Paginations";
import { SnippetPreview } from "../../Shared/SnippetPreview";
import { debounce, filter } from "lodash";
import { Scrubber } from "../../Shared/Scrubber";
import { FiltersComponent } from "../../Shared/FiltersComponent";

export function Snippets() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [action, setAction] = useState({});
  const [scrubber, showScrubber] = useState(null);
  const [snippetsData, setSnippetsData] = useState({});
  const [page, setPage] = useState(1);
  const [client, setClient] = useState(null);
  const [testSelectorOptions, setTestSelectorOptions] = useState(null);
  const [filters, setFilters] = useState([]);
  const [filter, showFilter] = useState(false);
  const [test, selectedTest] = useState(null);
  const [pageSelected, setPageSelected] = useState([]);
  const [tags, setTags] = useState([]);
  const [size] = useState(20);
  const [previewSnippets, setPreviewSnippets] = useState(null);
  const [getsnippets] = useLazyQuery(SNIPPETS);
  const [mytestslist] = useLazyQuery(MYTESTS);
  const [createsnippet] = useMutation(CREATESNIPPET);
  const [updatesnippet] = useMutation(UPDATESNIPPET);

  const { data, loading } = useQuery(FORMPREFILLDATAV2, {
    filterValues: {
      pageelements: true,
      tags: true,
    },
  });
  const request = debounce((key, value) => {
    setFilters((filters) => [
      ...filters,
      {
        [key]: value,
      },
    ]);
    setPage(1);
  }, 1000);

  const debouceRequest = useCallback((key, value) => request(key, value), []);
  const onChange = (key, value) => {
    debouceRequest(key, value);
  };
  const getTestsFromClient = useCallback(async (client) => {
    try {
      setTestSelectorOptions(null);
      selectedTest(null);
      const {
        data: { mytests },
      } = await mytestslist({
        variables: {
          testFilters: [
            {
              client: client?.value,
            },
          ],
        },
      });
      setTestSelectorOptions(
        mytests?.tests?.map(({ _id, name }) => ({ label: name, value: _id }))
      );
    } catch (error) {}
  });

  const getSnippets = useCallback(async () => {
    try {
      const {
        data: { snippets },
        loading,
      } = await getsnippets({
        variables: {
          page,
          size,
          filters,
        },
      });
      setSnippetsData({
        snippets: snippets?.snippets,
        total: snippets?.total,
        loading,
      });
    } catch (error) {
      setSnippetsData({
        loading: undefined,
        error,
      });
    }
  }, [page, filters]);

  const createFilterValue = (key, value) => {
    showFilter(true);
    let item = {
      [key]: value,
    };
    const filterPayload = [...filters];
    const index = filterPayload.findIndex((it) => Object.keys(it)[0] === key);
    if (index === -1) {
      filterPayload.push(item);
    } else {
      filterPayload[index] = item;
    }
    setFilters(filterPayload);
    setPage(1);
  };

  const intiateCodePreview = (htmlcode, csscode, jscode, figmaUrl) => {
    setPreviewSnippets({
      htmlcode,
      csscode,
      jscode,
      figmaUrl,
    });
  };

  // react set state on input change with debounce

  const createUpdateSnippet = async (e) => {
    e.preventDefault();
    const { name, details, htmlcode, csscode, jscode, figmaUrl } = e.target;
    if (name.value.trim() === "") {
      toast.error("Please add required fields");
      return;
    }
    // check regex for valid figma url
    if (
      figmaUrl.value.trim() !== "" &&
      !figmaUrl.value.match(
        /^(?:https:\/\/)?(?:www\.)?figma\.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/?([^\?]+)?(.*))?$/
      )
    ) {
      toast.error("Please add valid figma url");
      return;
    }
    const snippetPayload = {
      name: name?.value,
      pageelement: pageSelected?.map(({ value }) => value),
      tags: tags?.map(({ value }) => value),
      jscode: jscode?.value,
      htmlcode: htmlcode?.value,
      details: details?.value,
      csscode: csscode?.value,
      client: client?.value ?? null,
      figmaUrl: figmaUrl?.value ?? null,
      test: test?.value ?? null,
    };
    let query = null;
    try {
      if (action?.module === "add") {
        const { data } = await createsnippet({
          variables: {
            snippetPayload,
          },
        });
        query = data;
      }
      if (action?.module === "edit") {
        const { data } = await updatesnippet({
          variables: {
            id: action?.documentId,
            snippetPayload,
          },
        });
        query = data;
      }
      if (query) {
        toast.success("Snippet Updated.");
        setAction({});
        getSnippets();
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    getSnippets();
  }, [getSnippets]);
  // const teststatuses = ["Live", "Draft"];
  const TestRowComponent = ({
    _id,
    name,
    pageelement,
    tags,
    details,
    test,
    client,
    htmlcode,
    csscode,
    jscode,
    figmaUrl,
    index,
  }) => {
    // const [showTip, setShowTip] = useState(false);
    // const [showTipWinningClient, setShowTipWinningClient] = useState(false);
    // useEffect(() => {
    //   ReactTooltip.rebuild();
    // }, [name]);
    // const getPageObjects = (data) => {
    //   const _ = data
    //     ?.reduce((all, { pageelement }) => all.concat(pageelement), [])
    //     .map(({ name }) => name)
    //     .join(" , ");
    //   return _;
    // };
    // const ClientsList = ({ clients, showTip }) => {
    //   return (
    //     <div
    //       className="clientlist-tip"
    //       style={showTip ? { opacity: 1 } : { opacity: 0, zIndex: -5 }}
    //     >
    //       <ul>
    //         {clients?.map(({ name }, i) => (
    //           <li key={i}> {name} </li>
    //         ))}
    //       </ul>
    //     </div>
    //   );
    // };

    return (
      <>
        {index === 0 && (
          <RowHeading
            type="heading"
            headings={[
              {
                name: "Name",
                classes: "hypothesis-name",
              },
              {
                name: "Client / Test",
                classes: "hypothesis-details",
              },
              {
                name: "Section Details",
                classes: "hypothesis-details",
              },
              {
                name: "Code Snippets",
                classes: "tested-won",
              },

              {
                name: "Action",
                classes: "action",
              },
            ]}
          />
        )}
        <div className="test-details-table-data snippets-details">
          <div className="hypothesis-name text-break">
            <span>{name}</span>
          </div>
          <div className="hypothesis-details">
            <ul>
              <li
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (!client?._id) return;
                  createFilterValue("client", [client?._id]);
                }}
              >
                <span>Client:</span> {client?.name ?? "N/A"}{" "}
              </li>

              {test && (
                <>
                  <li>
                    <span>Test: </span>
                    {test?.name}
                  </li>
                  <li>
                    <span> Hypothesis: </span>
                  </li>
                  <li>
                    <div
                      className="learning-list"
                      style={{
                        maxWidth: "100%",
                      }}
                    >
                      <div className="learning-list-content">
                        <ul>
                          <li>
                            <p className="learning-text">
                              Name : {test?.hypothesis?.name}
                            </p>
                          </li>
                          <li>
                            <p className="learning-text">
                              Action : {test?.hypothesis?.action}
                            </p>
                          </li>
                          <li>
                            <p className="learning-text">
                              Expectation : {test?.hypothesis?.expectation}
                            </p>
                          </li>
                          <li>
                            <p className="learning-text">
                              Result : {test?.hypothesis?.result}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span>Control VS Variant: </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        showScrubber({
                          control: test?.controlimage,
                          variation: test?.variationimage,
                        })
                      }
                      className="badge bg-primary"
                    >
                      {" "}
                      Compare{" "}
                    </span>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="hypothesis-details">
            <ul>
              <li
                onClick={(_) => {
                  createFilterValue(
                    "pageelement",
                    pageelement?.map(({ _id }) => _id)
                  );
                }}
              >
                <span>Page Element:</span>{" "}
                {!pageelement
                  ? `N/A`
                  : pageelement?.map(({ name }) => name).join(",")}{" "}
              </li>
              <li>
                <span>Tags:</span>{" "}
                {!tags ? `N/A` : tags?.map(({ name }) => name).join(",")}{" "}
              </li>
            </ul>
          </div>
          <div className="client-hypothesis-list">
            <span
              style={{ cursor: "pointer" }}
              // onClick={(_) => setShowTip(!showTip)}
              // onMouseLeave={(_) => setShowTip(false)}
            >
              <ul>
                <li>
                  <button
                    title="Double click to enable code preview mode"
                    onClick={(e) => {
                      intiateCodePreview(htmlcode, csscode, jscode, figmaUrl);
                    }}
                    // onClick={(_) => {
                    //   initiateCopyToClipBoard(htmlcode);
                    // }}
                    disabled={!htmlcode}
                  >
                    {" "}
                    HTML
                  </button>{" "}
                </li>
                <li>
                  <button
                    title="Double click to enable code preview mode"
                    onClick={(e) => {
                      intiateCodePreview(htmlcode, csscode, jscode, figmaUrl);
                    }}
                    // onClick={(_) => {
                    //   initiateCopyToClipBoard(csscode);
                    // }}
                    disabled={!csscode}
                  >
                    {" "}
                    CSS{" "}
                  </button>{" "}
                </li>
                <li>
                  <button
                    title="Double click to enable code preview mode"
                    onClick={(e) => {
                      intiateCodePreview(htmlcode, csscode, jscode, figmaUrl);
                    }}
                    disabled={!jscode}
                    // onClick={(_) => {
                    //   initiateCopyToClipBoard(jscode);
                    // }}
                  >
                    {" "}
                    JS{" "}
                  </button>{" "}
                </li>
              </ul>
            </span>
          </div>

          <div className="action">
            <div
              className="action-group"
              onClick={(_) => {
                setAction({
                  module: "edit",
                  documentId: _id,
                  data: {
                    name,
                    csscode,
                    jscode,
                    htmlcode,
                    details,
                    figmaUrl,
                  },
                });
                setPageSelected(
                  pageelement?.map(({ _id, name }) => ({
                    label: name,
                    value: _id,
                  }))
                );
                setTags(
                  tags?.map(({ _id, name }) => ({ label: name, value: _id }))
                );
                setClient(
                  client
                    ? {
                        label: client.name,
                        value: client._id,
                      }
                    : null
                );
                selectedTest(
                  test
                    ? {
                        label: test.name,
                        value: test._id,
                      }
                    : null
                );
              }}
            >
              <PencilEditIcon />
              <span>Edit</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  // const removeFilter = (name) => {
  //   setFilters((prev) => {
  //     const _ = { ...prev };
  //     delete _[name];
  //     return _;
  //   });
  // };

  if (snippetsData?.loading || loading) return <MyLoader />;
  if (snippetsData?.error) return <div>{snippetsData?.error?.message} </div>;
  //  convert object to array and loop through it
  // const filtersArray = Object.keys(filters).map((key) => ({
  //   name: key,
  //   value:
  //     key === "client"
  //       ? data?.clients?.clients?.find(({ _id }) => _id === filters[key])?.name
  //       : filters[key],
  // }));
  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Snippets</h2>
          <div>
            <button
              className="btn-as-link d-inline-flex align-items-center text-white"
              onClick={(_) => {
                if (filter) {
                  setPage(1);
                  setFilters([]);
                }
                showFilter(!filter);
              }}
            >
              {filter ? "Close Filters" : "Filters"}
            </button>
            <div>
              {!is_user && (
                <button
                  className="add"
                  onClick={(_) =>
                    setAction({
                      module: "add",
                      data: null,
                    })
                  }
                >
                  Add Snippet
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Scrubber showScrubber={showScrubber} scrubber={scrubber} />

      {action?.module && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner addtestform">
            <div
              className="card add-new-test-card"
              style={{ minHeight: "455px" }}
            >
              <button className="close-modal" onClick={(_) => setAction({})}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <h3> Add Snippet</h3>
              <div className="card-body p-0">
                <form onSubmit={(e) => createUpdateSnippet(e)}>
                  <div className="fields-wrapper">
                    <label> Snippet Title *</label>
                    <input
                      placeholder="Test name"
                      className="field-control"
                      name="name"
                      defaultValue={action?.data?.name ?? ""}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label> Description</label>
                    <textarea
                      className="field-control"
                      placeholder="Add Description"
                      defaultValue={action?.data?.details ?? ""}
                      name="details"
                    ></textarea>
                  </div>

                  <div className="fields-wrapper">
                    <label> Figma Url</label>
                    <input
                      placeholder="Figma URL"
                      className="field-control"
                      name="figmaUrl"
                      defaultValue={action?.data?.figmaUrl ?? ""}
                    />
                  </div>

                  <div className="fields-wrapper input-groups">
                    <div>
                      <label> Select Client</label>

                      <Select
                        onChange={(client) => {
                          setClient(client);
                          getTestsFromClient(client);
                          // setProperties(JSON.parse(client?.value)?.gaproperties);
                          // setSelectedProperty(null);
                        }}
                        value={client}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        placeholder={
                          module === "edit" ? `Change Client` : `Select Client`
                        }
                        options={myProfile?.client?.map((c) => {
                          // const clientobj = JSON.stringify({
                          //   _id: c?._id,
                          //   gaproperties: c?.gaproperties,
                          // });
                          return {
                            label: c?.label,
                            value: c?.value?._id,
                          };
                        })}
                      />
                    </div>
                    <div>
                      <label> Test</label>
                      <Select
                        onChange={(test) => {
                          selectedTest(test);
                        }}
                        isDisabled={!testSelectorOptions}
                        value={test}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        placeholder="Select Test (optional)"
                        options={testSelectorOptions}
                      />
                    </div>
                  </div>
                  <div className="fields-wrapper input-groups">
                    <div>
                      <label> Select page object/element *</label>
                      <Select
                        isMulti={true}
                        options={data?.formPrefillData?.pageelements?.map(
                          (pe) => {
                            return {
                              label: pe?.name,
                              value: pe?._id,
                            };
                          }
                        )}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        value={pageSelected}
                        onChange={(pageelement) => {
                          setPageSelected(pageelement);
                        }}
                      />
                    </div>
                    <div>
                      <label> Tags</label>
                      <Select
                        isMulti={true}
                        options={data?.formPrefillData?.tags?.map(
                          ({ _id, name }) => ({
                            label: name,
                            value: _id,
                          })
                        )}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        value={tags}
                        onChange={(tag) => {
                          setTags(tag);
                        }}
                      />
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <label> HTML Code</label>
                    <textarea
                      className="field-control"
                      placeholder="Add HTML code"
                      defaultValue={action?.data?.htmlcode ?? ""}
                      name="htmlcode"
                    ></textarea>
                  </div>

                  <div className="fields-wrapper">
                    <label> CSS Code</label>
                    <textarea
                      className="field-control"
                      placeholder="Add CSS code"
                      defaultValue={action?.data?.csscode ?? ""}
                      name="csscode"
                    ></textarea>
                  </div>
                  <div className="fields-wrapper">
                    <label> JS Code</label>
                    <textarea
                      className="field-control"
                      placeholder="Add JS code"
                      name="jscode"
                      defaultValue={action?.data?.jscode ?? ""}
                    ></textarea>
                  </div>

                  <div
                    className="add__new__test__form-footer"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <button className="btn next-btn">Add</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {previewSnippets && (
        <SnippetPreview
          setPreviewSnippets={setPreviewSnippets}
          previewSnippets={previewSnippets}
        />
      )}

      {filter && (
        <FiltersComponent
          serverFilters={filters}
          setServerFilters={setFilters}
          setPage={setPage}
          filterTypes={[
            "findtestbyclientid",
            "findtestbytestname",
            "findtestbypageobjecttype",
          ]}
        />
      )}

      <div className="table-body">
        {snippetsData?.snippets?.map((snippet, i) => (
          <TestRowComponent {...snippet} key={i} index={i} />
        ))}
      </div>
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={snippetsData?.total}
      />

      {/* <div className="mt-3"> */}
      {/* <DataTable
            columns={columnstags}
            data={gettags?.data?.tags}
            progressComponent={<MyLoader />}
            progressPending={getgoals?.loading}
          /> */}
      {/* </div> */}
    </div>
  );
}
