import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { GETENDEDTESTSWITHREPORTS } from "../../Graphql/Queries";
import { MonthsListShort } from "../../Libs/Utilities";
import { ArrowDown, NextIcon, PrevIcon } from "../Shared/Icons";
import { ClientReportDetailsandInsights } from "./ClientReportDetailsandInsights";
import { TestsTimelineList } from "./TestsTimelineList";

export const ClientTestsTimeline = ({ viewId, client }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedReport, setSelectedReport] = useState(null);
  const monthListRef = useRef(null);
  const [selectedMonth, setSelectedMonth] = useState({
    name: new Date().toLocaleString("en-US", { month: "short" }),
    month: new Date().getMonth(),
  });
  const [timeLineTestList, setTimeLineTestsList] = useState({
    show: false,
  });
  const [endedtestswithreports] = useLazyQuery(GETENDEDTESTSWITHREPORTS);
  const getTimelineReports = useCallback(async () => {
    if (selectedYear && selectedMonth) {
      const month = selectedMonth?.month - 1;
      var firstDay = new Date(selectedYear, month, 1);
      firstDay.setHours(0, 0, 0, 0);
      var lastDay = new Date(selectedYear, month + 1, 0);
      lastDay.setHours(23, 59, 59, 999);
      setTimeLineTestsList({
        ...timeLineTestList,
        show: true,
      });
      const { data, loading, error } = await endedtestswithreports({
        variables: {
          client,
          viewId,
          toDate: lastDay.toISOString(),
          fromDate: firstDay.toISOString(),
        },
      });
      setTimeLineTestsList({
        loading,
        testsList: data?.endedTestsWithReports?.tests,
        error,
      });
    }
  }, [selectedYear, selectedMonth, client, viewId, endedtestswithreports]);

  const manageYearSelection = (action, currentValue) => {
    if (action === "increment") {
      if (new Date().getFullYear() !== currentValue) {
        setSelectedYear((year) => year + 1);
      }
    } else {
      setSelectedYear((year) => year - 1);
    }
    setSelectedMonth(null);
    setSelectedReport(null);
  };

  useEffect(() => {
    getTimelineReports();
  }, [client, viewId, selectedMonth]);

  return (
    <div id="test-timeline" className="tab-data">
      <div className="year-select">
        <div
          className="prev-icon"
          onClick={(e) => manageYearSelection("decrement", selectedYear)}
        >
          <PrevIcon />
        </div>
        <div>
          <span className="current-year-selected">{selectedYear}</span>
        </div>
        <div
          className="next-icon"
          onClick={(e) => manageYearSelection("increment", selectedYear)}
        >
          <NextIcon />
        </div>
      </div>
      <div className="month-select">
        <div className="month-dropdown-mobile">
          <button
            onClick={(e) => {
              const _tabcontent = monthListRef.current;
              if (_tabcontent.classList.contains("open-month")) {
                _tabcontent.classList.remove("open-month");
              } else {
                _tabcontent.classList.add("open-month");
              }
            }}
          >
            <span>{selectedMonth ? selectedMonth?.name : `Month`}</span>
            <span>
              {" "}
              <ArrowDown />{" "}
            </span>
          </button>
        </div>

        <div className="month-list" ref={monthListRef}>
          {MonthsListShort()?.map((month) => (
            <span
              onClick={(e) => {
                const _tabcontent = monthListRef.current;
                setSelectedMonth(month);
                setSelectedReport(null);
                if (_tabcontent.classList.contains("open-month")) {
                  _tabcontent.classList.remove("open-month");
                }
              }}
              className={`month ${
                selectedMonth?.month === month?.month ? `month-selected` : ``
              }`}
              key={month?.month}
            >
              {month?.name}
            </span>
          ))}
        </div>
      </div>
      {timeLineTestList?.testsList?.length === 0 ? (
        <div className="not-found-report">
          <p> No Reports found for selected month.</p>
        </div>
      ) : (
        <div className="test-timeline-details">
          <div className="test-select">
            <div className="test-timeline-card-list">
              {timeLineTestList?.testsList?.map((test, i) => (
                <TestsTimelineList
                  key={i}
                  test={test}
                  report={test?.report}
                  selectedReport={selectedReport}
                  setSelectedReport={setSelectedReport}
                />
              ))}
            </div>
          </div>
          {selectedReport && (
            <ClientReportDetailsandInsights selectedReport={selectedReport} />
          )}
        </div>
      )}
    </div>
  );
};
