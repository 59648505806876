import { useCallback, useEffect, useState } from "react";
import { BackIcon, CrossIcon, LiveReportsIcon } from "../Shared/Icons";
import { differenceInCalendarDays, format, subDays } from "date-fns";
import Select from "react-select";
import { LiveTestsList } from "../NewComponents/LiveTestsList";
import { FilterAnalyticsDimensionsAndMetrics } from "../NewComponents/FilterAnalyticsDimensionsAndMetrics";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATEREPORT,
  REFETCHALLTESTREPORTS,
  REFETCHCLIENTREPORTBIGQUERY,
  REFETCHCLIENTREPORTGA4,
  REPORTBIGQUERY,
  REPORTGA4,
} from "../../Graphql/Mutations";
import { toast } from "react-toastify";
import { ImpressionsTableGa4 } from "../NewComponents/ImpressionTableGa4";
import { CalculatedKpisGa4 } from "../NewComponents/CalculatedKpisGa4";
import { GraphsGa4 } from "../NewComponents/ClientGraphs/GraphsGa4";
import { GETCLIENTSREPORTSHISTORY } from "../../Graphql/Queries";
import ReactTooltip from "react-tooltip";
import MyLoader from "../MiroComponents/PreLoader";
import { ReportHistories } from "../NewComponents/ReportHistories";
import { BigQueryDataSetsComponent } from "../Shared/BigQueryDataSetsComponent";
import { CreateReportGa4 } from "./Admin/AdminComponents/CreateReportGa4";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { Button } from "../MiroComponents/Button/Button";
import { SmartInput } from "../MiroComponents/SmartInput/SmartInput";
import MyRoutes from "../../Routes";
import { GenerateContentReport } from "../MiroComponents/GenerateContentReport";
import { getFormatedContentForHistories } from "../../Libs/getFormatedContentForHistories";
import { ModalWithTableData } from "../Shared/ModalWithTableData";
import ReportTable from "../NewComponents/ReportTable/ReportTable";
import VariantPerformance from "../NewComponents/VariantPerformance/VariantPerformance";
import { CURRENCYSYMBOLSMAP } from "../../Libs/Utilities";
import ReportGraphs from "../NewComponents/ReportGraphs/ReportGraphs";
import SequentialResults from "../MiroComponents/SequentialResults/SequentialResults";

export const LiveTestsReportingGA4 = ({
  platform = "BQ",
  selectedClient,
  setSearchParams,
  viewId,
  _params,
}) => {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  // const [client, setClient] = useState(null);
  // const [selectedProperty, setSelectedProperty] = useState(null);
  const [evaluateWithoutData, setEvaluateWithoutData] = useState(false);
  const [selectedDataSet, setSelectedDataSet] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [includeProducts, setIncludeProducts] = useState(false);
  const [interaDayTable, setInteraDayTable] = useState(false);
  const [useRegexForUserId, setUseRegexForUserId] = useState(false);
  const [file, setFile] = useState(null);
  const [getga4report] = useMutation(REPORTGA4);
  const [getbigqueryreport] = useMutation(REPORTBIGQUERY);
  const [createreport] = useMutation(CREATEREPORT);
  const [refetchga4report] = useMutation(REFETCHCLIENTREPORTGA4);
  const [refetchbigqueryreport] = useMutation(REFETCHCLIENTREPORTBIGQUERY);
  const [getreportshistory] = useLazyQuery(GETCLIENTSREPORTSHISTORY);
  const [currentReportType, setCurrentReportType] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dimensionFilterExpression, setDimensionFilterExpression] = useState(
    []
  );
  const [content, setContent] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [reportsHistory, setReportsHistory] = useState(null);
  const [metricFilterExpression, setMetricFilterExpression] = useState([]);
  const [reportDetails, setReportDetails] = useState(null);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment(subDays(new Date(), 7)).startOf("day"),
      endDate: moment(new Date()).endOf("day"),
      key: "selection",
    },
  ]);
  const [refetchAllTestReports] = useMutation(REFETCHALLTESTREPORTS);

  const fetchData = async () => {
    try {
      const { data } = await refetchAllTestReports({
        variables: {
          client: selectedClient?.value?._id,
          viewId: viewId,
        },
      });
      return data?.getAllActiveTests;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  const selectedProperty = selectedClient
    ? selectedClient?.value?.gaproperties?.find((p) => p?.viewId === viewId)
    : null;

  const getReportsHistoryQuery = useCallback(async () => {
    reportsHistory?.data?.length > 0 && setReportsHistory({ data: "" });
    if (viewId && selectedTest && selectedClient) {
      const test = selectedTest?._id;
      try {
        const _query = {
          client: selectedClient?.value?._id,
          test,
          viewId,
        };
        const { data, loading, error } = await getreportshistory({
          fetchPolicy: "network-only",
          variables: _query,
        });
        setReportsHistory({
          data: data?.getCachedAnalyticsData,
          loading,
          error,
        });
        ReactTooltip.rebuild();
      } catch (error) {
        toast.error(error?.message);
      }
    }
  }, [viewId, selectedTest, selectedClient]);

  useEffect(() => {
    getReportsHistoryQuery();
  }, [getReportsHistoryQuery]);
  useEffect(() => {
    setSelectedTest(null);
    setReportsHistory(null);
  }, [selectedClient]);
  const createDimensionFilters = useCallback(
    (value, key, filterType, matchType) => {
      console.log(value, key, filterType, matchType);
      const filterObject = {
        filter: {
          fieldName: key,
        },
      };
      if (filterType === "string") {
        filterObject.filter.stringFilter = {
          matchType: matchType.toUpperCase(),
          value: value?.value,
        };
      }
      const newArr = [...dimensionFilterExpression];
      const fieldName = newArr?.findIndex((f) => f?.filter?.fieldName === key);
      if (fieldName > -1) {
        newArr[fieldName] = filterObject;
      } else {
        newArr.push(filterObject);
      }
      setDimensionFilterExpression(newArr);
    },
    [dimensionFilterExpression]
  );

  const removeSegment = useCallback((fieldName) => {
    const newArr = [...dimensionFilterExpression];
    const fieldNameIndex = newArr?.findIndex(
      (f) => f?.filter?.fieldName === fieldName
    );
    if (fieldNameIndex > -1) {
      newArr.splice(fieldNameIndex, 1);
    }
    setDimensionFilterExpression(newArr);
  });
  const getreport = async () => {
    if (platform === "GA4") {
      try {
        setLoader(true);
        const {
          data: { gaReport },
          loading,
        } = await getga4report({
          variables: {
            options: {
              dateRanges: selectionRange?.map((rest) => ({
                startDate: format(rest?.startDate, "yyyy-MM-dd"),
                endDate: format(rest?.endDate, "yyyy-MM-dd"),
              })),
              view_id: selectedProperty?.viewId,
              codebaseTestId: selectedTest?._id,
              filters: {
                dimensionFilters: dimensionFilterExpression,
                metricFilters: metricFilterExpression,
              },
            },
          },
        });
        setCurrentReportType({
          id: gaReport?.historyId,
          type: "live",
        });
        setReportDetails({
          loading,
          ...gaReport,
        });
        getReportsHistoryQuery();
        setLoader(false);
        setEvaluateWithoutData(true);
      } catch (error) {
        toast.error(error?.message);
        setLoader(false);
      }
    } else if (platform === "BQ") {
      try {
        setLoader(true);
        // const formData = new FormData();

        // formData.append("client", client);
        // formData.append("dateRange", selectionRange[0]?.startDate);
        // formData.append("dateRange", selectionRange[0]?.endDate);
        // formData.append("codebaseTestId", selectedTest?._id);
        // formData.append(
        //   "filters",
        //   JSON.stringify({ dimensionFilterExpression })
        // );
        // formData.append("filters", JSON.stringify({ metricFilterExpression }));
        // formData.append("filters", JSON.stringify({ includeProducts }));
        // formData.append("filters", JSON.stringify({ interaDayTable }));
        // formData.append("dataSetId", viewId);
        // formData.append("file", file);

        const {
          data: { getBigQueryData },
          loading,
        } = await getbigqueryreport({
          variables: {
            client: selectedClient?.value?._id,
            dateRange: {
              startDate: format(selectionRange[0]?.startDate, "yyyyMMdd"),
              endDate: format(selectionRange[0]?.endDate, "yyyyMMdd"),
            },
            codebaseTestId: selectedTest?._id,
            filters: {
              dimensionFilters: dimensionFilterExpression,
              metricFilters: metricFilterExpression,
              includeProducts,
              interaDayTable,
              useRegexForUserId,
              selectedCurrency: selectedCurrency?.value ?? null,
            },
            dataSetId: viewId,
            file,
          },
        });

        setReportDetails({
          loading,
          ...getBigQueryData,
        });
        setCurrentReportType({
          id: getBigQueryData?.historyId ?? null,
          type: "live",
        });
        getReportsHistoryQuery();
        setLoader(false);
      } catch (error) {
        toast.error(error?.message);
        if (error.message === "No data found") {
          setEvaluateWithoutData(true);
        }
        setLoader(false);
      }
    } else {
      toast.error("Platform not found");
    }
  };
  const refetchReportHistory = async (id) => {
    setLoader(true);

    if (platform === "GA4") {
      const dateRanges = [
        {
          startDate: new Date(selectedTest?.livedate),
          endDate: new Date(),
        },
      ];
      setSelectionRange(dateRanges);
      try {
        const { data, loading } = await refetchga4report({
          fetchPolicy: "network-only",
          variables: {
            id,
            dateRanges: dateRanges?.map((rest) => ({
              startDate: format(rest?.startDate, "yyyy-MM-dd"),
              endDate: format(rest?.endDate, "yyyy-MM-dd"),
            })),
          },
        });
        setLoader(loading);
        setReportDetails({
          loading,
          ...data?.refetchTemporaryReportGa4,
        });
        setCurrentReportType({
          id,
          type: "live",
        });
        getReportsHistoryQuery();
      } catch (error) {
        setLoader(false);
        toast.error(error?.message);
      }
    } else if (platform === "BQ") {
      try {
        const { data, loading } = await refetchbigqueryreport({
          fetchPolicy: "network-only",
          variables: {
            id,
            dateRange: {
              startDate: format(new Date(selectedTest?.livedate), "yyyyMMdd"),
              endDate: format(new Date(), "yyyyMMdd"),
            },
            otherFilters: {
              includeProducts,
              interaDayTable,
              useRegexForUserId,
              selectedCurrency: selectedCurrency?.value ?? null,
            },
          },
        });
        setLoader(loading);
        setSelectionRange([
          {
            startDate: new Date(selectedTest?.livedate),
            endDate: new Date(),
          },
        ]);

        setReportDetails({
          loading,
          ...data?.refetchTemporaryReportBigQuery,
        });
        setCurrentReportType({
          id,
          type: "live",
        });
        getReportsHistoryQuery();
      } catch (error) {
        setLoader(false);
        toast.error(error?.message);
      }
    } else {
      toast.error("Platform not found");
      return;
    }
  };

  const getGenerateContentReport = async () => {
    if (platform === "BQ") {
      setContent({
        loading: true,
      });

      try {
        const AllData = await fetchData();
        const validData = AllData.filter((data) => data !== null);
        // setRefetchContentReportData(validData);
        const generatedContent = await getFormatedContentForHistories({
          historyData: validData,
        });
        setContent({
          content: generatedContent,
        });
      } catch (error) {
        console.log("error", error);
        toast.error("Failed to fetch content report data.");
      } finally {
        getReportsHistoryQuery();
      }
    } else {
      toast.error("Platform not found");
    }
  };

  return (
    <div>
      {evaluateWithoutData && (
        <div className="form-wrapper" id="add-new-test-form">
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              const reportLink = e.target.reportLink.value;
              const reportPayload = {
                conclusion:
                  "no evaluation was done as there was no data in store",
                learnings: [],
                segmentName: "no data",
                variant: null,
                client: selectedClient?.value?._id,
                test: selectedTest?._id,
                from: moment(selectionRange[0]?.startDate)
                  .startOf("day")
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                viewId: platform === "BQ" ? selectedDataSet?.value : viewId,
                to: moment(selectionRange[0]?.endDate)
                  .endOf("day")
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                name: `${selectedTest?.name}^report`,
                createdBy: myProfile?._id,
                reportDetails: null,
              };
              if (reportLink?.trim() !== "") {
                reportPayload.reportLink = reportLink;
              }
              try {
                const { data } = await createreport({
                  variables: {
                    reportPayload,
                  },
                });
                setEvaluateWithoutData(false);
                window.open(
                  `${MyRoutes.createmanualreport}/${data?.createReport?._id}`,
                  "_blank"
                );
              } catch (error) {
                toast.error(error?.message);
              }
            }}
            className="form-wrapper-inner addtestform"
          >
            <div className="card add-new-test-card" id="formcard">
              <button
                type="button"
                className="close-modal"
                onClick={() => {
                  setEvaluateWithoutData(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <h3>Manual Evaluation</h3>
              <div className="card-body ps-0 pe-0">
                <p className="text-white default-margin-bottom">
                  Could not fetch data from Big Query. Make sure test name is
                  correct and other configuraions are done correctly.
                </p>
                <p className="text-white default-margin-bottom">
                  You can still evaluate the test manually and create a report.
                </p>

                <div className="fields-wrapper default-border default-padding">
                  <div>
                    <SmartInput
                      id="reportLink"
                      name="reportLink"
                      placeholder="Add external report link"
                      type="link"
                      label="Link to External Sheet (Optional)"
                    />
                  </div>
                </div>
              </div>
              <div className="add__new__test__form-footer">
                <Button
                  type="button"
                  className="smart-button small"
                  text={`Cancel`}
                  icon={<BackIcon />}
                  onClick={(_) => setEvaluateWithoutData(false)}
                />

                <Button
                  type="submit"
                  className="smart-button medium active"
                  text="Evaluate Manually"
                />
              </div>
            </div>
          </form>
        </div>
      )}
      <section className="live-reports">
        <div className="live-reports-inner">
          <h1 className="">Live Reports </h1>

          <div className="client-data-wrapper">
            <div className="client-details">
              {/* {platform !== "BQ" && selectedClient && (
                <div className="properties-dropdown">
                  <h1 className="section-heading">Properties:</h1>
                  <PropertySelector
                    options={
                      selectedClient
                        ? selectedClient?.value?.gaproperties
                        : null
                    }
                  />
                </div>
              )} */}
              {platform === "BQ" && selectedClient && (
                <BigQueryDataSetsComponent
                  projectId={
                    selectedClient ? selectedClient?.value?.bqClientId : null
                  }
                  client={selectedClient?.value ?? null}
                  viewId={viewId}
                  _params={_params}
                  setSearchParams={setSearchParams}
                />
              )}
            </div>

            <div className="experiment-details">
              {selectedClient && viewId && (
                <>
                  <h2 className="">Experiments:</h2>
                  <LiveTestsList
                    client={selectedClient ? selectedClient?.value?._id : null}
                    viewId={viewId}
                    platform={platform}
                    selectedTest={selectedTest}
                    setSelectedTest={setSelectedTest}
                    selectionRange={selectionRange}
                    setSelectionRange={setSelectionRange}
                    setReportDetails={setReportDetails}
                    setCurrentReportType={setCurrentReportType}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {selectedTest && (
        <div className="flex generate-update-report-margin-top">
          <Button
            className="smart-button small"
            type="button"
            onClick={(e) => {
              getGenerateContentReport();
            }}
          >
            {" "}
            Generate Update Report
          </Button>
        </div>
      )}
      <FilterAnalyticsDimensionsAndMetrics
        client={selectedClient ? selectedClient?.value?._id : null}
        viewId={viewId}
        selectedClient={selectedClient ? selectedClient?.value : null}
        platform={platform}
        selectedTest={selectedTest}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
        getreport={getreport}
        callBackWhenClose={callBackWhenClose}
        dimensionFilterExpression={dimensionFilterExpression}
        setDimensionFilterExpression={setDimensionFilterExpression}
        metricFilterExpression={metricFilterExpression}
        setMetricFilterExpression={setMetricFilterExpression}
        createDimensionFilters={createDimensionFilters}
        removeSegment={removeSegment}
        includeProducts={includeProducts}
        setIncludeProducts={setIncludeProducts}
        interaDayTable={interaDayTable}
        setInteraDayTable={setInteraDayTable}
        useRegexForUserId={useRegexForUserId}
        setUseRegexForUserId={setUseRegexForUserId}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
        file={file}
        setFile={setFile}
      />

      <div>
        <div className="selected-segment">
          <div className="selected-data-wrapper">
            {dimensionFilterExpression?.map((segment, i) => (
              <div className="selected-data" key={i}>
                <span
                  className="remove-data-icon"
                  onClick={(e) => removeSegment(segment.filter?.fieldName)}
                >
                  <CrossIcon />
                </span>
                <span className="selected-data-name">
                  {segment?.filter?.fieldName}
                  {segment?.filter?.stringFilter?.matchType !== "EXACT"
                    ? segment?.filter?.stringFilter?.matchType
                    : ":"}
                  {segment?.filter?.stringFilter.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {content && (
        <GenerateContentReport content={content} setContent={setContent} />
      )}
      {selectedClient &&
        viewId &&
        selectedTest &&
        reportsHistory?.data?.length > 0 && (
          <ReportHistories
            reportsHistory={reportsHistory}
            getReportsHistoryQuery={getReportsHistoryQuery}
            setReportDetails={setReportDetails}
            currentReportType={currentReportType}
            setCurrentReportType={setCurrentReportType}
            refetchReportHistory={refetchReportHistory}
            platform={platform}
            setIncludeProducts={setIncludeProducts}
            setFile={setFile}
            // getGenerateContentReport={getGenerateContentReport}
            // refetchContentReportData={refetchContentReportData}
            // contentLoader={contentLoader}
          />
        )}
      {typeof reportsHistory?.data === "string" && <MyLoader />}
      {loader ? (
        <>
          <MyLoader />
        </>
      ) : (
        <>
          {selectedClient && viewId && selectedTest && reportDetails && (
            <>
              {reportDetails?.COGSDATA && (
                <>
                  <div className="impression-table-heading">
                    <h4>Cost Of Goods Sale based on RPU</h4>{" "}
                  </div>
                  <div className="seq-values">
                    <p>
                      Total Revenue:{" "}
                      <span>{reportDetails?.COGSDATA?.totalRevenue}</span>{" "}
                    </p>
                    <p>
                      Total Profit:{" "}
                      <span>{reportDetails?.COGSDATA?.totalProfit}</span>
                    </p>
                  </div>
                </>
              )}
              <div className="report-table-wrapper border-bottom section-pd-bottom big-margin-top">
                <ReportTable
                  total={[reportDetails?.totalOfResults]}
                  controlVariantData={reportDetails?.result}
                />
              </div>

              <VariantPerformance
                calculatedResults={reportDetails?.calculatedResults}
                currency={
                  CURRENCYSYMBOLSMAP[selectedClient?.value?.currency ?? ""]
                }
              />

              <section className="selected-test-content live-reports-content">
                {/* <ImpressionsTableGa4
                  type="total"
                  {...reportDetails?.totalOfResults}
                /> */}
                {/* {reportDetails?.result?.map((r, i) => (
                  <ImpressionsTableGa4 key={i} {...r} />
                ))} */}
                <div className="experiment-data-inner">
                  {/* {reportDetails?.calculatedResults?.map((data, i) => (
                    <CalculatedKpisGa4
                      key={i}
                      {...data}
                      // currency={clientCurrency(client?.value)}
                    />
                  ))} */}
                  <div className="tests-canbe-evaluated">
                    <span>
                      {reportDetails?.resultsEvaluation?.label}
                      <strong className="d-inline-flex ms-1 me-1">
                        &nbsp;{reportDetails?.resultsEvaluation?.value}
                      </strong>
                    </span>
                  </div>
                </div>
                <ReportGraphs
                  timeSeriesData={reportDetails?.timeSeriesData}
                  probabilityOfWinner={reportDetails?.probabilityOfWinner}
                />
                {/* <GraphsGa4
                  timeSeriesData={reportDetails?.timeSeriesData}
                  calculatedResults={reportDetails?.calculatedResults}
                /> */}

                <SequentialResults
                  results={reportDetails?.sequentialABTestResult}
                />
              </section>
            </>
          )}
        </>
      )}
      {!is_user && !is_client && (
        <div>
          <CreateReportGa4
            reportDetails={reportDetails}
            currentReportType={currentReportType}
            client={selectedClient ? selectedClient?.value?._id : null}
            viewId={viewId}
            selectedTest={selectedTest}
            is_user={is_user}
            myProfile={myProfile}
            metricFilterExpression={metricFilterExpression}
            dimensionFilterExpression={dimensionFilterExpression}
            selectionRange={selectionRange}
          />
        </div>
      )}
    </div>
  );
};
