import { useLazyQuery, useQuery } from "@apollo/client";
import { GETCLIENTSNAMEANDIDS, MYPROFILE } from "../Graphql/Queries";
import { useEffect, useState } from "react";

const returnClientOptions = (clients) => {
  return clients?.map((c) => {
    return {
      label: c?.name,
      value: {
        _id: c?._id,
        gaproperties: c?.gaproperties,
        currency: c?.currency,
        logo: c?.logo,
        bqClientId: c?.bqClientId,
        defaultDataSet: c?.defaultDataSet,
      },
    };
  });
};

// useMyProfile for getting profile
export function useMyProfile() {
  const [getUser] = useLazyQuery(MYPROFILE);

  const [response, setResponse] = useState({
    myProfile: null,
    loading: true,
    error: null,
    entryRoute: "",
  });

  useEffect(() => {
    (async () => {
      const { data, loading, error } = await getUser();
      const roles = data?.myProfile?.role;
      const isadmin = roles?.some((rl) => rl?.name === "ADMIN");
      const isoptimizer = roles?.some((rl) => rl?.name === "OPTIMIZER");
      const isclient = roles?.some((rl) => rl?.name === "CLIENT");
      const isuser = roles?.some((rl) => rl?.name === "USER");
      const iseditor = roles?.some((rl) => rl?.name === "EDITOR");
      const client = returnClientOptions(data?.myProfile?.client);
      let entryRoute = "";
      if (isadmin || isoptimizer || isuser) {
        entryRoute = "/dashboard";
      } else if (isclient) {
        entryRoute = "/client-dashboard";
      } else if (iseditor) {
        entryRoute = "/editor-dashboard";
      } else {
        entryRoute = "/not-found";
      }
      setResponse({
        myProfile: {
          ...data?.myProfile,
          client,
        },
        loading,
        error,
        entryRoute,
      });
    })();
  }, [getUser]);

  return response;
}
