import { useEffect, useState } from "react";
import Input from "../../MiroComponents/Input/Input";
import SelectComponent from "../../MiroComponents/SelectComponent/SelectComponent";
import { Button } from "../../MiroComponents/Button/Button";
import Checkbox from "../../MiroComponents/Checkbox/Checkbox";
import { DeleteUrlIcon } from "../../Shared/Icons";
import _, { debounce } from "lodash";
import useStore from "../../../Store";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { CREATEAUDIENCE, UPDATEAUDIENCE } from "../../../Graphql/Mutations";

export default function AudienceTargetingForm({
  editObject,
  refreshPage,
  id,
  showForm,
}) {
  const selectedClient = useStore((state) => state.selectedClient);
  const [createAudience] = useMutation(CREATEAUDIENCE);
  const [updateAudience] = useMutation(UPDATEAUDIENCE);

  const [otherData, setOtherData] = useState({
    name: "",
    rules_js: "return true;",
    loading: true,
  });
  useEffect(() => {
    if (editObject) {
      setOtherData({
        name: editObject?.name,
        rules_js: editObject?.rules_js,
        loading: false,
      });
    }
    if (showForm) {
      setOtherData({
        name: "",
        rules_js: "return true;",
        loading: false,
      });
    }
  }, [editObject, id, showForm]);

  const updateState = debounce((key, value) => {
    setOtherData({ ...otherData, [key]: value });
  }, 1000);

  if (otherData?.loading) return null;
  return (
    <form
      className="default-margin-bottom default-padding default-border bg-1"
      onSubmit={async (e) => {
        e.preventDefault();
        if (!otherData?.name || !otherData?.rules_js) {
          return toast.error("Please fill all the fields");
        }
        const audiencePayload = {
          ..._.omit(otherData, ["loading"]),
          client: selectedClient?.value?._id,
        };
        if (editObject && editObject?._id) {
          const { data } = await updateAudience({
            variables: {
              id: editObject?._id,
              audiencePayload,
            },
          });
          refreshPage(data?.updateAudience);
        } else {
          const { data } = await createAudience({
            variables: {
              audiencePayload,
            },
          });
          refreshPage(data?.createAudience);
        }
        toast.success("Data Updated Successfully");
      }}
    >
      <Input
        type={"text"}
        value={otherData?.name || ""}
        inputWrapperClass="full-width medium bg-2"
        label="Audience Name"
        placeholder={"Enter Name"}
        name="name"
        onChange={(e) => {
          updateState("name", e.target.value);
        }}
      />

      <Input
        type={"text"}
        value={otherData?.rules_js || ""}
        inputWrapperClass="full-width medium bg-2"
        label="Rules for JS code to run"
        placeholder={"return true;"}
        name="rules_js"
        onChange={(e) => {
          updateState("rules_js", e.target.value);
        }}
      />

      <div className="d-flex justify-end default-column-gap">
        <Button
          className="smart-button medium notactive"
          type="button"
          onClick={(e) => {
            refreshPage(null);
          }}
          text={`Cancel`}
        />
        <Button
          className="smart-button medium active"
          type="Submit"
          text={`${editObject ? `Update` : `Create`} Page`}
        />
      </div>
    </form>
  );
}
