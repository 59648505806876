import React, { useMemo, useState } from "react";
import { SmartButton } from "../../MiroComponents/SmartButton/SmartButton";
import "./SmartProjectedEffect.scss";
import { UpwardsIcon } from "../../Shared/Icons";

export const SmartProjectedEffect = ({ data }) => {
  const [selectedMonth, setSelectedMonth] = useState(12);
  const months = [6, 12];

  const projectedEffectData = useMemo(() => {
    if (selectedMonth === 6) {
      return {
        realizedCLP:
          data?.calculatedData?.businessCaseWithClv6M?.total?.realizedClp,
        realizedCLV:
          data?.calculatedData?.businessCaseWithClv6M?.total?.realizedClv,
        projectedRevenue:
          data?.calculatedData?.businessCaseWithClv6M?.total?.allRealizedProfit,
        allRealizedProfitImprovement:
          data?.calculatedData?.businessCaseWithClv6M?.total
            ?.allRealizedProfitImprovement,
        realizedClpImprovement:
          data?.calculatedData?.businessCaseWithClv6M?.total
            ?.realizedClpImprovement,
        realizedClvImprovement:
          data?.calculatedData?.businessCaseWithClv6M?.total
            ?.realizedClvImprovement,
      };
    }
    return {
      realizedCLP:
        data?.calculatedData?.businessCaseWithClv12M?.total?.realizedClp,
      realizedCLV:
        data?.calculatedData?.businessCaseWithClv12M?.total?.realizedClv,
      projectedRevenue:
        data?.calculatedData?.businessCaseWithClv12M?.total?.allRealizedProfit,
      allRealizedProfitImprovement:
        data?.calculatedData?.businessCaseWithClv12M?.total
          ?.allRealizedProfitImprovement,
      realizedClpImprovement:
        data?.calculatedData?.businessCaseWithClv12M?.total
          ?.realizedClpImprovement,
      realizedClvImprovement:
        data?.calculatedData?.businessCaseWithClv12M?.total
          ?.realizedClvImprovement,
    };
  }, [data, selectedMonth]);

  return (
    <div className="projected-effect">
      <div className="projected-effect-header">
        <h3>Projected Effect</h3>
        <div>
          {months.map((month, i) => {
            return (
              <SmartButton
                key={i}
                onClick={() => setSelectedMonth(month)}
                className={`smart-button small ${
                  month === selectedMonth ? "active" : ""
                }`}
                text={`${month} Months`}
              />
            );
          })}
        </div>
      </div>
      <div className="projected-effect-data">
        <div className="projected-effect-card">
          <h3>
            <span>Realised CLP {selectedMonth}M</span>
            <span className="up-text">
              {projectedEffectData?.realizedClpImprovement && (
                <>
                  <UpwardsIcon /> {projectedEffectData?.realizedClpImprovement}%
                </>
              )}
            </span>{" "}
          </h3>
          <div>
            <span className="currency">€</span>{" "}
            <span className="price-value">
              {projectedEffectData?.realizedCLP?.toLocaleString("en-US")}
            </span>
          </div>
        </div>
        <div className="projected-effect-card">
          <h3>
            <span>Realized CLV {selectedMonth}M</span>
            <span className="up-text">
              {projectedEffectData?.realizedClvImprovement && (
                <>
                  <UpwardsIcon /> {projectedEffectData?.realizedClvImprovement}%
                </>
              )}
            </span>{" "}
          </h3>
          <div>
            <span className="currency">€</span>{" "}
            <span className="price-value">
              {projectedEffectData?.realizedCLV?.toLocaleString("en-US")}
            </span>
          </div>
        </div>
        <div className="projected-effect-card">
          <h3>
            <span>Projected {selectedMonth}M Revenue</span>
            <span className="up-text">
              {projectedEffectData?.allRealizedProfitImprovement && (
                <>
                  <UpwardsIcon />{" "}
                  {projectedEffectData?.allRealizedProfitImprovement}%
                </>
              )}
            </span>{" "}
          </h3>
          <div>
            <span className="currency">€</span>{" "}
            <span className="price-value">
              {projectedEffectData?.projectedRevenue?.toLocaleString("en-US")}
            </span>
          </div>
        </div>
      </div>
      <div className="projected-effect-footer">
        <SmartButton
          onClick={() => {
            const target = document.getElementById("projectionoverview");
            if (target) {
              target.scrollIntoView({ behavior: "smooth" });
            }
          }}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8344 12.02C15.5815 11.702 15.1315 11.6585 14.8286 11.924L11.7144 14.6488V5.75C11.7144 5.336 11.3944 5 11.0001 5C10.6058 5 10.2858 5.336 10.2858 5.75V14.6488L7.17158 11.924C6.86802 11.6585 6.41802 11.702 6.16588 12.02C5.91303 12.338 5.95374 12.8112 6.25731 13.076L10.543 16.826C10.5765 16.856 10.6165 16.8703 10.6537 16.8927C10.683 16.9108 10.7087 16.9318 10.7408 16.9452C10.8244 16.9797 10.9115 17 11.0001 17C11.0887 17 11.1758 16.9797 11.2594 16.9452C11.2915 16.9318 11.3173 16.9108 11.3465 16.8927C11.3837 16.8703 11.4237 16.856 11.4573 16.826L15.7429 13.076C16.0458 12.8112 16.0872 12.338 15.8344 12.02Z"
                fill="black"
              />
            </svg>
          }
          className="smart-button big"
          text={`See Details`}
          style={{
            backgroundColor: "#FFF",
            color: "#000",
            padding: "6px 12px",
            borderColor: "#FFF",
          }}
        />
      </div>
    </div>
  );
};
