import create from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set, get) => ({
      crumb: null,
      showForm: false,
      editForm: false,
      sideBar: true,
      serverFilters: [],
      size: 10,
      clients: [],
      selectedClient: null,
      showFaq: false,
      refetchClients: null,
      reportAdvanceFields: false,
      setSize: (size) => set({ size }),
      setCrumb: (val) => set({ crumb: val }),
      setClients: async (clients) => {
        set({ clients });
      },
      setSelectedClient: (selectedClient) => set({ selectedClient }),
      setShowFaq: (showFaq) => set({ showFaq }),
      setReportAdvaceFields: (reportAdvanceFields) =>
        set({ reportAdvanceFields }),
      setRefetchClients: (refetchClients) => set({ refetchClients }),
      setSideBar: (sideBar) => set({ sideBar }),
      setServerFilters: (serverFilters) => set({ serverFilters }),
      toggleForm: (_) => set({ showForm: _ }),
      toggleEditForm: (_) => set({ editForm: _ }),
    }),
    {
      name: "codebasepersiststore", // unique name
      getStorage: () => localStorage,
    }
  )
);

export default useStore;
