import { useEffect, useState } from "react";
import { VscInfo } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";
import useStore from "../../Store";
export const ExperimentsCalculatedResults = ({ edt, client, i }) => {
  const advanceFields = useStore((state) => state.reportAdvanceFields);
  const [clientCurrency, setClientCurrency] = useState({
    currency: "USD",
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (client) {
      setClientCurrency(JSON.parse(client?.value));
    }
  }, [client, edt]);

  return (
    <>
      <div className="calculated-boxes">
        <span className="fw-bold text-decoration-underline">
          {" "}
          {edt?.controlvariant.replaceAll("-", " ")}{" "}
        </span>
        <div className="calculated-boxes-inner">
          <div>
            <label className="">
              Change in CR
              <span
                className="toottip-span"
                data-tip={`= Variant CR (users) ÷ (Control CR (users)) - 1`}
              >
                <VscInfo />
              </span>
            </label>
            <div
              className={
                Number(edt?.uplift) <= 0 ? `text-danger` : `text-success`
              }
            >
              {edt?.uplift} %
            </div>
          </div>
          <div>
            <label className="">
              Additional Transactions
              <span
                className="toottip-span"
                data-tip={`= Variant CR (users) * Monthly Traffic -
                  Control CR (users) * Monthly Traffic`}
              >
                <VscInfo />
              </span>{" "}
              <br />
              <small>(Per Month)</small>
            </label>
            <div
              className={`${
                Number(edt?.additionaltransactionspermonth) < 0
                  ? `text-danger`
                  : `text-success`
              }`}
            >
              {Number(edt?.additionaltransactionspermonth) < 0
                ? 0
                : Number(edt?.additionaltransactionspermonth).toLocaleString(
                    "en-US"
                  )}
            </div>
          </div>
          {edt?.additionalrevenueduringtime && (
            <div>
              <label className="">
                Additional Revenue{" "}
                <span
                  className="toottip-span"
                  data-tip={`= Variant Revenue - Control Revenue`}
                >
                  <VscInfo />
                </span>{" "}
                <br />
                <small>(during now)</small>
              </label>{" "}
              <div
                className={
                  Number(edt?.additionalrevenueduringtime) <= 0
                    ? `text-danger`
                    : `text-success`
                }
              >
                {Number(edt?.additionalrevenueduringtime) < 0
                  ? 0
                  : Number(edt?.additionalrevenueduringtime).toLocaleString(
                      "en-US"
                    )}{" "}
                <span className="text-dark" style={{ fontSize: "80%" }}>
                  ({clientCurrency?.currency})
                </span>
              </div>
            </div>
          )}

          <div>
            <label className="">
              Additional Revenue{" "}
              <span
                className="toottip-span"
                data-tip={`= Additional Transactions (per month) * Average Order Value`}
              >
                <VscInfo />
              </span>{" "}
              <br />
              <small>(Per Month)</small>
            </label>{" "}
            <div
              className={
                Number(edt?.additionalrevenuepermonth) <= 0
                  ? `text-danger`
                  : `text-success`
              }
            >
              {Number(edt?.additionalrevenuepermonth) < 0
                ? 0
                : Number(edt?.additionalrevenuepermonth).toLocaleString(
                    "en-US"
                  )}{" "}
              <span className="text-dark" style={{ fontSize: "80%" }}>
                ({clientCurrency?.currency})
              </span>
            </div>
          </div>
          <div>
            <label className="">
              Additional Revenue{" "}
              <span
                className="toottip-span"
                data-tip={`= (Additional Transactions (per month)) * 12`}
              >
                <VscInfo />
              </span>{" "}
              <br />
              <small>(Per Year)</small>
            </label>
            <div
              className={
                Number(edt?.additionalrevenueperyear) <= 0
                  ? `text-danger`
                  : `text-success`
              }
            >
              {Number(edt?.additionalrevenueperyear) < 0
                ? 0
                : Number(edt?.additionalrevenueperyear).toLocaleString(
                    "en-US"
                  )}{" "}
              <span className="text-dark" style={{ fontSize: "80%" }}>
                ({clientCurrency?.currency})
              </span>
            </div>
          </div>
          <div>
            <label className="">
              Confidence of Variant{" "}
              <span
                className="toottip-span"
                data-tip={`= 1 - NormalDist(Control Transactions (per session) - Variant Transactions (per session)) ÷
                SQRT(Math.pow(Control Std. Error, 2) + Math.pow(Variant Std Error, 2))`}
              >
                <VscInfo />
              </span>
            </label>
            <div
              className={
                Number(edt?.confidence) < 90 ? `text-dark` : `text-success`
              }
            >
              {edt?.confidence} %
            </div>
          </div>
        </div>
      </div>
      {advanceFields && (
        <>
          <p className="mb-0 fw-bold text-decoration-underline">
            {" "}
            Advanced Fields
          </p>
          <div className="calculated-boxes">
            <div className="calculated-boxes-inner">
              <div className="">
                <label className="">
                  Change in AOV
                  <span
                    className="toottip-span"
                    data-tip={`= Variant AOV - Control AOV`}
                  >
                    <VscInfo />
                  </span>
                </label>
                <div
                  className={
                    Number(edt?.changeinaov) <= 0 ? `text-danger` : `text-dark`
                  }
                >
                  {edt?.changeinaov}{" "}
                </div>
              </div>
              <div>
                <label className="">
                  Change in RPU
                  <span
                    className="toottip-span"
                    data-tip={`= Variant RPU - Control RPU`}
                  >
                    <VscInfo />
                  </span>{" "}
                </label>
                <div
                  className={`${
                    Number(edt?.changeinrpu) < 0 ? `text-danger` : `text-dark`
                  }`}
                >
                  {edt?.changeinrpu}{" "}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
