import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DEACTIVATEUSER,
  UPDATEUSER,
  ACTIVATEUSER,
} from "../../../Graphql/Mutations";
import { GETALLROLES, GETUSERS } from "../../../Graphql/Queries";
import Select from "react-select";
import useStore from "../../../Store";
import FormInstance from "../../MiroComponents/FormInstance";
import { NotAuthorizedInternal } from "../../Shared/NotAuthorizedInternal";
import { Pagination } from "../../Shared/Paginations";
import { customStyles } from "../../../Libs/TableCustomStyles";
import { FiltersComponent } from "../../Shared/FiltersComponent";
function Users() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [activeuser] = useMutation(ACTIVATEUSER);
  const [page, setPage] = useState(1);
  const [editObj, setEditObj] = useState(null);
  const [size] = useState(20);
  const [filter, showFilter] = useState(false);
  const [roles, setRoles] = useState(null);
  const { toggleForm } = useStore();
  const [userFilters, setUserFilters] = useState([
    {
      deactivated: [false],
    },
  ]);
  const [deactivated, setdeactivated] = useState(false);
  const [loader, setLoader] = useState(false);
  // Set Edit User Roles
  const [userRoles, setUserRoles] = useState(null);
  const [userClients, setUserClients] = useState(null);
  const showForm = useStore((state) => state.showForm);
  const [deactiveuser] = useMutation(DEACTIVATEUSER);
  const [usersData, setUsersData] = useState({
    loading: true,
  });
  const [getusers] = useLazyQuery(GETUSERS);
  const getUsersQuery = useCallback(async () => {
    const {
      data: { users },
      loading,
      error,
    } = await getusers({
      variables: {
        page,
        size,
        filters: [
          ...userFilters,
          {
            roles: roles,
          },
          {
            deactivated: [deactivated],
          },
        ],
      },
    });
    setUsersData({
      loading,
      page,
      size,
      error,
      total: users?.total,
      users: users?.users,
    });
  }, [page, size, roles, deactivated, userFilters]);

  useEffect(() => {
    getUsersQuery();
  }, [getUsersQuery]);

  const allrolesquery = useQuery(GETALLROLES, {
    fetchPolicy: "network-only",
  });
  const [updateuser] = useMutation(UPDATEUSER);
  const allroles = allrolesquery?.data?.allroles;
  if (!is_admin) return <NotAuthorizedInternal />;
  if (loader || allroles?.loading) return "Loading";
  const columnsusers = [
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
      maxWidth: "20%",
    },
    {
      name: "Email",
      selector: (row) => <div> {row.email} </div>,
      maxWidth: "20%",
      wrap: true,
    },
    {
      name: "Internal / Client",
      cell: (row) => (
        <div className="client-login-links">
          {row?.client ? (
            <>
              {row?.client?.map((clnt, i) => (
                <div key={i} className="mb-1">
                  Client: <span className="fw-light">{clnt?.name} </span> |
                  <span
                    className="text-primary d-inline-flex ms-1"
                    style={{ cursor: "pointer" }}
                    onClick={(_) => {
                      navigator?.clipboard?.writeText &&
                        navigator?.clipboard?.writeText(
                          window.location.origin +
                            "/auth/login?clientId=" +
                            clnt?._id
                        );
                      toast.success("Link Copied!");
                    }}
                  >
                    Copy Login Link
                  </span>
                </div>
              ))}
            </>
          ) : (
            <div>
              <span className="fw-light">Internal</span>
            </div>
          )}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Role(s)",
      cell: (row) => {
        return row?.role?.map((rle, i) => (
          <button key={i} className="badge bg-success border-0 text-white me-1">
            {" "}
            {rle?.name}{" "}
          </button>
        ));
        // return allroles?.map((alrol, i) => {
        //   if (myroles.includes(alrol?._id)) {
        //     return (
        //       <button
        //         key={i}
        //         onClick={async (e) => {
        //           const _roles = row.role
        //             ?.map((_rl) => _rl?._id)
        //             .filter((it) => it !== alrol._id);
        //           const _ = await updaterole({
        //             variables: {
        //               id: row?._id,
        //               roles: _roles,
        //             },
        //           });
        //           if (_) {
        //             updaterolecallback.reset();
        //             refetch();
        //           }
        //         }}
        //         className="badge bg-success border-0 text-white me-1"
        //       >
        //         {alrol?.name}
        //       </button>
        //     );
        //   } else {
        //     return (
        //       <button
        //         key={i}
        //         className="badge bg-default text-dark me-1"
        //         onClick={async (e) => {
        //           const _roles = row.role?.map((_rl) => _rl?._id);
        //           _roles.push(alrol._id);
        //           const _ = await updaterole({
        //             variables: {
        //               id: row?._id,
        //               roles: _roles,
        //             },
        //           });
        //           if (_) {
        //             refetch();
        //           }
        //         }}
        //       >
        //         {alrol?.name}
        //       </button>
        //     );
        //   }
        // });
      },
      wrap: true,
      right: true,
    },
    {
      name: "Edit",
      wrap: true,
      selector: (row) => (
        <div>
          <button
            className="ms-2 badge bg-dark text-white border-0"
            onClick={(_) => {
              setEditObj(row);
              if (row?.role) {
                setUserRoles(
                  row?.role?.map((rol) => {
                    return {
                      label: rol?.name,
                      value: rol?._id,
                    };
                  })
                );
              }
              if (row?.client) {
                setUserClients(
                  row?.client?.map((clt) => {
                    return {
                      label: clt?.name,
                      value: clt?._id,
                    };
                  })
                );
              }
            }}
          >
            Edit
          </button>
          <button
            className={`ms-2 btn btn-sm text-white border-0 ${
              row?.deactivated ? "bg-primary" : "bg-danger"
            }`}
            onClick={async (e) => {
              if (
                window.confirm(
                  `Are you sure you wan't to ${
                    row?.deactivated ? "restore" : "deactivate"
                  } this user and his access ?`
                )
              ) {
                if (row?.deactivated) {
                  await activeuser({
                    variables: {
                      id: row?._id,
                    },
                  });
                } else {
                  await deactiveuser({
                    variables: {
                      id: row?._id,
                    },
                  });
                }
                toast.success(
                  `User ${
                    row?.deactivated ? "restored" : "deactivated"
                  } successfully!`
                );
                getUsersQuery();
              }
            }}
          >
            {row?.deactivated ? "Restore" : "Dactivate"} User
          </button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Users</h2>
          <div>
            <button
              className="btn-as-link d-inline-flex align-items-center text-white"
              onClick={(_) => {
                if (userFilters) {
                  setPage(1);
                  setUserFilters([]);
                }
                showFilter(!filter);
              }}
            >
              {filter ? "Close Filters" : "Filters"}
            </button>
            {is_admin && (
              <button className="add" onClick={(_) => toggleForm(!showForm)}>
                Add User
              </button>
            )}
          </div>
        </div>
      </div>
      {filter && (
        <FiltersComponent
          serverFilters={userFilters}
          setServerFilters={setUserFilters}
          resetFilters={() => {
            setPage(1);
            setUserFilters([]);
          }}
          setPage={setPage}
          filterTypes={["findtestbytestname"]}
        />
      )}

      {editObj && (
        <div className="">
          <div className="form-wrapper">
            <div className="form-wrapper-inner">
              <div className="card" id="formcard">
                <button
                  className="close-modal"
                  onClick={(_) => setEditObj(null)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#000"
                  >
                    <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                  </svg>{" "}
                </button>
                <div className="card-body p-0">
                  <h3>Edit User</h3>
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      const { name, email } = e.target;
                      try {
                        setLoader(true);
                        const { data } = await updateuser({
                          variables: {
                            id: editObj?._id,
                            name: name.value,
                            email: email.value,
                            roleid: userRoles
                              ? userRoles?.map((_) => _.value)
                              : null,
                            client: userClients
                              ? userClients?.map((_) => _.value)
                              : null,
                          },
                        });
                        if (data) {
                          getUsersQuery();
                          setEditObj(null);
                          setUserClients(null);
                          setUserRoles(null);
                          setLoader(false);
                        }
                      } catch (error) {
                        setLoader(false);
                        toast.error(error?.message);
                      }
                    }}
                  >
                    <div className="fields-wrapper">
                      <label>Name</label>
                      <input
                        name="name"
                        defaultValue={editObj?.name}
                        className="field-control"
                        placeholder="Add name"
                      />
                    </div>
                    <div className="fields-wrapper">
                      <label>Email</label>
                      <input
                        name="email"
                        defaultValue={editObj?.email}
                        className="field-control"
                        placeholder="Add name"
                      />
                    </div>
                    {editObj?.role && (
                      <div className="fields-wrapper">
                        <label>Select Role</label>
                        <Select
                          classNamePrefix="acc-select"
                          isMulti={true}
                          placeholder="Select Role(s)"
                          defaultValue={userRoles}
                          onChange={(_) => {
                            setUserRoles(_);
                            if (_?.some((rl) => rl?.label !== "CLIENT")) {
                              setUserClients(null);
                            }
                          }}
                          options={allroles?.map((clnts) => {
                            return {
                              label: clnts?.name,
                              value: clnts?._id,
                            };
                          })}
                        />
                      </div>
                    )}
                    {userRoles?.some((url) => url?.label === "CLIENT") && (
                      <div className="fields-wrapper">
                        <label>Select Client</label>
                        <Select
                          classNamePrefix="acc-select"
                          isMulti={true}
                          placeholder="Select Client(s)"
                          defaultValue={userClients}
                          onChange={(_) => {
                            setUserClients(_);
                          }}
                          options={myProfile?.client?.map((clnts) => {
                            return {
                              label: clnts?.label,
                              value: clnts?.value?._id,
                            };
                          })}
                        />
                      </div>
                    )}
                    <div className="fields-wrapper">
                      <button type="submit"> Update User</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showForm && (
        <FormInstance
          title="Add User"
          type="adduser"
          userrefetch={getUsersQuery}
          allroles={allroles}
          clients={myProfile?.client}
        />
      )}
      <div className="users-filters">
        <button
          className={`${
            !deactivated && !roles
              ? `btn bg-primary text-white me-1`
              : `btn bg-light me-1`
          }`}
          onClick={(e) => {
            setRoles(null);
            setPage(1);
            setdeactivated(false);
          }}
        >
          All Users
        </button>
        {allroles?.map((rle, i) => (
          <button
            className={`btn bg-light me-1 ${
              roles?.includes(rle?._id) ? `bg-dark text-white` : ``
            }`}
            key={i}
            onClick={(e) => {
              setRoles([rle?._id]);
              setPage(1);
              setdeactivated(false);
            }}
          >
            {rle.name}
          </button>
        ))}
        <button
          className={`${
            deactivated ? `btn bg-danger text-white me-1` : `btn bg-light me-1`
          }`}
          onClick={(e) => {
            setRoles(null);
            setPage(1);
            setdeactivated(true);
          }}
        >
          DELETED USERS
        </button>
      </div>
      <div className="mt-3">
        <DataTable
          columns={columnsusers}
          data={usersData?.users}
          customStyles={customStyles}
        />
      </div>
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={usersData?.total}
      />
    </div>
  );
}
export default Users;
