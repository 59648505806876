import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { WithContext as ReactTags } from "react-tag-input";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FAQS } from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import parse from "html-react-parser";
import { CREATEFAQ, UPDATEFAQ } from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { BackIcon } from "../../Shared/Icons";
import { customStyles } from "../../../Libs/TableCustomStyles";

export const ManageFaqs = () => {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [action, setAction] = useState(null);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const { data, loading, refetch } = useQuery(FAQS);
  const [updatefaq] = useMutation(UPDATEFAQ);
  const [createfaq] = useMutation(CREATEFAQ);
  if (loading) return <MyLoader />;
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const columns = [
    {
      name: "Title",
      selector: (row) => <div> {row?.title}</div>,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="pt-2 pb-2"> {parse(row?.description)}</div>
      ),
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="pt-2 pb-2">
          <button
            className="btn btn-sm btn-primary"
            onClick={(_) => {
              setAction({
                module: "edit",
                dataObj: {
                  title: row?.title,
                  _id: row?._id,
                  keywords: row?.keywords,
                },
              });
              setDescription(row?.description);
              setKeywords(row?.keywords ?? []);
            }}
          >
            {" "}
            Edit
          </button>
        </div>
      ),
      wrap: true,
      width: "10%",
    },
  ];
  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> FAQs</h2>
          <div>
            {!is_user && (
              <button
                className="add"
                onClick={(_) => {
                  setAction({
                    module: "add",
                    dataObj: null,
                  });
                  setDescription("");
                  setKeywords([]);
                }}
              >
                Add FAQ
              </button>
            )}
          </div>
        </div>
      </div>

      {action && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner addtestform">
            <div className="card add-new-test-card" id="formcard">
              <button className="close-modal" onClick={(_) => setAction(null)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <h3>{action?.module === "edit" ? "Edit FAQ" : "Add New FAQ"}</h3>
              <div className="card-body p-0">
                <form
                  onSubmit={async (_) => {
                    _.preventDefault();
                    const title = _.target.title.value;
                    const obj = {
                      title,
                      description,
                      keywords: keywords,
                    };
                    if (action?.module === "edit") {
                      obj["id"] = action?.dataObj?._id;
                      const { data, error } = await updatefaq({
                        variables: obj,
                      });
                      if (error) {
                        toast.error(error?.message);
                        return;
                      }
                      if (data) {
                        refetch();
                        toast.success("FAQ updated.");
                        setAction(null);
                      }
                    }
                    if (action?.module === "add") {
                      const { data, error } = await createfaq({
                        variables: obj,
                      });
                      if (error) {
                        toast.error(error?.message);
                        return;
                      }
                      if (data) {
                        refetch();
                        toast.success("FAQ created.");
                        setAction(null);
                      }
                    }
                  }}
                >
                  <div className="fields-wrapper theme-black">
                    {/* <label>Title</label> */}
                    <input
                      name="title"
                      className="field-control"
                      placeholder="Add Title"
                      defaultValue={action?.dataObj?.title ?? ""}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>
                  <div className="fields-wrapper theme-black">
                    <label> Keywords</label>
                    <TagsInput
                      value={keywords}
                      onChange={(value) => {
                        setKeywords(value);
                      }}
                      onlyUnique={true}
                      tagProps={{
                        className: "react-tagsinput-tag",
                        classNameRemove: "react-tagsinput-remove",
                      }}
                      inputProps={{ className: "field-control" }}
                    />
                  </div>
                  <div className="add__new__test__form-footer">
                    <div className="btn-prev" onClick={(_) => setAction(null)}>
                      <BackIcon />
                      Cancel
                    </div>

                    <button type="submit" className="btn next-btn">
                      {action?.module === "edit" ? "Update FAQ" : "Add FAQ"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <DataTable
        data={data?.faqs}
        columns={columns}
        customStyles={customStyles}
      />
    </div>
  );
};
