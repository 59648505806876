import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { GETAPPEVENTS } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { Pagination } from "./Paginations";
import { useQuery } from "@apollo/client";

const UserRowComponent = ({ event, refetch }) => {
  return (
    <div className="test-details-table-data">
      <div className="test-name text-break">
        <h3>{event?.clientId?.name}</h3>
      </div>
      <div className="test-name text-break">
        <h3>{event?.viewId}</h3>
      </div>
      <div className="test-name">
        <h3>{event?.variant}</h3>
      </div>
    </div>
  );
};

function AppEvents() {
  const [is_admin] = useOutletContext();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const { data, loading, error, refetch } = useQuery(GETAPPEVENTS, {
    variables: {
      page: page,
      size: size,
    },
  });

  if (loading) return <MyLoader />;
  if (error) return <div> {error?.message}</div>;
  return (
    <div className="clients-inner">
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> App Events</h2>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="table-body">
          <div className="test-details-table-data heading-row">
            <div className="test-name">
              <h3>Client Name</h3>
            </div>
            <div className="test-name">
              <h3>View Id</h3>
            </div>
            <div className="test-name">
              <h3>variant</h3>
            </div>
            {/* <div className="test-action-wrapper">
              <div className="test-action">
                <span>Action</span>
              </div>
            </div> */}
          </div>
          {data?.getAppEvents?.results?.map((event, i) => (
            <UserRowComponent event={event} key={i} refetch={refetch} />
          ))}
        </div>
      </div>
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        totalcount={data?.getAppEvents?.total}
      />
    </div>
  );
}

export default AppEvents;
