import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { GETALLCLIENTSURVEY } from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { customStyles } from "../../../Libs/TableCustomStyles";

function ClientSurveyReports() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [allSurveys, setAllSurveys] = useState(null);
  const [getAllSurvey] = useLazyQuery(GETALLCLIENTSURVEY);

  const getSurvey = async () => {
    const allSurvey = await getAllSurvey({
      variables: {
        page: page,
        size: size,
      },
    });
    if (allSurvey?.data?.getAllSurvey) {
      setAllSurveys(allSurvey?.data?.getAllSurvey);
    }
  };

  useEffect(() => {
    getSurvey();
  }, []);

  const columnsclientssurveys = [
    {
      name: "Client name",
      selector: (row) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
        >
          {row?.user?.name}
        </div>
      ),
      wrap: true,
      width: "20%",
    },
    {
      name: "Responses",
      cell: (row) => {
        const { responses } = row;

        return (
          <div className="properties">
            {responses?.map((res, i) => {
              return (
                <div
                  key={i}
                  style={{
                    padding: "5px 0px",
                  }}
                >
                  <div>- {res?.question}</div>
                  <div>
                    <b>- {res?.answer}</b>
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
      wrap: true,
    },
  ];

  return (
    <div className="clients-inner">
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Client Survey Reports</h2>
        </div>
      </div>

      <DataTable
        columns={columnsclientssurveys}
        data={allSurveys?.surveys || []}
        customStyles={customStyles}
        progressComponent={<MyLoader />}
      />
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        totalcount={allSurveys?.total}
      />
    </div>
  );
}
export default ClientSurveyReports;
