import { NoDataFoundIcon } from "../../Shared/Icons";
import "./NoDataFound.scss";

export default function NoDataFound({
  title = "Select Client Store",
  description = "We apologize, but there is currently no data to display. Please select the store to continue. Thank you for your patience and understanding.",
}) {
  return (
    <div className="nodata-wrapper">
      <div className="nodata-inner flex align-center">
        <div className="nodata-icon">
          <NoDataFoundIcon />
        </div>
        <div className="nodata-text">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
