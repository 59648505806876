import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GETCLIENTSTATS } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { Button } from "../MiroComponents/Button/Button";

export const AdminStats = ({ id, show, title }) => {
  const [showStat, setShowStat] = useState(show);
  const [getstats] = useLazyQuery(GETCLIENTSTATS);
  const [loader, setLoader] = useState(false);
  const [clientData, setClientData] = useState(null);
  useEffect(() => {
    if (showStat) {
      (async () => {
        const { data, loading } = await getstats({
          variables: {
            id,
          },
        });
        if (loading) {
          setLoader(true);
        }
        if (data) {
          setLoader(false);
          setClientData(data?.clientStats);
        }
      })();
    }
  }, [id, showStat]);
  if (showStat && loader) return "Please wait..";
  return (
    <>
      {!showStat ? (
        <Button
          className="smart-button small active"
          onClick={(_) => setShowStat(true)}
        >
          {" "}
          {title || "Show Stats"}{" "}
        </Button>
      ) : (
        <>
          {clientData?.conversionRate &&
          clientData?.averageRevenueMonthly &&
          clientData?.averageRevenueYearly ? (
            <p className="mb-0">
              Avg. Uplift :{" "}
              <span className="fw-bold">{clientData?.conversionRate} %</span>{" "}
              <br />
              Add. revenue / month :{" "}
              <span className="fw-bold">
                {clientData?.averageRevenueMonthly} ({clientData?.currency})
              </span>{" "}
              <br />
              Add. revenue / year :{" "}
              <span className="fw-bold">
                {clientData?.averageRevenueYearly} ({clientData?.currency})
              </span>
            </p>
          ) : (
            <p className="mb-0">No Data available.</p>
          )}
        </>
      )}
    </>
  );
};
