import React from "react";
import SmartContainer from "../SmartContainer/SmartContainer";
import { NewLogo } from "../../Shared/Icons";
import "./NewHeader.scss";
import { FaChevronDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";
export default function NewHeader({ myProfile, entryRoute }) {
  return (
    <div>
      <SmartContainer className="p-20-top p-20-bottom">
        <div className="new-header">
          <div>
            <NewLogo />
          </div>
          {myProfile && (
            <div className="new-menu-items">
              <ul>
                <li>
                  <NavLink to={entryRoute}>
                    <span className="name-initial">
                      {myProfile?.name?.charAt(0)}
                    </span>
                    <span>
                      {myProfile?.name} <FaChevronDown />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
      </SmartContainer>
    </div>
  );
}
