import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { TimeSeriesOptions, convertDate } from "../../../../Libs/Utilities";

export function TimeSeriesComponent({ timeSeriesData }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );

  const colors = [
    "255 51 60",
    "86 122 250",
    "255 124 51",
    "249 194 1",
    "176 89 244",
    "34 193 195", // Light teal
    "123 237 159", // Mint green
    "234 91 159", // Soft pink
    "72 52 212", // Deep purple
    "52 73 94", // Dark slate gray
    "46 204 113", // Light green
    "41 128 185", // Rich blue
  ];
  const labels = timeSeriesData?.labels?.map((_) => convertDate(_));
  const dataset = {
    labels,
    datasets: timeSeriesData?.datasets?.map((gde, i) => {
      return {
        label: gde?.controlvariant,
        lineTension: 0.4,
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 4,
        pointBorderColor: `rgb(${colors[i]} / 1)`,
        pointBackgroundColor: "#fff",
        borderColor: `rgb(${colors[i]} / 1)`,
        data: gde?.timeSeries?.map((_) => Number(_.value)),
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, `rgb(${colors[i]} / 100%)`);
          gradient.addColorStop(0.5, `rgb(${colors[i]} / 50%)`);
          gradient.addColorStop(1, `rgb(${colors[i]} / 10%)`);
          return gradient;
        },
        fill: true,
      };
    }),
  };

  return (
    <div className="report-charts-height">
      <Line options={TimeSeriesOptions} data={dataset} />
    </div>
  );
}

export const TimeSeriesGa4 = React.memo(TimeSeriesComponent);
