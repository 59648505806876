import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SINGLEABTEST } from "../../../Graphql/Queries";
import { ADDUPDATEVARIANT } from "../../../Graphql/Mutations";
import MyLoader from "../../MiroComponents/PreLoader";
import Input from "../../MiroComponents/Input/Input";
import { Button } from "../../MiroComponents/Button/Button";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { toast } from "react-toastify";
import _, { add } from "lodash";
import useAbTestStore from "../../../AbTestStore";
export default function ExperimentForm() {
  const { updateTest, insights } = useAbTestStore();
  const { experimentId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const _params = Object.fromEntries(searchParams);
  const [addupdatetestvariant] = useMutation(ADDUPDATEVARIANT);
  const { data, loading, error, refetch } = useQuery(SINGLEABTEST, {
    variables: {
      id: experimentId,
    },
  });
  const cancel = () => {
    navigate(`/abtesting/experiments`);
  };
  if (loading) return <MyLoader />;
  const variations = data?.singletest?.variations;
  return (
    <div>
      {experimentId}
      <h1>Experiment Form</h1>
      {variations?.map((variant, index) => (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const { name, csscode, jscode, reset_js } = e.target.elements;
            if (name.value === "") {
              toast.error("Name is required");
              return;
            }
            const variantPayload = {
              ..._.omit(variant, ["__typename"]),
              name: name.value,
              csscode: csscode.value || "",
              css_code: csscode.value || "",
              css: csscode.value || "",
              jscode: jscode.value,
              js: jscode.value,
              reset_js: reset_js.value,
            };
            const { data } = await addupdatetestvariant({
              variables: {
                test: experimentId,
                variant: variantPayload,
              },
            });
            refetch();
            window.location.href = `/abtesting/experiments/`;
            // navigate(`/abtesting/experiments?tid=${Math.random()}`);
          }}
          style={
            _params?.variation === variant?._id || !_params?.variation
              ? { display: "block" }
              : {
                  display: "none",
                }
          }
          className="default-margin-bottom default-padding default-border bg-1"
          key={index}
        >
          <Input
            type={"text"}
            value={variant?.name || ""}
            inputWrapperClass="full-width medium bg-2"
            label="Variant Name"
            placeholder={"Variation Name"}
            name="name"
            disabled={variant?.baseline}
          />
          <Input
            type={"textarea"}
            value={variant?.csscode || ""}
            inputWrapperClass="full-width medium bg-2"
            label="CSS Code"
            placeholder={"Enter CSS Code"}
            name="csscode"
          />
          <Input
            type={"textarea"}
            value={variant?.jscode || ""}
            inputWrapperClass="full-width medium bg-2"
            label="JS Code"
            placeholder={"Enter JS Code"}
            name="jscode"
          />
          <Input
            type={"textarea"}
            inputWrapperClass="full-width medium bg-2"
            label="Reset JS Code"
            placeholder={"Enter Reset JS Code"}
            name="reset_js"
          />
          {/* <div className="field-wrapper">
            <label>Reset JS</label>
            <div className="input-wrapper full-width medium bg-2">
              <Editor
                textareaClassName="full-width medium bg-2"
                value={variant?.reset_js || ""}
                onValueChange={(code) => {
                  console.log(code);
                  // setCode(code)
                }}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div> */}

          <div className="d-flex justify-end default-column-gap">
            <Button
              className="smart-button medium notactive"
              type="button"
              onClick={cancel}
              text={`Cancel`}
            />
            <Button
              className="smart-button medium active"
              type="Submit"
              text={`Update Variant`}
            />
          </div>
        </form>
      ))}
    </div>
  );
}
