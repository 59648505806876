import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useLayoutEffect, useRef } from "react";
import { Button } from "../MiroComponents/Button/Button";

export const ClientPropertiesList = ({
  properties,
  setSearchParams,
  selectedViewId,
  _params,
  listCount,
}) => {
  const splideRef = useRef(null);

  useLayoutEffect(() => {
    const selectedView = document.querySelector(".smart-button.active");
    if (selectedView) {
      splideRef.current.go(Number(selectedView.getAttribute("data-index")));
    }
  }, [selectedViewId]);
  return (
    <div className="experiment-list">
      <Splide
        aria-label="My Favorite Images"
        ref={splideRef}
        options={{
          type: "slide",
          perPage: listCount ?? 3,
          pagination: false,
          arrows: true,
          gap: "10px",
          breakpoints: {
            1279: {
              perPage: 2,
              pagination: true,
              arrows: false,
            },
            // 767: {
            //   pagination: true,
            //   // arrows: false,
            // },
          },
        }}
      >
        {properties?.map(({ platform, propertyname, viewId }, i) => (
          <SplideSlide key={i}>
            <Button
              type="button"
              key={i}
              data-index={i}
              className={`smart-button full-width big elipses ${
                viewId === selectedViewId ? `active` : ``
              } `}
              onClick={(e) => {
                _params.viewId = viewId;
                setSearchParams(_params);
              }}
            >
              <span>{propertyname}</span>
            </Button>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};
