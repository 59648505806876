export const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#121212",
      color: "#727272",
      fontSize: "18px",
      fontWeight: "400",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  rows: {
    style: {
      backgroundColor: "#121212",
      paddingTop: "17px",
      paddingBottom: "17px",
    },
  },
  cells: {
    style: {
      backgroundColor: "#121212",
      color: "#fff",
      fontSize: "16px",
    },
  },
};
