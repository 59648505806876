import { useQuery } from "@apollo/client";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useOutletContext } from "react-router-dom";
import { GETSEGMENTCOMBINATIONS } from "../../../Graphql/Queries";
import useStore from "../../../Store";
import { AddEditSegments } from "../../MiroComponents/AddEditSegments";
import MyLoader from "../../MiroComponents/PreLoader";

export function CreateSegments() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const { toggleForm } = useStore();
  const showForm = useStore((state) => state.showForm);
  const { data, loading, error, refetch } = useQuery(GETSEGMENTCOMBINATIONS);
  const [module, setModule] = useState(null);
  const [segmentObj, setSegmentObj] = useState(null);

  const closeModal = () => {
    setModule(null);
  };
  const columns = [
    {
      name: "Segment Name",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Segment Type",
      selector: (row) => (
        <span style={{ textTransform: "uppercase" }}>{row.segmentType} </span>
      ),
      wrap: true,
    },
    {
      name: "Segment Conditions",
      cell: (row) => {
        return (
          <div>
            {row?.combination.map((cm, i) => {
              return (
                <div key={i}>
                  {Object.entries(cm)[0]?.length > 0 && (
                    <span>
                      {Object.entries(cm)[0][0]} ={" "}
                      <span style={{ fontWeight: "100" }}>
                        {Object.entries(cm)[0][1]}
                      </span>
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        );
      },
      wrap: true,
    },
    {
      name: "Segment Secondary Filters",
      cell: (row) => {
        const secfilters = row?.secondaryFilters;
        return (
          <div>
            {secfilters?.map((scf, i) => {
              return (
                <div className="bg-light mb-2 mt-2 p-1" key={i}>
                  Metric :{" "}
                  <span style={{ fontWeight: "100" }}> {scf?.name}</span>
                  <br />
                  Filter Values:{" "}
                  <span style={{ fontWeight: "100" }}>{scf?.filters}</span>
                </div>
              );
            })}
          </div>
        );
      },
      wrap: true,
    },
    {
      name: "Status",
      cell: (row) => {
        if (row.published) {
          return (
            <div className="badge bg-success border-0 text-white">
              {" "}
              Published
            </div>
          );
        } else {
          return <div className="badge bg-warning text-dark"> Draft</div>;
        }
      },
      wrap: true,
    },

    {
      name: "Edit",
      cell: (row) => {
        return (
          <div>
            <button
              className="badge bg-dark border-0 text-white"
              onClick={(_) => {
                toggleForm(!showForm);
                setModule("edit");
                setSegmentObj(row);
              }}
            >
              Edit
            </button>
          </div>
        );
      },
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Segments</h2>
          <div>
            {!is_user && (
              <button
                className="add"
                onClick={(_) => {
                  toggleForm(!showForm);
                  setModule("add");
                  setSegmentObj(null);
                }}
              >
                Add Data
              </button>
            )}
          </div>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data?.segmentcombinations}
        progressPending={loading}
        progressComponent={<MyLoader />}
      />
      {showForm && (
        <AddEditSegments
          module={module}
          segmentObj={segmentObj}
          refetch={refetch}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}
