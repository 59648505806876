import React, { useMemo, useRef, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { ListHeader } from "./ListHeader";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { GETAUDIENCES } from "../../../Graphql/Queries";
import { CREATEAUDIENCE, UPDATEAUDIENCE } from "../../../Graphql/Mutations";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import useAbTestStore from "../../../AbTestStore";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../MiroComponents/Button/Button";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import TargetDefination from "../TargetDefination/TargetDefination";
import AudienceTargetingForm from "./AudienceTargetingForm";

const AUDIENCE_HEADER = ["Name", "Rules js", "Edit/Delete"];

export const AudienceTargeting = () => {
  const audienceBoxRef = useRef(null);
  const { insights, updateAudiences } = useAbTestStore();
  const [showForm, setShowForm] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const _params = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams]
  );

  const AudienceBox = React.forwardRef(({ audience }, ref) => {
    return (
      <section className={`big-margin-bottom`} ref={ref}>
        <TargetDefination
          targetSelected="audience"
          {...audience}
          allowEdit={true}
        />
      </section>
    );
  });

  const refreshPage = (data) => {
    if (data) {
      updateAudiences(data);
    }
    setSearchParams({});
    setShowForm(false);
  };

  return (
    <div>
      {(showForm || _params?.id) && (
        <>
          <AudienceTargetingForm
            editObject={insights?.audiences?.find(
              (item) => item?._id === _params?.id
            )}
            refreshPage={refreshPage}
            id={_params?.id}
            showForm={showForm}
          />
        </>
      )}
      <div className="d-flex justify-content-between default-margin-bottom">
        <h1 className="margin-bottom-auto">Audiences </h1>
        <div>
          <Button
            className="smart-button small active"
            onClick={() => setShowForm(!showForm)}
          >
            Add New Audience
          </Button>
        </div>
      </div>
      {insights?.audiences?.length === 0 && (
        <NoDataFound
          title="No Audiences Found"
          description="Add new audiences to start testing."
        />
      )}
      {insights?.audiences?.map((audience, index) => (
        <AudienceBox audience={audience} ref={audienceBoxRef} key={index} />
      ))}
    </div>
  );
};

// export const AudienceTargeting = ({
//   type,
//   module,
//   formType,
//   currentData,
//   client,
//   setModule,
//   setFormType,
//   setCurrentData,
// }) => {
//   const [createAudience] = useMutation(CREATEAUDIENCE);
//   const [updateAudience] = useMutation(UPDATEAUDIENCE);

//   const { data, loading, error, refetch } = useQuery(GETAUDIENCES, {
//     variables: {
//       filters: [
//         {
//           client: client,
//         },
//       ],
//     },
//   });

//   if (loading) return <LoaderLogo />;
//   if (error) return <div>Error: {error.message}</div>;
//   return (
//     <>
//       {type === "form" ? (
//         <form
//           onSubmit={async (e) => {
//             e.preventDefault();
//             const { Name, rules_js } = e.target;
//             const audiencePayload = {
//               client: client,
//               name: Name.value,
//               rules_js: rules_js.value,
//             };
//             if (formType === "add") {
//               await createAudience({
//                 variables: {
//                   audiencePayload: audiencePayload,
//                 },
//               });
//               toast.success("Audience Added Successfully");
//             } else {
//               await updateAudience({
//                 variables: {
//                   id: currentData?._id,
//                   audiencePayload: audiencePayload,
//                 },
//               });
//               toast.success("Audience Edited Successfully");
//             }
//             refetch();
//             setModule(null);
//           }}
//         >
//           <div className="fields-wrapper fields-wrapper__updated">
//             <div>
//               <label>Name</label>
//               <input
//                 required
//                 name="Name"
//                 className="field-control  field-control__updated"
//                 placeholder="Name"
//                 defaultValue={currentData ? currentData.name : ""}
//               />
//             </div>
//           </div>
//           <div className="fields-wrapper fields-wrapper__updated">
//             <label>Rules js</label>
//             <textarea
//               name="rules_js"
//               className="field-control  field-control__updated"
//               placeholder="Rules js"
//               defaultValue={currentData ? currentData.rules_js : "return true;"}
//             />
//           </div>
//           <div className="add__new__test__form-footer">
//             <button className="btn next-btn" type="submit">
//               {formType === "add" ? `Add ${module}` : `Edit ${module}`}
//             </button>
//           </div>
//         </form>
//       ) : (
//         <>
//           <ListHeader header={AUDIENCE_HEADER} />
//           <div>
//             {data?.getAudiences?.audiences?.map((row, idx) => (
//               <div
//                 key={`${row.name}-${idx}`}
//                 className="test-details-table-data"
//               >
//                 <div className="test-name text-break">
//                   <h3>{row?.name}</h3>
//                 </div>
//                 <div className="test-name text-break">
//                   <h3>{row?.rules_js}</h3>
//                 </div>
//                 <div className="test-name text-break">
//                   <FaEdit
//                     onClick={() => {
//                       setFormType("edit");
//                       setModule("Audience Targeting");
//                       setCurrentData(row);
//                     }}
//                     style={{ cursor: "pointer", color: "gray" }}
//                   />
//                 </div>
//               </div>
//             ))}
//           </div>
//         </>
//       )}
//     </>
//   );
// };
