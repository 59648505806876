import Select from "react-select";
import "./SelectComponent.scss";
export default function SelectComponent({
  options = [],
  value,
  placeholder = "Select an option",
  icon = `🔍`,
  onChange,
  label,
  disabled,
  customClass = "",
  isMulti = false,
  containerClass = "",
  selectWrapperClass = "margin-bottom-auto",
  filterOption = null,
  isSearchable = true,
}) {
  return (
    <div className={`field-wrapper ${selectWrapperClass}`}>
      {label && <label>{label}</label>}
      <div className={`select-component ${customClass}`}>
        {icon && <span className="icon">{icon}</span>}
        <Select
          // menuIsOpen={true}
          isDisabled={disabled}
          // filterOption={filterOption}
          isSearchable={isSearchable}
          value={value ?? null}
          options={options}
          filterOption={filterOption}
          isMulti={isMulti}
          placeholder={placeholder}
          onChange={onChange}
          className={`conversion-select-container ${containerClass}`}
          classNamePrefix={`conversion-select`}
        />
      </div>
    </div>
  );
}
