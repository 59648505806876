import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { RESETPASSWORD, CHANGEPASSWORD } from "../../Graphql/Mutations";
import { EyeInvisible, EyeVisible } from "../Shared/Icons";
import { LoaderLogo } from "../Shared/LoaderLogo";

function ChangePassword({ isModal, myProfile, setIsModal }) {
  const params = useParams();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [resetPassword] = useMutation(RESETPASSWORD);
  const [changePassword] = useMutation(CHANGEPASSWORD);
  const navigate = useNavigate();

  const validateFields = useCallback(() => {
    return !(password?.length > 5 && password === confirmPassword);
  }, [password, confirmPassword]);

  const promtError = (message) => {
    var errorDiv = document.querySelector("#error-messages");
    errorDiv.innerHTML = message;
    errorDiv.style.opacity = 1;
  };

  const handleChangePassword = async (_) => {
    try {
      setLoading(true);
      const user = await changePassword({
        variables: {
          email: myProfile?.email,
          oldPassword,
          newPassword: password,
        },
      });
      if (user) {
        setLoading(false);
        toast.success(`Password Updated Successfully!`);
        setIsModal(false);
      }
    } catch (error) {
      setLoading(false);
      promtError(error?.message);
    }
  };

  const handleResetPassword = async () => {
    try {
      const user = await resetPassword({
        variables: {
          password,
          userId: params.user_id,
          token: params.token,
        },
      });
      if (user) {
        toast.success(`Password Updated Successfully!`);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      promtError(error?.message);
    }
  };

  if (loading) return <LoaderLogo />;
  return (
    <div className="login-wrapper">
      <div className="login-inner">
        <div className="login-card">
          <div className="login-header">
            <div className="logo-wrapper">
              <img
                src={`${window.location.origin}/Images/AcceleratedLOGOICON.png`}
                alt=""
              />
            </div>
          </div>
          <form
            className=""
            onSubmit={(_) => {
              _.preventDefault();
              isModal ? handleChangePassword(_) : handleResetPassword(_);
            }}
          >
            {isModal && (
              <div className="fields-wrapper-group">
                <div className="border-bottom-custom">
                  <input
                    type={passwordVisible?.oldPassword ? `password` : `text`}
                    autoComplete="off"
                    className="field-control"
                    onChange={(e) => setOldPassword(e.currentTarget.value)}
                    placeholder="Old Password"
                    name="oldPassword"
                    onFocus={(e) => {
                      e.currentTarget.parentNode.classList.add("focus");
                    }}
                    onBlur={(e) => {
                      e.currentTarget.parentNode.classList.remove("focus");
                    }}
                  />
                  <button
                    type="button"
                    onClick={(_) =>
                      setPasswordVisible({
                        ...passwordVisible,
                        oldPassword: !passwordVisible.oldPassword,
                      })
                    }
                  >
                    {passwordVisible?.oldPassword ? (
                      <EyeInvisible />
                    ) : (
                      <EyeVisible />
                    )}
                  </button>
                </div>
              </div>
            )}
            <div className="fields-wrapper-group">
              <div className="border-bottom-custom">
                <input
                  type={passwordVisible?.newPassword ? `password` : `text`}
                  autoComplete="off"
                  className="field-control"
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  placeholder="New Password"
                  name="newPassword"
                  onFocus={(e) => {
                    e.currentTarget.parentNode.classList.add("focus");
                  }}
                  onBlur={(e) => {
                    e.currentTarget.parentNode.classList.remove("focus");
                  }}
                />
                <button
                  type="button"
                  onClick={(_) =>
                    setPasswordVisible({
                      ...passwordVisible,
                      newPassword: !passwordVisible.newPassword,
                    })
                  }
                >
                  {passwordVisible?.newPassword ? (
                    <EyeInvisible />
                  ) : (
                    <EyeVisible />
                  )}
                </button>
              </div>
            </div>
            <div className="fields-wrapper-group">
              <div className="border-bottom-custom">
                <input
                  type={passwordVisible?.confirmPassword ? `password` : `text`}
                  autoComplete="off"
                  className="field-control"
                  onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  onFocus={(e) => {
                    e.currentTarget.parentNode.classList.add("focus");
                  }}
                  onBlur={(e) => {
                    e.currentTarget.parentNode.classList.remove("focus");
                  }}
                />
                <button
                  type="button"
                  onClick={(_) =>
                    setPasswordVisible({
                      ...passwordVisible,
                      confirmPassword: !passwordVisible.confirmPassword,
                    })
                  }
                >
                  {passwordVisible?.confirmPassword ? (
                    <EyeInvisible />
                  ) : (
                    <EyeVisible />
                  )}
                </button>
              </div>
            </div>
            <div className="fields-wrapper submit-cta">
              <button className="" type="submit" disabled={validateFields()}>
                Change Password
              </button>
            </div>
          </form>
          <button id="error-messages"> Error message space</button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
