import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import "./ExperimentResults.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { abTestDataSample } from "../../../Libs/abTestDataSample";
import { ImpressionsTableGa4 } from "../ImpressionTableGa4";
import MyLoader from "../../MiroComponents/PreLoader";
import { FilterAnalyticsDimensionsAndMetrics } from "../FilterAnalyticsDimensionsAndMetrics";
import moment from "moment";
import { subDays } from "date-fns";
export default function ExperimentResults() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { experimentId } = useParams();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment(new Date(state.livedate)).startOf("day").toDate(),
      endDate: moment(new Date()).endOf("day").toDate(),
      key: "selection",
    },
  ]);
  const [dimensionFilterExpression, setDimensionFilterExpression] = useState(
    []
  );
  const [metricFilterExpression, setMetricFilterExpression] = useState([]);

  const users = ({ events, variationId }) => {
    const data = events.filter(
      (event) =>
        Object.values(event.user?.experimentsBucket).indexOf(variationId) > -1
    );
    return data.length;
  };
  const sessions = ({ events, variationId }) => {
    const data = events.filter(
      (event) =>
        Object.values(event.user?.experimentsBucket).indexOf(variationId) >
          -1 && event.user?.attributes?.visitor_type === "new"
    );
    return data.length;
  };

  const createDimensionFilters = useCallback(
    (value, key, filterType, matchType) => {
      const filterObject = {
        filter: {
          fieldName: key,
        },
      };
      if (filterType === "string") {
        filterObject.filter.stringFilter = {
          matchType: matchType.toUpperCase(),
          value: value?.value,
        };
      }
      const newArr = [...dimensionFilterExpression];
      const fieldName = newArr?.findIndex((f) => f?.filter?.fieldName === key);
      if (fieldName > -1) {
        newArr[fieldName] = filterObject;
      } else {
        newArr.push(filterObject);
      }
      setDimensionFilterExpression(newArr);
    },
    [dimensionFilterExpression]
  );

  const DataImpressionTable = ({ experimentData }) => {
    const variations = experimentData?.data?.test?.variations;
    let data = [];
    for (let i = 0; i < variations.length; i++) {
      data.push({
        controlvariant: variations[i].name,
        totalUsers: users({
          events: experimentData.data.dataEvents,
          variationId: variations[i]._id,
        }),
        totalSessions: sessions({
          events: experimentData.data.dataEvents,
          variationId: variations[i]._id,
        }),
        totalTransactions: 0,
        totalPurchaseRevenue: 0,
        conversionRateUsers: 0,
        conversionRateSessions: 0,
        calculatedAverageRevenuePerUser: 0,
        calculatedAveragePurchaseRevenue: 0,
      });
    }
    const total = data.reduce(
      (acc, item) => {
        return {
          controlvariant: "Total",
          totalUsers: acc.totalUsers + item.totalUsers,
          totalSessions: acc.totalSessions + item.totalSessions,
          totalTransactions: acc.totalTransactions + item.totalTransactions,
          totalPurchaseRevenue:
            acc.totalPurchaseRevenue + item.totalPurchaseRevenue,
          conversionRateUsers:
            acc.conversionRateUsers + item.conversionRateUsers,
          conversionRateSessions:
            acc.conversionRateSessions + item.conversionRateSessions,
          calculatedAverageRevenuePerUser:
            acc.calculatedAverageRevenuePerUser +
            item.calculatedAverageRevenuePerUser,
          calculatedAveragePurchaseRevenue:
            acc.calculatedAveragePurchaseRevenue +
            item.calculatedAveragePurchaseRevenue,
        };
      },
      {
        controlvariant: "Total",
        totalUsers: 0,
        totalSessions: 0,
        totalTransactions: 0,
        totalPurchaseRevenue: 0,
        conversionRateUsers: 0,
        conversionRateSessions: 0,
        calculatedAverageRevenuePerUser: 0,
        calculatedAveragePurchaseRevenue: 0,
      }
    );

    const countingSince = () => {
      let date =
        experimentData?.data?.dataEvents[experimentData?.data?.total - 1]
          ?.createdAt;
      date = new Date(date);

      return date.toDateString();
    };

    return (
      <>
        <ImpressionsTableGa4 type="total" {...total} />
        {data.map((item, index) => (
          <ImpressionsTableGa4 {...item} key={index} />
        ))}

        <div className="tests-canbe-evaluated">
          <span>
            - Test counting since
            <strong className="d-inline-flex ms-1 me-1">
              &nbsp; {countingSince()}
            </strong>
          </span>
        </div>
      </>
    );
  };

  const [data, setData] = useState({
    loading: true,
  });

  const getreport = useCallback(async () => {
    getExperimentResults(dimensionFilterExpression, selectionRange);
  }, [dimensionFilterExpression, selectionRange]);

  const getExperimentResults = useCallback(
    async (dimensionFilterExpression, selectionRange) => {
      const query = {
        testId: experimentId,
      };
      if (selectionRange) {
        query.selectionRange = JSON.stringify(selectionRange);
      }
      if (dimensionFilterExpression) {
        // console.log(dimensionFilterExpression, "DIMENSION FILTER EXPRESSION");
        query.dimensionFilterExpression = JSON.stringify(
          dimensionFilterExpression
        );
      }
      if (experimentId) {
        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_DATA_EVENTS_API_URL
            }/dataevents?${new URLSearchParams(query)}
          `,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          setData({
            loading: false,
            data: await response.json(),
          });
        } catch (error) {
          setData({
            loading: false,
            error,
          });
          // console.log(error, "YESSS");
        }
      }
    },
    [experimentId]
  );

  useEffect(() => {
    getExperimentResults();
    return () => {
      setDimensionFilterExpression([]);
      setMetricFilterExpression([]);
    };
  }, [getExperimentResults]);

  if (data.loading) return <MyLoader />;
  const { test } = data?.data;
  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2>Results</h2>
          <div>
            <button
              className="btn btn-secondary"
              onClick={(_) => {
                navigate(`/ab-testing-dashboard/${test?._id}`);
              }}
            >
              Back to Experiments
            </button>
            <button
              className="btn btn-primary"
              onClick={(_) => {
                getExperimentResults();
              }}
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
      <FilterAnalyticsDimensionsAndMetrics
        client={test?.client}
        viewId={test?.property?.value}
        selectedClient={test?.client?._id}
        platform={`GA4`}
        selectedTest={test}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
        getreport={getreport}
        callBackWhenClose={() => {
          setShowCalendar(false);
        }}
        dimensionFilterExpression={dimensionFilterExpression}
        setDimensionFilterExpression={setDimensionFilterExpression}
        metricFilterExpression={metricFilterExpression}
        setMetricFilterExpression={setMetricFilterExpression}
        createDimensionFilters={createDimensionFilters}
      />
      <DataImpressionTable experimentData={data} />
    </div>
  );
}
