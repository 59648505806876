import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ANNOUNCEMENTS, GETALLROLES, GETUSERS } from "../../../Graphql/Queries";
import InputEmoji from "react-input-emoji";
import useStore from "../../../Store";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  CREATEANNOUNCEMENT,
  DELETEANNOUNCEMENT,
  UPDATEANNOUNCEMENT,
} from "../../../Graphql/Mutations";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import DataTable from "react-data-table-component";
import { VscCheckAll, VscDebugPause } from "react-icons/vsc";
import { useOutletContext } from "react-router-dom";
import { customStyles } from "../../../Libs/TableCustomStyles";

export const Announcement = () => {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const { toggleForm } = useStore();
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState(null);
  const [active, setActive] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [editObj, setEditObj] = useState(null);
  const [usersOption, setUsersOption] = useState([]);
  const [announcementText, setAnnouncementText] = useState("");
  const showForm = useStore((state) => state.showForm);
  const { data, loading, error } = useQuery(GETALLROLES);
  const announcements = useQuery(ANNOUNCEMENTS, {
    variables: {
      page,
      size,
      announcementFilters: [],
    },
  });
  const [getusers] = useLazyQuery(GETUSERS);
  const [createannouncement] = useMutation(CREATEANNOUNCEMENT);
  const [updateannouncement] = useMutation(UPDATEANNOUNCEMENT);
  const [deleteannouncement] = useMutation(DELETEANNOUNCEMENT);
  const allroles = data?.allroles;
  useEffect(() => {
    if (roles.length > 0) {
      (async () => {
        const { data, loading, error } = await getusers({
          variables: {
            roles: roles?.map((_) => _.value),
          },
        });
        setUsersOption(data?.users?.users);
      })();
    }
  }, [roles, setUsersOption]);

  const columns = [
    {
      name: "Title",
      selector: (row) => <div> {row?.title} </div>,
      wrap: true,
      width: "30%",
    },
    {
      name: "Description",
      selector: (row) => <div className="pt-1 pb-1"> {row?.description} </div>,
      wrap: true,
      width: "40%",
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          {" "}
          {row?.active ? (
            <span className="text-success">Active</span>
          ) : (
            <span className="text-danger">Disabled</span>
          )}{" "}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Action",
      wrap: true,
      right: true,
      width: "15%",
      selector: (row) => (
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={(_) => {
              toggleForm(true);
              setEditObj(row);
              setActive(row?.active);
              if (row?.forRoles?.length > 0) {
                setRoles(
                  row?.forRoles?.map((rl) => {
                    return {
                      label: rl?.name,
                      value: rl?._id,
                    };
                  })
                );
              }
              if (row?.forUsers?.length > 0) {
                setUsers(
                  row?.forUsers?.map((usr) => {
                    return {
                      label: usr?.name,
                      value: usr?._id,
                    };
                  })
                );
              }
              setTimeout(function () {
                setAnnouncementText(row?.description);
              }, 100);
            }}
          >
            {" "}
            Edit
          </button>
          <button
            className="ms-1 btn btn-sm btn-danger"
            onClick={async (e) => {
              await deleteannouncement({
                variables: {
                  deleteAnnouncementId: row?._id,
                },
              });
              toast.success("Deleted");
              announcements?.refetch();
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  if (announcements?.loading) return <MyLoader />;
  return (
    <>
      {showForm && (
        <div className="form-wrapper add-sheet-link-modal">
          <div
            className="form-wrapper-inner form-wrapper-inner__updated"
            style={{ overflow: "inherit" }}
          >
            <div className="card" id="formcard">
              <button
                className="close-modal"
                onClick={(_) => {
                  toggleForm(!showForm);
                  setRoles([]);
                  setUsers([]);
                  setAnnouncementText("");
                  setEditObj(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <div className="card-body p-0">
                <h3> {editObj ? "Update" : "Create"} Announcement</h3>

                <form
                  onSubmit={async (_) => {
                    _.preventDefault();
                    const title = _.target.title.value;
                    if (
                      title === "" ||
                      announcementText === "" ||
                      roles?.length === 0
                    ) {
                      toast.error("Please add required data.");
                      return;
                    }
                    const input = {};
                    input["title"] = title;
                    input["description"] = announcementText;
                    input["active"] = active;
                    input["forRoles"] = roles?.map((_) => _.value);
                    input["forUsers"] =
                      users?.length > 0 ? users?.map((_) => _.value) : null;

                    if (editObj) {
                      const { data, loading, error } = await updateannouncement(
                        {
                          variables: {
                            updateAnnouncementId: editObj?._id,
                            input,
                          },
                        }
                      );
                      if (data) {
                        setRoles([]);
                        setUsers([]);
                        setAnnouncementText("");
                        toggleForm(!showForm);
                        setEditObj(null);
                        announcements?.refetch();
                      }
                    } else {
                      const { data, loading, error } = await createannouncement(
                        {
                          variables: {
                            input,
                          },
                        }
                      );
                      if (data) {
                        setRoles([]);
                        setUsers([]);
                        setAnnouncementText("");
                        toggleForm(!showForm);
                        announcements?.refetch();
                      }
                    }
                  }}
                >
                  <div className="fields-wrapper">
                    <label> Title (required)</label>
                    <input
                      className="field-control"
                      name="title"
                      defaultValue={editObj ? editObj?.title : ""}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <InputEmoji
                      borderRadius={0}
                      borderColor="#dfe2e8"
                      value={announcementText}
                      onChange={setAnnouncementText}
                      cleanOnEnter
                      placeholder="Type a message (required)"
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label> Select roles (required)</label>
                    <Select
                      // isDisabled={!client || module === "edit" ? true : false}
                      isMulti={true}
                      onChange={(role) => {
                        setRoles(role);
                      }}
                      value={roles}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      placeholder="Select Roles"
                      options={allroles?.map((rls, i) => {
                        return {
                          label: rls?.name,
                          value: rls?._id,
                        };
                      })}
                    />
                  </div>

                  {usersOption?.length > 0 && (
                    <div className="fields-wrapper">
                      <label> Select Users (optional)</label>
                      <Select
                        isMulti={true}
                        onChange={(users) => {
                          setUsers(users);
                        }}
                        value={users}
                        classNamePrefix="acc-select"
                        placeholder="Select Users"
                        options={usersOption?.map((uo, i) => {
                          return {
                            label: uo?.name,
                            value: uo?._id,
                          };
                        })}
                      />
                    </div>
                  )}

                  <div className="fields-wrapper">
                    <span
                      style={{ cursor: "pointer", color: "#fff" }}
                      onClick={(_) => setActive(!active)}
                    >
                      {active ? (
                        <>
                          <VscCheckAll /> <span> Announcement is active</span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <VscDebugPause /> Announcement is in-active
                        </>
                      )}
                    </span>
                  </div>

                  <div className="fields-wrapper">
                    <button> Submit Announcement</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> Announcements</h2>
            <div>
              {!is_user && (
                <button className="add" onClick={(_) => toggleForm(!showForm)}>
                  Add Announcement
                </button>
              )}
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={announcements?.data?.announcements?.announcements}
          progressPending={announcements?.loading}
          customStyles={customStyles}
          progressComponent={<MyLoader />}
        />

        <Pagination
          page={page}
          size={size}
          setPage={setPage}
          totalcount={announcements?.data?.announcements?.total}
        />
      </div>
    </>
  );
};
