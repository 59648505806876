import { useLazyQuery } from "@apollo/client";
import { subMonths } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  ADMINDASHBOARDSTATS,
  GETCLIENTSPORTFOLIO,
} from "../../../Graphql/Queries";
import { RevenuePerMonthGraph } from "./AdminComponents/RevenuePerMonthGraph";

function Dashboard() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subMonths(new Date(), 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [data, setData] = useState({
    loading: true,
  });
  const [clientPortfolio, setClientPortfolio] = useState({
    loading: true,
  });
  const [getStatsData] = useLazyQuery(ADMINDASHBOARDSTATS);
  const [getclientportfolio] = useLazyQuery(GETCLIENTSPORTFOLIO);

  const setAdminStats = useCallback(async () => {
    const { data, loading, error } = await getStatsData({
      variables: {
        from: selectionRange[0].startDate,
        to: selectionRange[0].endDate,
        client: null,
        active: true,
      },
    });
    setData({
      ...data,
      adminDashboardStatistics: data?.adminDashboardStatistics,
      getAdminStats: data?.getAdminStats,
      revenuePerMonth: data?.revenuePerMonth,
      loading,
      error,
    });
  }, [selectionRange]);

  useEffect(() => {
    setAdminStats();
  }, [setAdminStats]);

  useEffect(() => {
    (async () => {
      const { data, loading, error } = await getclientportfolio();
      setClientPortfolio({
        clientPortfolio: data?.clientPortfolio,
        loading,
        error,
      });
    })();
  }, [getclientportfolio]);

  const PerformanceCard = ({
    changeIn = null,
    title = "",
    value = "0",
    prefix = "",
    postfix = "",
    active = false,
    loading = true,
  }) => {
    if (loading)
      return (
        <div className={`p-card card-padding`}>
          <div className="p-card-inner justify-space-between full-height">
            <div className="p-card-top inline-flex align-center">
              <p> Loading...</p>
            </div>
            <div className="p-card-bottom inline-flex align-center flex-row-reverse justify-end">
              <span className="value"> ..... </span>
            </div>
          </div>
        </div>
      );
    return (
      <div
        className={`p-card card-padding ${active ? `active neon-shadow` : ``}`}
      >
        <div className="p-card-inner justify-space-between full-height">
          <div className="p-card-top inline-flex align-center">
            {/* <span className="change-in">
              <ArrowUpIcon />
            </span> */}

            <p> {title}</p>
          </div>
          <div
            className="p-card-bottom inline-flex align-center flex-row-reverse justify-end"
            title={value}
          >
            {title === "Return on Invest" && value <= 0 ? (
              <>
                <span> - </span> <span> to be updated</span>
              </>
            ) : (
              <>
                <span className="prepost">{postfix}</span>
                <span className="value"> {value} </span>
                <span className="prepost">{prefix}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="conversion-performance section-pd">
        <div>
          <h1>Performance Overview</h1>
          <div className="p-card-flex flex justify-space-between flex-wrap admin-performance-overview-cards">
            <PerformanceCard
              loading={data?.loading || !data?.getAdminStats?.activeClients}
              {...{
                title: "Active Clients",
                value:
                  data?.getAdminStats?.activeClients?.toLocaleString("en-US"),
                symbol: "",
              }}
            />
            <PerformanceCard
              loading={data?.loading || !data?.getAdminStats?.activeClients}
              {...{
                title: "Overall Win-rate",
                value: (
                  (data?.getAdminStats?.winningTests /
                    data?.getAdminStats?.endedTests) *
                  100
                ).toFixed(2),
                postfix: "%",
              }}
            />
            {is_admin && (
              <>
                <PerformanceCard
                  loading={data?.loading || !data?.getAdminStats?.yearlyRevenue}
                  {...{
                    title: "Yearly Revenue",
                    value: data?.getAdminStats?.yearlyRevenue,
                    symbol: "",
                  }}
                />
                <PerformanceCard
                  loading={
                    data?.loading || !data?.getAdminStats?.monthlyRevenue
                  }
                  {...{
                    title: "Monthly Revenue",
                    value: data?.getAdminStats?.monthlyRevenue,
                    symbol: "",
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {(is_admin || is_user) && (
        <div className="section-pd">
          <div className="">
            {/* <div className="">
              <DateRangeSelectorComponent
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                callBackWhenClose={callBackWhenClose}
                className="dark-calendar"
                classNameWrapper="dark-calendar-wrapper"
                color={"#000"}
              />
            </div> */}
            <RevenuePerMonthGraph
              labels={data?.revenuePerMonth?.map(
                (rpm) => `${rpm.month}, ${rpm.year}`
              )}
              data={data?.revenuePerMonth?.map((rpm) => rpm.revenue)}
            />
          </div>
        </div>
      )}

      {is_admin && (
        <div className="conversion-performance section-pd">
          <div>
            <h1>Overall Portfolio Performance </h1>
            <div className="p-card-flex flex flex-wrap admin-performance-overview-cards">
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.successRate
                }
                {...{
                  title: "Success Rate",
                  value: clientPortfolio?.clientPortfolio?.successRate,
                  postfix: "%",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.endedTest
                }
                {...{
                  title: "Ended Tests",
                  value: clientPortfolio?.clientPortfolio?.endedTest,
                  symbol: "",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio
                    ?.averageUpliftSuccessfullTests
                }
                {...{
                  title: "Avg. Uplift on CR of successful Tests",
                  value:
                    clientPortfolio?.clientPortfolio
                      ?.averageUpliftSuccessfullTests,
                  postfix: "%",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.monthlyRevenue
                }
                {...{
                  title: "Monthly Revenue",
                  value: clientPortfolio?.clientPortfolio?.monthlyRevenue,
                  symbol: "",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.yearlyRevenue
                }
                {...{
                  title: "Yearly Revenue",
                  value: clientPortfolio?.clientPortfolio?.yearlyRevenue,
                  symbol: "",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
