import React from "react";

import "./SmartInput.scss";
import { TipIcon } from "../../Shared/Icons";
export const SmartInput = React.forwardRef(
  ({ label, icon, placeholder, tip, ...props }, ref) => {
    return (
      <div className="smart-input">
        <label> {label}</label>
        {icon}
        <div className="smart-input-cover">
          <input placeholder={placeholder} {...props} ref={ref} />
          {tip && (
            <div data-for="soclose" data-tip={tip} className="tip toottip-span">
              <TipIcon />
            </div>
          )}
        </div>
      </div>
    );
  }
);
// Path: src/Components/MiroComponents/SmartInput/SmartInput.jsx
