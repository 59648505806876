import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { returnClientOptions } from "../../Libs/Utilities";
import { GETCLIENTSNAMEANDIDS } from "../../Graphql/Queries";
import { toast } from "react-toastify";
import { LiveTestsReportingGA4 } from "../Pages/LiveTestsReportingGA4";
import MyLoader from "../MiroComponents/PreLoader";
import useStore from "../../Store";
import NoDataFound from "../MiroComponents/NoDataFound/NoDataFound";

export function GoogleAnalyticsReporting() {
  const [searchParams, setSearchParams] = useSearchParams({
    platform: "BQ",
  });
  const selectedClient = useStore((state) => state.selectedClient);
  const viewId = searchParams.get("viewId");
  const platform = searchParams.get("platform");
  const _params = Object.fromEntries([...searchParams]);
  const [myProfile, is_client, is_admin] = useOutletContext();
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);

  const setAllClientsOptions = async () => {
    try {
      const { data } = await getActiveClients({
        variables: {
          clientFilters: [{ active: true }],
        },
      });
      setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
    } catch (error) {
      toast.error("Error fetching clients");
    }
  };

  const tabs = [
    // {
    //   label: "Universal Analytics",
    //   value: "UA",
    // },
    // {
    //   label: "Google Analytics 4",
    //   value: "GA4",
    // },
    {
      label: "BigQuery Store",
      value: "BQ",
    },
  ];

  const setLimitedClients = useCallback(
    (clients) => {
      const _clients = returnClientOptions(clients);
      if (_clients.length === 1) {
        _params.client = JSON.parse(_clients[0].value)?._id;
        const property = JSON.parse(_clients[0].value)?.gaproperties?.find(
          (item) => item.platform === platform
        );
        if (property) {
          _params.viewId = property.viewId;
        }
      }
      setSearchParams(_params);
      setClientSelectorOptions(_clients);
    },
    [viewId]
  );
  useEffect(() => {
    if (is_client) {
      setLimitedClients(myProfile?.client);
    } else {
      setAllClientsOptions();
    }
    if (!searchParams.get("platform")) {
      _params.platform = "BQ";
      setSearchParams(_params);
    }
  }, [myProfile]);

  if (!selectedClient) return <NoDataFound />;
  return (
    <React.Fragment>
      {/* <div className="data-store-tabs">
        {tabs.map((tab) => (
          <button
            style={
              platform === tab.value
                ? {
                    pointerEvents: "none",
                  }
                : {}
            }
            key={tab.value}
            className={platform === tab.value ? "active" : ""}
            onClick={() => {
              const _params = Object.fromEntries([...searchParams]);
              _params.platform = tab.value;
              delete _params.viewId;
              setSearchParams(_params);
            }}
          >
            {tab.label}
          </button>
        ))}
      </div> */}
      <LiveTestsReportingGA4
        platform={platform}
        selectedClient={selectedClient}
        viewId={viewId}
        setSearchParams={setSearchParams}
        _params={_params}
      />
      {/* <LiveTestsReportingRef /> */}
    </React.Fragment>
  );
}
