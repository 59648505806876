import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { VscCheckAll, VscChromeClose, VscRefresh } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import { ARCHIVENOTIFICATION, READNOTIFICATION } from "../../Graphql/Mutations";
import { ALLNOTIFICATIONS, NOTIFICATIONSFORUSER } from "../../Graphql/Queries";
import useStore from "../../Store";
import MyLoader from "../MiroComponents/PreLoader";

export const NotificationSidebar = ({
  is_admin,
  is_client,
  userId,
  notificationBar,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setSize } = useStore();
  const size = useStore((state) => state.size);
  const [total, setTotal] = useState(0);
  const [getNotifications] = useLazyQuery(ALLNOTIFICATIONS);
  const [getNotificationsforUser] = useLazyQuery(NOTIFICATIONSFORUSER);
  const [readNotification] = useMutation(READNOTIFICATION);
  const [archiveNotification] = useMutation(ARCHIVENOTIFICATION);
  const [dataState, setDataState] = useState({
    loading: false,
    error: false,
    message: "Loading..",
    data: null,
  });
  const scrollDiv = useRef();
  const activeItem = location?.state?.activeItem;

  useEffect(() => {
    if (activeItem) {
      const checkSelector = setInterval(() => {
        if (document.getElementById(activeItem)) {
          document.getElementById(activeItem).scrollIntoView({
            // behavior: "smooth",
            // block: "center",
            alignToTop: true,
          });
          clearInterval(checkSelector);
        }
      }, 100);
    }
  }, [activeItem]);

  const refetchNotifications = useCallback(async () => {
    setDataState({
      ...dataState,
      loading: true,
    });
    if (is_admin) {
      const { data, loading, error } = await getNotifications({
        variables: {
          page: 1,
          size,
        },
      });
      if (loading) {
        setDataState({
          loading: true,
          error: false,
          message: "Loading",
          data,
        });
      }
      if (error) {
        setDataState({
          loading: false,
          error: true,
          message: error?.message,
          data,
        });
      }
      if (data) {
        setDataState({
          loading: false,
          error: false,
          message: null,
          data: data?.allNotifications?.notifications,
        });
        setTotal(data?.allNotifications?.total);
        scrollDiv.current?.scrollIntoView({
          // behavior: "smooth",
          // block: "end",
          alignToTop: true,
        });
      }
    } else if (is_client && userId) {
      const { data, loading, error } = await getNotificationsforUser({
        variables: {
          userId,
          page: 1,
          size,
        },
      });
      if (loading) {
        setDataState({
          loading: true,
          error: false,
          message: "Loading",
          data,
        });
      }
      if (error) {
        setDataState({
          loading: false,
          error: true,
          message: error?.message,
          data,
        });
      }
      if (data) {
        setDataState({
          loading: false,
          error: false,
          message: null,
          data: data?.notificationsForUser?.notifications,
        });
        setTotal(data?.notificationsForUser?.total);
        if (scrollDiv?.current) {
          scrollDiv.current.scrollIntoView({
            // behavior: "smooth",
            // block: "end",
            alignToTop: true,
          });
        }
      }
    }
  }, [size]);

  useEffect(() => {
    refetchNotifications();
    if (notificationBar) {
      setInterval(() => {
        refetchNotifications();
      }, 600000);
    }
    return () => {
      setDataState({
        loading: false,
        error: false,
        message: "Loading..",
        data: null,
      });
      setTotal(0);
    };
  }, [refetchNotifications]);

  return (
    <div className="offcanvas-body">
      {dataState?.loading && <MyLoader />}
      {dataState?.error && (
        <div>
          <p> {dataState?.message}</p>
        </div>
      )}
      {!dataState?.loading && dataState?.data && dataState?.data?.length > 0 ? (
        <div className="list-group">
          {dataState?.data?.map((noti, i) => (
            <div className="notification-outer-skin" key={i}>
              {is_client && (
                <span
                  className="closenotification"
                  title="Close"
                  onClick={async (_) => {
                    await archiveNotification({
                      variables: {
                        id: noti?._id,
                      },
                    });
                    refetchNotifications();
                  }}
                >
                  {" "}
                  <VscChromeClose />
                </span>
              )}

              <span
                id={noti?._id}
                className={`list-group-item list-group-item-action border mb-3 p-2 ${
                  is_client && noti?.read ? `bg-light` : `bg-white`
                }`}
                aria-current="true"
                data-active={activeItem === noti?._id ? true : false}
                onClick={async (_) => {
                  if (is_client && !noti?.read) {
                    await readNotification({
                      variables: {
                        notificationId: noti?._id,
                        userId,
                      },
                    });
                  }
                  refetchNotifications();
                  document.querySelector("body").style.overflow = "";
                  document.querySelector("body").style.paddingRight = "";
                  const stateObj = {};
                  stateObj["moduleData"] = JSON.parse(noti?.moduleData);
                  stateObj["activeItem"] = noti?._id;
                  if (location.pathname.indexOf("/notification") <= -1) {
                    stateObj["backUrl"] = window.location.pathname;
                  } else {
                    stateObj["backUrl"] = location?.state?.backUrl;
                  }
                  navigate(`/notification/${noti?.module}`, {
                    state: stateObj,
                  });
                }}
              >
                <div className="d-flex w-100 justify-content-between">
                  <p className="mb-1 fw-bold"> {noti.title}</p>
                  <small>{moment(noti?.createdAt).format("ll")}</small>
                </div>
                <p className="mb-1">{noti?.body}</p>
                <div className="d-flex justify-content-between">
                  <small>
                    <i>Created by </i> {noti?.createdBy?.name}
                  </small>
                  {/* {!is_client && (
                  <small>
                    <i>Client -</i> {clientNames(noti?.clients)}
                  </small>
                )} */}
                </div>
              </span>
              <div />
            </div>
          ))}
          <div ref={scrollDiv}> </div>
          <button
            className="badge bg-light border-1 text-dark border-dark fw-light"
            onClick={(_) => {
              setSize(size + 10);
            }}
            disabled={size >= total ? true : false}
            style={total === 0 ? { display: "none" } : {}}
          >
            {size >= total ? (
              <span className="d-inline-flex align-items-center">
                All Checked &nbsp; <VscCheckAll />{" "}
              </span>
            ) : (
              <span className="d-inline-flex align-items-center">
                Load More &nbsp; <VscRefresh />{" "}
              </span>
            )}
          </button>
        </div>
      ) : (
        <>There are no notifications right now. </>
      )}
    </div>
  );
};
