import { FaExclamationTriangle } from "react-icons/fa";
export function NotAuthorizedInternal() {
  return (
    <div className="no-access-internal">
      <div>
        <FaExclamationTriangle />
        <p> You do not have enough permissions to view this page!</p>
      </div>
    </div>
  );
}
