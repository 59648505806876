import { useLazyQuery } from "@apollo/client";
import { differenceInCalendarDays } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { GETCLIENTTESTS } from "../../Graphql/Queries";
import {
  CrossIcon,
  ArrowDown,
  DownArrow,
  GridIcons,
  ListIcon,
  UpArrow,
  SearchIcon,
} from "../Shared/Icons";
import { ClientReportDetailsandInsights } from "./ClientReportDetailsandInsights";
import { ClientTestsTimeline } from "./ClientTestsTimeline";
import { Currencies } from "../../Libs/Utilities";
import { Scrubber } from "../Shared/Scrubber";

// declared here to save it from re rendering
const currencyWithSymbolsList = Currencies({ currencySymbol: true });

export const ClientTestCardSection = ({ client, viewId }) => {
  const [clientTestsData, setClientTestsData] = useState({
    show: false,
  });
  const _ = require("lodash");
  const [scrubber, showScrubber] = useState(null);
  const tabsRef = useRef();
  const [view, setView] = useState("grid");
  const [page] = useState(1);
  const [size] = useState(3);
  const [status, setStatus] = useState({
    name: "Reports / Ended tests",
    status: "ended",
  });
  const [filterTabs, setFilterTabs] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [nameSearch, setNameSearch] = useState(null);
  const [getclienttests] = useLazyQuery(GETCLIENTTESTS);
  const tabs = [
    {
      name: "Reports / Ended tests",
      status: "ended",
    },
    {
      name: "Drafts / Upcoming tests",
      status: "draft",
    },
    {
      name: "Tests timeline",
      status: null,
    },
  ];
  const countDays = (enddate, startdate) => {
    return (
      Number(differenceInCalendarDays(new Date(enddate), new Date(startdate))) +
      1
    );
  };

  const kpiNumber = (kpi) => {
    return Number(kpi.toString().replaceAll(/%/g, ""));
  };

  const AnalyticsWinnerLoser = ({ platform, report }) => {
    if (platform === "GA4") {
      return (
        <div className="analytics">
          {report?.reportDetails?.calculatedResults?.map((result, i) => (
            <div
              key={i}
              className={`analytics-percentage ${
                kpiNumber(result?.changeInConversionRate) < 0
                  ? `analytics-decrement`
                  : `analytics-increment`
              }`}
            >
              {kpiNumber(result?.changeInConversionRate) > 0 ? (
                <UpArrow />
              ) : (
                <DownArrow />
              )}
              <span className="figure">{result?.changeInConversionRate}</span>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="analytics">
        {report?.reportDetails?.experimentResults?.map((result, i) => (
          <div
            key={i}
            className={`analytics-percentage ${
              kpiNumber(result?.uplift) < 0
                ? `analytics-decrement`
                : `analytics-increment`
            }`}
          >
            {kpiNumber(result?.uplift) > 0 ? <UpArrow /> : <DownArrow />}
            <span className="figure">{result?.uplift}%</span>
          </div>
        ))}
      </div>
    );
  };

  const AdditionalRevenuePerMonth = ({ platform, report, client }) => {
    return (
      <div className="analytics">
        {report?.reportDetails?.experimentResults?.map((result, i) => (
          <div
            key={i}
            className={`analytics-percentage ${
              kpiNumber(result?.additionalrevenuepermonth) < 0
                ? `analytics-decrement`
                : `analytics-increment`
            }`}
          >
            <span className="figure add-rev-amt">
              {kpiNumber(result?.additionalrevenuepermonth) > 0 ? "+" : null}
              {Math.trunc(
                Number(result?.additionalrevenuepermonth)
              ).toLocaleString("de-DE")}
              {currencyWithSymbolsList[client?.currency]}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const showHideCards = (value, target) => {
    var cards = document.querySelectorAll(
      ".product-page-card-list .product-page-card"
    );
    const filters = document.querySelectorAll(".filtertests-tabs-inner button");

    filters.forEach((elm) => {
      elm.classList.remove("bg-light", "text-dark");
      target.classList.add("bg-light", "text-dark");
    });

    cards.forEach((element) => {
      if (!value) {
        element.classList.remove("hide");
      } else {
        if (element.getAttribute("data-conclusion") !== value) {
          element.classList.add("hide");
          element.classList.remove("show");
        } else {
          element.classList.remove("hide");
          element.classList.add("show");
        }
      }
    });
  };

  const toggleView = (view) => {
    setView(view);
  };
  // const getCountWithConclusion = (tests, conclusion) => {
  //   if (tests) {
  //     return tests.filter((test) => test?.report?.conclusion === conclusion)
  //       ?.length;
  //   }
  //   return 0;
  // };
  const getClientTestsData = useCallback(
    async (client, viewId) => {
      if (status?.status) {
        const {
          data: { getClientTests },
          loading,
        } = await getclienttests({
          variables: {
            client,
            viewId,
            status: status?.status,
            nameSearch,
          },
        });
        const _tabs = _.unionBy(getClientTests.tests, "report.conclusion").map(
          ({ report }) => {
            const count = getClientTests.tests.filter(
              (data) => data?.report?.conclusion === report?.conclusion
            )?.length;
            return {
              name: report?.conclusion,
              count,
            };
          }
        );
        setFilterTabs(_tabs);
        setClientTestsData({
          show: !loading,
          loading,
          getClientTests,
        });
      }
    },
    [status, page, size, nameSearch]
  );

  useEffect(() => {
    if (client && viewId) {
      getClientTestsData(client, viewId);
    }
  }, [client, viewId, getClientTestsData]);

  if (!client || !viewId) return ``;
  return (
    <section className="product-page">
      <Scrubber showScrubber={showScrubber} scrubber={scrubber} />
      <div className="product-page-inner">
        <div className="tab-dropdown-mobile">
          <button
            onClick={(e) => {
              const _tabcontent = tabsRef.current;
              if (_tabcontent.classList.contains("open-tabs")) {
                _tabcontent.classList.remove("open-tabs");
              } else {
                _tabcontent.classList.add("open-tabs");
              }
            }}
          >
            <span>{status?.name}</span>
            <span>
              {" "}
              <ArrowDown />{" "}
            </span>
          </button>
        </div>
        <div className="tab-component" ref={tabsRef}>
          {tabs?.map((tab, i) => (
            <div
              onClick={(e) => {
                const _tabcontent = tabsRef.current;
                setStatus(tab);
                if (_tabcontent.classList.contains("open-tabs")) {
                  _tabcontent.classList.remove("open-tabs");
                }
              }}
              key={i}
              className={`tab tab1 ${
                status?.status === tab?.status ? `tab-active` : ``
              }`}
            >
              <span className="tab-title"> {tab?.name}</span>
            </div>
          ))}
        </div>
        {status?.status !== null && (
          <>
            <div className="product-page-search">
              <form
                className="search-wrapper"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (e.target.search.value.trim() !== "") {
                    setNameSearch(e.target.search.value.trim());
                  } else {
                    setNameSearch(null);
                  }
                }}
              >
                <div className="search-icon">
                  <SearchIcon />
                </div>
                <div className="input-search">
                  <input
                    type="text"
                    className="product-search"
                    placeholder="search"
                    name="search"
                  />
                </div>
              </form>
              <div className="product-card-view">
                <div
                  className={`grid-view ${
                    view === "grid" ? `view-active` : ``
                  }`}
                >
                  <span
                    className="grid-icon"
                    onClick={(e) => toggleView("grid")}
                  >
                    <GridIcons />
                  </span>
                </div>
                <div
                  className={`menu-view ${
                    view === "list" ? "view-active" : ``
                  }`}
                >
                  <span
                    className="menu-icon"
                    onClick={(e) => toggleView("list")}
                  >
                    <ListIcon />
                  </span>
                </div>
              </div>
            </div>
            {status?.status === "ended" && (
              <div className="filtertests-tabs">
                <div className="filtertests-tabs-inner">
                  <button
                    data-value={null}
                    onClick={(e) => {
                      showHideCards(null, e.target);
                    }}
                  >
                    All ({clientTestsData?.getClientTests?.tests?.length}){" "}
                  </button>
                  {filterTabs?.map((tab, i) => (
                    <button
                      data-value={tab?.name}
                      key={i}
                      onClick={(e) => {
                        showHideCards(tab?.name, e.target);
                      }}
                    >
                      {tab?.name} ({tab?.count})
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div>
              {view !== "list" && (
                <h3 className="product-page-title">Product page&nbsp;</h3>
              )}
            </div>
            <div
              className={`product-page-card-list ${
                view === "list" ? `product-card-list-view` : ``
              }`}
            >
              <div className="list-view-headings">
                <h2 className="list-view-heading-single">Test</h2>
                <h2 className="list-view-heading-single">Result</h2>
                <h2 className="list-view-heading-single">Segment</h2>
                <h2 className="list-view-heading-single duration-title">
                  Duration
                </h2>
                <h2 className="list-view-heading-single percentage">%</h2>
                <h2
                  className="list-view-heading-single additional-revenue"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  Additional Revenue /
                  <span
                    style={{
                      textTransform: "none",
                    }}
                  >
                    mo
                  </span>
                </h2>
                <h2 className="list-view-heading-single">Details</h2>
              </div>

              {clientTestsData?.getClientTests?.tests?.map((test, i) => (
                <div
                  className="product-page-card"
                  data-conclusion={test?.report?.conclusion}
                  key={i}
                >
                  <div className="page-card-title">{test?.name}</div>
                  <div
                    className="content"
                    onClick={(e) => {
                      showScrubber({
                        control: test?.controlimage,
                        variation: test?.variationimage,
                      });
                    }}
                  >
                    <div className="page-card-img">
                      <img
                        src={`${window.location.origin}/Images/hovertoview.png`}
                        alt="Hover to see images"
                      />
                    </div>
                    <div className="page-card-img-hover">
                      <div className="before-test hover-test-data">
                        <h3 className="test-result-heading">Before:</h3>
                        <img src={test?.controlimage} alt="" />
                      </div>
                      <div className="after-test hover-test-data">
                        <h3 className="test-result-heading">After:</h3>
                        <img src={test?.variationimage} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="page-card-footer updated-page-card-footer">
                    {test?.report && (
                      <>
                        <div className="test-details">
                          <span className="footer-data footer-title tested-by updated-footer-title">
                            Segment:
                          </span>
                          <span className="footer-data footer-title-data test-dev-ops">
                            {test?.report?.segmentName}
                          </span>
                          <span className="footer-data footer-title duration">
                            Duration:
                          </span>
                          <span className="footer-data footer-title-data days">
                            {countDays(test?.report?.to, test?.report?.from)}{" "}
                            days
                          </span>
                        </div>
                        <div className="test-results">
                          <div className="result-group">
                            <span
                              className="footer-title footer-data result"
                              style={
                                test?.report?.segmentName === "no data"
                                  ? { textAlign: "left" }
                                  : {}
                              }
                            >
                              Result:
                            </span>
                            <span
                              className={`footer-title-data footer-data result-data ${
                                test?.report?.conclusion === "winner"
                                  ? `text-success`
                                  : test?.report?.conclusion === "neutral"
                                  ? `text-warning`
                                  : `text-danger`
                              }`}
                            >
                              {test?.report?.conclusion}
                            </span>
                          </div>
                          <AnalyticsWinnerLoser {...test} />
                        </div>
                        {test?.report?.segmentName !== "no data" && (
                          <div className="test-results">
                            <span className="footer-title footer-data additional-revenue">
                              Additional Revenue /month:
                            </span>
                            <AdditionalRevenuePerMonth {...test} />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <a
                    href="#"
                    className={`button product-card-btn ${
                      !test?.report ? `disabled` : ``
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedReport(test?.report?._id);
                    }}
                  >
                    Details
                  </a>
                </div>
              ))}
            </div>
          </>
        )}
        {status?.status === null && (
          <>
            <ClientTestsTimeline client={client} viewId={viewId} />
          </>
        )}
      </div>
      {selectedReport && (
        <div className="report-details-modal">
          <div className="report-details-modal-inner">
            <div className="close-modal">
              <button onClick={(e) => setSelectedReport(null)}>
                {" "}
                <CrossIcon />
              </button>
            </div>
            <div className="report-details-modal-content-wrapper">
              <ClientReportDetailsandInsights selectedReport={selectedReport} />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
