import React from "react";
import { ImpressionsTableGa4 } from "./ImpressionTableGa4";
import { CalculatedKpisGa4 } from "./CalculatedKpisGa4";
import { GraphsGa4 } from "./ClientGraphs/GraphsGa4";
import { ImpressionsTable } from "./ImpressionsTable";
import { ClientCalculatedKpis } from "./ClientCalculatedKpis";
import { ClientTimeSeriesandProbability } from "./ClientGraphs/ClientTimeSeriesandProbability";

export const UaGa4Report = ({ data }) => {
  if (data?.platform === "GA4") {
    return (
      <React.Fragment>
        {data?.reportDetails && (
          <section className="selected-test-content live-reports-content">
            <ImpressionsTableGa4
              type="total"
              {...data?.reportDetails?.totalOfResults}
            />
            {data?.reportDetails?.result?.map((r, i) => (
              <ImpressionsTableGa4 key={i} {...r} />
            ))}
            <div className="experiment-data-inner">
              {data?.reportDetails?.calculatedResults?.map((data, i) => (
                <CalculatedKpisGa4
                  key={i}
                  {...data}
                  // currency={clientCurrency(client?.value)}
                />
              ))}
            </div>
            {data?.reportDetails?.evaluationType !== "manual" && (
              <GraphsGa4
                timeSeriesData={data?.reportDetails?.timeSeriesData}
                calculatedResults={data?.reportDetails?.calculatedResults}
              />
            )}
          </section>
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="impression-table">
        <ImpressionsTable
          name="Total"
          type="total"
          users={data?.reportDetails?.experiments?.reduce(
            (total, item) => total + Number(item?.users),
            0
          )}
          experiments={data?.reportDetails?.experiments?.reduce(
            (total, item) => total.concat(item?.experiments),
            []
          )}
        />

        {data?.reportDetails?.experiments?.map((exp, i) => (
          <ImpressionsTable
            name={exp?.controlvariant}
            type="single"
            users={exp?.users}
            experiments={exp?.experiments}
            key={i}
          />
        ))}
      </div>
      {data?.calculatedResults?.map((data, i) => (
        <ClientCalculatedKpis
          key={i}
          data={data}
          // currency={data}
        />
      ))}
      {data?.reportDetails && (
        <ClientTimeSeriesandProbability
          experiments={data?.reportDetails?.experiments}
          calculatedResults={data?.calculatedResults}
        />
      )}
    </React.Fragment>
  );
};
